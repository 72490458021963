<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M0.292674 1.87906L1.8787 0.293036C2.06623 0.105561 2.32055 0.000244141 2.58572 0.000244141C2.85088 0.000244141 3.1052 0.105561 3.29273 0.293036L4.64575 1.64606L1.6457 4.64611L0.292674 3.29309C0.105199 3.10556 -0.000118732 2.85125 -0.000118732 2.58608C-0.000118732 2.32091 0.105199 2.0666 0.292674 1.87906Z"
        :fill="fill" />
      <path
        id="Vector_2"
        d="M5.35388 2.35425L11 8.00035L12 12.0004L7.99993 11.0004L2.35383 5.3543L5.35388 2.35425Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
