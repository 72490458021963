function constructFeatureURL(feature, layerConfig, scenarioId) {
  const filterByProperty = feature.properties[layerConfig.key_name];
  const name = layerConfig.router_name;
  const appName = layerConfig.app_name;
  if (layerConfig.dependsOnScenario) {
    return `/api/scenarios/${scenarioId}/${name}/${filterByProperty}`;
  } else {
    return `/api/${appName}/${name}/${filterByProperty}`;
  }
}

export default function onLeftClick(
  map,
  e,
  methods,
  click,
  getLayersToClick,
  store,
) {
  // prevent click conditions are set in getter
  if (store.getters['map/preventMapSelect']) return;

  click.resetFeatureState();

  click.iconLayers.forEach((el) => {
    map.U.removeSource(el);
  });

  const features = map.queryRenderedFeatures(e.point, {
    layers: getLayersToClick,
  });

  const feature = features[0];

  methods.featureClicked(feature);
  if (feature == null) {
    methods.showSidebar(false);
  } else {
    const layerConfig = store.state.map.layerConfigs[feature.layer.id];
    if (layerConfig.onClick) layerConfig.onClick(click, feature);

    const idKey = layerConfig.layerConfig.key_name;
    methods.featureIdClicked(feature.properties[idKey]);

    click.setFeatureState([feature], true);
    methods.showSidebar(true);
  }
}
