import { modes } from '@/features/draw/constants';
import {
  blocksWithin,
  buildingsWithin,
  drawAutoHomeConnect,
  drawBoxSelect,
  drawCutLines,
  drawDistributionNetwork,
  drawHeatConnection,
  drawHomeConnection,
} from '@/features/draw/draw';
import { markRaw } from 'vue';
import IconLineNodes from '@/components/storybook/src/assets/icons/custom/energy-grid/IconLineNodes.vue';
import IconHouseLineFilled from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHouseLineFilled.vue';
import IconHeatWavesLineFilled from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHeatWavesLineFilled.vue';
import IconHousePointFilled from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHousePointFilled.vue';

export default class MapActionsData {
  constructor() {
    this.actions = [
      {
        processId: 'heatProject',
        items: [
          [
            {
              icon: markRaw(IconLineNodes),
              drawMode: modes.DRAW_DISTRIBUTION_NETWORK,
              tooltipText: 'Verteilnetzleitung zeichnen',
              tooltipDetail:
                'Ermöglicht das erweitern des Verteilnetzes ' +
                'mittels linker Maustaste auf einen beliebigen Punkt auf der ' +
                'Karte. Für jeden weiteren Klick wird ein Segment erstellt. ' +
                'Wird durch einen Doppelklick / Klick auf dem zuletzt ' +
                'platzierten Segmentendpunkt beendet. *Esc* bricht den ' +
                'Prozess ab',
              offset: [-122, -66],
              iconClasses: 'rotate-45',
              action: drawDistributionNetwork,
              isEditMode: true,
            },
            {
              icon: markRaw(IconHouseLineFilled),
              drawMode: modes.DRAW_CONNECT_HOME,
              tooltipText: 'Hausanschlussleitung zeichnen',
              tooltipDetail:
                'Ermöglicht das Auswählen eines Hausanschlusses ' +
                'mittels linker Maustaste. Dieser kann dann mittels ' +
                'weiteren Klick auf ein Verteilnetzsegment an das ' +
                'Netz angeschlossen werden. *Esc* bricht den Prozess ab',
              offset: [-136, -66],
              action: drawHomeConnection,
              isEditMode: true,
            },
            {
              icon: markRaw(IconHeatWavesLineFilled),
              drawMode: modes.DRAW_CONNECT_HEAT_SOURCE,
              tooltipText: 'Leitung zur Wärmequelle zeichnen',
              tooltipDetail:
                'Ermöglicht das Auswählen einer Wärmequelle ' +
                'mittels linker Maustaste. Diese kann dann mittels ' +
                'weiteren Klick auf ein Verteilnetzsegment an das ' +
                'Netz angeschlossen werden. *Esc* bricht den Prozess ab',
              offset: [-148, -66],
              action: drawHeatConnection,
              isEditMode: true,
            },
          ],
          [
            {
              icon: markRaw(IconHousePointFilled),
              drawMode: null,
              tooltipText: 'Flurstücke automatisch anschließen',
              tooltipDetail:
                'Zeichnet Hausanschlussleitungen für alle aktuell ' +
                'ausgewählten Hausanschlüsse an das nächstgelegene ' +
                'Verteilnetzsegment',
              offset: [-152, -66],
              action: drawAutoHomeConnect,
              isEditMode: true,
            },
          ],
          [
            {
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Auswahlwerkzeug',
              tooltipDetail:
                'Zeichen sie eine Box durch gedrückthalten der ' +
                'linken Maustaste. Bei loslassen der Maustaste werden alle ' +
                'Elemente innerhalb der Box ausgewählt. *Esc* bricht den ' +
                'Prozess ab',
              offset: [-90, -66],
              action: drawBoxSelect,
              isEditMode: true,
            },
            {
              icon: 'content_cut',
              drawMode: modes.DRAW_CUT_LINES,
              tooltipText: 'Leitung trennen',
              tooltipDetail:
                'Ermöglicht das Teilen eines ' +
                'Verteilnetzsegments. Durch Klick der linken Maustaste kann ' +
                'der Start- und Endpunkt der Teilungslinie festgelegt ' +
                'werden. Verteilnetzsegmente, welche diese Linie schneiden, ' +
                'werden am Schnittpunkt getrennt',
              offset: [-81, -66],
              action: drawCutLines,
              isEditMode: true,
            },
          ],
          [
            {
              id: 'heatProject',
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Gebäude auswählen',
              tooltipDetail:
                'Zeichnen Sie ein Polygon durch gedrückthalten der ' +
                'linken Maustaste um alle Gebäude innerhalb dieses Polygons ' +
                'auszuwählen. Loslassen der Maustaste beendet das zeichnen und ' +
                'selektiert die innenliegenden Gebäude. *Esc* bricht den ' +
                'Prozess ab',
              offset: [-100, -66],
              action: buildingsWithin,
              isEditMode: false,
            },
          ],
          [
            {
              custom: true,
              id: 'radiusSettings',
              isEditMode: true,
              tooltipText: 'Einstellung Snapping-Radius',
              tooltipDetail:
                'Geben Sie an, in welchem Radius der Snapping-Effekt ' +
                'eintreten soll',
            },
          ],
        ],
      },
      {
        processId: 'AssignPriorityAreaToBlock',
        items: [
          [
            {
              id: 'AssignPriorityAreaToBlock',
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Blöcke auswählen',
              offset: [-90, -66],
              action: blocksWithin,
            },
          ],
        ],
      },
      {
        processId: 'measure',
        items: [
          [
            {
              custom: true,
              id: 'measure',
            },
          ],
        ],
      },
    ];
    this.showPipeSettings = false;
    this.showRadiusSettings = false;
  }

  togglePipeSettings() {
    this.showPipeSettings = !this.showPipeSettings;
  }

  toggleRadiusSettings() {
    this.showRadiusSettings = !this.showRadiusSettings;
  }
}
