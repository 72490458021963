<template>
  <!-- Measurement Info Panel -->
  <div
    class="relative bg-white standard-elevation-1 gap-3 rounded-tl-none flex flex-col w-[240px] p-3 rounded-[10px] z-10 h-max"
  >
    <!-- Measurement Actions -->
    <div class="flex justify-between items-center">
      <div class="body-2">Messen</div>
      <div class="flex gap-1">
        <ButtonEl
          v-for="mode in measurementModes"
          :key="mode.type"
          size="m"
          :icon="mode.icon"
          color="color2"
          variant="secondary"
          :disabled="measureMode === mode.type"
          @click="setMeasurementMode(mode.type)"
        />
        <ButtonEl
          size="m"
          icon="restart_alt"
          color="color2"
          variant="secondary"
          @click="undoAction"
        />
        <ButtonEl
          size="m"
          icon="close"
          color="color2"
          variant="secondary"
          @click="exitMeasure"
        />
      </div>
    </div>

    <!-- Conditional Display Based on Measurement Values -->
    <div v-if="showMeasurementDetails" class="flex flex-col gap-3">
      <div class="flex gap-2 items-end justify-between">
        <DropDown
          v-model="measurementData[measureMode].selectedValue"
          :label="measurementData[measureMode].label"
          size="m"
          initial-selection
          :items-data="measurementData[measureMode].values"
          class="w-full"
        />
        <IconWrapper
          icon="content_copy"
          class="mb-1 cursor-pointer"
          fill="blue-grey-500"
          @click="copyToClipboard(measurementData[measureMode].selectedValue)"
        />
      </div>
    </div>

    <div v-else class="body-3">
      Entfernungen und Flächen können gemessen werden, indem eine Strecke oder
      Fläche auf der Karte durch Klicken erzeugt wird.
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { getMap } from '@/mapbox/main';
import { useStore } from 'vuex';
import {
  deleteFeatures,
  drawMeasureArea,
  drawMeasurePoints,
  exitDraw,
} from '@/features/draw/draw';
import { useListeners } from '@/components/storybook/src/composables/useListeners';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const store = useStore();
const map = getMap();
const measureMode = ref(null); // Initially set to null to ensure no mode is selected

const props = defineProps({
  drawMode: {
    type: String,
    default: null,
  },
});

const measurementModes = [
  { type: 'length', action: drawMeasurePoints, icon: 'timeline' },
  { type: 'area', action: drawMeasureArea, icon: 'crop_square' },
];

const localDistance = ref(null);
const localArea = ref(null);

const measurementData = reactive({
  length: {
    selectedValue: ref(null),
    label: 'Länge',
    values: computed(() =>
      formatMeasurementValues(localDistance.value, ['m', 'km']),
    ),
  },
  area: {
    selectedValue: ref(null),
    label: 'Fläche',
    values: computed(() =>
      formatMeasurementValues(localArea.value, ['m²', 'km²', 'ha']),
    ),
  },
});

function formatMeasurementValues(value, units) {
  if (!value) return []; // Only compute values if there's a valid number
  return units.map((unit) => {
    let convertedValue;
    switch (unit) {
      case 'm':
      case 'm²':
        convertedValue = value;
        break;
      case 'km':
        convertedValue = value / 1000;
        break;
      case 'km²':
        convertedValue = value / 1000000;
        break;
      case 'ha':
        convertedValue = value / 10000;
        break;
      default:
        convertedValue = value;
    }
    return `${convertedValue.toLocaleString('de-DE', { maximumFractionDigits: 2 })} ${unit}`;
  });
}

function setMeasurementMode(modeType) {
  const mode = measurementModes.find((m) => m.type === modeType);
  if (mode) {
    mode.action();
    measureMode.value = mode.type;
  }
}

useListeners({
  keydown: handleKeyDown,
});

onMounted(() => {
  map.on('measure:update', (e) => {
    localDistance.value = e.distance;
    localArea.value = e.area;
  });
  setToast(
    'Messungen können durch Klick auf das Reset-Symbol oder CTRL+Z zurückgesetzt werden',
  );
});

onUnmounted(() => {
  store.commit('layout/HIDETOAST');
});

const showMeasurementDetails = computed(() => {
  return (
    measureMode.value && measurementData[measureMode.value].values.length > 0
  );
});

function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  store.commit('layout/SHOWTOAST', {
    message: 'Messwert in Zwischenablage kopiert',
    color: 'green-50',
    textColor: 'green-900',
    timeout: 3000,
  });
}

function handleKeyDown(event) {
  if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
    event.preventDefault();
    undoAction();
  }
}

function undoAction() {
  deleteFeatures();
  localDistance.value = null;
  localArea.value = null;
  measureMode.value = null;
}

function exitMeasure() {
  undoAction();
  exitDraw();
  measureMode.value = null;
  store.commit('SET_MAP_ACTIONS', null);
}

function setToast(message) {
  store.commit('layout/SHOWTOAST', {
    color: 'infra-highlight-500',
    message: message,
    timeout: 10000,
  });
}

watch(measureMode, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    deleteFeatures();
    localDistance.value = null;
    localArea.value = null;
    if (newVal === 'area') {
      drawMeasureArea();
      setToast(
        'Flächenmessung aktiviert - Modus durch Klick auf Startpunkt oder ESC beenden',
      );
    } else if (newVal === 'length') {
      drawMeasurePoints();
      setToast(
        'Streckenmessung aktiviert - Modus durch Doppelklick oder ESC beenden',
      );
    }
  }
});
</script>
