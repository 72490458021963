<template>
  <label
    v-if="label"
    class="caption-1 w-fit flex leading-[18px]"
    :for="forInput"
    >{{ label }}</label
  >
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    forInput: {
      type: String,
      default: '',
    },
  },
};
</script>
