<template>
  <div class="map-container h-full">
    <InfoPanel
      v-if="showInfoPanel"
      class="absolute top-[85px] flex items-stretch z-20"
      :class="{
        'left-[400px]':
          getMainMenuShow || (showSearch && getResultsLength !== 0),
        'left-5': !(getMainMenuShow || (showSearch && getResultsLength !== 0)),
      }"
      :feature="clickedFeature"
      :feature-id="clickedFeatureId"
      data-test="info-panel"
    />
    <SearchResults />
    <div id="map" :class="{ 'pointer-events-none': !enableMapClick }"></div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  changeSourceLayer,
  createMap,
  destroyMap,
  setFilter,
  setStyle,
  setVisibility,
  toggleSatellite,
} from '@/mapbox/main';
import { projectNotInYearRange } from '@/features/heat-project/project-not-in-year-range';
import { highlightBuildings } from '@/features/heat-project/highlight-buildings';
import InfoPanel from '@/features/map/info-panel/InfoPanel.vue';
import SearchResults from '@/features/map/control-options/search/SearchResults.vue';
import { getCustomFilterPayload } from '@/features/map/helpers';

const store = useStore();

const props = defineProps({ activeProcessOptions: Object, map: Object });

// Reactive state
const clickedFeature = ref(null);
const clickedFeatureId = ref(null);
const initialMapStyleIsLoaded = ref(false);
const layer = computed(() => store.state.map.layerConfigs);
const enableMapClick = ref(false);

// Computed properties and getters from Vuex
const satelliteIsActive = computed(() => store.state.map.satelliteIsActive);
const showInfoPanel = computed(() => store.state.map.showInfoPanel);
const getMainMenuShow = computed(() => store.getters['getMainMenuShow']);
const showSearch = computed(() => store.getters['search/showSearch']);
const getResults = computed(() => store.getters['search/getResults']);
const getResultsLength = computed(() =>
  getResults.value ? getResults.value.length : 0,
);
const getLayerStates = computed(() => store.getters['map/getLayerStates']);
const getYear = computed(() => store.state.map.scenarioYear);
const getLayersForYearlyFilter = computed(
  () => store.getters['map/getLayersForYearlyFilter'],
);
const getLayersForScenario = computed(
  () => store.getters['map/getLayersForScenario'],
);
const getAppliedFilters = computed(
  () => store.getters['map/getAppliedFilters'],
);
const getYearDependantFilterData = computed(
  () => store.getters['map/getYearDependantFilterData'],
);
const scenarioSelected = computed(() => store.state.scenario.scenarioSelected);

const emit = defineEmits(['applyMunicipalityFilter']);

onMounted(() => {
  createMap(
    store,
    {
      commitYearFilter,
      emitMunicipalityFilter,
      applyFilters,
      featureClicked,
      featureIdClicked,
      showSidebar,
      initialMapStyleLoaded,
      toggleEnableMapClick,
    },
    layer.value,
    getYear.value,
  );
});

onUnmounted(() => {
  store.commit('SET_MAP_ACTIONS', null);
  store.commit('map/MAP_LOADED', false);
  destroyMap();
});

// Methods
function commitYearFilter() {
  store.commit('map/ADD_FILTER', {
    layerKeys: getLayersForYearlyFilter.value,
    filter: {
      id: 'yearFilterFromSlider',
      filter: ['>=', getYear.value, ['get', 'year_of_technology_switch']],
    },
  });
}

function applyFilters(filters) {
  Object.entries(filters).forEach(([key, value]) => {
    setFilter(key, value);
  });
}

function initialMapStyleLoaded(isLoaded) {
  initialMapStyleIsLoaded.value = isLoaded;
}

function showSidebar(show) {
  store.commit('map/SHOW_INFO_PANEL', show);
}

function featureClicked(feature) {
  clickedFeature.value = feature;
}

function featureIdClicked(id) {
  clickedFeatureId.value = id;
}

function emitMunicipalityFilter() {
  emit('applyMunicipalityFilter', localStorage.getItem('municipality'));
}

function toggleEnableMapClick() {
  enableMapClick.value = !enableMapClick.value;
}

function commitCustomFilters() {
  for (const layer of Object.values(getYearDependantFilterData.value)) {
    for (const filterData of Object.values(layer)) {
      const payload = getCustomFilterPayload(getYear.value, filterData);
      store.commit('map/ADD_FILTER', payload);
    }
  }
}

watch(satelliteIsActive, (newValue) => {
  toggleSatellite(newValue);
});

watch(
  [getLayerStates, getYear],
  () => {
    setVisibility(getLayerStates.value);
    setStyle(
      getLayerStates.value,
      layer.value,
      getYear.value,
      scenarioSelected.value.years,
    );
  },
  { deep: true },
);

watch(
  getYear,
  () => {
    commitYearFilter();
    commitCustomFilters();
    projectNotInYearRange(getYear.value);
    highlightBuildings(true);
  },
  { deep: true },
);

watch(
  scenarioSelected,
  (newVal, oldVal) => {
    const scenarioIds = {
      previous: oldVal.scenario_id,
      new: newVal.scenario_id,
    };
    getLayersForScenario.value.forEach((sourceLayer) => {
      changeSourceLayer(sourceLayer, scenarioIds);
    });
    store.commit('map/SHOW_INFO_PANEL', false);
  },
  { deep: true },
);

watch(
  getAppliedFilters,
  (newValue) => {
    if (initialMapStyleIsLoaded.value) {
      applyFilters(newValue);
    }
  },
  { deep: true },
);

watch(
  () => props.activeProcessOptions,
  (val) => {
    if (Object.keys(val).length !== 0) showSidebar(false);
  },
);
</script>
