<template>
  <div class="flex flex-col w-full items-center py-7">
    <h5
      :style="
        props.titleCustomSize
          ? { 'font-size': props.titleCustomSize + 'px' }
          : {}
      "
      class="flex gap-0.5 items-center"
    >
      {{ props.title }}
      <IconWrapper
        v-if="props.downloadFileName"
        icon="download"
        hover="hover:text-success"
        class="cursor-pointer"
        @click="download"
      />
    </h5>
    <v-chart
      ref="chartRef"
      class="h-full w-full"
      :style="{ 'min-height': props.customMinHeight }"
      :option="option"
      autoresize
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { sum } from 'lodash';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  downloadFileName: String,
  titleInside: String,
  title: String,
  chartUnit: {
    type: String,
    default: '',
  },
  chartTop: String,
  titleTop: {
    type: String,
    default: 'center',
  },
  titleInsideLH: {
    type: Number,
    default: 0,
  },
  titleCustomSize: Number,
  multiply: Number,
  customPadding: String,
  customMinHeight: {
    type: String,
    default: '250px',
  },
  customRadius: {
    type: Array,
    default: () => ['35%', '45%'],
  },
});

const chartRef = ref(null);

const filterChartData = computed(() => {
  return props.chartData.filter(
    (item) => item.value !== 0 && item.value !== 0.0,
  );
});

const option = computed(() => {
  const sumValues = sum(filterChartData.value.map((e) => e.value));
  return {
    title: {
      text:
        `{a|${sum(
          filterChartData.value.map((item) =>
            Math.round(
              props.multiply ? item.value * props.multiply : item.value,
            ),
          ),
        ).toLocaleString(navigator.language)}}` +
        `{b| ${props.chartUnit}` +
        '\n' +
        `${props.titleInside}}`,
      left: 'center',
      top: props.titleTop,
      textStyle: {
        color: 'black',
        lineHeight: 15,
        fontFamily: 'Borna, sans-serif',
        rich: {
          a: {
            fontWeight: 'bold',
            fontSize: 12,
            lineHeight: props.titleInsideLH,
          },
          b: {
            fontSize: 12,
            color: 'black',
            padding: [2, 0, 0, 0],
            fontFamily: 'Borna, sans-serif',
            fontWeight: 500,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        radius: props.customRadius,
        top: props.chartTop,
        data: filterChartData.value.map((item) => ({
          ...item,
          emphasis: {
            scale: true,
            itemStyle: {
              color: item.color,
            },
          },
        })),
        label: {
          formatter: (value) => {
            const percentage = (value.value / sumValues) * 100;
            const decimalPlaces = percentage === 0 || percentage >= 10 ? 0 : 1;
            return `{a|${value.name}}\n{b|${percentage.toLocaleString('de-DE', {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            })}%}`;
          },
          rich: {
            a: {
              fontSize: 12,
              lineHeight: 20,
              color: 'black',
              fontWeight: 500,
              fontFamily: 'Inter, sans-serif',
              align: 'center',
              style: 'normal',
            },
            b: {
              fontSize: 12,
              color: 'black',
              fontFamily: 'Inter, sans-serif',
              align: 'center',
              fontWeight: 500,
            },
          },
        },
        color: filterChartData.value.map((item) => item.color),
      },
    ],
  };
});

function download() {
  if (!props.downloadFileName) return;

  const chartImageSrc = chartRef.value.getDataURL({
    pixelRatio: 4, // Increased pixel ratio for higher resolution
    backgroundColor: '#fff',
  });

  const img = new Image();
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set dimensions of the canvas to accommodate larger title and padding
    const titleHeight = 150; // Increased height for title area
    canvas.width = img.width;
    canvas.height = img.height + titleHeight;

    // Draw the title background
    ctx.fillStyle = 'white'; // White background for the title
    ctx.fillRect(0, 0, canvas.width, titleHeight);

    // Set up the title font and style
    const fontSize = 58; // Increased font size for better visibility
    ctx.font = `600 ${fontSize}px 'Borna', sans-serif`;
    // Font weight and family
    ctx.textAlign = 'center';
    ctx.fillStyle = 'black'; // Font color
    ctx.fillText(props.title, canvas.width / 2, 130);

    // Draw the chart image below the title
    ctx.drawImage(img, 0, titleHeight);

    // Create the final image URL and download it
    const finalImgUrl = canvas.toDataURL('image/png');
    const anchor = document.createElement('a');
    anchor.href = finalImgUrl;
    anchor.download = `${props.downloadFileName}.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  img.src = chartImageSrc;
}
</script>
