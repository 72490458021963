import {
  LAYER_KEY__SCENARIO_NETWORK_CLUSTER,
  LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS,
  LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK,
} from '../layers/scenario';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__CUSTOM_AGGREGATION,
  LAYER_KEY__DISTRICT_LINE,
  LAYER_KEY__MUNICIPALITY_LINE,
} from '@/configs/layers/aggregation/aggregation';
import {
  SWITCH_ID__BLOCK,
  SWITCH_ID__COUNTY,
  SWITCH_ID__CUSTOM,
  SWITCH_ID__DISTRICT,
  SWITCH_ID__GOVERNMENT_DISTRICT,
  SWITCH_ID__BKG_RASTER,
  SWITCH_ID__HEAT_DENSITY_STREETS,
  SWITCH_ID__MUNICIPALITY,
  SWITCH_ID__AGGREGATION_LEVEL_CLIENT_DATA,
  SWITCH_ID__FEDERAL_STATE,
} from '@/configs/layer-panel/aggregation';
import {
  SWITCH_ID__HEATNETWORK_DOMINANCE_BLOCK,
  SWITCH_ID__DECENTRALIZED_DOMINANCE_BLOCK,
  SWITCH_ID__HYDROGEN_DOMINANCE_BLOCK,
  SWITCH_ID__SUMMARY_DOMINANCE_BLOCK,
  SWITCH_ID__HEATNETWORK_DOMINANCE_CUSTOM_AGGREGATION,
  SWITCH_ID__DECENTRALIZED_DOMINANCE_CUSTOM_AGGREGATION,
  SWITCH_ID__HYDROGEN_DOMINANCE_CUSTOM_AGGREGATION,
  SWITCH_ID__SUMMARY_DOMINANCE_CUSTOM_AGGREGATION,
  DOMINANCE_SWITCH_IDS,
} from '@/configs/layers/baseData/constants';

export const SWITCH_ID__NETWORK_CLUSTER = 'LC_SZ_NETWORK_CLUSTER';
export const SWITCH_ID__BUILDING_NETWORK = 'LC_SZ_BUILDING_NETWORK';
export const SWITCH_ID__DISTRIBUTION_NETWORK = 'LC_SZ_DISTRIBUTION_NETWORK';

const AGGREGATION_SWITCH_IDS = [
  SWITCH_ID__BLOCK,
  SWITCH_ID__COUNTY,
  SWITCH_ID__CUSTOM,
  SWITCH_ID__DISTRICT,
  SWITCH_ID__GOVERNMENT_DISTRICT,
  SWITCH_ID__BKG_RASTER,
  SWITCH_ID__HEAT_DENSITY_STREETS,
  SWITCH_ID__MUNICIPALITY,
  SWITCH_ID__AGGREGATION_LEVEL_CLIENT_DATA,
  SWITCH_ID__FEDERAL_STATE,
];

const dominanceSwitchesWithout = (filteredSwitchId) => {
  return DOMINANCE_SWITCH_IDS.filter(
    (switchId) => switchId !== filteredSwitchId,
  );
};

export default [
  {
    title: 'Fernwärme',
    items: [
      {
        title: 'Verteilnetz',
        switchId: SWITCH_ID__DISTRIBUTION_NETWORK,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK],
        turnOffSwitches: [
          'LC_POT_NETWORK_CLUSTER',
          'LC_POT_BUILDING_NETWORK',
          'LC_POT_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
      {
        title: 'Hausanschluss',
        switchId: SWITCH_ID__BUILDING_NETWORK,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS],
        turnOffSwitches: [
          'LC_POT_NETWORK_CLUSTER',
          'LC_POT_BUILDING_NETWORK',
          'LC_POT_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
      {
        title: 'Netzcluster',
        switchId: SWITCH_ID__NETWORK_CLUSTER,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__SCENARIO_NETWORK_CLUSTER],
        turnOffSwitches: [
          'LC_POT_NETWORK_CLUSTER',
          'LC_POT_BUILDING_NETWORK',
          'LC_POT_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
    ],
  },
  {
    title: 'Wärmeversorgungsgebiete',
    items: [
      {
        title: 'Wärmenetz (Block)',
        switchId: SWITCH_ID__HEATNETWORK_DOMINANCE_BLOCK,
        itemClass: 'switch',
        onLayers: [
          LAYER_KEY__BLOCK,
          LAYER_KEY__MUNICIPALITY_LINE,
          LAYER_KEY__DISTRICT_LINE,
        ],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(SWITCH_ID__HEATNETWORK_DOMINANCE_BLOCK),
          ...AGGREGATION_SWITCH_IDS,
        ],
        onActivateSetLayout: 'heatnetwork_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Wärmenetz (Benutzerdefiniert)',
        switchId: SWITCH_ID__HEATNETWORK_DOMINANCE_CUSTOM_AGGREGATION,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__CUSTOM_AGGREGATION],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(
            SWITCH_ID__HEATNETWORK_DOMINANCE_CUSTOM_AGGREGATION,
          ),
          ...AGGREGATION_SWITCH_IDS,
        ],
        disabledByEmptyModel: 'CustomAggregation',
        onActivateSetLayout: 'heatnetwork_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Wasserstoffnetz (Block)',
        switchId: SWITCH_ID__HYDROGEN_DOMINANCE_BLOCK,
        itemClass: 'switch',
        onLayers: [
          LAYER_KEY__BLOCK,
          LAYER_KEY__MUNICIPALITY_LINE,
          LAYER_KEY__DISTRICT_LINE,
        ],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(SWITCH_ID__HYDROGEN_DOMINANCE_BLOCK),
          ...AGGREGATION_SWITCH_IDS,
        ],
        onActivateSetLayout: 'hydrogen_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Wasserstoffnetz (Benutzerdefiniert)',
        switchId: SWITCH_ID__HYDROGEN_DOMINANCE_CUSTOM_AGGREGATION,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__CUSTOM_AGGREGATION],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(
            SWITCH_ID__HYDROGEN_DOMINANCE_CUSTOM_AGGREGATION,
          ),
          ...AGGREGATION_SWITCH_IDS,
        ],
        disabledByEmptyModel: 'CustomAggregation',
        onActivateSetLayout: 'hydrogen_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Dezentrale Versorgung (Block)',
        switchId: SWITCH_ID__DECENTRALIZED_DOMINANCE_BLOCK,
        itemClass: 'switch',
        onLayers: [
          LAYER_KEY__BLOCK,
          LAYER_KEY__MUNICIPALITY_LINE,
          LAYER_KEY__DISTRICT_LINE,
        ],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(SWITCH_ID__DECENTRALIZED_DOMINANCE_BLOCK),
          ...AGGREGATION_SWITCH_IDS,
        ],
        onActivateSetLayout: 'decentralized_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Dezentrale Versorgung (Benutzerdefiniert)',
        switchId: SWITCH_ID__DECENTRALIZED_DOMINANCE_CUSTOM_AGGREGATION,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__CUSTOM_AGGREGATION],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(
            SWITCH_ID__DECENTRALIZED_DOMINANCE_CUSTOM_AGGREGATION,
          ),
          ...AGGREGATION_SWITCH_IDS,
        ],
        disabledByEmptyModel: 'CustomAggregation',
        onActivateSetLayout: 'decentralized_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Voraussichtliche Wärmeversorgung (Block)',
        switchId: SWITCH_ID__SUMMARY_DOMINANCE_BLOCK,
        itemClass: 'switch',
        onLayers: [
          LAYER_KEY__BLOCK,
          LAYER_KEY__MUNICIPALITY_LINE,
          LAYER_KEY__DISTRICT_LINE,
        ],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(SWITCH_ID__SUMMARY_DOMINANCE_BLOCK),
          ...AGGREGATION_SWITCH_IDS,
        ],
        onActivateSetLayout: 'summary_dominance',
        active: false,
        radioGroups: [],
      },
      {
        title: 'Voraussichtliche Wärmeversorgung (Benutzerdefiniert)',
        switchId: SWITCH_ID__SUMMARY_DOMINANCE_CUSTOM_AGGREGATION,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__CUSTOM_AGGREGATION],
        turnOffSwitches: [
          ...dominanceSwitchesWithout(
            SWITCH_ID__SUMMARY_DOMINANCE_CUSTOM_AGGREGATION,
          ),
          ...AGGREGATION_SWITCH_IDS,
        ],
        disabledByEmptyModel: 'CustomAggregation',
        onActivateSetLayout: 'summary_dominance',
        active: false,
        radioGroups: [],
      },
    ],
  },
];
