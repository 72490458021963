<template>
  <div class="flex flex-wrap gap-2">
    <div v-for="task in tasks" :key="task.index" class="w-fit">
      <ButtonEl
        v-if="task.fields"
        :name="task.name"
        :text="task.buttonText"
        :variant="activeButtonText === task.buttonText ? 'primary' : 'tertiary'"
        :icon="task.icon"
        icon-type="filled"
        @click="emit('taskClick', task)"
      />
      <ButtonEl
        v-else
        :name="task.name"
        :text="task.buttonText"
        :icon="task.icon"
        :icon-type="task.iconType"
        size="m"
        @click="emit('taskClick', task)"
      />
    </div>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  tasks: {
    type: Array,
    default: () => null,
  },
  activeButtonText: {
    type: String,
  },
});

const emit = defineEmits(['taskClick']);
</script>
