import { axios } from '@/utils/axiosHelper';
import { draw } from '@/features/draw/draw';
import cookie from 'vue-cookies';
import { getMap } from '@/mapbox/main';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import MapboxGlDraw from '@mapbox/mapbox-gl-draw';

export default class MultiselectFeatures {
  /**
   * Base Class for initializing a multi and single select map interaction for
   * a layer.
   * @param  {String} withinUrl defines url to retrieve ids within polygon from backend
   * @param  {String} IdPropertyKey defines id key in feature properties to set feature state
   * @param  {String} layerKey defines mapbox layer id for styling
   * @param  {String} actionType check for this type in draw feature and fire event if it is defined.
   */
  constructor(withinUrl, IdPropertyKey, layerKey, actionType) {
    this.IDs = new Set();
    this.withinUrl = withinUrl;
    this.IdPropertyKey = IdPropertyKey;
    this.layerKey = layerKey;
    this.actionType = actionType;
  }
  /**
   * Retrieve polygon from draw object, add feature IDs to heat project
   * where building is in polygon and remove polygon from draw object
   */
  addFeaturesInPolygon(feature) {
    const polygonDraw = feature.geometry;
    axios({
      url: this.withinUrl,
      method: 'POST',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: { polygon: polygonDraw },
    }).then((resp) => {
      console.log(resp);
      const IdsWithin = new Set(resp.data.ids);
      IdsWithin.forEach((e) => this.IDs.add(e));
      const features = getMap().queryRenderedFeatures({
        layers: [this.layerKey],
      });
      const intersection = features.filter((f) =>
        IdsWithin.has(f.properties[this.IdPropertyKey]),
      );
      getMarked().setFeatureState(intersection, true);
    });
  }
  drawEventListener() {
    const featuresInDraw = draw.getAll().features;
    const lastFeature = featuresInDraw[featuresInDraw.length - 1];
    // Check for type to fire events accordingly
    if (lastFeature.properties.type === this.actionType) {
      this.addFeaturesInPolygon(lastFeature);
    }
  }
  /**
   * add building ID to project or remove if already present
   * @param  {String} PK
   */
  addFeature(PK) {
    if (this.IDs.has(PK)) this.IDs.delete(PK);
    else this.IDs.add(PK);
  }
  /**
   * reset feature Ids and marked features on map
   */
  resetFeatures() {
    this.IDs = new Set();
    getMarked().resetFeatureState();
  }
  /**
   * init eventhooks for map interaction to select buildings.
   */
  initEventHooks() {
    // onContextBuilding needs to be reassigned
    // with bound context in order to take the event hook off
    this.rightClickFeature = this.rightClickFeature.bind(this);
    getMap().on('contextmenu', this.rightClickFeature);

    this.drawEventListener = this.drawEventListener.bind(this);
    getMap().on(MapboxGlDraw.constants.events.CREATE, this.drawEventListener);
  }
  /**
   * remove eventhooks for map interaction
   */
  removeEventHooks() {
    getMap().off('contextmenu', this.rightClickFeature);
    getMap().off(MapboxGlDraw.constants.events.CREATE, this.drawEventListener);
  }

  /**
   * function bound to right-click event.
   * Queries building layer and adds or removes features from building ids.
   * @param  {Object} e
   */
  rightClickFeature(e) {
    const features = getMap().queryRenderedFeatures(e.point, {
      layers: [this.layerKey],
    });
    if (features.length > 0) {
      const feature = features[0];
      this.addFeature(feature.properties[this.IdPropertyKey]);
      getMarked().reverseFeatureState(feature);
    }
  }
  /**
   * Remove map events und reset building feature states
   */
  destroy() {
    if (getMap().isStyleLoaded()) {
      this.removeEventHooks();
      getMarked().resetFeatureState();
    }
  }
}
