<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9287 10.5235C10.5383 6.73304 10.5535 5.07457 11.1471 2.43918C11.2254 2.09126 10.8061 1.85098 10.5578 2.107C10.4512 2.21688 10.3462 2.32752 10.2429 2.43918C8.25137 4.59253 6.92758 7.12956 8.47069 12.0707C9.94003 16.7757 9.36838 18.1782 8.77466 19.6349C8.74875 19.6985 8.72279 19.7622 8.69696 19.8262C8.60545 20.0532 8.82439 20.2471 9.03312 20.1193C9.16519 20.0385 9.31513 19.941 9.47694 19.8262C11.0871 18.6839 13.8729 15.8239 11.9287 10.5235ZM16.8911 10.6008C15.8484 7.75801 15.8598 6.51416 16.305 4.53762C16.3637 4.27668 16.0492 4.09647 15.863 4.28848C15.7831 4.37089 15.7043 4.45387 15.6268 4.53762C14.1332 6.15263 13.1403 8.0554 14.2977 11.7613C14.4411 12.2207 14.5587 12.6381 14.6536 13.0191C15.4233 12.501 16.3197 12.1565 17.2866 12.042C17.207 11.591 17.0783 11.111 16.8911 10.6008ZM6.09918 10.6008C5.05641 7.75801 5.0678 6.51416 5.51299 4.53762C5.57176 4.27668 5.25723 4.09647 5.07102 4.28848C4.9911 4.37089 4.9123 4.45387 4.83485 4.53762C3.34121 6.15263 2.34837 8.0554 3.50569 11.7613C4.6077 15.29 4.17896 16.3419 3.73368 17.4344C3.71424 17.4821 3.69477 17.5299 3.6754 17.5779C3.60677 17.7481 3.77097 17.8935 3.92752 17.7977C4.02657 17.7371 4.13903 17.664 4.26039 17.5779C5.46798 16.7212 7.55737 14.5762 6.09918 10.6008Z"
      :fill="fill" />
    <path
      d="M18.428 18.1624V13C20.9992 13.2129 23.0209 15.3633 23.0209 17.9896C23.0209 20.7566 20.7775 23 18.0104 23C16.7775 23 15.6497 22.5524 14.777 21.8134L18.428 18.1624ZM17.5929 13C15.0217 13.2129 13 15.3633 13 17.9896C13 19.223 13.4476 20.3503 14.1866 21.2234L14.7795 20.6305C14.19 19.9102 13.8351 18.9904 13.8351 17.9896C13.8351 15.828 15.4856 14.0455 17.5929 13.8355V13Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
