export default {
  GET_PLACES: (state, payload) => {
    if (payload.data.features) {
      state.searchResults = payload.data.features;
    } else {
      state.searchResults = payload;
    }
  },
  RESET_PLACES: (state, payload) => {
    state.searchResults = payload;
    state.showSearch = false;
  },
  SET_SEARCHBAR: (state, payload) => {
    state.showSearch = payload;
  },
};
