const municipality_selector = {
  type: 'select-multiple',
  label: 'Gemeinden',
  itemTypeAll: 'Gemeinden',
  value: [],
  name: 'municipalities',
  itemsDataGetter: 'task_manager/getMunicipalities',
  itemFormatter: (municipalities) =>
    municipalities.map((m) => ({
      value: m.municipality_key,
      name: m.name,
      toString: function () {
        return this.value;
      },
    })),
};
const bool_select_objects_without_municipality = {
  type: 'switch',
  label: 'Objekte ohne Gemeinde löschen',
  name: 'select_null',
  value: false,
};
const file_format_selector = {
  type: 'select',
  label: 'Gewünschtes Dateiformat',
  name: 'file_format',
  value: 'csv',
  itemsData: [
    { value: 'csv', name: 'CSV (.csv)' },
    { value: 'xlsx', name: 'Excel Workbook (.xlsx)' },
    { value: 'geojson', name: 'GeoJSON (.geojson)' },
  ],
};
const heat_projects_selector = {
  type: 'select-multiple',
  label: 'Wärmeprojekte',
  itemTypeAll: 'Wärmeprojekte',
  value: [],
  name: 'heatprojects',
  itemsDataGetter: 'heatProject/getHeatProjects',
  itemFormatter: (heatprojects) =>
    heatprojects.map((s) => ({
      value: s.id.toString(),
      name: s.name,
      toString: function () {
        return this.value;
      },
    })),
};
const scenario_selector = {
  type: 'select-multiple',
  label: 'Szenarien',
  itemTypeAll: 'Szenarien',
  value: [],
  name: 'scenarios',
  itemsDataGetter: 'scenario/getScenariosSuccess',
  itemFormatter: (scenarios) =>
    scenarios.map((s) => ({
      value: s.scenario_id.toString(),
      name: s.name,
      toString: function () {
        return this.value;
      },
    })),
};
const scenarios_selector = {
  type: 'select',
  label: 'Szenario',
  name: 'scenario_id',
  itemsDataGetter: 'scenario/getScenariosSuccess',
  itemFormatter: (scenarios) =>
    scenarios.map((s) => ({
      value: s.scenario_id,
      name: s.name,
    })),
  initialValueFunction: (scenarios) => scenarios[0].value,
};
const description_textarea = {
  type: 'textarea',
  name: 'description',
  value: null,
  label: 'Optional: Zusätzliche Infos',
  placeholder: 'Zusätzliche Infos eingeben',
};
const bool_only_new_data = {
  type: 'switch',
  label:
    'Nicht auf Duplikate überprüfen: Führt zu schnellerem Upload, aber die Daten dürfen nicht schon vorher hochgeladen worden sein (auch nicht teilweise).',
  name: 'only_new_data',
  value: false,
};
const epsg_field = {
  type: 'integer',
  name: 'epsg',
  label: 'Input-EPSG (Output ist immer in 4326)',
  value: 4326,
};
const heating_carrier_selector = {
  type: 'select',
  label: 'Heizträger',
  value: 'UNK',
  name: 'heating_carrier',
  itemsData: [
    { value: 'UNK', name: 'Unbekannt (UNK)' },
    { value: 'GAS', name: 'Erdgaskessel (GAS)' },
    { value: 'DIS', name: 'Wärmenetze (DIS)' },
    { value: 'ELE', name: 'Strom Direktheizung (ELE)' },
    { value: 'OIL', name: 'Ölkessel (OIL)' },
    { value: 'PMP', name: 'Wärmepumpe (PMP)' },
    { value: 'H2', name: 'Synthetische Energieträger Heizkessel (H2)' },
    { value: 'COA', name: 'Kohlekessel (COA)' },
    { value: 'SOL', name: 'Solarthermie (SOL)' },
    { value: 'BIO', name: 'Biomassekessel (BIO)' },
    { value: 'NH', name: 'Keine Heizung (NH)' },
  ],
  //   todo get choices directly from HeatingCarrier
};

const tasks = [
  {
    categoryName: 'Upload',
    resultTableHeaders: [
      { title: 'Tasktyp', key: 'type_name' },
      { title: 'Status', key: 'detailed_state' },
      { title: 'Erzeugt', key: 'created_at' },
    ],
    resultUrl: '/api/upload-tasks/',
    taskGroups: [
      {
        title: 'Allgemeine Daten hochladen',
        id: 'general-data-upload',
        withInput: true,
        tasks: [
          {
            url: '/api/buildingmodel/uploadBaseData/',
            buttonText: 'Basisdaten',
            icon: 'file_upload',
            helperText: [
              {
                icon: 'checklist_rtl',
                text: 'Die hochgeladenen Objekte werden erstellt bzw. aktualisiert, wenn sie schon vorhanden sind.',
              },
              {
                icon: 'rule_folder',
                text: 'Dateien können weggelassen werden.',
              },
              {
                icon: 'domain_add',
                text: 'Wenn Objekte in neuen Kommunen / Stadtteilen hochgeladen werden, müssen alle gröberen Ebenen gleichzeitig mit hochgeladen werden (Beispiel: Gebäudeblöcke benötigen Stadtteile und Kommunen, Gebäude benötigen alle anderen Dateien).',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bundesland-Datei',
                name: 'federal_states_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Regierungsbezirksdatei',
                name: 'government_districts_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Landkreis-Datei',
                name: 'counties_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Gemeinde-Datei',
                name: 'municipalities_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Stadtteil-Datei',
                name: 'districts_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Gebäudeblöcke-Datei',
                name: 'buildingblocks_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Flurstücke-Datei',
                name: 'estates_file',
                value: null,
              },
              {
                type: 'file',
                label: 'Gebäude-Datei',
                fileExtension: 'csv',
                name: 'buildings_file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadCustomerData/',
            buttonText: 'Kundendaten',
            icon: 'file_upload',
            name: 'customerData',
            helperText: [
              {
                icon: 'summarize',
                text: 'Datei mit Verbrauchsdaten im CSV-Format (und mit UTF-8 Text-Kodierung) hochladen.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Kundendaten-Datei',
                name: 'file',
                fileExtension: 'csv',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingNetwork/',
            buttonText: 'Bestehendes Wärmenetz',
            icon: 'file_upload',
            name: 'existingNetwork',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "route_length": Länge eines Abschnitts in m (wird berechnet wenn nicht gegeben), exakt auf eine Stelle nach dem Komma.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "type": 0 (Unbekannt, default), 1 (Wasser), 2 (Dampf).',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "commissioning_year": Jahr der Inbetriebnahme, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "temperature": Temperatur in °C, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "total_number_of_connections": Gesamtanzahl der Anschlüsse, ganze Zahl.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bestandsnetz-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingGasNetwork/',
            buttonText: 'Bestehendes Gasnetz',
            icon: 'file_upload',
            name: 'existingGasNetwork',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "route_length": Länge eines Abschnitts in m (wird berechnet wenn nicht gegeben), exakt auf eine Stelle nach dem Komma.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "type": 0 (Unbekannt, default), 1 (Methan), 2 (Wasserstoff), 3 (Biogas), 4 (Methan, wasserstofffähig).',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "commissioning_year": Jahr der Inbetriebnahme, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "total_number_of_connections": Gesamtanzahl der Anschlüsse, ganze Zahl.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bestandsnetz-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingPowerGrid/',
            buttonText: 'Bestehendes Stromnetz',
            icon: 'file_upload',
            name: 'existingPowerGrid',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "route_length": Länge eines Abschnitts in m (wird berechnet wenn nicht gegeben), exakt auf eine Stelle nach dem Komma.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "commissioning_year": Jahr der Inbetriebnahme, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "grid_connection_capacity": Höhe der freien Netzanschlusskapazität in kW, ganze Zahl',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bestandsnetz-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingSewageNetwork/',
            buttonText: 'Bestehendes Abwassernetz',
            icon: 'file_upload',
            name: 'existingSewageNetwork',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "route_length": Länge eines Abschnitts in m (wird berechnet wenn nicht gegeben), exakt auf eine Stelle nach dem Komma.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "commissioning_year": Jahr der Inbetriebnahme, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "nominal_width": Nennweite in m, exakt auf eine Stelle nach dem Komma',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "dry_weather_drainage": Trockenwetterabfluss in m³, Kommazahl',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bestandsnetz-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadScalingLayerAdvanced/',
            buttonText: 'Aggregierte Kundendaten',
            icon: 'file_upload',
            name: 'scalingLayerAdvanced',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die CSV Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Kundendaten-Datei',
                name: 'file',
                fileExtension: 'csv',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadScalingLayer/',
            buttonText: 'Aggregierte Kundendaten (Template)',
            icon: 'file_upload',
            name: 'scalingLayer',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die CSV Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'summarize',
                text: 'Für diese Task muss im Downloadbereich das Template Aggregierte Kundendaten heruntergeladen und an dieser Stelle ausgefüllt hochgeladen werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Kundendaten-Datei',
                name: 'file',
                fileExtension: 'csv',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadCustomAggregation/',
            buttonText: 'Benutzerdefinierte Aggregationsebenen',
            icon: 'file_upload',
            name: 'customAggregation',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Aggregationsebenen-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingHydrogenNetwork/',
            buttonText: 'Wasserstoffnetzgebiete',
            name: 'existingHydrogenNetwork',
            icon: 'file_upload',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Aggregationsebenen-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadExistingNetworkAreas/',
            buttonText: 'Wärmenetzgebiete',
            name: 'existingNetworkAreas',
            icon: 'file_upload',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Aggregationsebenen-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
        ],
      },
      {
        title: 'Potenziale hochladen',
        id: 'potencials-upload',
        withInput: true,
        tasks: [
          {
            url: '/api/potentials/uploadPotentialWasteHeat',
            buttonText: 'Abwärme',
            icon: 'file_upload',
            name: 'wasteHeat',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Abwärme-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialBiomass',
            buttonText: 'Biomasse',
            icon: 'file_upload',
            name: 'biomass',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Biomasse-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialPVFree',
            buttonText: 'PV Freifläche',
            icon: 'file_upload',
            name: 'PVfree',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'PV Freifläche Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialPVRoof',
            buttonText: 'PV Dach',
            icon: 'file_upload',
            name: 'PVroof',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'PV Dach Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialSolarThermalFree',
            buttonText: 'Solarthermie Freifläche',
            icon: 'file_upload',
            name: 'solarthermal_free',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Solarthermie Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialSolarThermalRoof',
            buttonText: 'Solarthermie Dach',
            icon: 'file_upload',
            name: 'solarthermal_roof',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Solarthermie Dach Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/potentials/uploadPotentialWind',
            buttonText: 'Wind',
            icon: 'file_upload',
            name: 'wind',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Wind Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
        ],
      },
      {
        title: 'Expertentasks: Upload',
        id: 'expert-data-upload',
        withInput: true,
        tasks: [
          {
            url: '/api/buildingmodel/uploadFederalStates/',
            buttonText: 'Bundesländer',
            icon: 'file_upload',
            name: 'federalStates',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bundesland-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadGovernmentDistricts/',
            buttonText: 'Regierungsbezirke',
            icon: 'file_upload',
            name: 'governmentDistricts',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Regierungsbezirksdatei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadCounties/',
            buttonText: 'Landkreise',
            icon: 'file_upload',
            name: 'counties',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Landkreis-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadMunicipalities/',
            buttonText: 'Gemeinden',
            icon: 'file_upload',
            name: 'municipalities',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Gemeinde-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadCityDistricts/',
            buttonText: 'Stadtteile',
            icon: 'file_upload',
            name: 'districts',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Stadtteil-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadBuildingBlocks/',
            buttonText: 'Baublöcke',
            icon: 'file_upload',
            name: 'buildingBlocks',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Gebäudeblöcke-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/uploadEstatesData/',
            buttonText: 'Flurstücke',
            icon: 'file_upload',
            name: 'estates',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Flurstücke-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/upload/',
            buttonText: 'Gebäude',
            icon: 'file_upload',
            name: 'buildings',
            helperText: [
              {
                icon: 'summarize',
                text: 'Datei mit Verbrauchsdaten im CSV-Format (und mit UTF-8 Text-Kodierung) hochladen.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Gebäude-Datei',
                fileExtension: 'csv',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadBKGraster/',
            buttonText: 'BKG-Raster',
            icon: 'file_upload',
            name: 'BKGraster',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden und die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'BKG-Raster-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadPostalCodes/',
            buttonText: 'Postleitzahlen',
            icon: 'file_upload',
            name: 'postalCodes',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden und die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Postleitzahlen-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
              bool_only_new_data,
            ],
          },
          {
            url: '/api/buildingmodel/importExistingNetwork/',
            buttonText: 'Bestehendes Wärmenetz',
            icon: 'file_upload',
            name: 'existingNetworkWithoutTasks',
            helperText: [
              {
                icon: 'terminal',
                text: 'Die GeoJSON Datei muss die UTF-8 Textkodierung verwenden und die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'pin_invoke',
                text: 'Die Koordinaten müssen in CRS84-Projektion angegeben werden.',
              },
              {
                icon: 'account_tree',
                text: 'Die Netzwerk Tasks werden nicht getriggert.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "route_length": Länge eines Abschnitts in m (wird berechnet wenn nicht gegeben), exakt auf eine Stelle nach dem Komma.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "type": 0 (Unbekannt, default), 1 (Wasser), 2 (Dampf).',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "commissioning_year": Jahr der Inbetriebnahme, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "temperature": Temperatur in °C, ganze Zahl.',
              },
              {
                icon: 'summarize',
                text: 'Mögliche GeoJSON property: "total_number_of_connections": Gesamtanzahl der Anschlüsse, ganze Zahl.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Bestandsnetz-Datei',
                name: 'file',
                value: null,
              },
              description_textarea,
            ],
          },
          {
            url: '/api/buildingmodel/uploadProviderId/',
            buttonText: 'Versorger-ID',
            icon: 'file_upload',
            name: 'customerData',
            helperText: [
              {
                icon: 'summarize',
                text: 'Datei mit ALKIS-ID und Versorger-ID im CSV-Format (und mit UTF-8 Text-Kodierung) hochladen.',
              },
              {
                icon: 'summarize',
                text: "CSV-Eigenschaft: 'alkis_id' – Offizielle ALKIS-ID des Gebäudes, die bereits im Gebäudemodell hinterlegt ist.",
              },
              {
                icon: 'summarize',
                text: "CSV-Eigenschaft: 'provider_id' – Zugehörige Versorger-ID zur ALKIS-ID. Maximale Länge: 255 Zeichen.",
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Versroger-ID-Datei',
                name: 'file',
                fileExtension: 'csv',
                value: null,
              },
              description_textarea,
            ],
          },
        ],
      },
      {
        title: 'Expertentasks: Verarbeitung',
        withInput: true,
        id: 'expert-data-processing',
        tasks: [
          {
            url: '/api/buildingmodel/enrich/',
            buttonText: 'Gebäude anreichern',
            icon: 'domain_add',
            name: 'enrich-buildings',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            helperText: [
              {
                icon: 'checklist_rtl',
                text: 'Alle Gebäude innerhalb der Gemeinde werden neu angereichert.'
              },
              {
                icon: 'terminal',
                text: 'Dies hat keine Auswirkungen auf Gebäude, die bereits Kundendaten enthalten und/oder in einer Geometrie mit aggregierten Kundendaten liegen.'
              },
              {
                icon: 'terminal',
                text: 'Anschließend muss die Aufgabe "Gebäude kombinieren" gestartet werden, um eine gültige Version der Gebäude zu erstellen.'
              },
              {
                icon: 'terminal',
                text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
              },
            ],
            loading: true,
            fields: [
              municipality_selector,
            ],
          },
          {
            url: '/api/buildingmodel/validate/',
            buttonText: 'Gebäude validieren',
            icon: 'domain_add',
            fields: [
            ],
          },
          {
            url: '/api/buildingmodel/combine/',
            buttonText: 'Gebäude kombinieren',
            icon: 'domain_add',
            name: 'combine-buildings',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Gebäude innerhalb der Gemeinde werden neu kombiniert um eine gültige Version der Gebäude zu erstellen.'
              },
              {
                icon: 'terminal',
                text: 'Das bedeutet, dass neben den Wärmebedarfsschätzungen aus der Anreicherung sowohl vorhandene gebäudespezifische Kundendaten als auch aggregierte Kundendaten verarbeitet werden.'
              },
              {
                icon: 'terminal',
                text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
              },
            ],
            loading: true,
            fields: [
              municipality_selector,
            ],

          },
          {
            url: '/api/buildingmodel/enrichcombine/',
            buttonText: 'Gebäude anreichern / kombinieren',
            icon: 'domain_add',
            name: 'enrich-and-combine-buildings',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Gebäude innerhalb der Gemeinde werden neu angereichert.'
              },
              {
                icon: 'terminal',
                text: 'Anschließend werden die Gebäude neu kombiniert, um eine gültige Version der Gebäude zu erstellen.'
              },
              {
                icon: 'terminal',
                text: 'Das bedeutet, dass neben den Wärmebedarfsschätzungen aus der Anreicherung sowohl vorhandene gebäudespezifische Kundendaten als auch aggregierte Kundendaten verarbeitet werden.'
              },
              {
                icon: 'terminal',
                text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
              },
            ],
            loading: true,
            fields: [
              municipality_selector,
            ],
          },
          {
            url: '/api/buildingmodel/triggerdefaultnetwork/',
            buttonText: 'Default-Netz erstellen',
            icon: 'polyline',
            name: 'trigger-default-network',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            helperText: [
              {
                icon: 'terminal',
                text: 'Das Basisnetz für den Projektplaner und für die Fernwärmepotenzialanalyse wird für alle Gemeinden neu erstellt.'
              },
              {
                icon: 'terminal',
                text: 'Alle bestehenden Wärmenetze werden verarbeitet.'
              },
              {
                icon: 'terminal',
                text: 'Anschließend muss die Aufgabe "Netzcluster erstellen" gestartet werden, damit die Änderungen im Basisnetz berücksichtigt werden.'
              },
              {
                icon: 'terminal',
                text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
              },
            ],
            loading: true,
            fields: [
            ],
          },
          {
            url: '/api/buildingmodel/triggernetworkcluster/',
            buttonText: 'Netzcluster erstellen',
            icon: 'polyline',
            name: 'create-network-cluster',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Fernwärmenetzcluster der Fernwärmepotenzialanalyse in der ausgewählten Gemeinde werden neu gerechnet.'
              },
              {
                icon: 'terminal',
                text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
              },
            ],
            loading: true,
            fields: [
              municipality_selector,
            ],
          },
          {
            url: '/api/buildingmodel/createDefaultScenarioFrame/',
            buttonText: 'Default-Szenariorahmen erstellen',
            icon: 'dashboard_customize',
            iconType: 'filled',
            fields: [
            ],
          },
          {
            url: '/api/integrityCheck/',
            buttonText: 'Integrity Check',
            icon: 'sync_problem',
            helperText: [
              {
                icon: 'terminal',
                text: 'Bei längerer Laufzeit einer Aufgabe kann ein Integritäts-Check durchgeführt werden, um zu überprüfen, ob die Aufgabe noch aktiv ist.'
              },
              {
                icon: 'terminal',
                text: 'Ein Absturz des Workers weist darauf hin, dass die Aufgabe nicht mehr aktiv ist.'
              },
              {
                icon: 'terminal',
                text: 'Falls der Integritäts-Check beim ersten Versuch keine Antwort liefert, bitte erneut ausführen.'
              }
            ],
            fields: [
            ],
          },
        ],
      },
      {
        title: 'Expertentasks: Daten',
        id: 'expert-data-management',
        withInput: false,
        tasks: [
          {
            url: '/api/buildingmodel/recalculateAggregationKeys/',
            buttonText: 'Aggregationsschlüssel neu berechnen',
            icon: 'autorenew',
          },
          {
            url: '/api/buildingmodel/updateBKGRaster/',
            buttonText: 'BKG-Raster aktualisieren',
            icon: 'sync_alt',
            iconType: 'filled',
          },
          {
            url: '/api/buildingmodel/updatePostalCodes/',
            buttonText: 'Postleitzahlen aktualisieren',
            icon: 'sync_alt',
            iconType: 'filled',
          },
          {
            url: '/api/buildingmodel/copyFromPublic/',
            buttonText: 'Höhere Aggregationsebenen aktualisieren',
            icon: 'sync_alt',
            iconType: 'filled',
          },
          {
            url: '/api/basedata/query-basedata-power-plant/',
            buttonText: 'Marktstammdaten aktualisieren',
            icon: 'sync_alt',
            iconType: 'filled',
          },
          {
            url: '/api/tileworker/render_tiles/tilesets-for-all-scenarios/',
            buttonText: 'Tilesets aller Szenarien neu berechnen',
            icon: 'autorenew',
          },
          {
            url: '/api/tileworker/render_tiles/tilesets-for-all-potentials/',
            buttonText: 'Tilesets aller Potenziale neu berechnen',
            icon: 'autorenew',
          },
          {
            url: '/api/potentials/calculate_air_heat_pump_potential/',
            buttonText: 'Wärmepumpenampel erstellen',
            icon: 'traffic',
          },
        ],
      },
      {
        title: 'Expertentasks: Gemeindeweise löschen',
        id: 'expert-delete-select',
        withInput: true,
        tasks: [
          {
            url: '/api/buildingmodel/delete/customerVersions/',
            buttonText: 'Kundendaten löschen',
            icon: 'delete',
            name: 'delete-select-customer-versions',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Die Kundendaten für jedes Gebäude der ausgewählten Gemeinde werden gelöscht.',
              },
              {
                icon: 'terminal',
                text: 'Anschließend werden die Gebäude in der augewählten Gemeinde neu kombiniert, um eine gültige Version der Gebäude zu erstellen.'
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/providerID/',
            buttonText: 'Versorger-ID löschen',
            icon: 'delete',
            name: 'delete-select-providerID',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Die Versorger-ID-Spalte wird für die ausgewählte Gemeinde zurückgesetzt. Zur Sicherstellung der Datenintegrität können die Versorger-IDs vorab über den Pfad Downloads > Gebäude exportiert werden.',
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/customAggregations/',
            buttonText: 'Benutzerdefinierte Aggregationsebenen löschen',
            icon: 'delete',
            name: 'delete-select-custom-aggregations',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Objekte, die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/scalingLayers/',
            buttonText: 'Aggregierte Kundendaten löschen',
            icon: 'delete',
            name: 'delete-select-scalingLayers',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Skalierungs-Objekte, die über die Schnittstelle "aggregierte Kundendaten" hochgeladen wurden und die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
              {
                icon: 'terminal',
                text: 'Anschließend werden die Gebäude in der augewählten Gemeinde neu kombiniert, um eine gültige Version der Gebäude zu erstellen.'
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/heat/',
            buttonText: 'Bestandsnetz löschen',
            icon: 'delete',
            name: 'delete-select-existingNetworkPartHeat',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Objekte, die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
              {
                icon: 'terminal',
                text: 'Dies hat Auswirkungen auf das Basisnetz in der Fernwärmepotenzialanalyse. Deswegen muss die Aufgabe unter "Expertentasks: Verarbeitung > Default-Netz erstellen" neu ausgeführt werden.',
              },
              {
                icon: 'terminal',
                text: 'Anschließend muss die Aufgabe unter "Expertentasks: Verarbeitung > Netzcluster erstellen" für die ausgewählte Gemeinde neu ausgeführt werden.',
              },
              {
              icon: 'terminal',
              text: 'Um die Änderungen auf der Karte sichtbar zu machen, bitte die betroffenen Szenarien der Gemeinden aktualisieren und/oder neu berechnen.'
            }
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/gas/',
            buttonText: 'Bestehendes Gasnetz löschen',
            icon: 'delete',
            name: 'delete-select-existingNetworkPartGas',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Objekte, die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/power/',
            buttonText: 'Bestehendes Stromnetz löschen',
            icon: 'delete',
            name: 'delete-select-existingNetworkPartPower',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Objekte, die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/sewage/',
            buttonText: 'Bestehendes Abwassernetz löschen',
            icon: 'delete',
            name: 'delete-select-existingNetworkPartSewage',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Alle Objekte, die ganz oder teilweise in den gewählten Gemeinden liegen, werden gelöscht (auch wenn sie überwiegend in einer anderen Gemeinde liegen).',
              },
            ],
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/solarthermal_free/',
            buttonText: 'Solarthermie-Freiflächenpotenzial löschen',
            icon: 'delete',
            name: 'delete-select-solarthermal-free',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/solarthermal_roof/',
            buttonText: 'Solarthermie-Dachflächenpotenzial löschen',
            icon: 'delete',
            name: 'delete-select-solarthermal-roof',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/pv_free/',
            buttonText: 'PV-Freiflächenpotenzial löschen',
            icon: 'delete',
            name: 'delete-select-pv-free',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/pv_roof/',
            buttonText: 'PV-Dachflächenpotenzial löschen',
            icon: 'delete',
            name: 'delete-select-pv-roof',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/biomass/',
            buttonText: 'Biomassepotenzial löschen',
            icon: 'delete',
            name: 'delete-select-biomass',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/waste_heat/',
            buttonText: 'Abwärmepotenzial löschen',
            icon: 'delete',
            name: 'delete-select-waste-heat',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
          {
            url: '/api/potentials/delete/wind/',
            buttonText: 'Windpotenzial löschen',
            icon: 'delete',
            name: 'delete-select-wind',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            fields: [
              municipality_selector,
              bool_select_objects_without_municipality,
            ],
          },
        ],
      },
      {
        title: 'Expertentasks: Für Gesamtgebiet löschen',
        id: 'expert-delete-all',
        withInput: false,
        tasks: [
          {
            url: '/api/buildingmodel/delete/customerVersions/',
            buttonText: 'Kundendaten löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/providerID/',
            buttonText: 'Versorger-ID löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/scalingLayers/',
            buttonText: 'Aggregierte Kundendaten löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/customAggregations/',
            buttonText: 'Benutzerdefinierte Aggregationsebene löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/heat/',
            buttonText: 'Bestandsnetz löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/gas/',
            buttonText: 'Bestehendes Gasnetz löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/power/',
            buttonText: 'Bestehendes Stromnetz löschen',
            icon: 'delete',
          },
          {
            url: '/api/buildingmodel/delete/existingNetworkPart/sewage/',
            buttonText: 'Bestehendes Abwassernetz löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/waste_heat/',
            buttonText: 'Abwärmepotenzial löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/biomass/',
            buttonText: 'Biomassepotenzial löschen',
            icon: 'delete',
          },

          {
            url: '/api/potentials/delete/pv_free/',
            buttonText: 'PV-Freiflächenpotenzial löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/pv_roof/',
            buttonText: 'PV-Dachflächenpotenzial löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/solarthermal_free/',
            buttonText: 'Solarthermie-Freiflächenpotenzial löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/solarthermal_roof/',
            buttonText: 'Solarthermie-Dachflächenpotenzial löschen',
            icon: 'delete',
          },
          {
            url: '/api/potentials/delete/wind/',
            buttonText: 'Windpotenzial löschen',
            icon: 'delete',
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Download',
    resultTableHeaders: [
      { title: 'Tasktyp', key: 'type_name' },
      { title: 'Status', key: 'detailed_state' },
      { title: 'Erzeugt', key: 'created_at' },
      { title: 'Aktionen', key: 'downloadable_file.file_url' },
    ],
    resultUrl: '/api/download-tasks/',
    taskGroups: [
      {
        title: 'Parametrierte Downloads',
        id: 'param-download',
        withInput: true,
        tasks: [
          {
            url: '/api/buildingmodel/download/current_buildings/',
            buttonText: 'Gebäude',
            icon: 'file_download',
            name: 'current_buildings',
            valueGetterActions: ['task_manager/GET_MUNICIPALITIES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Lädt die Gebäude herunter, in der Version, die im Tool für Szenarienberechnung etc. verwendet wird. Das heißt Anreicherungen durch das Tool und manuelle Änderungen sind enthalten.',
              },
            ],
            fields: [municipality_selector, file_format_selector],
          },
          {
            url: '/api/buildingmodel/download/template/scaling_layer/',
            buttonText: 'Template Aggregierte Kundendaten',
            icon: 'file_download',
            name: 'template_scalinglayer',
            helperText: [
              {
                icon: 'terminal',
                text: 'Es werden immer die bestehenden aggregierten Kundendaten inkl. der ausgewählten Aggregationsebene bereitgestellt.',
              },
              {
                icon: 'terminal',
                text: 'Füllen Sie die fehlenden Spalten aus und laden Sie die Datei als .csv im Upload Bereich wieder hoch.',
              },
              {
                icon: 'terminal',
                text: 'Wenn bei dem Wärmebedarf oder der Netzabdeckung 0 eingetragen wird oder die Zelle freigelassen wird, wird die Zeile ignoriert',
              },
            ],
            fields: [
              {
                type: 'select',
                label: 'Aggregationsebene',
                name: 'model_field_name',
                value: 'buildingblock',
                itemsData: [
                  { value: 'estate', name: 'Flurstücke' },
                  { value: 'buildingblock', name: 'Gebäudeblöcke' },
                  { value: 'district', name: 'Stadtteile' },
                  { value: 'municipality', name: 'Gemeinden' },
                  { value: 'county', name: 'Landkreise' },
                  { value: 'government_district', name: 'Regierungsbezirke' },
                  { value: 'federal_state', name: 'Bundesländer' },
                  {
                    value: 'custom_aggregation',
                    name: 'Benutzerdefinierte Aggregationsebenen',
                  },
                  {
                    value: 'bkg_raster',
                    name: '100m*100m BKG Rasterdaten',
                  },
                  {
                    value: null,
                    name: 'bestehende aggregierte Kundendaten',
                  },
                ],
              },
              file_format_selector,
            ],
          },
          {
            url: '/api/buildingmodel/download/scenario/',
            buttonText: 'Szenario',
            icon: 'file_download',
            name: 'download_scenario',
            valueGetterActions: ['scenario/GET_SCENARIOS'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Wählen Sie ein Szenario aus, um eine Liste mit den Szenarioergebnissen für jedes Gebäude zu erhalten.',
              },
            ],
            fields: [scenarios_selector, file_format_selector],
          },
          {
            url: '/api/buildingmodel/download/scenario/',
            buttonText: 'Szenarien',
            icon: 'file_download',
            name: 'download_scenarios',
            valueGetterActions: ['scenario/GET_SCENARIOS'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Wählen Sie die Szenarien aus, die Sie herunterladen möchten.',
              },
            ],
            fields: [scenario_selector, file_format_selector],
          },
          {
            url: '/api/buildingmodel/download/heatproject/',
            buttonText: 'Wärmeprojekte',
            icon: 'file_download',
            name: 'download_heatproject',
            valueGetterActions: ['heatProject/GET_HEAT_PROJECT_SELECTABLES'],
            loading: true,
            helperText: [
              {
                icon: 'terminal',
                text: 'Wählen Sie die Wärmeprojekte aus, die Sie herunterladen möchten.',
              },
            ],
            fields: [heat_projects_selector, file_format_selector],
          },
          {
            url: '/api/buildingmodel/download/marktstammdaten/',
            buttonText: 'Marktstammdaten',
            icon: 'file_download',
            name: 'download_marktstammdaten',
            helperText: [
              {
                icon: 'terminal',
                text: 'Wählen Sie ein Dateiformat um die Marktstammdaten herunterzuladen.',
              },
            ],
            fields: [file_format_selector],
          },
        ],
      },
      {
        title: 'Sofort-Downloads',
        id: 'instant-download',
        withInput: false,
        tasks: [
          {
            url: '/api/buildingmodel/download/basedata/',
            buttonText: 'Basisdaten',
            confirmButtonText: 'Basisdaten herunterladen',
            icon: 'file_download',
          },
        ],
      },
      {
        title: 'Datenpunkte clustern',
        id: 'clustering',
        withInput: true,
        tasks: [
          {
            url: '/api/buildingmodel/upload-cluster-datapoints/',
            buttonText: 'Daten hochladen & clustern',
            icon: 'file_download',
            name: 'cluster-datapoints',
            helperText: [
              {
                icon: 'checklist_rtl',
                text: 'Bitte laden Sie hier Zählpunkte (Koordinaten-Punkte) hoch, welche zu Clustern von mind. 5 benachbarten Punkten zusammengefasst werden sollen (UTF-8 CSV).',
              },
              {
                icon: 'checklist_rtl',
                text: 'Wählen Sie das Dateiformat der Ergebnis-Datei aus. In der Excel-Datei ist ein zusätzliches Blatt mit Erklärungen zu Spaltennamen und Abkürzungen vorhanden.',
              },
              {
                icon: 'checklist_rtl',
                text: 'Wenn Sie den Task starten ohne Daten hochzuladen, wird eine leere Ergebnis-Datei erstellt.',
              },
            ],
            fields: [
              {
                type: 'file',
                label: 'Zählpunkte-Datei',
                name: 'file',
                fileExtension: 'csv',
                value: null,
              },
              epsg_field,
              heating_carrier_selector,
              file_format_selector,
              description_textarea,
            ],
          },
        ],
      },
    ],
  },
];

export { tasks };
