<template>
  <VuetifyDataTable
    :headers="dataHeaders"
    :item-data="props.scenarioFrames"
    :show-expand="false"
    :show-pagination="props.scenarioFrames.length > 10"
  >
    <template #date="item">
      {{
        new Date(item.item.created_at).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      }}
    </template>
    <template #isFinished="{ item }">
      <div class="flex justify-center">
        <img :src="getIconSource(item.progress)" alt="progress" />
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex gap-2 justify-end">
        <IconWrapper
          v-if="!(item.is_default && !isSuperUser)"
          :icon="item.progress === 0 ? 'segment' : 'edit'"
          fill="text-title-color1"
          :type="item.progress === 0 ? 'filled' : null"
          class="cursor-pointer opacity-80 hover:opacity-100"
          data-test="edit-scenario-frame"
          @click="editScenario(item.id)"
        />
        <IconWrapper
          v-if="!item.is_default && isSuperUser && item.progress === 1"
          icon="delete_forever"
          fill="text-title-color1"
          type="filled"
          class="cursor-pointer opacity-80 hover:opacity-100"
          @click="
            dialog = true;
            deleteFrame = item;
          "
        />
      </div>
      <VuetifyDialog
        v-if="deleteFrame === item"
        v-model="dialog"
        :title="`Szenariorahmen ${deleteFrame.name} wirklich löschen?`"
      >
        <template #content>
          Dieser Vorgang kann nicht rückgängig gemacht werden.
        </template>
        <template #actions>
          <ButtonEl :primary="false" text="Abbrechen" @click="dialog = false" />
          <ButtonEl
            text="Löschen"
            color="error"
            variant="secondary"
            @click="deleteScenarioFrame(deleteFrame.id)"
          />
        </template>
      </VuetifyDialog>
    </template>
  </VuetifyDataTable>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, nextTick, ref } from 'vue';
import cookie from 'vue-cookies';
import { useStore } from 'vuex';
import CheckGreenIcon from '@/assets/svg/check-green.svg';
import ProgressIcon from '@/assets/svg/progress-icon.svg';
import InfoFilledIcon from '@/assets/svg/info-filled.svg';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const store = useStore();
const emit = defineEmits(['editFrame', 'updateBaseData', 'abort']);
const props = defineProps({ scenarioFrames: Array });

const deleteFrame = ref({});
const dialog = ref(false);
const dataHeaders = [
  {
    title: 'Name',
    key: 'name',
    width: '150px',
  },
  {
    title: 'Erstellt am',
    key: 'date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Status',
    key: 'isFinished',
    align: 'center',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Aktionen',
    key: 'functions',
    align: 'end',
    sortable: false,
    width: '125px',
  },
];

const isSuperUser = computed(() => store.getters['auth/profil'].isSuperUser);

function getIconSource(progress) {
  switch (progress) {
    case 0:
      return CheckGreenIcon;
    case 1:
      return InfoFilledIcon;
    default:
      return ProgressIcon;
  }
}

function editScenario(frameId) {
  emit('editFrame', frameId);
  // scroll to this class .new-scenario-custom
  nextTick(() => {
    document.querySelector('.edit-scenario-custom').scrollIntoView();
  });
}

function deleteScenarioFrame(id) {
  dialog.value = false;
  axios({
    url: `/api/scenarioFrames/${id}`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    emit('updateBaseData');
    emit('abort');
  });
}
</script>
