import { axios } from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';
import { cloneDeep } from 'lodash';
import { fetchData } from './utils';

const VERSION_IMPORTED = 0;
const VERSION_ENRICHED = 1;
const VERSION_MANUAL = 2;
const VERSION_COMBINED = 3;

export class BuildingPanel {
  layerIds = [LAYER_KEY__BUILDINGS];

  combineBuildings(features, options) {
    let imported = features.filter((b) => b.version === VERSION_IMPORTED);
    imported = imported.length === 1 ? imported[0] : null;
    let enriched = features.filter((b) => b.version === VERSION_ENRICHED);
    enriched = enriched.length === 1 ? enriched[0] : null;
    let manual = features.filter((b) => b.version === VERSION_MANUAL);
    manual = manual.length === 1 ? manual[0] : null;
    let combined = features.filter((b) => b.version === VERSION_COMBINED);
    combined = combined.length === 1 ? combined[0] : null;
    const sorted_versions = [imported, enriched, manual];

    const result = {};
    // iteratively overwrite attributes from each version
    for (const key of Object.keys(options)) {
      if (!Object.keys(features[0]).includes(key)) continue;
      for (const buildingVersion of sorted_versions) {
        if (buildingVersion === null) continue;
        // overwrite values if they are set
        if (buildingVersion[key] !== null && buildingVersion[key] !== '') {
          result[key] = {
            value: combined[key],
            sourceVersion: buildingVersion.version,
          };
        }
      }
      // add attributes that are empty in all versions
      if (!(key in result)) {
        result[key] = {
          value: null,
          sourceVersion: null,
        };
      }
    }
    return result;
  }

  async requestFeatureData(feature, scenarioId) {
    const featureId = feature.properties.alkis_id;
    const layerId = feature.layer.id;
    const resp = await fetchData(
      `/api/scenarios/${scenarioId}/buildingAggregate/${featureId}`,
    );
    if (resp === null) return resp;
    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    const mainResult = this.combineBuildings(
      featureData.versions,
      featureData.options,
    );
    const scenarioYears = store.getters['scenario/getScenarioYears'];
    const baseYear = Math.min(...scenarioYears);
    const panelConfig = cloneDeep(
      store.state.map.layerConfigs[featureData.layerId].sidebar,
    );
    if (mainResult.is_construction_year_known.value) {
      panelConfig.chips[0].items[0].items =
        panelConfig.chips[0].items[0].items.filter(
          (item) => item.key !== 'range_construction_year',
        );
    } else {
      panelConfig.chips[0].items[0].items =
        panelConfig.chips[0].items[0].items.filter(
          (item) => item.key !== 'construction_year',
        );
    }
    const panelData = mergeFeatureDataAndLayerConfig(
      mainResult,
      featureData.yearlyresult,
      scenarioYear,
      panelConfig,
      store.state.scenario.scenarioSelected.name,
      featureData.options,
      true,
    );

    panelData.isEditable = scenarioYear === baseYear;

    return panelData;
  }
}
