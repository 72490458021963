import { axios } from '@/utils/axiosHelper';
import store from '@/store/index';

let signal;
let ongoingRequest = 0;
let controller = new AbortController();

export async function fetchData(url) {
  if (ongoingRequest > 0) {
    controller.abort(signal);
    controller = new AbortController();
  }
  signal = controller.signal;
  ongoingRequest += 1;
  try {
    const resp = await axios({
      url,
      method: 'GET',
      signal,
    });
    ongoingRequest -= 1;
    return resp;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      store.commit('map/SHOW_INFO_PANEL', false);
      store.commit('layout/SHOWTOAST', {
        color: 'rgb(var(--spot-error))',
        message:
          'Zum angeforderten Datenpunkt konnte kein Eintrag gefunden werden. Bitte versuchen Sie es nach einem Karten refresh erneut, oder überprüfen Sie ihre Daten.',
        timeout: 3000,
      });
    }
    ongoingRequest -= 1;
    return null;
  }
}
