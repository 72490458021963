<template>
  <div
    class="flex items-center bg-white rounded-[8px] standard-elevation-1 w-max p-2"
  >
    <input
      v-model="location"
      type="text"
      placeholder="Suchbegriff"
      class="bg-infra-$gray-highlight focus:outline-none w-full"
      @input="
        searchLocation();
        searchState(true);
      "
    />
    <div
      v-if="!location.length"
      class="h-6 w-6 pointer-events-none flex items-center justify-center"
    >
      <ZoomIcon />
    </div>
    <div
      v-else
      class="h-6 w-6 cursor-pointer flex items-center justify-center"
      @click="closeSearchbar(false)"
    >
      <VIcon>mdi-close</VIcon>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters } from 'vuex';
import { removeMarker } from '@/mapbox/main';
import ZoomIcon from '@/components/storybook/src/assets/icons/ZoomIcon.vue';

export default {
  name: 'SearchBar',
  components: { ZoomIcon },
  data() {
    return {
      location: '',
    };
  },
  computed: {
    ...mapGetters({
      showSearch: 'search/showSearch',
    }),
  },
  watch: {
    showSearch(val) {
      if (!val) {
        this.location = '';
      }
    },
  },
  methods: {
    searchLocation: debounce(function () {
      this.$store.dispatch('search/FETCH_PLACES', this.location);
    }, 500),
    searchState(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
    },
    closeSearchbar(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
      this.$store.commit('search/RESET_PLACES', null);
      removeMarker();
      this.location = '';
    },
  },
};
</script>
