export const modes = {
  DRAW_FEATURES_WITHIN: 'draw_features_within',
  DRAW_DISTRIBUTION_NETWORK: 'draw_distribution_network',
  DRAW_CONNECT_POINT: 'draw_connect_point',
  DRAW_CUT_LINES: 'draw_cut_lines',
  AUTO_CONNECT_HOMES: 'draw_auto_home_connect_mode',
  DRAW_CONNECT_HOME: 'draw_connect_home',
  DRAW_CONNECT_HEAT_SOURCE: 'draw_connect_heat_source',
  DRAW_MEASURE_POINTS: 'draw_measure_points',
  DRAW_MEASURE_AREA: 'draw_measure_area',
};

export const BUILDINGS_WITHIN_HEATPROJECT = 'buildings_within_heatproject';
export const BLOCKS_WITHIN_PRIORITY_AREA = 'blocks_within_priority_area';
