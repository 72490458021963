export default {
  mapView: {
    mapConstraints: {
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v11',
      pitch: 0,
      maxZoom: 16.9,
      minZoom: 5.3,
      center: [10.451526, 51.165691],
      transformRequest: (url) => {
        if (url.includes('/ts/tiles/')) {
          return {
            url,
            credentials: 'include',
          };
        }
        return {
          url,
        };
      },
    },
  },
};
