<template>
  <ToolTipAdvanced
    v-model="showActionToolTip"
    fixed="all"
    :offset-y="offset[1]"
    :offset-x="offset[0]"
  >
    <template #activator>
      <div
        class="control-icon-custom"
        :class="[{ 'bg-infra-highlight-600': active }, classes]"
        @mouseenter="showActionToolTip = true"
        @mouseleave="clearToolTips"
        @click="$emit('action')"
      >
        <IconWrapper
          :icon="icon"
          :class="iconClasses"
          :fill="active ? 'text-white' : 'text-infra-highlight-800'"
        />
      </div>
    </template>
    <template v-if="toolTip" #content>
      <div class="tool-tip-map-actions">
        <p>{{ toolTip }}</p>
        <p
          v-if="toolTipDetail"
          v-show="showToolTipDetails"
          class="text-xs max-w-60 pt-2"
        >
          {{ toolTipDetail }}
        </p>
      </div>
    </template>
  </ToolTipAdvanced>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import ToolTipAdvanced from '@/components/storybook/src/stories/toolTipAdvanced/ToolTipAdvanced.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const store = useStore();

const emits = defineEmits(['action']);

const props = defineProps({
  active: {
    type: Boolean,
    default: null,
  },
  icon: {
    type: [String, Object],
    default: null,
  },
  classes: {
    type: [String, Object],
    default: null,
  },
  iconClasses: {
    type: [String, Object],
    default: null,
  },
  toolTip: {
    type: String,
    default: null,
  },
  toolTipDetail: {
    type: String,
    default: null,
  },
  offset: {
    type: Array,
    default: () => [-122, -66],
  },
});

const showActionToolTip = ref(false);
const showToolTipDetails = ref(false);
const showToolTipDetailTimerId = ref(null);

const clearToolTips = () => {
  showActionToolTip.value = false;
  showToolTipDetails.value = false;
  if (showToolTipDetailTimerId.value) {
    clearTimeout(showToolTipDetailTimerId.value);
    showToolTipDetailTimerId.value = null;
  }
};

watch(
  () => showActionToolTip.value,
  (val) => {
    if (val) {
      showToolTipDetailTimerId.value = setTimeout(() => {
        showToolTipDetails.value = true;
      }, 1000);
    }
  },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.control-icon-custom {
  @apply hover:bg-infra-highlight-50 cursor-pointer h-10 w-10
  flex justify-center items-center rounded-[8px];
}

.tool-tip-map-actions {
  @extend .body-1, .standard-elevation-1;
  @apply bg-infra-highlight-50 text-infra-highlight-900 px-1.5 py-1
  rounded-[4px];
}
</style>
