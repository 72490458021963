export const LAYER_KEY__INVENTORY_HEAT_NETWORK = 'existing_network';
export const LAYER_KEY__INVENTORY_GAS_NETWORK = 'existing_gas_network';
export const LAYER_KEY__INVENTORY_POWER_GRID = 'existing_power_grid';
export const LAYER_KEY__INVENTORY_SEWAGE_NETWORK = 'existing_sewage_network';

export const LAYER_KEY__EXISTING_NETWORK_AREAS = 'existing_network_areas';
export const LAYER_KEY_EXISTING_HYDROGEN_NETWORK = 'existing_hydrogen_network';

export default {
  [LAYER_KEY__INVENTORY_HEAT_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      sourceLayer: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      layerId: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          '#42005c',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      type: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'type'], 2],
          '#42005c',
          ['==', ['get', 'type'], 1],
          '#ca80ee',
          '#777777',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      commissioningYear: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['typeof', ['get', 'commissioning_year']], 'number'],
          [
            'interpolate',
            ['linear'],
            ['get', 'commissioning_year'],
            1950,
            '#42005c',
            new Date().getFullYear(),
            '#ca80ee',
          ],
          '#777777',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#42005c',
            name: 'Fernwärmenetz',
            icon: 'mdi-minus',
          },
        ],
      },
      type: {
        name: 'Fernwärmenetz nach Art',
        unit: '',
        items: [
          {
            color: '#42005c',
            name: 'Dampf',
            icon: 'mdi-minus',
          },
          {
            color: '#ca80ee',
            name: 'Wasser',
            icon: 'mdi-minus',
          },
          {
            color: '#777777',
            name: 'Unbekannt',
            icon: 'mdi-minus',
          },
        ],
      },
      commissioningYear: {
        type: 'gradient',
        name: 'Fernwärmenetz nach Jahr der Inbetriebnahme',
        unit: '',
        items: {
          min: {
            value: 1950,
            noFormat: true,
            color: 'rgb(66, 0, 92)',
          },
          max: {
            value: new Date().getFullYear(),
            noFormat: true,
            color: 'rgb(202, 128, 238)',
          },
        },
      },
    },
    sidebar: {
      name: 'Abschnitt Bestandswärmenetz',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Abschnittsdaten',
          active: true,
          items: [
            {
              title: 'Allgemein',
              active: true,
              items: [
                {
                  key: 'route_length',
                  unit: 'm',
                },
                {
                  key: 'type',
                  unit: '',
                },
                {
                  key: 'commissioning_year',
                  unit: '',
                },
                {
                  key: 'temperature',
                  unit: '°C',
                },
                {
                  key: 'total_number_of_connections',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__INVENTORY_GAS_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_GAS_NETWORK,
      sourceLayer: LAYER_KEY__INVENTORY_GAS_NETWORK,
      layerId: LAYER_KEY__INVENTORY_GAS_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          '#fc9f23',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      type: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'type'], 4],
          '#8f5404',
          ['==', ['get', 'type'], 3],
          '#c77406',
          ['==', ['get', 'type'], 2],
          '#fc9f23',
          ['==', ['get', 'type'], 1],
          '#fdbd36',
          '#777777',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      commissioningYear: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['typeof', ['get', 'commissioning_year']], 'number'],
          [
            'interpolate',
            ['linear'],
            ['get', 'commissioning_year'],
            1950,
            '#c77406',
            new Date().getFullYear(),
            '#fdbd36',
          ],
          '#777777',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#fc9f23',
            name: 'Gasnetz',
            icon: 'mdi-minus',
          },
        ],
      },
      type: {
        name: 'Gasnetz nach Art',
        unit: '',
        items: [
          {
            color: '#8f5404',
            name: 'Methan (Wasserstofffähig)',
            icon: 'mdi-minus',
          },
          {
            color: '#c77406',
            name: 'Biogas',
            icon: 'mdi-minus',
          },
          {
            color: '#fc9f23',
            name: 'Wasserstoff',
            icon: 'mdi-minus',
          },
          {
            color: '#fdbd36',
            name: 'Methan',
            icon: 'mdi-minus',
          },
          {
            color: '#777777',
            name: 'Unbekannt',
            icon: 'mdi-minus',
          },
        ],
      },
      commissioningYear: {
        type: 'gradient',
        name: 'Gasnetz nach Jahr der Inbetriebnahme',
        unit: '',
        items: {
          min: {
            value: 1950,
            noFormat: true,
            color: '#c77406',
          },
          max: {
            value: new Date().getFullYear(),
            noFormat: true,
            color: '#fdbd36',
          },
        },
      },
    },
    sidebar: {
      name: 'Abschnitt Bestandsgasnetz',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Abschnittsdaten',
          active: true,
          items: [
            {
              title: 'Allgemein',
              active: true,
              items: [
                {
                  key: 'route_length',
                  unit: 'm',
                },
                {
                  key: 'type',
                  unit: '',
                },
                {
                  key: 'commissioning_year',
                  unit: '',
                },
                {
                  key: 'total_number_of_connections',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__INVENTORY_POWER_GRID]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_POWER_GRID,
      sourceLayer: LAYER_KEY__INVENTORY_POWER_GRID,
      layerId: LAYER_KEY__INVENTORY_POWER_GRID,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          '#FDF903',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      commissioningYear: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['typeof', ['get', 'commissioning_year']], 'number'],
          [
            'interpolate',
            ['linear'],
            ['get', 'commissioning_year'],
            1950,
            '#a49500',
            new Date().getFullYear(),
            '#FDF903',
          ],
          '#777777',
        ],
        linewidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#FDF903',
            name: 'Stromnetz',
            icon: 'mdi-minus',
          },
        ],
      },
      commissioningYear: {
        type: 'gradient',
        name: 'Stromnetz nach Jahr der Inbetriebnahme',
        unit: '',
        items: {
          min: {
            value: 1950,
            noFormat: true,
            color: '#FDF903',
          },
          max: {
            value: new Date().getFullYear(),
            noFormat: true,
            color: '#a49500',
          },
        },
      },
    },
    sidebar: {
      name: 'Abschnitt Bestandsstromnetz',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Abschnittsdaten',
          active: true,
          items: [
            {
              title: 'Allgemein',
              active: true,
              items: [
                {
                  key: 'route_length',
                  unit: 'm',
                },
                {
                  key: 'grid_connection_capacity',
                  unit: 'kW',
                },
                {
                  key: 'commissioning_year',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__INVENTORY_SEWAGE_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_SEWAGE_NETWORK,
      sourceLayer: LAYER_KEY__INVENTORY_SEWAGE_NETWORK,
      layerId: LAYER_KEY__INVENTORY_SEWAGE_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          '#865200',
        ],
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
      commissioningYear: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['typeof', ['get', 'commissioning_year']], 'number'],
          [
            'interpolate',
            ['linear'],
            ['get', 'commissioning_year'],
            1950,
            '#865200',
            new Date().getFullYear(),
            '#e5ac5a',
          ],
          '#777777',
        ],
        linewidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#bb7a0d',
            name: 'Abwassernetz',
            icon: 'mdi-minus',
          },
        ],
      },
      commissioningYear: {
        type: 'gradient',
        name: 'Abwassernetz nach Jahr der Inbetriebnahme',
        unit: '',
        items: {
          min: {
            value: 1950,
            noFormat: true,
            color: '#865200',
          },
          max: {
            value: new Date().getFullYear(),
            noFormat: true,
            color: '#e5ac5a',
          },
        },
      },
    },
    sidebar: {
      name: 'Abschnitt Bestandsabwassernetz',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Abschnittsdaten',
          active: true,
          items: [
            {
              title: 'Allgemein',
              active: true,
              items: [
                {
                  key: 'route_length',
                  unit: 'm',
                },
                {
                  key: 'nominal_width',
                  unit: 'm',
                },
                {
                  key: 'commissioning_year',
                  unit: '',
                },
                {
                  key: 'dry_weather_drainage',
                  unit: 'm³',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__EXISTING_NETWORK_AREAS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__EXISTING_NETWORK_AREAS,
      sourceLayer: LAYER_KEY__EXISTING_NETWORK_AREAS,
      layerId: LAYER_KEY__EXISTING_NETWORK_AREAS,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        fillColor: '#B4A5FE',
        fillOpacity: 0.5,
        fillOutlineColor: '#B4A5FE',
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#B4A5FE',
            name: 'Wärmenetzgebiete',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY_EXISTING_HYDROGEN_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      sourceLayer: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      layerId: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        fillColor: '#FFD667',
        fillOpacity: 0.5,
        fillOutlineColor: '#FFD667',
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#FFD667',
            name: 'Wasserstoffnetzgebiete',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
};
