<template>
  <div class="relative overflow-hidden h-screen w-screen">
    <img
      src="@/assets/img/background_error_page.webp"
      alt="electrical towers covered in fog"
      class="absolute w-full h-full object-cover"
    />
    <div
      class="h-full flex flex-col justify-between p-10 xl:p-[40px] 2xl:p-[120px] relative z-50"
    >
      <header>
        <div class="flex justify-between">
          <img src="@/assets/svg/logo_two_lines_blue_grey.svg" alt="logo" />
          <h1 class="text-blue-grey-500 error-page-heading">
            {{ $t('notFound.title') }}
          </h1>
        </div>
        <h2 class="text-blue-grey-500 error-page-sub-heading text-right">
          {{ $t('notFound.subTitle') }}
        </h2>
      </header>
      <main class="text-blue-grey-500">
        <h3 class="font-bold mb-5">
          Häufige Probleme, die zu defekten Links führen können:
        </h3>
        <div class="flex mb-5 explanation-text">
          <article
            v-for="(hint, index) in hints"
            :key="hint.title"
            class="p-5 bg-brown-grey"
            :class="{ 'mr-5': index !== hints.length - 1 }"
          >
            <h4>{{ hint.title }}</h4>
            <p>
              {{ hint.text }}
            </p>
          </article>
        </div>
        <RouterLink to="/">
          <ButtonEl
            variant="secondary"
            text="Zurück zur Startseite"
            icon="arrow_left_alt"
            :full-width="true"
            custom-bg-color="bg-blue-grey-500"
            custom-text-color="text-infra-highlight-25"
            custom-bg-hover-color="hover:bg-blue-grey-700"
            custom-font-size="xl"
          />
        </RouterLink>
      </main>
    </div>
  </div>
</template>

<script setup>
import ButtonEl from '../components/storybook/src/stories/button/ButtonEl.vue';
import arrowLeft from '../components/storybook/src/assets/icons/arrow-left.vue';

const hints = [
  {
    title: 'Unvollständige URLs',
    text: 'Einige E-Mail-Anwendungen beschädigen URLs aufgrund von Zeilenumbrüchen . Möglicherweise müssen Sie eine URL aus Ihrer E-Mail-Anwendung ausschneiden und in Ihren Browser einfügen.',
  },
  {
    title: 'Groß- und Kleinschreibung',
    text: 'Bei URLs muss die Groß-/Kleinschreibung beachtet werden. „File.html“ und „file.html“ werden vom Server nicht als dieselbe Datei betrachtet. Bitte überprüfen Sie, ob die URL korrekt eingegeben wurde.',
  },
  {
    title: 'Schnee von gestern',
    text: 'Die Webseite oder Datei, auf die Sie zugreifen wollten, wurde möglicherweise an einen anderen Speicherort verschoben oder entfernt, da die darin enthaltenen Informationen nicht mehr gültig sind.',
  },
];
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.error-page-heading {
  font-family: 'Borna', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 6rem;

  @include responsive('xl') {
    font-size: 6rem;
  }
}

.error-page-sub-heading {
  font-family: 'Borna', sans-serif;
  font-size: 3rem;
  font-weight: 400;
  line-height: normal;

  @include responsive('xl') {
    font-size: 5rem;
  }

  @include responsive('2xl') {
    font-size: 6rem;
  }
}

.explanation-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;

  @include responsive('xl') {
    font-size: 1.35rem;
    line-height: 1.35;
  }

  @include responsive('2xl') {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
}
</style>
