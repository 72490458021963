import { axios } from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import {
  LAYER_KEY__WIND_POWER_PLANT,
  LAYER_KEY__SOLAR_FREE_POWER_PLANT,
  LAYER_KEY__BIOMASS_POWER_PLANT,
  LAYER_KEY__GEOTHERMAL_POWER_PLANT,
} from '@/configs/layers/constants';
import { fetchData } from './utils';

const urlMapper = (featureId, layerKey) => {
  const urlMap = {
    [LAYER_KEY__WIND_POWER_PLANT]: `/api/basedata/BasedataWindPowerPlant/${featureId}`,
    [LAYER_KEY__SOLAR_FREE_POWER_PLANT]: `/api/basedata/BasedataSolarFreePowerPlant/${featureId}`,
    [LAYER_KEY__BIOMASS_POWER_PLANT]: `/api/basedata/BasedataBiomassPowerPlant/${featureId}`,
    [LAYER_KEY__GEOTHERMAL_POWER_PLANT]: `/api/basedata/BasedataGeothermalPowerPlant/${featureId}`,
  };
  return urlMap[layerKey];
};

export class BaseDataPanel {
  layerIds = [
    LAYER_KEY__WIND_POWER_PLANT,
    LAYER_KEY__SOLAR_FREE_POWER_PLANT,
    LAYER_KEY__BIOMASS_POWER_PLANT,
    LAYER_KEY__GEOTHERMAL_POWER_PLANT,
  ];

  async requestFeatureData(feature, _) {
    const id = feature.properties.unit_id;
    const layerId = feature.layer.id;
    const url = urlMapper(id, layerId);

    const resp = await fetchData(url);
    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    const mainResult = featureData.result;
    const yearlyResult = [];
    const panelConfig =
      store.state.map.layerConfigs[featureData.layerId].sidebar;
    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      null,
      featureData.options,
    );
  }
}
