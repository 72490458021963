<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3491_7752)">
      <path
        d="M15 3H12V1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0H5C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V3H1C0.734784 3 0.48043 3.10536 0.292893 3.29289C0.105357 3.48043 0 3.73478 0 4C0 4.26522 0.105357 4.51957 0.292893 4.70711C0.48043 4.89464 0.734784 5 1 5H15C15.2652 5 15.5196 4.89464 15.7071 4.70711C15.8946 4.51957 16 4.26522 16 4C16 3.73478 15.8946 3.48043 15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3ZM6 3V2H10V3H6Z"
        :fill="fill" />
      <path
        d="M2.55493 7L3.00493 15.1C3.02981 15.3475 3.14605 15.5769 3.33096 15.7433C3.51586 15.9097 3.75616 16.0012 4.00493 16H12.0049C12.2528 16 12.4919 15.9079 12.6758 15.7416C12.8596 15.5753 12.9751 15.3467 12.9999 15.1L13.4499 7H2.55493Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_3491_7752">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
