<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1667 4V3.5H10.6667H4H3.5V4V5.33333V5.83333H4H10.6667H11.1667V5.33333V4ZM6.16667 8V8.5H6.66667H8H8.5V8V6.66667V6.16667H8H6.66667H6.16667V6.66667V8ZM8.5 9.33333V8.83333H8H6.66667H6.16667V9.33333V10.6667V11.1667H6.66667H8H8.5V10.6667V9.33333ZM5.33333 8.5H5.83333V8V6.66667V6.16667H5.33333H4H3.5V6.66667V8V8.5H4H5.33333ZM9.33333 6.16667H8.83333V6.66667V8V8.5H9.33333H10.6667H11.1667V8V6.66667V6.16667H10.6667H9.33333ZM4 8.83333H3.5V9.33333V10.6667V11.1667H4H5.33333H5.83333V10.6667V9.33333V8.83333H5.33333H4ZM8.83333 10.6667V11.1667H9.33333H10.6667H11.1667V10.6667V9.33333V8.83333H10.6667H9.33333H8.83333V9.33333V10.6667ZM8.83333 1.33333V1.5H8.5V1.33333V0.833333H8H1.33333H0.833333V1.33333V8V8.5H1.33333H1.5V8.83333H1.33333C0.876143 8.83333 0.5 8.45719 0.5 8V1.33333C0.5 0.876142 0.876142 0.5 1.33333 0.5H8C8.45719 0.5 8.83333 0.876143 8.83333 1.33333ZM4 3.16667H10.6667C11.1239 3.16667 11.5 3.54281 11.5 4V10.6667C11.5 11.1239 11.1239 11.5 10.6667 11.5H4C3.54281 11.5 3.16667 11.1239 3.16667 10.6667V4C3.16667 3.54281 3.54281 3.16667 4 3.16667Z"
      fill="none"
      :stroke="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: String,
      default: '12',
    },
  },
};
</script>
