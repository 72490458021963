<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6619 4.44881C10.2494 6.14188 10.2388 7.20735 11.2051 9.64247C12.5563 13.0477 10.6202 14.885 9.50123 15.6189C9.38877 15.6927 9.28457 15.7553 9.19279 15.8072C9.04773 15.8893 8.89557 15.7647 8.95917 15.6189C9.38839 14.6348 9.86763 13.7911 8.80192 10.6365C7.72952 7.46209 8.6495 5.8322 10.0335 4.44881C10.1053 4.37707 10.1783 4.306 10.2524 4.23541C10.4249 4.07093 10.7164 4.2253 10.6619 4.44881Z"
      :fill="fill" />
    <path
      d="M14.2464 5.24184C13.937 6.69304 13.9291 7.6063 14.6538 9.69354C15.6672 12.6123 14.2151 14.1872 13.3759 14.8162C13.2916 14.8794 13.2134 14.9331 13.1446 14.9776C13.0358 15.0479 12.9217 14.9412 12.9694 14.8162C13.2913 13.9727 13.6507 13.2495 12.8514 10.5455C12.0471 7.82465 12.7371 6.4276 13.7751 5.24184C13.829 5.18035 13.8837 5.11943 13.9393 5.05892C14.0687 4.91794 14.2873 5.05025 14.2464 5.24184Z"
      :fill="fill" />
    <path
      d="M6.74643 5.24184C6.43705 6.69304 6.42913 7.6063 7.15381 9.69354C8.1672 12.6123 6.71515 14.1872 5.87592 14.8162C5.79158 14.8794 5.71343 14.9331 5.64459 14.9776C5.53579 15.0479 5.42168 14.9412 5.46938 14.8162C5.79129 13.9727 6.15072 13.2495 5.35144 10.5455C4.54714 7.82465 5.23713 6.4276 6.27515 5.24184C6.32898 5.18035 6.38374 5.11943 6.43928 5.05892C6.56869 4.91794 6.78727 5.05025 6.74643 5.24184Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5ZM4.16667 3.33333C3.70643 3.33333 3.33333 3.70643 3.33333 4.16667V15.8333C3.33333 16.2936 3.70643 16.6667 4.16667 16.6667H15.8333C16.2936 16.6667 16.6667 16.2936 16.6667 15.8333V4.16667C16.6667 3.70643 16.2936 3.33333 15.8333 3.33333H4.16667Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: String,
      default: '20',
    },
  },
};
</script>
