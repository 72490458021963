<template>
  <div class="flex items-center">
    <img
      src="../../assets/svg/scenarios-icon.svg"
      class="mr-6 w-[64px]"
      alt="logo"
    />
    <h2>Szenarien</h2>
  </div>
  <VCard elevation="0" class="w-full overflow-visible">
    <VCardText class="px-0">
      <VWindow v-model="tab" class="overflow-visible">
        <VWindowItem value="one">
          <div class="flex flex-col gap-5">
            <ScenarioList
              :data-loaded
              :scenarios
              :scenario-form-open
              @scenario-updated="checkAndUpdateProgress($event)"
              @toggle-scenario="expandedScenario = $event"
            />
            <ScenarioForm
              :data-loaded
              :expanded-scenario
              @scenario-created="handleCreatedScenario($event)"
              @reset="expandedScenario = {}"
              @scenario-form-open="scenarioFormOpen = $event"
            />
          </div>
        </VWindowItem>
      </VWindow>
    </VCardText>
  </VCard>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import ScenarioList from '@/apps/scenarios/components/ScenarioList.vue';
import ScenarioForm from '@/apps/scenarios/components/ScenarioForm.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';

const tab = ref(null);
const dataLoaded = ref(false);
const expandedScenario = ref({});
const scenarioFormOpen = ref(false);

const store = useStore();
const router = useRouter();

function handleCreatedScenario(scenarioId) {
  expandedScenario.value = {};
  checkAndUpdateProgress(scenarioId);
}

onMounted(async () => {
  init();
});

const scenarios = computed(() => store.state.scenario.scenarios);
const municipality = computed(() => store.state.municipality);

const queryScenarioId = computed(() => {
  if (!dataLoaded.value) {
    return null;
  }
  return Number(router.currentRoute.value.query.scenario);
});

watch(dataLoaded, (val) => {
  if (val) {
    const scenarioId = queryScenarioId.value;
    const foundScenario = scenarios.value.find(
      (scenario) => scenario.scenario_id === scenarioId,
    );
    if (foundScenario) {
      expandedScenario.value = foundScenario;
    }
  }
});

function init() {
  dataLoaded.value = false;
  Promise.all([
    store.dispatch('scenario/GET_SCENARIOS'),
    store.dispatch('scenario/GET_SCENARIO_DISTRICT_OPTIONS'),
  ]).then((response) => {
    const scenarios = response[0].data.results;
    scenarios.forEach((scenario) => {
      if (scenario.progress === 2) checkAndUpdateProgress(scenario.scenario_id);
    });
    dataLoaded.value = true;
  });
}

// We call this function recursively until the progress key is settled
// progress = 2, means still calculating
// progress = 0, means success
function checkAndUpdateProgress(id) {
  // Ensure id is defined before proceeding
  if (typeof id === 'undefined') return;

  // Maybe also more stuff needed here as in EconomicEfficiency?
  axios({
    url: `/api/scenarios/${id}/`,
    method: 'GET',
    skipLoading: true,
  })
    .then((response) => {
      // Ensure the response is valid and id is defined before recursion or update
      if (response.data.progress !== 0 && response.data.progress !== 1) {
        setTimeout(() => checkAndUpdateProgress(id), 1000);
      }
      // Progress change needs to be reflected in the store and screen
      store.commit('scenario/UPDATE_SCENARIO_PROGRESS', {
        sid: response.data.scenario_id,
        progress: response.data.progress,
        detailedProgress: response.data.detailed_progress,
        progressPercentage: response.data.progress_percentage,
      });
    })
    .catch((error) => {
      console.error(
        'Error during GET request in checkAndUpdateProgress:',
        error,
      );
    });
}
</script>

<style lang="scss">
.v-slide-group-item--active {
  @apply text-infra-highlight-500;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0 !important;
}
</style>
