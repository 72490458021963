export default {
  getLegendEntries: (state, _, rootState) => {
    const vis = [];
    Object.entries(state.layerConfigs).forEach((item) => {
      const e = item[1];
      if (e.visible && e.legend) {
        let legend = e.legend[e.layoutState];
        if (legend && legend.withAllYears)
          legend = legend.withAllYears(
            rootState.scenario.scenarioSelected.years,
          );
        if (legend) vis.push(legend);
      }
    });
    return vis;
  },
  getLayerStates: (state) =>
    Object.keys(state.layerConfigs).map((e) => ({
      layer: e,
      state: state.layerConfigs[e].layoutState,
      visible: state.layerConfigs[e].visible,
    })),
  getSwitchStates: (state) => {
    // simplify layer-panel
    const switchStates = {};
    for (const section of state.layerPanelConfig.sections) {
      for (const group of section.groups) {
        if (group.items) {
          for (const switchItem of group.items) {
            if (switchItem.itemClass === 'switch') {
              switchStates[switchItem.switchId] = switchItem.active;
            }
          }
        }
      }
    }
    return switchStates;
  },
  getLayersToClick: (state) => {
    const layers = [];
    Object.entries(state.layerConfigs).forEach((item) => {
      if (item[1].sidebar || item[1].onClick || item[1].autoGenerateSidebar) {
        layers.push(item[0]);
      }
    });
    return layers;
  },
  getLayersForScenario: (state) => {
    const layers = [];
    Object.keys(state.layerConfigs).map((e) => {
      if (state.layerConfigs[e].layerConfig.dependsOnScenario === true) {
        layers.push(e);
      }
    });
    return layers;
  },
  getLayersForYearlyFilter: (state) => {
    const layers = [];
    Object.keys(state.layerConfigs).map((e) => {
      if (state.layerConfigs[e].layerConfig.applyYearlyFilter === true) {
        layers.push(e);
      }
    });
    return layers;
  },
  getLayersForMunicipalityFilter: (state) => {
    const layers = [];
    Object.keys(state.layerConfigs).map((e) => {
      if (state.layerConfigs[e].layerConfig.applyMunicipalityFilter === true) {
        layers.push(e);
      }
    });
    return layers;
  },
  getAppliedFilters: (state) => {
    const appliedFilters = {};
    for (const layer of Object.keys(state.filters)) {
      const filter = Object.values(state.filters[layer]);
      appliedFilters[layer] = null;
      if (filter.length === 1) appliedFilters[layer] = filter[0];
      if (filter.length > 1) appliedFilters[layer] = ['all', ...filter];
    }
    return appliedFilters;
  },
  getYearDependantFilterData: (state) => state.yearDependantFilterData,
  getPropertyDataIsLoading: (state) => state.propertyDataIsLoading,
  getContainerHeight: (state) => state.containerHeight,
  preventMapSelect: (state, _, rootState) =>
    typeof rootState.root.processOptions.find((e) => e.active === true) !==
    'undefined',
};
