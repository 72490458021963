import { onMounted, onUnmounted } from 'vue';

/**
 * A Vue composable to dynamically add and remove event listeners based on an object mapping event types to handler functions.
 *
 * @param {Object} events - An object where each key is an event type (e.g.
 * 'click') and each value is the corresponding handler function.
 *
 * Usage example:
 * useListeners({
 *   click: handleClickOutside,
 *   keydown: handleKeyDown,
 * });
 */
export function useListeners(events) {
  const addEventListeners = () => {
    Object.entries(events).forEach(([eventType, handler]) => {
      document.addEventListener(eventType, handler);
    });
  };

  const removeEventListeners = () => {
    Object.entries(events).forEach(([eventType, handler]) => {
      document.removeEventListener(eventType, handler);
    });
  };

  onMounted(addEventListeners);
  onUnmounted(removeEventListeners);
}
