import {
  buildingConnections,
  distributionNetwork,
  networkCluster,
} from './districtHeatingUtils';

export const LAYER_KEY__PV_FREE = 'potential_pv_free';
export const LAYER_KEY__PV_ROOF = 'potential_pv_roof';
export const LAYER_KEY__PV_ROOF_LINES = 'potential_pv_roof_lines';
export const LAYER_KEY__SOLAR_THERMAL_FREE = 'potential_solarthermal_free';
export const LAYER_KEY__SOLAR_THERMAL_ROOF = 'potential_solarthermal_roof';
export const LAYER_KEY__SOLAR_THERMAL_ROOF_LINES =
  'potential_solarthermal_roof_lines';
export const LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS =
  'potential_building_connections';
export const LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK =
  'potential_distribution_network';
export const LAYER_KEY__POTENTIAL_NETWORK_CLUSTER = 'potential_network_cluster';
export const LAYER_KEY__WASTE_HEAT = 'potential_waste_heat';
export const LAYER_KEY__BIOMASS = 'potential_biomass';
export const LAYER_KEY__WIND = 'potential_wind';
// export const LAYER_KEY__GEOTHERMAL = '';

export default {
  [LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS]: buildingConnections(
    LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS,
    false,
  ),
  [LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK]: distributionNetwork(
    LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK,
    false,
  ),
  [LAYER_KEY__POTENTIAL_NETWORK_CLUSTER]: networkCluster(
    LAYER_KEY__POTENTIAL_NETWORK_CLUSTER,
    false,
    [
      LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS,
      LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK,
    ],
    'Auswertung: Fernwärme-Potenzial',
  ),
  [LAYER_KEY__PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialPVFree',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__PV_FREE,
      sourceLayer: LAYER_KEY__PV_FREE,
      layerId: LAYER_KEY__PV_FREE,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'PV (Freifläche)',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_per_year',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'pv_power_mw',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'pv_production_mwh',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'pv_invest_euro',
                  unit: 'Tsd. €',
                  multiply: 0.001,
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          'rgba(255,146,0,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      power: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'pv_power_mw'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'pv_power_mw'],
            0,
            'rgba(254,232,200,0.7)',
            40,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      power_production: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'pv_production_mwh'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'pv_production_mwh'],
            0,
            'rgba(254,232,200,0.7)',
            40000,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Potenziale',
        unit: '',
        items: [
          {
            color: 'rgba(255,146,0,0.7)',
            name: 'Photovoltaik (Freifläche)',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      power: {
        type: 'gradient',
        name: 'Photovoltaik (Freifläche) nach Anlagenleistung',
        unit: 'MW',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 40,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
      power_production: {
        type: 'gradient',
        name: 'Photovoltaik (Freifläche) nach Stromerzeugung',
        unit: 'MWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 40000,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
    },
  },
  [LAYER_KEY__PV_ROOF_LINES]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__PV_ROOF_LINES,
      sourceLayer: LAYER_KEY__PV_ROOF,
      layerId: LAYER_KEY__PV_ROOF_LINES,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: '#000000',
        lineWidth: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          2,
          0.5,
        ],
      },
    },
  },
  [LAYER_KEY__PV_ROOF]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialPVRoof',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__PV_ROOF,
      sourceLayer: LAYER_KEY__PV_ROOF,
      layerId: LAYER_KEY__PV_ROOF,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    onClick(click, feature) {
      const gml_id = feature.properties.gml_id;
      const filter = ['==', gml_id, ['get', 'gml_id']];
      const layers = ['potential_pv_roof_lines'];
      click.setForeignFeatureState(layers, filter);
      click.setFeatureState([feature], true);
    },
    sidebar: {
      name: 'Photovoltaik (Dach)',
      subtitles: [{ key: 'gml_id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'area_roof_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'area_roof_available_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_per_year',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'pv_power_kw',
                  unit: 'kW',
                  decimal: 1,
                },
                {
                  key: 'pv_production_kwh',
                  unit: 'kWh/a',
                  decimal: 0,
                },
                {
                  key: 'pv_invest_euro',
                  unit: '€',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'match',
          ['get', 'direction'],
          'S',
          '#005a32',
          'SW',
          '#41ab5d',
          'SO',
          '#41ab5d',
          'W',
          '#ffffb2',
          'O',
          '#ffffb2',
          'NW',
          '#e31a1c',
          'NO',
          '#e31a1c',
          'N',
          '#b10026',
          'flat',
          '#c6dbef',
          'rgba(206,209,218,0)',
        ],
        fillOpacity: 1,
      },
      operating_hours: {
        fillColor: [
          'interpolate',
          ['linear'],
          ['get', 'operating_hours_h_per_year'],
          0,
          'rgb(222,235,247)',
          3000,
          'rgb(49,130,189)',
        ],
        fillOpacity: 1,
      },
      power: {
        fillColor: [
          'interpolate',
          ['linear'],
          ['get', 'pv_power_kw'],
          0,
          'rgb(254,232,200)',
          1000,
          'rgb(227,74,51)',
        ],
        fillOpacity: 1,
      },
      power_production: {
        fillColor: [
          'interpolate',
          ['linear'],
          ['get', 'pv_production_kwh'],
          0,
          'rgb(254,232,200)',
          100000,
          'rgb(227,74,51)',
        ],
        fillOpacity: 1,
      },
    },
    legend: {
      default: {
        name: 'PV Dachausrichtung',
        unit: '',
        items: [
          {
            color: '#005a32',
            name: 'S',
            icon: 'mdi-square',
          },
          {
            color: '#41ab5d',
            name: 'SW',
            icon: 'mdi-square',
          },
          {
            color: '#41ab5d',
            name: 'SO',
            icon: 'mdi-square',
          },
          {
            color: '#ffffb2',
            name: 'W',
            icon: 'mdi-square',
          },
          {
            color: '#ffffb2',
            name: 'O',
            icon: 'mdi-square',
          },
          {
            color: '#e31a1c',
            name: 'NW',
            icon: 'mdi-square',
          },
          {
            color: '#e31a1c',
            name: 'NO',
            icon: 'mdi-square',
          },
          {
            color: '#b10026',
            name: 'N',
            icon: 'mdi-square',
          },
          {
            color: '#c6dbef',
            name: 'Flach',
            icon: 'mdi-square',
          },
        ],
      },
      operating_hours: {
        type: 'gradient',
        name: 'PV (Dach) nach Volllaststunden',
        unit: 'h/a',
        items: {
          min: {
            value: 0,
            color: 'rgb(222,235,247)',
          },
          max: {
            value: 1000,
            color: 'rgb(49,130,189)',
          },
        },
      },
      power: {
        type: 'gradient',
        name: 'PV (Dach) nach Leistung',
        unit: 'kW',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: 1000,
            color: 'rgb(227,74,51)',
          },
        },
      },
      power_production: {
        type: 'gradient',
        name: 'PV (Dach) nach Stromerzeugung',
        unit: 'kWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: 1000000,
            color: 'rgb(227,74,51)',
          },
        },
      },
    },
  },
  [LAYER_KEY__SOLAR_THERMAL_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialSolarThermalFree',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__SOLAR_THERMAL_FREE,
      sourceLayer: LAYER_KEY__SOLAR_THERMAL_FREE,
      layerId: LAYER_KEY__SOLAR_THERMAL_FREE,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Solarthermie (Freifläche)',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_per_year',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'solar_heat_power_mw',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'solar_heat_production_mwh',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'solar_invest_euro',
                  unit: 'Tsd. €',
                  multiply: 0.001,
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          'rgba(233,43,255,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      power: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'solar_heat_power_mw'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'solar_heat_power_mw'],
            0,
            'rgba(254,232,200,0.7)',
            100,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      power_production: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'solar_heat_production_mwh'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'solar_heat_production_mwh'],
            0,
            'rgba(254,232,200,0.7)',
            100000,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Potenziale',
        unit: '',
        items: [
          {
            color: 'rgba(233,43,255,0.7)',
            name: 'Solarthermie (Freifläche)',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      power: {
        type: 'gradient',
        name: 'Solarthermie (Freifläche) nach Wärmeleistung',
        unit: 'MW',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 100,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
      power_production: {
        type: 'gradient',
        name: 'Solarthermie (Freifläche) nach Wärmeerzeugung',
        unit: 'MWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 100000,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
    },
  },
  [LAYER_KEY__SOLAR_THERMAL_ROOF_LINES]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__SOLAR_THERMAL_ROOF_LINES,
      sourceLayer: LAYER_KEY__SOLAR_THERMAL_ROOF,
      layerId: LAYER_KEY__SOLAR_THERMAL_ROOF_LINES,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: '#000000',
        lineWidth: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          2,
          0.5,
        ],
      },
    },
  },
  [LAYER_KEY__SOLAR_THERMAL_ROOF]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialSolarThermalRoof',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__SOLAR_THERMAL_ROOF,
      sourceLayer: LAYER_KEY__SOLAR_THERMAL_ROOF,
      layerId: LAYER_KEY__SOLAR_THERMAL_ROOF,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    onClick(click, feature) {
      const gml_id = feature.properties.gml_id;
      const filter = ['==', gml_id, ['get', 'gml_id']];
      const layers = [LAYER_KEY__SOLAR_THERMAL_ROOF_LINES];
      click.setForeignFeatureState(layers, filter);
      click.setFeatureState([feature], true);
    },
    sidebar: {
      name: 'Solarthermie (Dach)',
      subtitles: [{ key: 'gml_id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'area_roof_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'area_roof_available_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'solar_heat_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_per_year',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'solar_heat_power_kw',
                  unit: 'kW',
                  decimal: 1,
                },
                {
                  key: 'solar_heat_production_kwh',
                  unit: 'kWh/a',
                  decimal: 0,
                },
                {
                  key: 'solar_invest_euro',
                  unit: '€',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'match',
          ['get', 'direction'],
          'S',
          '#005a32',
          'SW',
          '#41ab5d',
          'SO',
          '#41ab5d',
          'W',
          '#ffffb2',
          'O',
          '#ffffb2',
          'NW',
          '#e31a1c',
          'NO',
          '#e31a1c',
          'N',
          '#b10026',
          'flat',
          '#c6dbef',
          'rgba(206,209,218,0)',
        ],
        fillOpacity: 1,
      },
      power: {
        fillColor: [
          'interpolate',
          ['linear'],
          ['get', 'solar_heat_power_kw'],
          0,
          'rgb(254,232,200)',
          1000,
          'rgb(227,74,51)',
        ],
        fillOpacity: 1,
      },
      power_production: {
        fillColor: [
          'interpolate',
          ['linear'],
          ['get', 'solar_heat_production_kwh'],
          0,
          'rgb(254,232,200)',
          100000,
          'rgb(227,74,51)',
        ],
        fillOpacity: 1,
      },
    },
    legend: {
      default: {
        name: 'PV Dachausrichtung',
        unit: '',
        items: [
          {
            color: '#005a32',
            name: 'S',
            icon: 'mdi-square',
          },
          {
            color: '#41ab5d',
            name: 'SW',
            icon: 'mdi-square',
          },
          {
            color: '#41ab5d',
            name: 'SO',
            icon: 'mdi-square',
          },
          {
            color: '#ffffb2',
            name: 'W',
            icon: 'mdi-square',
          },
          {
            color: '#ffffb2',
            name: 'O',
            icon: 'mdi-square',
          },
          {
            color: '#e31a1c',
            name: 'NW',
            icon: 'mdi-square',
          },
          {
            color: '#e31a1c',
            name: 'NO',
            icon: 'mdi-square',
          },
          {
            color: '#b10026',
            name: 'N',
            icon: 'mdi-square',
          },
          {
            color: '#c6dbef',
            name: 'Flach',
            icon: 'mdi-square',
          },
        ],
      },
      power: {
        type: 'gradient',
        name: 'Solarthermie (Dach) nach Wärmeleistung',
        unit: 'kW',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: 1000,
            color: 'rgb(227,74,51)',
          },
        },
      },
      power_production: {
        type: 'gradient',
        name: 'Solarthermie (Dach) nach Wärmeerzeugung',
        unit: 'kWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: 1000000,
            color: 'rgb(227,74,51)',
          },
        },
      },
    },
  },
  [LAYER_KEY__WASTE_HEAT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialWasteHeat',
      key_name: 'id',
      type: 'point',
      id: LAYER_KEY__WASTE_HEAT,
      sourceLayer: LAYER_KEY__WASTE_HEAT,
      layerId: LAYER_KEY__WASTE_HEAT,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Abwärme',
      subtitles: [
        { key: 'id' },
        { key: 'plz' },
        { key: 'strasse' },
        { key: 'unternehmensname' },
      ],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'anzahl_mitarbeiter',
                  unit: '',
                },
                {
                  key: 'schaetzung_abwaermepotenzial_niedrig_mwh',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'schaetzung_abwaermepotenzial_hoch_mwh',
                  unit: 'MWh/a',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          '#af1e42',
        ],
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
      power_production: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'interpolate',
            ['linear'],
            ['get', 'schaetzung_abwaermepotenzial_hoch_mwh'],
            0,
            'rgb(254,232,200)',
            20000,
            'rgb(227,74,51)',
          ],
        ],
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Potenziale',
        unit: '',
        items: [
          {
            color: '#B72146FF',
            name: 'Abwärme',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      power_production: {
        type: 'gradient',
        name: 'Abwärmepotenzial',
        unit: 'GWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: 20,
            color: 'rgb(227,74,51)',
          },
        },
      },
    },
  },
  [LAYER_KEY__BIOMASS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialBiomass',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__BIOMASS,
      sourceLayer: LAYER_KEY__BIOMASS,
      layerId: LAYER_KEY__BIOMASS,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Biomasse',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Baumarten',
              active: true,
              items: [
                {
                  key: 'area_ha',
                  unit: 'ha',
                  decimal: 0,
                },
                {
                  key: 'availability_info_tree_types',
                  unit: '',
                },
                {
                  key: 'anteil_birke',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_buche',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_eiche',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_esche',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_fichte',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_laerche',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_kiefer',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_tanne',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_sonst_nadelbaeume',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'anteil_sonst_laubbaeume',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
              ],
            },
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'mean_cluster_holzeinschlag_cbm_ha',
                  unit: 'cbm/ha',
                  decimal: 1,
                },
                {
                  key: 'mean_cluster_energieholz_percentage',
                  unit: '%',
                  decimal: 1,
                  multiply: 100,
                },
                {
                  key: 'mean_cluster_energieholz_cbm_per_ha',
                  unit: 'cbm/ha',
                  decimal: 1,
                },
                {
                  key: 'mean_cluster_heizwert_kwh_per_cbm',
                  unit: 'kWh/cbm',
                  decimal: 0,
                },
                {
                  key: 'mean_cluster_spez_biomasseertrag_kwh_per_ha',
                  unit: 'kWh/ha',
                  decimal: 0,
                },
                {
                  key: 'biomasseertrag_kwh',
                  unit: 'MWh/a',
                  decimal: 0,
                  multiply: 0.001,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          'rgba(25,112,28,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      availability_info: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'availability_info_tree_types'], true],
          'rgba(0,185,121,0.7)',
          ['==', ['get', 'availability_info_tree_types'], false],
          'rgba(188,6,6,0.7)',
          'rgba(206,209,218,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      needle_trees: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'anteil_nadelbaeume'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'anteil_nadelbaeume'],
            0,
            'rgba(247,252,185,0.7)',
            1,
            'rgba(49,163,84,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      deciduous_trees: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['==', ['get', 'anteil_laubbaeume'], null],
          'rgba(255,255,255,0)',
          [
            'interpolate',
            ['linear'],
            ['get', 'anteil_laubbaeume'],
            0,
            'rgba(247,252,185,0.7)',
            1,
            'rgba(49,163,84,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      availability_info: {
        name: 'Biomasse Datenverfügbarkeit Baumarten',
        unit: '',
        items: [
          {
            color: 'rgba(0,185,121,0.7)',
            name: 'Verfügbar',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: 'rgba(188,6,6,0.7)',
            name: 'Nicht verfügbar',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      default: {
        name: 'Potenziale',
        unit: '',
        items: [
          {
            color: 'rgba(25,112,28,0.7)',
            name: 'Biomasse',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      needle_trees: {
        type: 'gradient',
        name: 'Biomasse Nadelbaumanteil',
        unit: '%',
        items: {
          min: {
            value: 0,
            color: 'rgba(247,252,185,0.7)',
          },
          max: {
            value: 100,
            color: 'rgba(49,163,84,0.7)',
          },
        },
      },
      deciduous_trees: {
        type: 'gradient',
        name: 'Biomasse Laubbaumanteil',
        unit: '%',
        items: {
          min: {
            value: 0,
            color: 'rgba(247,252,185,0.7)',
          },
          max: {
            value: 100,
            color: 'rgba(49,163,84,0.7)',
          },
        },
      },
    },
  },
  [LAYER_KEY__WIND]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialWind',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__WIND,
      sourceLayer: LAYER_KEY__WIND,
      layerId: LAYER_KEY__WIND,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Wind',
      subtitles: [
        { key: 'id' },
        { key: 'landkreis' },
        { key: 'regionalverband' },
      ],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            /* {
              title: 'Referenzanlage',
              active: true,
              items: [
                {
                  key: 'manufacturer',
                  unit: '',
                },
                {
                  key: 'turbine_type',
                  unit: '',
                },
                {
                  key: 'power_turbine',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'hubheight',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'rotor_diameter',
                  unit: 'm',
                  decimal: 0,
                },
              ],
            }, */
            {
              title: 'Fläche',
              active: true,
              items: [
                {
                  key: 'area_ha',
                  unit: 'ha',
                  decimal: 1,
                },
                {
                  key: 'suitability',
                  unit: '',
                },
                /* {
                  key: 'sub_category',
                  unit: '',
                },
                {
                  key: 'v_wind_100m',
                  unit: 'm/s',
                  decimal: 1,
                },
                {
                  key: 'v_wind_hubheight',
                  unit: 'm/s',
                  decimal: 1,
                }, */
                {
                  key: 'mean_altitude',
                  unit: 'm',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Auswertung',
              active: false,
              items: [
                {
                  key: 'amount_turbines',
                  unit: '',
                  decimal: 0,
                },
                {
                  key: 'power_park',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'operating_hours_turbine',
                  unit: 'h/a',
                  decimal: 0,
                },
                /* {
                  key: 'efficiency',
                  unit: '%',
                  decimal: 0,
                  multiply: 100,
                }, */
                {
                  key: 'production_park',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'invest_park',
                  unit: 'Tsd. €',
                  decimal: 0,
                  multiply: 0.001,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          'rgba(0,61,136,0.7)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      wind_speed_100m: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'interpolate',
            ['linear'],
            ['get', 'v_wind_100m'],
            0,
            'rgba(222,235,247,0.7)',
            10,
            'rgba(49,130,189,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      suitability: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'match',
            ['get', 'suitability'],
            'bedingt',
            'rgba(206,190,17,0.7)',
            'geeignet',
            'rgba(25,112,28,0.7)',
            'rgba(206,209,218,0)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      operating_hours: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'interpolate',
            ['linear'],
            ['get', 'operating_hours_turbine'],
            0,
            'rgba(222,235,247,0.7)',
            3000,
            'rgba(49,130,189,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      production_park: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'interpolate',
            ['linear'],
            ['get', 'production_park'],
            0,
            'rgba(254,232,200,0.7)',
            40000,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
      power_park: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          [
            'interpolate',
            ['linear'],
            ['get', 'power_park'],
            0,
            'rgba(254,232,200,0.7)',
            20,
            'rgba(227,74,51,0.7)',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Potenziale',
        unit: '',
        items: [
          {
            color: 'rgba(0,61,136,0.7)',
            name: 'Wind',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      wind_speed_100m: {
        type: 'gradient',
        name: 'Windgeschw. 100 m',
        unit: 'm/s',
        items: {
          min: {
            value: 0,
            color: 'rgba(222,235,247,0.7)',
          },
          max: {
            value: 10,
            color: 'rgba(49,130,189,0.7)',
          },
        },
      },
      suitability: {
        name: 'Windpotenziale Eignung',
        unit: '',
        items: [
          {
            color: 'rgba(25,112,28,0.7)',
            name: 'Geeignet',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: 'rgba(206,190,17,0.7)',
            name: 'Bedingt geeignet',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      operating_hours: {
        type: 'gradient',
        name: 'Volllaststunden',
        unit: 'h/a',
        items: {
          min: {
            value: 0,
            color: 'rgba(222,235,247,0.7)',
          },
          max: {
            value: 3000,
            color: 'rgba(49,130,189,0.7)',
          },
        },
      },
      production_park: {
        type: 'gradient',
        name: 'Windpotenzial Stromerzeugung',
        unit: 'MWh/a',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 40000,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
      power_park: {
        type: 'gradient',
        name: 'Windpotenzial Leistung',
        unit: 'MW',
        items: {
          min: {
            value: 0,
            color: 'rgba(254,232,200,0.7)',
          },
          max: {
            value: 20,
            color: 'rgba(227,74,51,0.7)',
          },
        },
      },
    },
  },
};
