import netzLevel11icon from '@/assets/img/mapIcons/netzv-level-v1-1.png';
import netzLevel17icon from '@/assets/img/mapIcons/netzv-level-v1-7.png';
import netzVerteilerActiveIcon from '@/assets/img/mapIcons/netzverteiler_active.png';

function get_netzcluster_sidebar(name, subtitles) {
  return {
    name,
    subtitles,
    chips: [
      {
        title: 'Gebäude',
        active: true,
        items: [
          {
            title: 'Gebäudeinformationen',
            active: true,
            items: [
              {
                key: 'buildings',
                unit: '',
              },
              {
                key: 'usable_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'residential_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'non_residential_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'number_residents',
                unit: '',
              },
              {
                key: 'units_residential',
                unit: '',
              },
              {
                key: 'units_commercial',
                unit: '',
              },
            ],
          },
          {
            title: 'Gebäudestruktur',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Sektoren',
                titleInside: 'Gebäude',
                key: 'sector_count',
                unit: '',
              },
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Baualtersklassen',
                titleInside: 'Gebäude',
                key: 'construction_year_count',
                unit: '',
              },
            ],
          },
        ],
      },
      {
        title: 'Auswertung',
        active: false,
        items: [
          {
            title: 'Wärmebedarf',
            active: false,
            items: [
              {
                key: 'heat_demand_mwh',
                unit: 'MWh',
                decimal: 0,
              },
              {
                key: 'linear_heat_density_kwh_m',
                unit: 'kWh/m',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Netzlängen',
            active: true,
            items: [
              {
                key: 'length_distribution_network_meter',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'length_building_connections_meter',
                unit: 'm',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Investitionskosten',
            active: false,
            items: [
              {
                key: 'invest_distribution_network',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'invest_heat_substation',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'invest_building_connections',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'invest_total',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
            ],
          },
          {
            title: 'Deckungsbeitrag',
            active: false,
            items: [
              {
                key: 'contribution_margin',
                unit: '€/a',
                decimal: 0,
              },
              {
                key: 'specific_margin_eur_mwh',
                unit: '€/MWh',
                decimal: 0,
              },
              {
                key: 'specific_heat_costs_eur_mwh',
                unit: '€/MWh',
                decimal: 0,
              },
              {
                key: 'specific_heat_price_eur_mwh',
                unit: '€/MWh',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Vollkostenvergleich',
            active: false,
            items: [
              {
                key: 'total_full_costs_district',
                unit: '€/a',
                decimal: 0,
              },
              {
                key: 'total_full_costs_pellet',
                unit: '€/a',
                decimal: 0,
              },
              {
                key: 'total_full_costs_hydrogen',
                unit: '€/a',
                decimal: 0,
              },
              {
                key: 'total_full_costs_gas',
                unit: '€/a',
                decimal: 0,
              },
              {
                key: 'total_full_costs_heat_pump',
                unit: '€/a',
                decimal: 0,
              },
            ],
          },
        ],
      },
    ],
  };
}

export const buildingConnections = (layerKey, applyYearlyFilter) => ({
  layoutState: 'default',
  visible: false,
  layerConfig: {
    type: 'line',
    id: layerKey,
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: 'none',
    dependsOnScenario: true,
    applyYearlyFilter,
    applyMunicipalityFilter: true,
  },
  style: {
    default: {
      lineColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#5c0000',
        '#112bd2',
      ],
      lineWidth: [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        15,
        1.5,
        18,
        2.5,
      ],
    },
  },
  legend: {
    default: {
      name: 'Fernwärme',
      unit: '',
      items: [
        {
          color: '#112bd2',
          name: 'Hausanschlüsse',
          icon: 'mdi-minus',
        },
      ],
    },
  },
});
export const distributionNetwork = (layerKey, applyYearlyFilter) => ({
  layoutState: 'default',
  visible: false,
  layerConfig: {
    type: 'line',
    id: layerKey,
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: 'none',
    dependsOnScenario: true,
    applyYearlyFilter,
    applyMunicipalityFilter: true,
  },
  style: {
    default: {
      lineColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#5c0000',
        '#03a899',
      ],
      lineWidth: [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        15,
        1.5,
        18,
        2.5,
      ],
    },
  },
  legend: {
    default: {
      name: 'Fernwärme',
      unit: '',
      items: [
        {
          color: '#03a899',
          name: 'Verteilnetz',
          icon: 'mdi-minus',
        },
      ],
    },
  },
});

export const networkCluster = (
  layerKey,
  applyYearlyFilter,
  markFeatures,
  objectPanelName,
  addSubtitles = [],
) => ({
  layoutState: 'default',
  visible: false,
  layerConfig: {
    app_name: 'buildingmodel',
    router_name: 'networkCluster',
    key_name: 'cluster_id',
    type: 'symbol',
    sourceLayer: layerKey,
    visibility: 'none',
    id: layerKey,
    layerId: layerKey,
    dependsOnScenario: true,
    applyYearlyFilter,
    applyMunicipalityFilter: true,
    icons: [
      {
        name: 'clusterExpansion',
        path: netzLevel11icon,
      },
      {
        name: 'clusterCompression',
        path: netzLevel17icon,
      },
      {
        name: 'symbolSelectedVerteil',
        path: netzVerteilerActiveIcon,
      },
    ],
  },
  onClick(click, feature) {
    const clusterId = feature.properties.cluster_id;
    const filter = ['==', clusterId, ['get', 'cluster_id']];
    const layers = ['buildingmodel_buildingaggregate', ...markFeatures];
    const changeIcon = {
      name: 'selectedNvt',
      iconImage: 'symbolSelectedVerteil',
      iconSize: 0.4,
    };
    click.changeIconOnClick(feature, changeIcon);
    click.setForeignFeatureState(layers, filter);
    click.setFeatureState([feature], true);
  },
  sidebar: get_netzcluster_sidebar(objectPanelName, [
    { key: 'cluster_id' },
    { key: 'district_name' },
    { key: 'municipality_name' },
    ...addSubtitles,
  ]),
  style: {
    default: {
      layout: {
        'icon-image': [
          'match',
          ['get', 'expansion_type'],
          0,
          'clusterCompression',
          'clusterExpansion',
        ],
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        visibility: 'none',
      },
    },
  },
  legend: {
    default: {
      name: 'Fernwärme',
      unit: '',
      items: [
        {
          name: 'Netzcluster Ausbau',
          image: netzLevel11icon,
        },
        {
          name: 'Netzcluster Verdichtung',
          image: netzLevel17icon,
        },
      ],
    },
  },
});
