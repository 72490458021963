import { LAYERS } from '@/features/heat-project/constants';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import {
  GeoJsonMultiLineString,
  GeoJsonLineString,
} from '@/features/draw/utilities';
import { SET_PROPERTIES } from '@/features/draw/properties';
import MapboxGlDraw from '@mapbox/mapbox-gl-draw';

const autoHomeConnect = {};

autoHomeConnect.onSetup = function (opts) {
  const featuresForEvent = [];
  const distributionNetwork = this._ctx.api
    .getAll()
    .features.filter((e) => e.properties.type === LAYERS.DISTRIBUTION_NETWORK);
  if (!distributionNetwork.length) return;
  const distNetCoords = distributionNetwork.map((d) => d.geometry.coordinates);
  const lineToSnap = GeoJsonMultiLineString(distNetCoords);
  for (const selected of this.getSelected()) {
    if (
      selected.properties.type === LAYERS.HOME_CONNECTION_POINT &&
      selected.properties.isConnected === false
    ) {
      const snap = nearestPointOnLine(lineToSnap, selected);
      const homeConnectionGeoJson = GeoJsonLineString(
        [snap.geometry.coordinates, selected.coordinates],
        {
          type: LAYERS.HOME_CONNECTION,
          estate_id: selected.properties.estate_id,
        },
      );
      const homeConnection = this.newFeature(homeConnectionGeoJson);
      for (const [key, value] of Object.entries(SET_PROPERTIES)) {
        homeConnection.setProperty(key, value);
      }
      this.addFeature(homeConnection);
      featuresForEvent.push(homeConnectionGeoJson);
      selected.setProperty('isConnected', true);
    }
  }
  this.map.fire(MapboxGlDraw.constants.events.CREATE, {
    features: featuresForEvent,
  });
  const wasSelected = this.getSelectedIds();
  if (wasSelected.length) {
    this.clearSelectedFeatures();
    wasSelected.forEach((id) => this.doRender(id));
  }
  MapboxGlDraw.lib.doubleClickZoom.enable(this);
  this.map.dragPan.enable();
};

autoHomeConnect.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

export default autoHomeConnect;
