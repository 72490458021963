<template>
  <div
    class="flex justify-between w-full panelItem py-1 border-b border-infra-highlight-200 hover:border-none singleItemcustom"
  >
    <div
      class="flex items-center relative body-3"
      :style="{ width: item.class === 'powerplant' ? '50%' : '60%' }"
    >
      {{ item.label }}
      <ToolTipOnHover
        v-if="item.help_text"
        class="ml-0.5"
        :tooltip-text="item.help_text"
        :icon-size="18"
      />
    </div>
    <div
      :style="{ width: item.class === 'powerplant' ? '48%' : '38%' }"
      class="flex items-center"
      :class="
        item.type !== 'boolean'
          ? 'overflow-hidden hover:overflow-visible hover:z-10 justify-start'
          : 'justify-end'
      "
    >
      <DropdownField
        v-if="item.type === 'choice'"
        :item="item"
        :is-loading="getPropertyDataIsLoading"
        v-bind="$attrs"
      />
      <CheckboxField
        v-else-if="item.type === 'boolean'"
        :item="item"
        :is-loading="getPropertyDataIsLoading"
        v-bind="$attrs"
      />
      <InputField
        v-else
        :item="item"
        :class="item.key"
        :is-loading="getPropertyDataIsLoading"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script>
import InputField from './InputField.vue';
import CheckboxField from './CheckboxField.vue';
import DropdownField from './DropdownField.vue';
import { mapGetters } from 'vuex';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';

export default {
  components: {
    ToolTipOnHover,
    InputField,
    CheckboxField,
    DropdownField,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('map', ['getPropertyDataIsLoading']),
  },
};
</script>

<style lang="scss">
.panelItem {
  .left-outside-custom {
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .right-outside-custom {
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
