import { axiosInstance } from '@/utils/axiosHelper';

export default {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_MAP_ACTIONS: (state, payload) => {
    for (const processOption of state.processOptions) {
      processOption.active = false;
      processOption.editObjectId = null;
    }
    if (payload) {
      const processId = payload.processId;
      const editObjectId = payload.editObjectId;
      for (const processOption of state.processOptions) {
        if (processOption.id === processId) {
          processOption.active = true;
          if (editObjectId !== null && typeof editObjectId !== 'undefined') {
            processOption.editObjectId = editObjectId;
          }
        }
      }
    }
  },
  SET_MAIN_MENU_SHOW: (state, payload) => {
    state.showMainMenu = payload;
  },
  SET_PROJECT_MENU_SHOW: (state, payload) => {
    state.showProjectMenu = payload;
  },
  SET_LOGO_URL: (state, resp) => {
    state.logoUrl = `${axiosInstance.defaults.baseURL}/api${resp}`;
  },
  TOGGLE_MU_SC_SELECTOR: (state, payload) => {
    state.showMuScSelector = payload;
  },
  SET_HEAT_PROJECT_EDIT: (state, isEditing) => {
    state.editHeatProjectNetwork = isEditing;
  },
};
