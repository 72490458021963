<template>
  <div :style="styles" :class="classes" class="rounded-[4px] flex items-center">
    <IconWrapper icon="info" :size="iconSize" />
    <slot />
  </div>
</template>

<script setup>
import IconWrapper from '../IconWrapper/IconWrapper.vue';
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'l',
    validator: (value) => ['m', 'l'].includes(value),
  },
  bgColor: {
    type: String,
    default: '#FAFAFA',
  },
  color: {
    type: String,
    default: '#424242',
  },
});

const styles = computed(() => ({
  backgroundColor: props.bgColor,
  color: props.color,
}));

const classes = computed(() => ({
  'body-1 gap-2 p-2': props.size === 'l',
  'body-3 gap-1 py-1 px-2': props.size === 'm',
}));

const iconSize = computed(() => {
  if (props.size === 'm') return 16;
  return 20;
});
</script>
