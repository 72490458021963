import { de } from 'vuetify/locale';

export default {
  common: {
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    description: 'Beschreibung',
    delete: 'Löschen',
    title: 'Titel',
    save: 'Speichern',
    faq: 'FAQ',
    contact: 'Kontaktieren Sie uns',
    tos: 'Nutzungsbedingungen',
    policy: 'Datenschutzerklärung',
  },
  board: {
    titlePlaceholder: 'Geben Sie einen Titel für diese Karte ein',
    deleteDescription: 'Möchten Sie diese Karte wirklich löschen?',
    editCard: 'Karte bearbeiten',
    deleteCard: 'Karte löschen',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'PRÜFUNG',
      DONE: 'FERTIG',
    },
  },
  chat: {
    online: 'Benutzer online ({count})',
    addChannel: 'Kanal hinzufügen',
    channel: 'Kanal | Kanäle',
    message: 'Nachricht',
  },
  email: {
    compose: 'E-Mail verfassen',
    send: 'Senden',
    subject: 'Betreff',
    labels: 'Etiketten',
    emptyList: 'Leere E-Mail-Liste',
    inbox: 'Posteingang',
    sent: 'Gesendet',
    drafts: 'Entwürfe',
    starred: 'Markiert',
    trash: 'Müll',
    work: 'Arbeit',
    invoice: 'Rechnung',
  },
  todo: {
    addTask: 'Aufgabe hinzufügen',
    tasks: 'Aufgaben',
    completed: 'Abgeschlossen',
    labels: 'Etiketten',
  },
  dashboard: {
    activity: 'Aktivität',
    weeklySales: 'Wochenverkäufe',
    sales: 'Vertrieb',
    recentOrders: 'Letzte Bestellungen',
    sources: 'Verkehrsquellen',
    lastweek: 'vs letzte Woche',
    orders: 'Bestellungen',
    customers: 'Kunden',
    tickets: 'Support Tickets',
    viewReport: 'Bericht anzeigen',
  },
  usermenu: {
    profile: 'Profil',
    signin: 'Anmelden',
    dashboard: 'Dashboard',
    signout: 'Abmelden',
  },
  error: {
    notfound: 'Seite nicht gefunden',
    other: 'Ein Fehler ist aufgetreten',
  },
  notFound: {
    title: 'Fehler 404',
    subTitle: 'Hier ist nichts zu sehen',
  },
  check: {
    title: 'Neues Passwort festlegen',
    backtosign: 'Zurück zur Anmeldung',
    newpassword: 'Neues Passwort',
    button: 'Legen Sie ein neues Passwort fest und melden Sie sich an',
    error: 'Der Aktionslink ist ungültig',
    verifylink: 'Link überprüfen ...',
    verifyemail: 'E-Mail-Adresse überprüfen ...',
    emailverified: 'Email überprüft! Umleiten ...',
  },
  forgot: {
    title: 'Passwort vergessen?',
    subtitle:
      'Geben Sie die E-Mail-Adresse Ihres Kontos ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
    email: 'E-Mail',
    button: 'Link zusenden',
    backtosign: 'Zurück zur Anmeldung',
  },
  login: {
    username: 'Benutzername',
    title: 'Login',
    email: 'E-Mail',
    password: 'Passwort',
    button: 'Jetzt einloggen',
    orsign: 'Oder melden Sie sich mit',
    forgot: 'Passwort vergessen?',
    noaccount: 'Sie haben noch keinen Account?',
    create: 'Erstellen Sie hier eine',
    error: 'Die E-Mail / Passwort-Kombination ist ungültig',
  },
  register: {
    title: 'Registrierung abschließen',
    name: 'Vollständiger Name',
    email: 'E-Mail',
    password: 'Passwort',
    button: 'Konto erstellen',
    orsign: 'Oder melden Sie sich mit',
    agree: 'an Mit Ihrer Anmeldung stimmen Sie dem',
    account: 'zu Sie haben bereits ein Konto?',
    signin: 'Anmelden',
  },
  reset: {
    title: 'Passwort festlegen',
  },
  utility: {
    maintenance: 'In der Wartung',
  },
  faq: {
    call: 'Haben Sie weitere Fragen? Bitte erreichen Sie',
  },
  menu: {
    gebaeude: 'Gebäude',
    fernwaerme: 'Fernwärme',
    auswertung: 'Auswertung',
    oewinfra: 'Breitband Infrastruktur',
    oewnvt: 'NVT',
    existence: 'Bestand',
    test: 'Test',
    search: 'Suchen...',
    dashboard: 'Dashboard',
    logout: 'Abmelden',
    profile: 'Profil',
    blank: 'Leere Seite',
    pages: 'Seiten',
    others: 'Andere',
    email: 'E-Mail',
    chat: 'Chat',
    news: 'Neuigkeiten',
    filesharing: 'Filesharing',
    oewBreitband: 'OEW',
    board: 'Task Board',
    users: 'Benutzer',
    usersList: 'Liste',
    usersEdit: 'Bearbeiten',
    auth: 'Auth-Seiten',
    authLogin: 'Anmelden / Anmelden',
    authRegister: 'Anmeldung / Registrierung',
    authVerify: 'E-Mail überprüfen',
    authForgot: 'Passwort vergessen',
    authReset: 'Passwort zurücksetzen',
    errorPages: 'Fehlerseiten',
    errorNotFound: 'Nicht gefunden / 404',
    errorUnexpected: 'Unerwartet / 500',
    utilityPages: 'Hilfsseiten',
    utilityMaintenance: 'Wartung',
    utilitySoon: 'Bald erhältlich',
    utilityHelp: 'FAQs / Hilfe',
    levels: 'Menüebenen',
    disabled: 'Menü deaktiviert',
    docs: 'Docs',
    feedback: 'Feedback',
    support: 'Unterstützung',
  },
  $vuetify: {
    ...de,
  },
  // To remove VDataTableServer pagination warnings
  10: '10',
  25: '25',
  50: '50',
  100: '100',
};
