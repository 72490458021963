<template>
  <v-chart
    ref="geoChart"
    :option="option"
    :class="(municipalities.length === 1 || readOnly) && 'pointer-events-none'"
    @click="syncSelectedMunicipalitiesFromChartToggle"
  />
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue';
import { registerMap } from 'echarts/core';

const props = defineProps({
  selectedMunicipalities: {
    type: Array,
    default: () => [],
  },
  availableMunicipalities: {
    type: Array,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  selectAllFromTheStart: {
    type: Boolean,
    default: false,
  },
});

const loaded = ref(false);
const geoChart = ref(null);
const features = ref({ type: 'FeatureCollection', features: [] });
const municipalities = ref([]);

const emit = defineEmits([
  'loaded',
  'update:municipalities',
  'update:selectedMunicipalities',
]);

// register empty map to init chart ref in mounted cycle
registerMap('municipalities', features.value);

onBeforeMount(() => {
  axios({
    url: '/api/buildingmodel/municipality/geometry-data/',
    method: 'GET',
  }).then(async (r) => {
    features.value.features = [];
    municipalities.value = [];
    for (const municipality of r.data.data) {
      features.value.features.push({
        geometry: JSON.parse(municipality.geometry_json),
        type: 'Feature',
        properties: { name: municipality.municipality_key },
        id: municipality.municipality_key,
      });
      municipalities.value.push({
        // name to indentify geo map selects
        name: municipality.municipality_key,
        municipality_key: municipality.municipality_key,
        municipality_id: municipality.municipality_key,
        municipality_name: municipality.name,
        federal_state_name: municipality.federal_state_name,
        federal_state_id: municipality.federal_state_id,
        county_name: municipality.county_name,
        county_id: municipality.county_id,
      });
    }
    emit('update:municipalities', municipalities.value);
    const newFeatureCollection = generateFeatureCollection();
    registerMap('municipalities', newFeatureCollection || features.value);
    await nextTick();
    geoChart.value?.setOption(geoChart.value.getOption());
    loaded.value = true;
    emit('loaded');
    syncGeoSelect();
    defaultSelectAllMunicipalities();
  });
});

const option = computed(() => {
  const style = getComputedStyle(document.documentElement);
  const selectedColor = `rgb(${style.getPropertyValue('--core-color1')})`;
  const unSelectedColor = `rgb(${style.getPropertyValue('--bg-default')})`;
  const hoverColor = `rgb(${style.getPropertyValue('--text-inverted-disabled-color1')})`;
  const borderColor = `rgb(${style.getPropertyValue('--border-line-active-neutral')})`;

  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
      fontWeight: 300,
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name;
      },
    },
    geo: {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return `${params.data.municipality_name} (${params.name})`;
        },
      },
      map: 'municipalities',
      layoutCenter: ['50%', '50%'],
      layoutSize: '100%',
      selectedMode: 'multiple',
      emphasis: {
        label: {
          show: false,
        },
        itemStyle: {
          areaColor: hoverColor,
        },
      },
      itemStyle: {
        areaColor: unSelectedColor,
        borderColor: borderColor,
      },
      select: {
        label: {
          show: false,
        },
        itemStyle: {
          areaColor: selectedColor,
        },
      },
    },
    series: [
      {
        selectedMode: 'multiple',
        type: 'map',
        map: 'municipalities',
        geoIndex: 0,
        data: municipalities.value,
      },
    ],
  };
});

// dispatch action. Type can be select, unselect or toggleSelect
function selectAction(type, municipalityKeys) {
  geoChart.value.dispatchAction({
    type,
    name: municipalityKeys,
  });
}

function syncSelectedMunicipalitiesFromChartToggle(param) {
  const { data } = param;
  if (municipalities.value.length === 1) return;
  emit('update:selectedMunicipalities', data);
}

function syncGeoSelect() {
  selectAction(
    'unselect',
    municipalities.value.map((e) => e.municipality_key),
  );
  selectAction(
    'select',
    props.selectedMunicipalities.map((e) => e.municipality_id),
  );
}

watch(
  () => props.selectedMunicipalities,
  () => {
    syncGeoSelect();
  },
);

watch(
  () => props.availableMunicipalities,
  () => {
    if (!geoChart.value) return;
    const newFeatureCollection = generateFeatureCollection();
    registerMap('municipalities', newFeatureCollection);
    selectAction(
      'select',
      props.selectedMunicipalities.map((e) => e.municipality_id),
    );
    geoChart.value.setOption(geoChart.value.getOption());
  },
);

function defaultSelectAllMunicipalities() {
  if (props.selectAllFromTheStart) {
    selectAction(
      'select',
      municipalities.value.map((e) => e.municipality_id),
    );
  }
}

function generateFeatureCollection() {
  if (props.availableMunicipalities) {
    const municipalitiesKeys = new Set(
      props.availableMunicipalities.map((e) => e.municipality_key),
    );
    return {
      type: 'FeatureCollection',
      features: features.value.features.filter((e) =>
        municipalitiesKeys.has(e.id),
      ),
    };
  }
  return null;
}
</script>
