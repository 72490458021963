import { getMap } from '@/mapbox/main';

export default class DragPoint {
  constructor(layerId) {
    this.layerId = layerId;
    this.coords = [];
  }

  get layer() {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.layerId,
      paint: {
        'circle-radius': 10,
        'circle-color': '#F84C4C',
      },
    };
  }

  get getFeatureCollection() {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: this.coords,
          },
        },
      ],
    };
  }

  get geometry() {
    return { type: 'Point', coordinates: this.coords };
  }

  /**
   * Sets Source and Layer to map and initializes eventhooks
   */
  initialize() {
    if (!Array.isArray(this.coords)) {
      this.coords = Object.values(getMap().getCenter());
    }
    if (!getMap().getSource(this.layerId)) {
      getMap().U.addGeoJSON(this.layerId);

      // eventhooks for curser and layer UI
      getMap().on('mouseenter', this.layerId, () => {
        getMap().setPaintProperty(this.layerId, 'circle-color', '#3bb2d0');
        getMap().getCanvasContainer().style.cursor = 'move';
      });
      getMap().on('mouseleave', this.layerId, () => {
        getMap().setPaintProperty(this.layerId, 'circle-color', '#F84C4C');
        getMap().getCanvasContainer().style.cursor = '';
      });
    }
    this.onMouseDown = this.onMouseDown.bind(this);
    getMap().on('mousedown', this.onMouseDown);
    getMap().addLayer(this.layer);
    getMap().U.setData(this.layerId, this.getFeatureCollection);
  }

  /**
   * Updates the point coordinates. Is bound to mousemove.
   * @param  {Object} e
   */
  _onMove(e) {
    this.coords = Object.values(e.lngLat);
    getMap().getCanvasContainer().style.cursor = 'grabbing';
    getMap().getSource(this.layerId).setData(this.getFeatureCollection);
  }

  /**
   * Removes mousemove event. Is bound to mouseup.
   */
  _onUp() {
    getMap().getCanvasContainer().style.cursor = '';
    getMap().off('mousemove', this._onMove);
  }

  /**
   * Event hook at mousedown on features of layerID. Binds _onMove function
   * to mousemove and _onUp once to mouseup, which removes the mousemove Hook
   * @param  {Object} e
   */
  onMouseDown(e) {
    getMap().getCanvasContainer().style.cursor = 'grab';
    const features = getMap().queryRenderedFeatures(e.point, {
      layers: [this.layerId],
    });
    if (features.length > 0) {
      e.preventDefault();
      // event hooks for feature dragging on Map
      this._onMove = this._onMove.bind(this);
      getMap().on('mousemove', this._onMove);

      this._onUp = this._onUp.bind(this);
      getMap().once('mouseup', this._onUp);
    }
  }
  removeEventhooks() {
    getMap().off('mousedown', this.onMouseDown);
  }
}
