import { axios } from '@/utils/axiosHelper';
import HeatProject from '@/features/heat-project/heat-project';
import cookie from 'vue-cookies';
import { LAYER_KEY__HEAT_PROJECT_CENTROID } from '@/configs/layers/heatProject';
import { cleanPanelData } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import { fetchData } from './utils';

export class HeatProjectCentroidPanel {
  options = null;

  layerIds = [LAYER_KEY__HEAT_PROJECT_CENTROID];

  async requestFeatureData(feature, scenarioId) {
    const featureId = feature.properties.id;

    if (!HeatProject.OPTIONS) {
      const optionsResp = await axios({
        url: '/api/heatprojects/HeatProject/',
        method: 'OPTIONS',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      HeatProject.OPTIONS = optionsResp.data.actions.POST;
    }

    const resp = await fetchData(`/api/heatprojects/HeatProject/${featureId}`);

    if (resp === null) return resp;

    resp.data.options = HeatProjectCentroidPanel.options;
    return resp.data;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    const heatProject = new HeatProject();
    heatProject.initFromRequest(featureData);
    const panelData = heatProject.objectPanelConfig;
    panelData.breakEven = heatProject.resultsConnectionRate.breakEven;
    store.commit('map/SET_SCENARIO_YEAR', heatProject.realisationYear);
    return cleanPanelData(panelData);
  }

  calculatePanelDataConnectionRate(featureData, connectionRate) {
    const heatProject = new HeatProject();
    heatProject.initFromRequest(featureData);
    heatProject.connectionRate = connectionRate;
    const panelData = heatProject.objectPanelConfig;
    return cleanPanelData(panelData);
  }
}
