export function barChartData(heatOutput, heatLoad) {
  const delta = heatOutput - heatLoad;
  let hiddenStackColor = 'transparent';
  if (delta < 0) {
    heatLoad += delta;
    hiddenStackColor = '#D32F2F';
  }
  return {
    hiddenStacks: [
      {
        value: 0,
        itemStyle: { color: 'transparent' },
      },
      {
        value: delta,
        itemStyle: { color: hiddenStackColor },
      },
      {
        value: 0,
        itemStyle: { color: 'transparent' },
      },
    ],
    visibleStacks: [
      {
        value: heatOutput,
        itemStyle: { color: '#4CAF50' },
      },
      {
        value: heatLoad,
        itemStyle: { color: '#D32F2F' },
      },
      {
        value: delta,
        itemStyle: { color: '#00A1D1' },
      },
    ],
  };
}
