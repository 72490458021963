import { getAggregationSidebar } from './aggregation/helper';

export const LAYER_KEY__ESTATE = 'estates';
export const LAYER_KEY__ESTATE_CONNECTION = 'estateconnection';

export default {
  [LAYER_KEY__ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'scenario',
      router_name: 'estate',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__ESTATE,
      sourceLayer: LAYER_KEY__ESTATE,
      layerId: LAYER_KEY__ESTATE,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: getAggregationSidebar('Flurstück', [
      'district_name',
      'municipality_name',
    ]),
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          'rgba(255,255,255,0)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
  },
  [LAYER_KEY__ESTATE_CONNECTION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__ESTATE_CONNECTION,
      sourceLayer: LAYER_KEY__ESTATE_CONNECTION,
      layerId: LAYER_KEY__ESTATE_CONNECTION,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        circleColor: '#00A1D1',
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 4,
      },
    },
  },
};
