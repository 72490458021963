import axios from '@/utils/axiosHelper';
import state from './index';

export default {
  SET_VALID_MODE: ({ commit }, payload) =>
    new Promise(() => {
      commit('SET_VALID_MODE', payload);
    }),
  GET_LOGO_URL: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      if (state.logoUrl) {
        resolve(state.logoUrl); // Immediately resolve if logoUrl already exists
      } else {
        axios({
          url: '/api/config/customer-styling/',
          method: 'GET',
        })
          .then((resp) => {
            if (resp.data.results.length) {
              const logoUrl = resp.data.results[0].logo;
              commit('SET_LOGO_URL', logoUrl);
              resolve(logoUrl);
            } else {
              resolve(); // Resolve with no value if no results
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};
