<template>
  <div class="absolute right-5">
    <ButtonEl
      icon="format_list_bulleted"
      data-test="legend-icon"
      variant="tertiary"
      :custom-bg-hover-color="showLegend ? 'bg-default' : null"
      :class="[
        'ml-auto absolute z-10 right-0',
        {
          'standard-elevation-1': !showLegend,
        },
      ]"
      @click="toggleLegend"
    />
    <LegendMap v-if="showLegend" v-bind="$attrs" />
  </div>
</template>
<script setup>
import LegendMap from '@/features/map/legend/LegendMap.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  showLegend: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['toggleLegendState']);

function toggleLegend() {
  emit('toggleLegendState');
}
</script>
