<template>
  <div class="skeleton-wrapper">
    <div class="skeleton-bar" :style="{ width: customWidth + 'px' }"></div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoaderCustom',
  props: {
    customWidth: {
      type: Number,
      required: false,
      default: 70,
    },
  },
};
</script>

<style lang="scss">
/* Skeleton styles*/
.skeleton-wrapper {
  animation: scale 2s infinite;
}

.skeleton-bar {
  background: #ced2db;
  height: 13px;
  border-radius: 5px;
}

/* Skeleton animation*/
@keyframes scale {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
