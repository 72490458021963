<template>
  <div
    class="oew_subnavi side-bar-custom hide-scrollbar nav-drawer bg-white rounded-[8px] left-5 top-[85px] h-fit w-[360px] absolute z-10 standard-elevation-1 p-0 pt-2.5"
    :class="{ 'overflow-y-auto': !getProjectMenuShow }"
    :style="{
      maxHeight: containerHeight + 'px',
    }"
  >
    <h4 class="text-infra-highlight-500 px-3">
      {{ getProjectMenuShow ? 'Projektgebiete' : 'Kartenansicht' }}
    </h4>
    <div
      v-for="(section, index) in allowedLayerPanelConfig.sections"
      v-show="section.show"
      :key="index"
      class="font-medium flex-col mt-5"
      :class="{ flex: section.show }"
    >
      <h5
        v-if="section.title && section.id !== 'heatProject'"
        class="text-infra-highlight-800 px-3 mb-2"
      >
        {{ section.title }}
      </h5>

      <LayerPanelItem
        v-for="(group, groupIndex) in section.groups"
        v-show="group.items.length > 0"
        :key="groupIndex"
        :menu-item="group"
        class="nav-menu-item"
        :open="open"
        :data-test="`item-section-${group.title}`"
        @emit-activator="setOpenedActivator($event)"
      >
        <template v-if="group.items">
          <LayerPanelItem
            v-for="(item, itemIndex) in group.items"
            :key="itemIndex"
            :data-test="`item-group-${item.title}`"
            :menu-item="item"
            subgroup
            small
          >
            <template v-if="item.items">
              <LayerPanelItem
                v-for="(level3Item, level3Index) in item.items"
                :key="level3Index"
                :menu-item="level3Item"
                small
              />
            </template>
          </LayerPanelItem>
        </template>
      </LayerPanelItem>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import LayerPanelItem from '@/features/map/layer-panel/LayerPanelItem.vue';
import {computed, ref, watch} from 'vue';
import {useModuleStore} from '@/configs/module-store';

const open = ref(null);

const moduleStore = useModuleStore();

function setOpenedActivator(title) {
  open.value = open.value === title ? null : title;
}

const store = useStore();

const getProjectMenuShow = computed(() => store.getters.getProjectMenuShow);
const layerPanelConfig = computed(() => {
  return store.state.map.layerPanelConfig;
});

const containerHeight = computed(() => store.state.map.containerHeight);

function getAllowed(items) {
  return items.filter((item) => moduleStore.isActive(item.module));
}

const allowedLayerPanelConfig = computed(() => {
  const filteredConfig = {};
  filteredConfig.sections = getAllowed(layerPanelConfig.value.sections);
  for (const section of filteredConfig.sections) {
    section.groups = getAllowed(section.groups);
    for (const group of section.groups) {
      group.items = getAllowed(group.items);
      const itemsPerm = [];
      for (const groupItem of group.items) {
        itemsPerm.push(groupItem);
      }
      group.items = itemsPerm;
      for (const item of group.items) {
        if (item.radioGroups != null) {
          item.radioGroups = getAllowed(item.radioGroups);
          for (const radioGroup of item.radioGroups) {
            radioGroup.radios = getAllowed(radioGroup.radios);
          }
        }
      }
    }
  }
  return filteredConfig;
});

watch(getProjectMenuShow, (val) => {
  // Directly set the visibility of all sections based on val
  store.state.map.layerPanelConfig.sections.forEach((section) => {
    section.show = !val;
  });

  // Find the 'heatProject' section and set its visibility opposite to val
  const heatProjectSection = store.state.map.layerPanelConfig.sections.find(
    (section) => section.id === 'heatProject',
  );

  if (heatProjectSection) {
    heatProjectSection.show = val;
  }
});
</script>
