<template>
  <v-list-item
    active-class="primary--text"
    class="p-0 rounded-0"
    link
    @click="showModal()"
  >
    <v-list-item-title class="flex justify-between whitespace-normal">
      <div class="w-fit">
        {{ menuItem.title }}
      </div>
      <div class="w-4 mr-2 flex flex-wrap items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.001"
          height="12.001"
          viewBox="0 0 12.001 12.001"
        >
          <path
            d="M6,0a6,6,0,1,0,6,6A6.018,6.018,0,0,0,6,0ZM5.25,
            8.551,4.2,7.5,5.7,6,4.2,4.5,5.25,3.45,7.8,6Z"
            fill="#001257"
          />
        </svg>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['show-modal'],
  methods: {
    showModal() {
      this.$store.commit('SET_FULLSCREEN_MODAL', {
        visible: true,
        type: this.menuItem.modalId,
        title: this.menuItem.title,
      });
    },
  },
};
</script>
