export default {
  TOGGLE_SHOW_TASK_DETAILS: (state, taskId) => {
    state.showTaskDetails = !state.showTaskDetails;
    state.taskId = taskId;
  },
  RESET_TASK_VALUES: (state) => {
    state.showTaskDetails = false;
    state.taskId = null;
  },
};
