export const buildCustomLayerPanelConfig = (
  layerPanelConfig,
  customTilesets,
) => {
  layerPanelConfig.sections.push({
    title: 'Eigene Analyseergebnisse',
    id: 'customTileset',
    show: true,
    groups: [
      {
        title: 'Eigene Analyseergebnisse',
        items: customTilesets.map((ct) => ({
          title: ct.name,
          switchId: ct.path,
          itemClass: 'switch',
          onLayers: [ct.path],
          active: false,
          radioGroups: [],
        })),
      },
    ],
  });
  return layerPanelConfig;
};

const getGradientColor = (start_color, end_color, percent) => {
  // strip the leading # if it's there
  start_color = start_color.replace(/^\s*#|\s*$/g, '');
  end_color = end_color.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (start_color.length === 3) {
    start_color = start_color.replace(/(.)/g, '$1$1');
  }

  if (end_color.length === 3) {
    end_color = end_color.replace(/(.)/g, '$1$1');
  }

  // get colors
  let start_red = parseInt(start_color.substr(0, 2), 16),
    start_green = parseInt(start_color.substr(2, 2), 16),
    start_blue = parseInt(start_color.substr(4, 2), 16);

  let end_red = parseInt(end_color.substr(0, 2), 16),
    end_green = parseInt(end_color.substr(2, 2), 16),
    end_blue = parseInt(end_color.substr(4, 2), 16);

  // calculate new color
  let diff_red = end_red - start_red;
  let diff_green = end_green - start_green;
  let diff_blue = end_blue - start_blue;

  diff_red = (diff_red * percent + start_red).toString(16).split('.')[0];
  diff_green = (diff_green * percent + start_green).toString(16).split('.')[0];
  diff_blue = (diff_blue * percent + start_blue).toString(16).split('.')[0];

  // ensure 2 digits by color
  if (diff_red.length === 1) diff_red = '0' + diff_red;
  if (diff_green.length === 1) diff_green = '0' + diff_green;
  if (diff_blue.length === 1) diff_blue = '0' + diff_blue;

  return '#' + diff_red + diff_green + diff_blue;
};

const createStyle = (customTileset, color) => {
  switch (customTileset.type) {
    case 'line':
      return {
        lineColor: color,
        lineWidth: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          3,
          2,
        ],
      };
    case 'point':
      return {
        circleColor: color,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 4,
      };
    case 'polygon':
      return {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          getGradientColor(color, '#000000', 0.2),
          color,
        ],
        fillOpacity: 0.5,
        fillOutlineColor: '#000000',
      };
  }
};

export const buildCustomLayerConfig = (layerConfigs, customTilesets) => {
  const startColor = '#04c407';
  const endColor = '#c40404';
  for (let i = 0; i < customTilesets.length; i++) {
    const color = getGradientColor(
      startColor,
      endColor,
      i / customTilesets.length,
    );
    const layerKey = customTilesets[i].path.split('.mbtiles')[0];
    layerConfigs[layerKey] = {
      layoutState: 'default',
      visible: false,
      layerConfig: {
        type: customTilesets[i].type,
        id: layerKey,
        sourceLayer: layerKey,
        layerId: layerKey,
        visibility: 'none',
        dependsOnScenario: false,
        applyYearlyFilter: false,
        applyMunicipalityFilter: false,
      },
      autoGenerateSidebar: true,
      autoGenerateSidebarTitle: customTilesets[i].name,
      style: {
        default: createStyle(customTilesets[i], color),
      },
      legend: {
        default: {
          name: customTilesets[i].name,
          unit: '',
          items: [
            {
              color,
              name: customTilesets[i].name,
              icon:
                customTilesets[i].type === 'point'
                  ? 'mdi-checkbox-blank-circle'
                  : 'mdi-minus',
            },
          ],
        },
      },
    };
  }
  return layerConfigs;
};
