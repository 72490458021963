import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';

const mergeValueOptions = (key, value, options) => {
  return {
    key,
    value,
    ...options,
  };
};

class PremiseSetDataSeries {
  constructor(series, seriesKey, options) {
    this.fields = [];
    this.seriesKey = seriesKey;
    this.seriesValues = new Set();

    for (const prop of series) {
      const seriesValue = prop[this.seriesKey];
      this.seriesValues.add(seriesValue);

      delete prop[this.seriesKey];
      for (const [key, value] of Object.entries(prop)) {
        const option = options[key];
        this.fields.push({
          key,
          value,
          ...option,
          [this.seriesKey]: seriesValue,
        });
      }
    }
  }

  get series() {
    return Array.from(this.seriesValues);
  }

  get payload() {
    const payload = this.series.map((e) => ({ [this.seriesKey]: e }));
    for (const field of this.fields) {
      const seriesGroup = payload.find(
        (e) => e[this.seriesKey] === field[this.seriesKey],
      );
      seriesGroup[field.key] = field.value;
    }
    return payload;
  }
}

export default class PremiseSet {
  static OPTIONS = null;
  static OPTIONS_LOADED = false;

  power = null;
  yearly = null;
  fields = [];
  progress = null;

  /**
   * populate PremiseSet with data. Merge values and options for all fields
   * and create Frame Classes for related tables.
   * @param  {Object} data
   */
  populateData(data) {
    this.fields = [];
    this.id = data.id;
    this.progress = data.progress;

    if (!PremiseSet.OPTIONS_LOADED) {
      console.warn('call and await fetchOptions first');
    }

    this.power = new PremiseSetDataSeries(
      data.power,
      'power_kw',
      this.powerOptions,
    );
    delete data.power;

    this.yearly = new PremiseSetDataSeries(
      data.yearly,
      'year',
      this.yearlyOptions,
    );
    delete data.yearly;

    for (const [key, value] of Object.entries(data)) {
      const options = PremiseSet.OPTIONS[key];
      this.fields.push(mergeValueOptions(key, value, options));
    }
  }

  get powerOptions() {
    return PremiseSet.OPTIONS.power.child.children;
  }

  get yearlyOptions() {
    return PremiseSet.OPTIONS.yearly.child.children;
  }

  get name() {
    return this.fields.find((e) => e.key === 'name');
  }

  get payload() {
    const payload = {
      yearly: this.yearly.payload,
      power: this.power.payload,
    };
    for (const field of this.fields) {
      payload[field.key] = field.value;
    }
    return payload;
  }

  isValidValue(field) {
    return (
      field.value === '' ||
      typeof field.value === 'undefined' ||
      field.value === null
    );
  }

  get isValid() {
    const missingFields = [];
    const fields = [
      ...this.yearly.fields.filter((e) => e.required),
      ...this.power.fields.filter((e) => e.required),
      ...this.fields,
    ];
    for (const field of fields) {
      if (this.isValidValue(field)) {
        missingFields.push(field.key);
      }
    }
    return missingFields.length === 0;
  }

  static async fetchOptions() {
    await axios({
      url: '/api/heatprojects/wi-re/premises/',
      method: 'OPTIONS',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      PremiseSet.OPTIONS = resp.data.actions.POST;
      PremiseSet.OPTIONS_LOADED = true;
    });
  }
}
