<template>
  <div
    class="flex w-full justify-center subtitle-2 items-center gap-2 h-fit py-5 text-infra-gray-highlight"
  >
    <span
      >© {{ new Date().getFullYear() }} LBD-Beratungsgesellschaft mbH
      Berlin</span
    >
    <span>|</span>
    <div class="flex gap-2 items-center">
      <a href="https://www.lbd.de/datenschutz/" target="_blank">Datenschutz</a>
      <a href="https://www.lbd.de/impressum/" target="_blank">Impressum</a>
    </div>
  </div>
</template>
