import {
  SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
  SWITCH_ID__HEATING_CENTER_POTENTIAL,
} from '@/configs/layer-panel/heatSource';

import {
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
} from '@/configs/layers/constants.js';

import { energySourceColors, energyTypeColors } from './baseData/map-colors';

const heatSourceConfig = (key, filter, assetType, defaultStyle) => ({
  layoutState: 'default',
  visible: false,
  layerConfig: {
    app_name: 'heatprojects',
    router_name: 'HeatSource',
    key_name: 'id',
    type: 'point',
    id: key,
    layerId: key,
    dataSourceFromStore: `heatProject/getHeatSourcesAsFC`,
    geoJSONSource: true,
    visibility: 'none',
    dependsOnScenario: false,
    applyYearlyFilter: false,
    filter,
  },
  sidebar: {
    name: 'Wärmequelle',
    subtitles: [{ key: 'name' }],
    enableDelete: true,
    enableWiRe: false,
    enableEdit: true,
    customEditFunction(store) {
      store.commit('SET_MAP_ACTIONS', {
        processId: 'heatSource',
        editObjectId: this.id,
      });
    },
    customDeleteFunction(store) {
      store.dispatch('heatProject/DELETE_HEAT_SOURCE', this.id);
    },
    chips: [
      {
        title: 'Info',
        active: true,
        items: [
          {
            title: 'Info',
            active: true,
            items: [
              {
                key: 'system_type',
                unit: '',
              },
              {
                key: 'asset_type',
                unit: '',
              },
              {
                key: 'generation_cost',
                unit: '',
              },
              {
                key: 'plant_type',
                unit: '',
              },
              {
                key: 'energy_source',
                unit: '',
              },
              {
                key: 'thermal_power_mw',
                unit: 'MW',
              },
              {
                key: 'generated_heat_quantity_mwh',
                unit: 'MWh/a',
              },
              {
                key: 'electrical_power_mw',
                unit: 'MW',
                decimal: 2,
              },
              {
                key: 'generated_electricity_mwh',
                unit: 'MWh/a',
                decimal: 0,
              },
              {
                key: 'emissions_t_mwh',
                unit: 'tCO2/MWh',
                decimal: 0,
              },
            ],
          },
        ],
      },
    ],
  },
  style: {
    default: defaultStyle.style,
    energySource: {
      circleColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#BC0606',
        ['boolean', ['feature-state', 'marked'], false],
        '#00E5FF',
        [
          'match',
          ['get', `energy_source`],
          'COA',
          energySourceColors.COA,
          'GAS',
          energySourceColors.GAS,
          'OIL',
          energySourceColors.OIL,
          'BIO',
          energySourceColors.BIO,
          'BGA',
          energySourceColors.BGA,
          'H2',
          energySourceColors.H2,
          'ELE',
          energySourceColors.ELE,
          'SOL',
          energySourceColors.SOL,
          'GEO',
          energySourceColors.GEO,
          'H2O',
          energySourceColors.H2O,
          'WAS',
          energySourceColors.WAS,
          energySourceColors.UNK,
        ],
      ],
      circleStrokeWidth: 2,
      circleStrokeColor: [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        '#01799D',
        '#000000',
      ],
      circleRadius: [
        'interpolate',
        ['linear'],
        ['get', 'heat_output'],
        0,
        5,
        10,
        10,
      ],
    },
    sourceType: {
      circleColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#BC0606',
        ['boolean', ['feature-state', 'marked'], false],
        '#00E5FF',
        [
          'match',
          ['get', `plant_type`],
          'BOI',
          energyTypeColors.BOI,
          'CHP',
          energyTypeColors.CHP,
          'PMP',
          energyTypeColors.PMP,
          'DHU',
          energyTypeColors.DHU,
          energyTypeColors.UKN,
        ],
      ],
      circleStrokeWidth: 2,
      circleStrokeColor: [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        '#01799D',
        '#000000',
      ],
      circleRadius: [
        'interpolate',
        ['linear'],
        ['get', 'heat_output'],
        0,
        5,
        10,
        10,
      ],
    },
  },
  legend: {
    default: defaultStyle.legend,
    energySource: {
      name: `Wärmequelle ${assetType}`,
      unit: '',
      items: [
        {
          color: energySourceColors.COA,
          name: 'Kohle',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.GAS,
          name: 'Erdgas',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.OIL,
          name: 'Heizöl',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.BIO,
          name: 'Biomasse',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.BGA,
          name: 'Biogas',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.H2,
          name: 'Wasserstoff',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.ELE,
          name: 'Strom',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.SOL,
          name: 'Solarthermie',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.GEO,
          name: 'Geothermie',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.H2O,
          name: 'Oberflächengewässer',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.WAS,
          name: 'Industrielle Abwärme',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energySourceColors.UNK,
          name: 'Unbekannt',
          icon: 'mdi-checkbox-blank-circle',
        },
      ],
    },
    sourceType: {
      name: `Wärmequelle ${assetType}`,
      unit: '',
      items: [
        {
          color: energyTypeColors.BOI,
          name: 'Heizkessel',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energyTypeColors.CHP,
          name: 'KWK-Anlage',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energyTypeColors.PMP,
          name: 'Wärmepumpe',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: energyTypeColors.DHU,
          name: 'Direkte Wärmenutzung',
          icon: 'mdi-checkbox-blank-circle',
        },
      ],
    },
  },
});

export default {
  [LAYER_KEY__HEAT_SOURCE_INVENTORY]: heatSourceConfig(
    LAYER_KEY__HEAT_SOURCE_INVENTORY,
    [
      'all',
      ['==', ['get', 'asset_type'], 0],
      ['==', ['get', 'system_type'], 'HSO'],
    ],
    'Bestand',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#df97f8',
            name: 'Wärmequelle Bestand',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#df97f8',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),

  [LAYER_KEY__HEAT_SOURCE_POTENTIAL]: heatSourceConfig(
    LAYER_KEY__HEAT_SOURCE_POTENTIAL,
    [
      'all',
      ['==', ['get', 'asset_type'], 1],
      ['==', ['get', 'system_type'], 'HSO'],
    ],
    'Potenzial',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#df97f8',
            name: 'Wärmequelle Potenzial',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#df97f8',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),
  [LAYER_KEY__HEATING_CENTER_INVENTORY]: heatSourceConfig(
    LAYER_KEY__HEATING_CENTER_INVENTORY,
    [
      'all',
      ['==', ['get', 'asset_type'], 0],
      ['==', ['get', 'system_type'], 'HSY'],
    ],
    'Bestand',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#FF4500',
            name: 'Heizzentrale Bestand',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#FF4500',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),
  [LAYER_KEY__HEATING_CENTER_POTENTIAL]: heatSourceConfig(
    LAYER_KEY__HEATING_CENTER_POTENTIAL,
    [
      'all',
      ['==', ['get', 'asset_type'], 1],
      ['==', ['get', 'system_type'], 'HSY'],
    ],
    'Potenzial',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#FF4500',
            name: 'Heizzentrale Potenzial',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#FF4500',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),
  [LAYER_KEY__GRID_FEED_POINT_INVENTORY]: heatSourceConfig(
    LAYER_KEY__GRID_FEED_POINT_INVENTORY,
    [
      'all',
      ['==', ['get', 'asset_type'], 0],
      ['==', ['get', 'system_type'], 'GFP'],
    ],
    'Bestand',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#73009a',
            name: 'Aufspeisepunkt Bestand',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#73009a',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),
  [LAYER_KEY__GRID_FEED_POINT_POTENTIAL]: heatSourceConfig(
    LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
    [
      'all',
      ['==', ['get', 'asset_type'], 1],
      ['==', ['get', 'system_type'], 'GFP'],
    ],
    'Potenzial',
    {
      legend: {
        name: 'Standorte',
        unit: '',
        items: [
          {
            color: '#73009a',
            name: 'Aufspeisepunkt Potenzial',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      style: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#BC0606',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          '#73009a',
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#01799D',
          '#000000',
        ],
        circleRadius: [
          'interpolate',
          ['linear'],
          ['get', 'heat_output'],
          0,
          5,
          10,
          10,
        ],
      },
    },
  ),
};
