import { SWITCH_ID__BUILDINGS } from './buildings';
import {
  heatSourcePotentialSwitch,
  heatingCenterPotentialSwitch,
  gridFeedPointPotentialSwitch,
} from './heatSource';
import {
  LAYER_KEY__BIOMASS,
  LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS,
  LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK,
  LAYER_KEY__POTENTIAL_NETWORK_CLUSTER,
  LAYER_KEY__PV_FREE,
  LAYER_KEY__PV_ROOF,
  LAYER_KEY__PV_ROOF_LINES,
  LAYER_KEY__SOLAR_THERMAL_FREE,
  LAYER_KEY__SOLAR_THERMAL_ROOF,
  LAYER_KEY__SOLAR_THERMAL_ROOF_LINES,
  LAYER_KEY__WASTE_HEAT,
  LAYER_KEY__WIND,
} from '../layers/potentials';
import { infraModules } from '@/configs/infra-modules';

export const SWITCH_ID__PV_FREE = 'LC_PV_FREE';
export const SWITCH_ID__PV_ROOF = 'LC_PV_ROOF';
export const SWITCH_ID__SOLAR_THERMAL_FREE = 'LC_SOLAR_THERMAL_FREE';
export const SWITCH_ID__SOLAR_THERMAL_ROOF = 'LC_SOLAR_THERMAL_ROOF';
export const SWITCH_ID__WASTE_HEAT = 'LC_WASTE_HEAT';
export const SWITCH_ID__BIOMASS = 'LC_BIOMASS';
export const SWITCH_ID__WIND = 'LC_WIND';
export const SWITCH_ID__GEOTHERMAL = 'LC_GEOTHERMAL';
export const SWITCH_ID__NETWORK_CLUSTER = 'LC_POT_NETWORK_CLUSTER';
export const SWITCH_ID__BUILDING_NETWORK = 'LC_POT_BUILDING_NETWORK';
export const SWITCH_ID__DISTRIBUTION_NETWORK = 'LC_POT_DISTRIBUTION_NETWORK';

export default [
  {
    title: 'Erzeugungsanlagen',
    module: infraModules.projectPlaner,
    items: [
      heatSourcePotentialSwitch,
      heatingCenterPotentialSwitch,
      gridFeedPointPotentialSwitch,
    ],
  },
  {
    title: 'Abwärme',
    items: [
      {
        title: 'Abwärme',
        switchId: SWITCH_ID__WASTE_HEAT,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__WASTE_HEAT],
        disabledByEmptyModel: 'PotentialWasteHeat',
        active: false,
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Keine Einfärbung',
                onLayers: [LAYER_KEY__WASTE_HEAT],
                layerState: 'default',
              },
              {
                title: 'Abwärmeleistung',
                onLayers: [LAYER_KEY__WASTE_HEAT],
                layerState: 'power_production',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Biomasse',
    items: [
      {
        title: 'Biomasse',
        switchId: SWITCH_ID__BIOMASS,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__BIOMASS],
        active: false,
        module: infraModules.potentials,
        disabledByEmptyModel: 'PotentialBiomass',
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Keine Einfärbung',
                onLayers: [LAYER_KEY__BIOMASS],
                layerState: 'default',
              },
              {
                title: 'Verfügbarkeit Baumarten',
                onLayers: [LAYER_KEY__BIOMASS],
                layerState: 'availability_info',
              },
              {
                title: 'Laubbäume',
                onLayers: [LAYER_KEY__BIOMASS],
                layerState: 'deciduous_trees',
              },
              {
                title: 'Nadelbäume',
                onLayers: [LAYER_KEY__BIOMASS],
                layerState: 'needle_trees',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // the space behind "Fernwärme" is necessary because the title is used as an ID and another entry with title "Fernwärme" already exists.
    title: 'Fernwärme ',
    module: infraModules.districtHeating,
    items: [
      {
        title: 'Verteilnetz',
        switchId: SWITCH_ID__DISTRIBUTION_NETWORK,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__POTENTIAL_DISTRIBUTION_NETWORK],
        turnOffSwitches: [
          'LC_SZ_NETWORK_CLUSTER',
          'LC_SZ_BUILDING_NETWORK',
          'LC_SZ_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
      {
        title: 'Hausanschluss',
        switchId: SWITCH_ID__BUILDING_NETWORK,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__POTENTIAL_BUILDING_CONNECTIONS],
        turnOffSwitches: [
          'LC_SZ_NETWORK_CLUSTER',
          'LC_SZ_BUILDING_NETWORK',
          'LC_SZ_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
      {
        title: 'Netzcluster',
        switchId: SWITCH_ID__NETWORK_CLUSTER,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__POTENTIAL_NETWORK_CLUSTER],
        turnOffSwitches: [
          'LC_SZ_NETWORK_CLUSTER',
          'LC_SZ_BUILDING_NETWORK',
          'LC_SZ_DISTRIBUTION_NETWORK',
        ],
        active: false,
        radioGroups: [],
      },
    ],
  },
  {
    title: 'Geothermie',
    module: infraModules.potentials,
    items: [],
  },
  {
    title: 'Photovoltaik',
    items: [
      {
        title: 'Freifläche',
        switchId: SWITCH_ID__PV_FREE,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__PV_FREE],
        active: false,
        disabledByEmptyModel: 'PotentialPVFree',
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Keine Einfärbung',
                onLayers: [LAYER_KEY__PV_FREE],
                layerState: 'default',
              },
              {
                title: 'Anlagenleistung',
                onLayers: [LAYER_KEY__PV_FREE],
                layerState: 'power',
              },
              {
                title: 'Stromerzeugung',
                onLayers: [LAYER_KEY__PV_FREE],
                layerState: 'power_production',
              },
            ],
          },
        ],
      },
      {
        title: 'Dach',
        switchId: SWITCH_ID__PV_ROOF,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__PV_ROOF, LAYER_KEY__PV_ROOF_LINES],
        turnOffSwitches: [SWITCH_ID__BUILDINGS, SWITCH_ID__SOLAR_THERMAL_ROOF],
        active: false,
        disabledByEmptyModel: 'PotentialPVRoof',
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Dachausrichtung',
                onLayers: [LAYER_KEY__PV_ROOF],
                layerState: 'default',
              },
              {
                title: 'Volllaststunden',
                onLayers: [LAYER_KEY__PV_ROOF],
                layerState: 'operating_hours',
              },
              {
                title: 'PV-Leistung',
                onLayers: [LAYER_KEY__PV_ROOF],
                layerState: 'power',
              },
              {
                title: 'Stromerzeugung',
                onLayers: [LAYER_KEY__PV_ROOF],
                layerState: 'power_production',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Solarthermie',
    items: [
      {
        title: 'Freifläche',
        switchId: SWITCH_ID__SOLAR_THERMAL_FREE,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__SOLAR_THERMAL_FREE],
        active: false,
        disabledByEmptyModel: 'PotentialSolarThermalFree',
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Keine Einfärbung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_FREE],
                layerState: 'default',
              },
              {
                title: 'Wärmeleistung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_FREE],
                layerState: 'power',
              },
              {
                title: 'Wärmeerzeugung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_FREE],
                layerState: 'power_production',
              },
            ],
          },
        ],
      },
      {
        title: 'Dach',
        switchId: SWITCH_ID__SOLAR_THERMAL_ROOF,
        itemClass: 'switch',
        onLayers: [
          LAYER_KEY__SOLAR_THERMAL_ROOF,
          LAYER_KEY__SOLAR_THERMAL_ROOF_LINES,
        ],
        turnOffSwitches: [SWITCH_ID__PV_ROOF, SWITCH_ID__BUILDINGS],
        active: false,
        disabledByEmptyModel: 'PotentialSolarThermalRoof',
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Dachausrichtung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_ROOF],
                layerState: 'default',
              },
              {
                title: 'Wärmeleistung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_ROOF],
                layerState: 'power',
              },
              {
                title: 'Wärmeerzeugung',
                onLayers: [LAYER_KEY__SOLAR_THERMAL_ROOF],
                layerState: 'power_production',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Wind',
    items: [
      {
        title: 'Wind',
        switchId: SWITCH_ID__WIND,
        itemClass: 'switch',
        onLayers: [LAYER_KEY__WIND],
        active: false,
        disabledByEmptyModel: 'PotentialWind',
        module: infraModules.potentials,
        radioGroups: [
          {
            visible: false,
            activated: 0,
            radios: [
              {
                title: 'Keine Einfärbung',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'default',
              },
              {
                title: 'Windgeschw. 100 m',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'wind_speed_100m',
              },
              {
                title: 'Eignung',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'suitability',
              },
              {
                title: 'Volllaststunden',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'operating_hours',
              },
              {
                title: 'Anlagenleistung',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'power_park',
              },
              {
                title: 'Stromerzeugung',
                onLayers: [LAYER_KEY__WIND],
                layerState: 'production_park',
              },
            ],
          },
        ],
      },
    ],
  },
];
