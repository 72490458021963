<template>
  <div
    ref="municipalityWrapper"
    class="municipality-wrapper z-20"
    :class="{
      extended: isAnyExpanded,
    }"
  >
    <!-- toggle bar-->
    <div class="municipality-box flex gap-2">
      <template v-for="(tab, index) in denominatorConfig" :key="tab">
        <div
          v-show="index === 0 || (index === 1 && tab.show)"
          :class="{
            flex: index === 0 || (index === 1 && tab.show),
            'pointer-events-none':
              tab.id === 'municipality' && municipalities.length === 1,
          }"
          :data-test="'munScenSelection-' + tab.id"
          class="items-center min-w-[170px] cursor-pointer justify-between gap-1.5 standard-elevation-0-dark py-1 px-1.5 rounded-[6px]"
          @click="toggleShow(index)"
        >
          <div class="flex flex-col">
            <div
              :class="{
                'text-color1': index === 0,
                'text-neutral': index === 1,
              }"
              class="caption-1 whitespace-nowrap flex"
            >
              {{ tab.label }}
              <ToolTipOnHover
                v-if="isSelectedScenarioOutdated && tab.id === 'scenario'"
                class="ml-0.5 mt-[0.5px]"
                tooltip-box-background="bg-spot-error"
                cursor-type="cursor-pointer"
                tooltip-text="Szenario ist veraltet. Ein Klick auf das Ausrufezeichen-Icon bringt sie zur Szenario-Übersicht. Dort können Sie eine Neuberechung anstoßen."
                icon="error"
                :icon-size="12"
                :tooltip-offset-y="-120"
                icon-fill="text-spot-error"
                @click="$router.push({ name: 'scenarios' })"
              />
            </div>
            <h5
              :class="{
                'text-title-color1': index === 0,
                'text-title-neutral': index === 1,
              }"
              class="whitespace-nowrap max-w-60 truncate"
            >
              {{
                tab.id === 'municipality'
                  ? selectedNames.municipality
                  : selectedNames.scenario
              }}
            </h5>
          </div>
          <IconWrapper
            v-if="municipalities.length > 1"
            icon="keyboard_arrow_down"
            :fill="getIconFill(index)"
            :class="[
              {
                'rotate-180': tab.expanded,
              },
              'transform duration-300',
            ]"
          />
        </div>
      </template>
      <ButtonEl
        icon="playlist_play"
        color="neutral"
        variant="tertiary"
        :class="{
          'transform scale-x-[-1]': isSecondTabVisible,
        }"
        @click="toggleSecondTab"
      />
    </div>

    <div v-if="isAnyExpanded" class="municipality-list-wrapper" @click.stop>
      <InputEl
        v-model="filterText"
        placeholder="Bitte eingeben"
        :label="
          expandedItemId === 'municipality'
            ? 'Name der Kommune'
            : 'Name des Szenarios'
        "
        size="m"
      />
      <div
        class="flex flex-col gap-0.5 overflow-y-auto hide-scrollbar"
        :style="{
          'max-height': `${containerHeight - 150}px`,
        }"
      >
        <!-- municipality items-->
        <template v-if="expandedItemId === 'municipality'">
          <div
            v-for="(item, index) in filteredMunicipalities"
            :key="item"
            class="municipality-item"
            :class="{
              disabled: isSelectedMunicipality(item),
            }"
            :data-test="'municipality-select-' + index"
            @click="selectMunicipality(item.municipality_key)"
          >
            {{ item.name }}
          </div>
        </template>

        <!-- scenario items-->
        <template v-else-if="expandedItemId === 'scenario'">
          <div
            v-for="(item, index) in filteredScenarios"
            :key="index"
            class="cursor-pointer"
            :class="scenarioHoverIndex === index ? 'bg-white' : 'bg-subtle'"
            @mouseenter="scenarioHoverIndex = index"
          >
            <div
              :class="{
                'standard-elevation-0-dark': scenarioHoverIndex === index,
              }"
              class="m-1 p-2.5 flex flex-col items-start gap-2.5 rounded-[4px]"
            >
              <div
                class="flex flex-col gap-1 w-full border-b border-line-neutral"
              >
                <VRadio
                  :model-value="isSelectedScenario(item)"
                  :density="null"
                  :data-test="'scenario-select-' + index"
                  @click="selectScenario(item)"
                  @update:model-value="isSelectedScenario(item)"
                >
                  <template #label>
                    <h6
                      class="flex"
                      :class="{
                        'text-title-color1': isSelectedScenario(item),
                        'text-title-neutral': !isSelectedScenario(item),
                      }"
                    >
                      {{ item.name }}
                      <ToolTipOnHover
                        v-if="item.building_data_changed"
                        class="ml-1 mt-[0.5px]"
                        tooltip-box-background="bg-spot-error"
                        cursor-type="cursor-pointer"
                        tooltip-text="Szenario ist veraltet. Ein Klick auf das Ausrufezeichen-Icon bringt sie zur Szenario-Übersicht. Dort können Sie eine Neuberechung anstoßen."
                        icon="error"
                        :icon-size="18"
                        :tooltip-offset-y="-120"
                        icon-fill="text-spot-error"
                        @click="$router.push({ name: 'scenarios' })"
                      />
                    </h6>
                  </template>
                </VRadio>
              </div>

              <!-- more infos-->
              <div class="flex flex-col gap-1 w-full text-neutral">
                <div class="flex justify-between">
                  <div class="body-2">Zieljahr</div>
                  <div class="body-3">{{ item.target_year }}</div>
                </div>
              </div>

              <ButtonEl
                v-show="scenarioHoverIndex === index"
                :class="[
                  'button-transition',
                  { visible: scenarioHoverIndex === index },
                ]"
                size="s"
                icon="ads_click"
                text="Öffnen"
                @click="
                  $router.push({
                    name: 'scenarios',
                    query: {
                      scenario: item.scenario_id,
                    },
                  })
                "
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useListeners } from '@/components/storybook/src/composables/useListeners';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';

const store = useStore();

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

const props = defineProps({
  denominator: {
    type: String,
    default: 'municipality',
  },
  scenarios: {
    type: Array,
    default: () => [],
  },
  scenarioSelected: {
    type: Number,
    default: null,
  },
  municipalities: {
    type: Array,
    default: () => [],
  },
  municipalitySelected: {
    type: String,
    default: null,
  },
  reduceScenarioInfo: {
    type: Boolean,
    default: false,
  },
  municipalityCount: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([
  'expanded',
  'select-scenario',
  'select-municipality',
]);

// component interaction regarding tabs, filters, denominator, expanded selects
const scenarioHoverIndex = ref(null);
const tabConfig = ref([
  {
    key: 'municipality',
    items: [
      {
        id: 'municipality',
        label: 'Gemeinde',
        expanded: false,
        searchLabel: 'Name der Kommune',
      },
      {
        id: 'scenario',
        label: computed(
          () => `Verknüpfte Szenarien (${props.scenarios.length})`,
        ),
        show: true,
        expanded: false,
        searchLabel: 'Name des Szenarios',
      },
    ],
  },
  {
    key: 'scenario',
    items: [
      {
        id: 'scenario',
        label: 'Szenario',
        expanded: false,
        searchLabel: 'Name der Kommune',
      },
      {
        id: 'municipality',
        label: computed(
          () => `Betroffene Gemeinden (${props.municipalityCount})`,
        ),
        show: true,
        expanded: false,
        searchLabel: 'Name der Kommune',
      },
    ],
  },
]);
const municipalityWrapper = ref(null);
const containerHeight = computed(() => store.state.map.containerHeight);
const expandedItemId = computed(() => {
  const activeConfig = tabConfig.value.find(
    (tab) => tab.key === props.denominator,
  );
  return activeConfig.items.find((item) => item.expanded)?.id;
});

const isAnyExpanded = computed(() => {
  const activeConfig = tabConfig.value.find(
    (tab) => tab.key === props.denominator,
  );
  return activeConfig.items.some((item) => item.expanded);
});

const isSecondTabVisible = computed(() => {
  const activeConfig = tabConfig.value.find(
    (tab) => tab.key === props.denominator,
  );
  return activeConfig.items[1].show;
});

const denominatorConfig = computed(() => {
  return tabConfig.value.find((tab) => tab.key === props.denominator).items;
});

const isSelectedScenarioOutdated = computed(() => {
  return props.scenarios.find((e) => e.scenario_id === props.scenarioSelected)
    ?.building_data_changed;
});

const filterText = ref(null);

const selectedNames = computed(() => {
  const municipality =
    props.municipalities.find(
      (e) => e.municipality_key === props.municipalitySelected,
    )?.name || '--';
  const scenario =
    props.scenarios.find((e) => e.scenario_id === props.scenarioSelected)
      ?.name || '--';
  return { municipality, scenario };
});

const filteredMunicipalities = computed(() => {
  const items = props.municipalities;

  if (!filterText.value) {
    return items;
  }

  return items.filter((e) =>
    e.name.toLowerCase().includes(filterText.value.toLowerCase()),
  );
});

const filteredScenarios = computed(() => {
  const items = props.scenarios;

  if (!filterText.value) {
    return items;
  }

  return items.filter((e) =>
    e.name.toLowerCase().includes(filterText.value.toLowerCase()),
  );
});

function closeAllExpanded() {
  tabConfig.value.forEach((tab) => {
    tab.items.forEach((item) => {
      item.expanded = false;
    });
  });
}

function handleClickOutside(event) {
  if (!municipalityWrapper.value.contains(event.target)) {
    closeAllExpanded();
  }
}

function handleKeydown(event) {
  if (
    event.key === 'Escape' &&
    !municipalityWrapper.value.contains(event.target)
  ) {
    closeAllExpanded();
  }
}

function toggleShow(index) {
  const tab = tabConfig.value.find((tab) => tab.key === props.denominator);
  tab.items.forEach((item, i) => {
    if (i === index) {
      item.expanded = !item.expanded;
    } else {
      item.expanded = false;
    }
  });
}

function toggleSecondTab() {
  const activeConfig = tabConfig.value.find(
    (tab) => tab.key === props.denominator,
  );
  activeConfig.items[1].show = !activeConfig.items[1].show;

  // if the second tab is expanded, it should be closed and the first tab should be expanded
  if (activeConfig.items[1].expanded) {
    activeConfig.items[0].expanded = true;
    activeConfig.items[1].expanded = false;
  }
}

function getIconFill(index) {
  return index === 0 ? 'text-color1' : 'text-neutral';
}

function isSelectedScenario(item) {
  return item.scenario_id === props.scenarioSelected;
}

function isSelectedMunicipality(item) {
  if (!props.municipalitySelected) return false;
  return props.municipalitySelected === item.municipality_key;
}

function selectMunicipality(municipalityKey) {
  emit('select-municipality', municipalityKey);
  filterText.value = null;
  closeAllExpanded();
}

function selectScenario(scenario) {
  emit('select-scenario', scenario.scenario_id);
  closeAllExpanded();
}

watch(isAnyExpanded, (val) => {
  emit('expanded', val);
});

watch(
  () => props.denominator,
  () => {
    tabConfig.value.forEach((tab) => {
      tab.items.forEach((item) => {
        item.show = true;
        item.expanded = false;
      });
    });
  },
);

watch(expandedItemId, () => {
  filterText.value = null;
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.municipality-wrapper {
  display: flex;
  flex-direction: column;
  @apply rounded-[15px];

  .municipality-list-wrapper {
    @apply p-2 pt-0 rounded-b-[8px] flex flex-col gap-1 w-full left-0
    top-[59px] z-10;

    @apply absolute bg-white gap-[6px];
    @extend .standard-elevation-1;

    &::before {
      @apply absolute left-0 top-[-3px] w-full h-[3px] bg-white;
      content: '';
    }

    .municipality-item {
      @apply bg-subtle rounded-[3px] flex items-center justify-start p-2 min-w-max h-8
      cursor-pointer text-neutral;
      @extend .body-2;

      &.disabled {
        pointer-events: none;
        background-color: white;
        opacity: 0.7;
      }
    }
  }
}
</style>
