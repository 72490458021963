import MapboxGlDraw from '@mapbox/mapbox-gl-draw';
import area from '@turf/area';

const measureAreaMode = Object.assign({}, MapboxGlDraw.modes.draw_polygon);

measureAreaMode.clickAnywhere = function (state, e) {
  const clickAnywhere = MapboxGlDraw.modes.draw_polygon.clickAnywhere.bind(
    this,
    state,
    e,
  );
  clickAnywhere();
  const areaSquareMeters = area(state.polygon.toGeoJSON());
  this.map.fire('measure:update', { area: areaSquareMeters });
};

export default measureAreaMode;
