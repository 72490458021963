<template>
  <AuthFormWrapper
    :title="title"
    :error="registrationError"
    error-message="Die Registrierung konnte nicht abgeschlossen werden. Bitte wenden Sie
        sich an unseren Kundenservice."
  >
    <!-- form tag needed for autofill functionality -->
    <FormValidator v-model="isFormValid">
      <InputEl
        v-model="firstName"
        :rules="{ required: true }"
        label="Vorname"
        placeholder="Bitte eingeben"
        class="w-full"
        @update:model-value="registrationError = false"
      />
      <InputEl
        v-model="surName"
        :rules="{ required: true }"
        label="Nachname"
        placeholder="Bitte eingeben"
        class="w-full"
        @update:model-value="registrationError = false"
      />
      <InputEl
        v-model="password"
        :rules="{
          required: true,
          length: true,
          uppercase: true,
          number: true,
          specialChar: true,
        }"
        :type="showPassword ? 'text' : 'password'"
        label="Neues Passwort"
        placeholder="Bitte eingeben"
        :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
        :suffix-icon="true"
        :suffix-clickable="true"
        class="w-full"
        @icon-click="showPassword = !showPassword"
        @update:model-value="registrationError = false"
      />
      <InputEl
        v-model="passwordConfirm"
        :rules="{ required: true, match: password }"
        :type="showPassword ? 'text' : 'password'"
        label="Neues Passwort bestätigen"
        placeholder="Bitte eingeben"
        :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
        :suffix-icon="true"
        :suffix-clickable="true"
        class="w-full"
        @icon-click="showPassword = !showPassword"
        @keyup.enter="confirmRegisterUser"
        @update:model-value="registrationError = false"
      />
      <ButtonEl
        text="Registrieren"
        :full-width="true"
        :disabled="!isFormValid || isSubmitting"
        class="mt-4"
        @click="confirmRegisterUser"
      />
    </FormValidator>
  </AuthFormWrapper>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AuthFormWrapper from '@/apps/auth/AuthFormWrapper.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import EyeCrossedIcon from '@/components/storybook/src/assets/icons/EyeCrossedIcon.vue';
import EyeIcon from '@/components/storybook/src/assets/icons/EyeIcon.vue';

const store = useStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const isFormValid = ref(false);
const isSubmitting = ref(false);

const successToast = ref(
  'Registrierung erfolgreich! Sie können sich nun anmelden.',
);
const registrationError = ref(false);
const password = ref('');
const passwordConfirm = ref('');
const firstName = ref('');
const surName = ref('');
const showPassword = ref(false);

const title = computed(() => t('register.title'));

function confirmRegisterUser() {
  if (isFormValid.value) {
    isSubmitting.value = true;
    registerUser();
  }
}

function registerUser() {
  const formData = {
    firstName: firstName.value,
    surName: surName.value,
    password: password.value,
    token: route.params.token,
  };
  axios({
    url: '/api/create-account/',
    data: formData,
    method: 'POST',
  })
    .then(() => {
      router.push('/login');
      store.commit('layout/SHOWTOAST', {
        color: 'success',
        message: successToast.value,
      });
    })
    .catch(() => {
      isSubmitting.value = false;
      registrationError.value = true;
    });
}
</script>
