import { connectBuildingInterface } from '@/features/heat-project/connect-building-interface';

export default {
  getHeatSourcesAsFC: (state) => ({
    type: 'FeatureCollection',
    features: state.heatSources.map((e) => e.asFeature),
  }),
  heatProjectNetworkAsFC: (state) => ({
    type: 'FeatureCollection',
    features: state.heatProjects.flatMap((e) => e.networkFeatures),
  }),
  heatProjectCentroidAsFC: (state) => ({
    type: 'FeatureCollection',
    features: state.heatProjects.map((e) => e.centroidFeature),
  }),
  heatProjectEstateConnections: (state) => ({
    type: 'FeatureCollection',
    features: state.heatProjects.flatMap(
      (e) => e.connectBuildingInterface.pointFeatures,
    ),
  }),
  getHeatProjectData: (state) => state.heatProjectData,
  getHeatProjects: (state) => state.heatProjectSelectables,
};
