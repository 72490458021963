<template>
  <div
    class="w-full bg-default rounded-md flex flex-col p-5"
    :class="{ 'gap-5': !readOnly }"
  >
    <h5 class="text-title-color2">Zuteilung Wärmeversorgungsgebiete</h5>
    <div class="flex flex-col w-[35%] gap-5">
      <InputEl
        :key="heatSupplyThresholds[0].key"
        v-model="dominantTechnologyVeryLikelyThreshold"
        :label="heatSupplyThresholds[0].label"
        :disabled="readOnly"
        suffix="%"
        input-type="float"
        :tooltip-text="heatSupplyThresholds[0].tooltip"
        :rules="{
          required: true,
          min: scenario[heatSupplyThresholds[1].key] || 0,
          max: 100,
        }"
        @update:model-value="emitHeatNetworkThresholds"
      />
      <InputEl
        :key="heatSupplyThresholds[1].key"
        v-model="dominantTechnologyLikelyThreshold"
        :label="heatSupplyThresholds[1].label"
        :disabled="readOnly"
        suffix="%"
        input-type="float"
        :tooltip-text="heatSupplyThresholds[1].tooltip"
        :rules="{
          required: true,
          min: scenario[heatSupplyThresholds[2].key] || 0,
          max: scenario[heatSupplyThresholds[0].key] || 100,
        }"
        @update:model-value="emitHeatNetworkThresholds"
      />
      <InputEl
        :key="heatSupplyThresholds[2].key"
        v-model="dominantTechnologyNotLikelyThreshold"
        :label="heatSupplyThresholds[2].label"
        :disabled="readOnly"
        suffix="%"
        input-type="float"
        :tooltip-text="heatSupplyThresholds[2].tooltip"
        :rules="{
          required: true,
          min: 0,
          max: scenario[heatSupplyThresholds[1].key] || 100,
        }"
        @update:model-value="emitHeatNetworkThresholds"
      />
    </div>
  </div>
  <div
    class="w-full bg-default rounded-md flex flex-col p-5"
    :class="{ 'gap-5': !readOnly }"
  >
    <h5 class="text-title-color2">Manuelle Festlegung von Vorranggebieten</h5>
    <DropDown
      v-if="!readOnly"
      v-model="priorityArea"
      :items-data="scenarioDistrictOptions.priority_area.choices"
      items-data-key="display_name"
      placeholder="Keine"
      label="Typ Vorranggebiet"
      :clearable="true"
      class="min-w-[400px] w-fit"
    />
    <DetailSelection
      :apply-selection-enabled="true"
      :show-unset-filter="false"
      :read-only
      :available-items="availableItems"
      :filtered-items="districtsForPriorityAreas"
      :selected-items="selectedPriorityAreasDistricts"
      :headers="tableHeaders.priorityArea"
      :granular-selection-enabled="true"
      :key-name-for-checkbox="'district'"
      @update:filter="priorityAreasFilters = $event"
      @item-click="selectedPriorityAreasDistricts = [...$event]"
      @selection-button-click="emitPriorityData(selectedPriorityAreasDistricts)"
    />
  </div>
  <div
    class="w-full bg-default rounded-md flex flex-col p-5"
    :class="{ 'gap-5': !readOnly }"
  >
    <div class="flex">
      <h5 class="text-title-color2">
        Festlegung technologiespezifischer Ausbaulimits pro Jahr
      </h5>
      <span class="relative">
        <ToolTipOnHover
          tooltip-text="Ausbaulimit: maximaler Wert, bis zu dem die jeweilige Technologie ausgebaut wird"
          tooltip-container-classes="absolute bottom-[-2px]"
          :icon-size="22"
        />
      </span>
    </div>
    <template v-if="!readOnly">
      <DropDown
        v-model="expansionLimitUnit"
        :initial-selection="true"
        :items-data="scenarioDistrictOptions.expansion_limit_unit.choices"
        items-data-key="display_name"
        class="min-w-[400px] w-fit"
        label="Einheit Ausbaulimit"
        :rules="{ required: true }"
      />
      <div
        v-for="choice in scenarioDistrictOptions.priority_area.choices"
        :key="choice.value"
        class="flex gap-2.5 items-end"
      >
        <CheckboxEl
          v-model="selectedPriorityAreas[choice.value]"
          class="mb-3"
          @update:model-value="resetIfDeactivated(choice.value)"
        />
        <InputEl
          :model-value="
            expansionLimitValue[
              choice.value === 'DIS'
                ? 'expansion_limit_heating_network'
                : choice.value === 'H2'
                  ? 'expansion_limit_hydrogen_network'
                  : choice.value === 'PMP'
                    ? 'expansion_limit_decentral'
                    : null
            ]
          "
          :label="choice.display_name"
          :input-type="expansionLimitUnit === 2 ? 'integer' : 'float'"
          :placeholder="displayNameTechnicalUnit.placeholder"
          :suffix="displayNameTechnicalUnit.suffix"
          :disabled="!selectedPriorityAreas[choice.value]"
          class="w-fit min-w-[374px]"
          @update:model-value="
            expansionLimitValue[
              choice.value === 'DIS'
                ? 'expansion_limit_heating_network'
                : choice.value === 'H2'
                  ? 'expansion_limit_hydrogen_network'
                  : choice.value === 'PMP'
                    ? 'expansion_limit_decentral'
                    : null
            ] = $event
          "
        />
      </div>
    </template>
    <DetailSelection
      class="w-full"
      :apply-selection-enabled="true"
      :show-unset-filter="false"
      :read-only
      :available-items="availableItems"
      :filtered-items="districtsForExpansionLimit"
      :selected-items="selectedExpansionLimitDistricts"
      :headers="tableHeaders.expansionLimit"
      :granular-selection-enabled="true"
      :key-name-for-checkbox="'district'"
      @update:filter="expansionLimitFilters = $event"
      @item-click="selectedExpansionLimitDistricts = [...$event]"
      @selection-button-click="
        emitExpansionLimitData(selectedExpansionLimitDistricts)
      "
    />
  </div>
</template>

<script setup>
import {
  heatSupplyThresholds,
  tableHeaders,
} from '@/apps/scenarios/scenario-create-data';
import { computed, ref, watch } from 'vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import DetailSelection from '@/apps/scenarios/components/shared/DetailSelection.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import { isNullOrUndefined } from '@/utils/generalUtils';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';
import { useDetailFiltering } from '@/apps/scenarios/detailFiltering';

const props = defineProps({
  scenario: Object,
  activeTab: Number,
  scenarioDistrictOptions: Object,
  districtParameterizations: Array,
  availableCounties: Array,
  availableItems: Object,
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['setOptionalParameter', 'setHeatNetworkThresholds']);

const {
  selectedItems: selectedPriorityAreasDistricts,
  filters: priorityAreasFilters,
  filteredItems: filteredPriorityAreasDistricts,
} = useDetailFiltering(props.districtParameterizations, props.availableItems, [
  'priority_area',
]);
const {
  selectedItems: selectedExpansionLimitDistricts,
  filters: expansionLimitFilters,
  filteredItems: filteredExpansionLimitDistricts,
} = useDetailFiltering(props.districtParameterizations, props.availableItems, [
  'expansion_limit_unit',
  'expansion_limit_heating_network',
  'expansion_limit_hydrogen_network',
  'expansion_limit_decentral',
]);

// setting Values Logic
const priorityArea = ref(null);
const expansionLimitUnit = ref(null);
const expansionLimitValue = ref({
  expansion_limit_heating_network: null,
  expansion_limit_hydrogen_network: null,
  expansion_limit_decentral: null,
});
const selectedPriorityAreas = ref({});
const dominantTechnologyVeryLikelyThreshold = ref(
  props.scenario.dominantTechnologyVeryLikelyThreshold,
);
const dominantTechnologyLikelyThreshold = ref(
  props.scenario.dominantTechnologyLikelyThreshold,
);
const dominantTechnologyNotLikelyThreshold = ref(
  props.scenario.dominantTechnologyNotLikelyThreshold,
);

function emitPriorityData(districtIds) {
  const updateDistrictParameter = {};
  for (const districtId of districtIds) {
    updateDistrictParameter[districtId] = {
      priority_area: priorityArea.value,
    };
  }
  emit('setOptionalParameter', updateDistrictParameter);
}

function emitHeatNetworkThresholds() {
  emit('setHeatNetworkThresholds', {
    dominantTechnologyVeryLikelyThreshold:
      dominantTechnologyVeryLikelyThreshold.value,
    dominantTechnologyLikelyThreshold: dominantTechnologyLikelyThreshold.value,
    dominantTechnologyNotLikelyThreshold:
      dominantTechnologyNotLikelyThreshold.value,
  });
}

function emitExpansionLimitData(districtIds) {
  const updateDistrictParameter = {};
  for (const districtId of districtIds) {
    updateDistrictParameter[districtId] = {
      expansion_limit_unit: expansionLimitUnit.value,
      ...expansionLimitValue.value,
    };
  }
  emit('setOptionalParameter', updateDistrictParameter);
}

const expansionLimitMap = {
  DIS: 'expansion_limit_heating_network',
  H2: 'expansion_limit_hydrogen_network',
  PMP: 'expansion_limit_decentral',
};

function resetIfDeactivated(choice) {
  const expansionKey = expansionLimitMap[choice];
  if (selectedPriorityAreas.value[choice] === false) {
    expansionLimitValue.value[expansionKey] = null;
  }
}

const districtsForPriorityAreas = computed(() => {
  return filteredPriorityAreasDistricts.value.map((district) => {
    const priorityAreaChoice =
      props.scenarioDistrictOptions.priority_area.choices.find(
        (item) => item.value === district.priority_area,
      );

    return {
      ...district,
      priorityArea: priorityAreaChoice?.display_name || '-',
    };
  });
});

const districtsForExpansionLimit = computed(() => {
  return filteredExpansionLimitDistricts.value.map((district) => {
    const {
      expansion_limit_unit,
      expansion_limit_heating_network,
      expansion_limit_hydrogen_network,
      expansion_limit_decentral,
    } = district;
    const unitChoice =
      props.scenarioDistrictOptions.expansion_limit_unit.choices.find(
        (item) => item.value === expansion_limit_unit,
      );
    const unitDisplayName =
      unitChoice?.display_name === 'Hausanschlüsse in Anzahl'
        ? 'Hausanschlüsse'
        : unitChoice?.display_name.split(' ').pop();
    const heatingNetworkVal = isNullOrUndefined(expansion_limit_heating_network)
      ? '-'
      : `${expansion_limit_heating_network} ${unitDisplayName}`;
    const hydroNetworkVal = isNullOrUndefined(expansion_limit_hydrogen_network)
      ? '-'
      : `${expansion_limit_hydrogen_network} ${unitDisplayName}`;
    const decentralAreaVal = isNullOrUndefined(expansion_limit_decentral)
      ? '-'
      : `${expansion_limit_decentral} ${unitDisplayName}`;

    return {
      ...district,
      heatingNetwork: heatingNetworkVal,
      hydrogenNetwork: hydroNetworkVal,
      decentralArea: decentralAreaVal,
    };
  });
});

const displayNameTechnicalUnit = computed(() => {
  const selectedUnitOption =
    props.scenarioDistrictOptions.expansion_limit_unit.choices.find(
      (unit) => unit.value === expansionLimitUnit.value,
    );
  const displayName = selectedUnitOption ? selectedUnitOption.display_name : '';
  const placeholder = `${displayName.split(' ').shift()} eingeben`;
  const suffix =
    displayName === 'Hausanschlüsse' ? 'Anzahl' : displayName.split(' ').pop();
  return { placeholder, suffix };
});

watch(props.scenarioDistrictOptions, () => {
  props.scenarioDistrictOptions.priority_area.choices.forEach((choice) => {
    selectedPriorityAreas.value[choice.value] = false;
  });
});
</script>

<style scoped></style>
