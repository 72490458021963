<template>
  <div class="grid grid-cols-3">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="flex flex-col items-center"
      :class="[
        {
          'tab--active': activeTab === index,
        },
        (index === 1 || index === 2) &&
        triggerActiveTab === 0 &&
        !validCards.includes(0)
          ? 'pointer-events-none'
          : 'cursor-pointer',
      ]"
      @click="setActiveTab(index)">
      <!-- Icon will be conditionally styled based on the active tab -->
      <div :class="{ 'text-[#007bff]': activeTab === index }" class="p-2.5">
        <Component :is="tab.icon" :fill="tabFillColor(index)" />
      </div>
      <div
        class="w-full h-1.5 bottom-line"
        :style="{ backgroundColor: tabFillColor(index) }"
        :class="{
          'rounded-l-[4px]': index === 0,
          'rounded-r-[4px]': index === tabs.length - 1,
        }"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  validCards: {
    type: Array,
    default: () => [],
  },
  triggerActiveTab: {
    type: Number,
    default: 0,
  },
  disableAutomaticTabSwitching: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:activeTab', 'clickTab']);
const activeTab = ref(0);

watch(activeTab, (newValue) => {
  emit('update:activeTab', newValue);
});

watch(
  () => props.triggerActiveTab,
  (newValue) => {
    activeTab.value = newValue;
  },
);

function setActiveTab(index) {
  if (!props.disableAutomaticTabSwitching) {
    activeTab.value = index;
  } else {
    emit('clickTab', index);
  }
}

function tabFillColor(index) {
  const isActive = activeTab.value === index;
  const isValid = props.validCards.includes(index);
  const isValidLessOne = Array.from(
    { length: props.tabs.length - 1 },
    (_, i) => index - i - 1,
  ).some((i) => props.validCards.includes(i));

  if (isActive && !isValid) {
    return '#00A1D1'; // Edit Active tab color
  } else if (isActive && isValid) {
    return '#4CAF50'; // Valid Active tab color
  } else if (!isActive && isValid) {
    return '#4CAF50'; // Valid Inactive tab color
  } else if (!isActive && isValidLessOne) {
    return '#B0BEC5'; // Color for inactive tabs
  } else {
    return '#CFD8DC'; // Color for deactivated tabs
  }
}
</script>
