<template>
  <div class="max-h-[68px] h-fit min-w-[68px] flex justify-center items-center">
    <div v-if="logoUrl">
      <img
        v-show="imageLoaded"
        :src="logoUrl"
        alt="logo-client"
        class="w-full object-contain rounded-[2px] standard-elevation-1"
        @load="imageLoaded = true"
        @error="imageLoaded = false"
      />
    </div>
    <IconGenericWappen v-else class="mt-0.5" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import IconGenericWappen from '@/components/municipality-scenario-selector/IconGenericWappen.vue';

const store = useStore();
const logoUrl = computed(() => store.getters.getLogoUrl);
const imageLoaded = ref(false);

onMounted(() => {
  store.dispatch('GET_LOGO_URL');
});
</script>
