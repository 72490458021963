export const panels = [
  {
    title: 'Anschrift',
    fields: [{ dbColumnKey: 'street' }, { dbColumnKey: 'house_number' }],
  },
  {
    title: 'Gebäudeinformation',
    fields: [
      { dbColumnKey: 'function', effectsHeatDemand: true },
      { dbColumnKey: 'construction_method', effectsHeatDemand: true },
      { dbColumnKey: 'construction_year', effectsHeatDemand: true },
      {
        dbColumnKey: 'is_construction_year_known',
        effectsHeatDemand: false,
        patchable: true,
      },
    ],
  },
  {
    title: 'Gebäudenutzfläche',
    fields: [
      { dbColumnKey: 'height_m', unit: 'm', effectsHeatDemand: true },
      { dbColumnKey: 'floor_count', effectsHeatDemand: true },
    ],
  },
  {
    title: 'Nutzungsstruktur',
    fields: [
      { dbColumnKey: 'is_municipal', patchable: true },
      { dbColumnKey: 'is_high_rise', patchable: true },
    ],
  },
  {
    title: 'Wärme',
    fields: [
      { dbColumnKey: 'demand_kwh', unit: 'kWh/a' },
      { dbColumnKey: 'heating_carrier', effectsHeatDemand: true },
    ],
  },
];
