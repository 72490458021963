<template>
  <div class="flex flex-col px-2 bg-infra-highlight-25">
    <div
      class="flex items-center py-1 overflow-hidden"
      :class="{ 'w-fit': noDataAvailable }"
    >
      <SwitchItem
        v-model="active"
        :disabled="noDataAvailable"
        :label="menuItem.title"
        :data-test="`switch-item-${menuItem.title}`"
      />
      <span v-if="!noDataAvailable && menuItem.tooltip" class="relative ml-1"
        ><ToolTipOnHover :tooltip-text="menuItem.tooltip" :icon-size="18"
      /></span>
      <v-icon
        v-if="noDataAvailable"
        class="ml-1 hover:cursor-pointer hover:text-infra-highlight"
        size="16"
        color="grey"
        icon="mdi-help-circle"
        @click="showInfo()"
      />
    </div>

    <!-- Sub-Radio-List -->
    <div v-if="menuItem.radioGroups" class="w-full">
      <transition
        v-for="radioGroup in menuItem.radioGroups"
        :key="radioGroup.index"
        name="reveal"
      >
        <v-list-item
          v-show="active"
          class="p-0"
          style="padding-inline-start: 0 !important"
        >
          <v-radio-group
            v-model="radioGroup.activated"
            hide-details="auto"
            class="sub-radio-list-group pb-2"
          >
            <div v-for="(item, index) in radioGroup.radios" :key="index">
              <v-radio :value="index" @change="emitRadioAction(item)">
                <template #label>
                  <div class="flex justify-between w-full">
                    <div class="flex items-center">
                      <span
                        class="body-3"
                        :data-test="item.title.replace(/\s+/g, '_')"
                      >
                        {{ item.title }}
                      </span>
                      <div class="relative w-10 ml-1">
                        <ToolTipOnHover
                          v-if="item.tooltip"
                          tooltip-container-classes="absolute bottom-[-8px]"
                          :tooltip-text="item.tooltip"
                          :icon-size="20"
                        />
                      </div>
                    </div>
                    <div class="flex">
                      <ToolTipOnHover
                        v-if="
                          yearDependantFilterData[item.onLayers]?.[
                            item.extraFilter
                          ]
                        "
                        icon="filter_alt"
                        :tooltip-text="`Filter für ${item.title} aktiv`"
                        icon-fill="text-color2"
                      />
                      <IconWrapper
                        v-if="item.extraFilter"
                        :class="
                          radioGroup.activated !== index &&
                          'pointer-events-none'
                        "
                        :fill="
                          radioGroup.activated !== index
                            ? 'text-disabled-neutral'
                            : ''
                        "
                        icon="display_settings"
                        @click="setExtraFilter(item.layerState)"
                      />
                    </div>
                  </div>
                </template>
              </v-radio>
              <ExtraFilter
                v-if="extraFilters[item.layerState]"
                :year-dependant-filter-data
                :radio-item="item"
              />
            </div>
          </v-radio-group>
        </v-list-item>
      </transition>
    </div>
    <!-- / Sub-Radio-List -->
  </div>
  <!-- / Switch-List -->
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SwitchItem from '@/components/storybook/src/stories/SwitchItem/SwitchItem.vue';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ExtraFilter from '@/features/map/layer-panel/input-forms/ExtraFilter.vue';

const props = defineProps({
  menuItem: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();

const extraFilters = ref({});

const yearDependantFilterData = computed(
  () => store.getters['map/getYearDependantFilterData'],
);
const noDataAvailable = computed(() => {
  const model = props.menuItem.disabledByEmptyModel;
  return model && store.state.map.emptyModels.includes(model);
});
const active = computed({
  get() {
    return store.getters['map/getSwitchStates'][props.menuItem.switchId];
  },
  set(val) {
    store.commit('map/CHANGE_SWITCH', {
      switches: [props.menuItem.switchId],
      active: val,
    });
  },
});

watch(active, (newActive) => {
  const s = props.menuItem;
  if (s.turnOffSwitches && newActive) emitChangeSwitch(s, false);
  if (typeof s.customFunction === 'function') s.customFunction(newActive);
  if (s.onLayers) emitLayerVisibility(s, newActive);
});

function emitLayerVisibility(switchItem, active) {
  let { onLayers, onActivateSetLayout, resetRadioOnActivate } = switchItem;
  if (active && resetRadioOnActivate) {
    for (let radioGroup of props.menuItem.radioGroups) {
      radioGroup.activated = 0;
    }
  }
  if (
    !active &&
    store.state.map.cachedSwitch?.turnOffSwitches?.includes(switchItem.switchId)
  ) {
    onLayers = switchItem.onLayers.filter(
      (e) => !store.state.map.cachedSwitch.onLayers.includes(e),
    );
  }
  store.commit('map/SET_LAYER_VIS', {
    onLayers,
    active,
    onActivateSetLayout: active && onActivateSetLayout,
  });
}

function emitRadioAction(item) {
  extraFilters.value = {};
  if (item.layerState) {
    store.commit('map/SET_LAYER_STATE', {
      layerKeys: item.onLayers,
      layerState: item.layerState,
    });
  }
  if (item.filter) {
    store.commit('map/ADD_FILTER', {
      layerKeys: item.layerKeys,
      filter: item.filter,
    });
  }
}

function emitChangeSwitch(switchItem, active) {
  store.commit('map/CHANGE_SWITCH', {
    switches: switchItem.turnOffSwitches,
    active,
  });
  store.commit('map/CACHE_SWITCH', switchItem);
}

function showInfo() {
  store.commit('layout/SHOWTOAST', {
    color: 'infra-highlight-500',
    message: 'Keine Daten oder Potenziale.',
    title: 'Keine Daten',
    timeout: 3000,
    multiLine: true,
    outlined: true,
  });
}

function setExtraFilter(layerState) {
  extraFilters.value[layerState] = !extraFilters.value[layerState];
}
</script>

<style lang="scss" scoped>
:deep(.v-label--clickable) {
  width: 100%;
  height: 22px;
}

:deep(.v-radio) {
  display: flex !important;
  align-items: center !important;
}
</style>
