import { axios } from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import { LAYER_KEY__HEAT_PROJECT_NETWORK } from '@/configs/layers/heatProject';
import { LAYERS } from '@/features/heat-project/constants';
import { fetchData } from './utils';

const urlMapper = (featureId) => ({
  [LAYERS.HEAT_SOURCE_CONNECTION]: `/api/heatprojects/heat-source-connection-segment/${featureId}`,
  [LAYERS.DISTRIBUTION_NETWORK]: `/api/heatprojects/distribution-network-segment/${featureId}`,
  [LAYERS.HOME_CONNECTION]: `/api/heatprojects/home-connection/${featureId}`,
});

export let metadataOptions = {};

export class DistributionNetworkSegmentPanel {
  layerIds = [LAYER_KEY__HEAT_PROJECT_NETWORK]; // this apparently has to be here even if it looks unused

  async requestFeatureData(feature, _) {
    const layerId = feature.layer.id;
    const url = urlMapper(feature.id)[feature.properties.type];

    const resp = await fetchData(url);

    if (resp === null) return resp;

    const featureData = resp.data;
    const pipeData = featureData.options.pipe_determined.children;

    metadataOptions = {
      ...featureData.options,
      pipe_inner_diameter: pipeData.inner_diameter,
      pipe_outer_diameter: pipeData.outer_diameter,
      pipe_friction_coefficient: pipeData.friction_coefficient,
      pipe_thermal_conductivity: pipeData.thermal_conductivity,
    };

    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    const { pipe_determined, ...rest } = featureData.result;
    const mainResult = {
      ...rest,
      ...(pipe_determined
        ? Object.keys(pipe_determined).reduce((acc, key) => {
            acc[`pipe_${key}`] = pipe_determined[key];
            return acc;
          }, {})
        : {}),
    };
    const yearlyResult = [];
    const panelConfig =
      store.state.map.layerConfigs[featureData.layerId].sidebar;

    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      null,
      metadataOptions,
    );
  }
}
