import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const state = {
  heatSources: [],
  heatProjects: [],
  heatProjectData: [],
  heatProjectSelectables: [],
  pipeSelectables: [],
  loadProjectsOnMapLoad: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
