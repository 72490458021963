<template>
  <div class="edit-Premises-custom">
    <div v-if="!Object.keys(structuredFields).length" class="flex flex-col">
      <EditScenarioFrameWorkSkeletonLoader />
    </div>
    <div v-else>
      <h3>{{ `Prämissenset "${Premises.name?.value}" bearbeiten` }}</h3>
      <v-card elevation="0" class="mt-3">
        <div class="border-b-2">
          <v-tabs
            v-model="tab"
            class="mb-[-2px]"
            density="compact"
            slider-color="#0692BC"
          >
            <v-tab :class="tabStyle" value="titel" :ripple="false"
              ><h5>Titel</h5></v-tab
            >
            <v-tab
              v-for="header in tabData"
              :key="header"
              :class="tabStyle"
              :value="header"
              :ripple="false"
              ><h5>{{ header }}</h5></v-tab
            >
          </v-tabs>
        </div>
        <v-card-text class="px-0">
          <v-window v-model="tab">
            <div v-if="tab === 'titel'">
              <TabTitle
                :label="'Prämissenset'"
                :scenario-frame-name="Premises.name"
                :read-only="readOnly"
                @update-name="Premises.name.value = $event"
              />
            </div>
            <TabDynamic
              v-if="tab !== 'titel'"
              :tab="tab"
              :data="getTabData(tab)"
              :read-only="readOnly"
            />
          </v-window>
        </v-card-text>
        <!-- finish-->
        <div class="flex justify-between items-center gap-[20px]">
          <ButtonEl
            v-if="Premises.progress === 1"
            text="Für Wirtschaftlichkeitsrechunng freigeben"
            color="success"
            data-test="enable-for-wire-computation"
            @click="releaseDialog = true"
          />
          <div class="flex gap-5 ml-auto">
            <ButtonEl text="Abbrechen" variant="secondary" @click="abortForm" />
            <ButtonEl
              text="Speichern"
              :disabled="readOnly || !Premises.isValid"
              @click="saveFrame()"
            />
          </div>
        </div>
        <VuetifyDialog
          v-model="releaseDialog"
          :title="`Prämissenset ${Premises.name.value} freigeben?`"
        >
          <template #content>
            Nach Veröffentlichung des Prämissensets kann dieses verwendet
            werden,
            <br />
            um die Wirtschaftlichkeitsrechnung zu parametrisieren. Eine
            nachträgliche
            <br />
            Bearbeitung ist jedoch nicht mehr möglich.
          </template>
          <template #actions>
            <ButtonEl
              variant="secondary"
              text="Abbrechen"
              @click="releaseDialog = false"
            />
            <ButtonEl
              text="freigeben"
              variant="secondary"
              color="success"
              data-test="enable-for-wire-computation-confirm"
              @click="releaseToEdit"
            />
          </template>
        </VuetifyDialog>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, nextTick, ref, watch } from 'vue';
import PremiseSet from './premise-set';
import cookie from 'vue-cookies';
import EditScenarioFrameWorkSkeletonLoader from '@/features/scenario-frame/components/EditScenarioFrameWorkSkeletonLoader.vue';
import TabDynamic from '@/features/scenario-frame/components/TabDynamic.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import TabTitle from '@/features/scenario-frame/components/TabTitle.vue';

const emit = defineEmits(['abort', 'updateBaseData']);

const props = defineProps({ editPremisesId: Number });

const tab = ref('Allgemein');
const tabStyle =
  'ml-0 px-2 normal-case text-[18px] hover:text-infra-highlight-500 ml-4 tracking-normal';
const tabData = computed(() => Object.keys(structuredFields.value).sort());
const getTabData = computed(() => (tab) => structuredFields.value[tab]);
const readOnly = computed(() => Premises.value.progress === 0);
const releaseDialog = ref(false);
const Premises = ref(new PremiseSet());
const structuredFields = ref({});

function updateName(name) {
  Premises.value.name = name;
}

watch(
  () => props.editPremisesId,
  (newId) => {
    axios({
      url: `/api/heatprojects/wi-re/premises/${newId}/`,
      method: 'GET',
    }).then((resp) => {
      structuredFields.value = {};
      Premises.value.populateData(resp.data);
      const fields = Premises.value.fields.concat(
        Premises.value.yearly.fields,
        Premises.value.power.fields,
      );
      for (const field of fields) {
        if (!field.wildcard) continue;
        const tab = field.wildcard.tab;
        const exp = field.wildcard.expandable;
        if (!structuredFields.value[tab]) {
          structuredFields.value[tab] = {};
        }
        if (!structuredFields.value[tab][exp]) {
          structuredFields.value[tab][exp] = [];
        }
        structuredFields.value[tab][exp].push(field);
      }
    });
  },
  { immediate: true },
);

function releaseToEdit() {
  const payload = Premises.value.payload;
  payload.progress = 0;
  axios({
    url: `/api/heatprojects/wi-re/premises/${Premises.value.id}/`,
    method: 'PATCH',
    data: payload,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    Premises.value.progress = 0;
    emit('updateBaseData');
    releaseDialog.value = false;
  });
}

function abortForm() {
  Premises.value = new PremiseSet();
  emit('abort', false);
  // scroll to top
  nextTick(() => {
    document.querySelector('.single-page-scroll-wrapper').scrollTo({
      top: 0,
    });
  });
}

function saveFrame() {
  const payload = Premises.value.payload;
  axios({
    url: `/api/heatprojects/wi-re/premises/${Premises.value.id}/`,
    method: 'PUT',
    data: payload,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    emit('updateBaseData');
    abortForm();
  });
}
</script>

<style lang="scss">
.edit-Premises-custom {
  @apply w-full;
  .v-window,
  .v-card {
    overflow: visible;
  }

  .v-tab {
    min-width: unset !important;

    h5 {
      @apply text-blue-grey-500;
    }

    &.v-slide-group-item--active h5 {
      color: #0692bc !important;
    }
  }
}
</style>
