import { en } from 'vuetify/locale';

export default {
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    faq: 'FAQ',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
  },
  board: {
    titlePlaceholder: 'Enter a title for this card',
    deleteDescription: 'Are you sure you want to delete this card?',
    editCard: 'Edit Card',
    deleteCard: 'Delete Card',
    state: {
      TODO: 'TODO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTING',
      DONE: 'DONE',
    },
  },
  chat: {
    online: 'Users Online ({count})',
    addChannel: 'Add Channel',
    channel: 'Channel | Channels',
    message: 'Message',
  },
  email: {
    compose: 'Compose Email',
    send: 'Send',
    subject: 'Subject',
    labels: 'Labels',
    emptyList: 'Empty email list',
    inbox: 'Inbox',
    sent: 'Sent',
    drafts: 'Drafts',
    starred: 'Starred',
    trash: 'Trash',
    work: 'Work',
    invoice: 'Invoice',
  },
  todo: {
    addTask: 'Add Task',
    tasks: 'Tasks',
    completed: 'Completed',
    labels: 'Labels',
  },
  dashboard: {
    activity: 'Activity',
    weeklySales: 'Weekly Sales',
    sales: 'Sales',
    recentOrders: 'Recent Orders',
    sources: 'Traffic Sources',
    lastweek: 'vs last week',
    orders: 'Orders',
    customers: 'Customers',
    tickets: 'Support Tickets',
    viewReport: 'View Report',
  },
  usermenu: {
    profile: 'Profile',
    signin: 'Sign In',
    dashboard: 'Dashboard',
    signout: 'Sign Out',
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Ocurred',
  },
  notFound: {
    title: 'Error 404',
    subTitle: 'Here is nothing to see',
  },
  check: {
    title: 'Set New Password',
    backtosign: 'Back to Sign In',
    newpassword: 'New Password',
    button: 'Set new password and Sign in',
    error: 'The action link is invalid',
    verifylink: 'Verifying link...',
    verifyemail: 'Verifying email address...',
    emailverified: 'Email verified! Redirecting...',
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle:
      'Enter your account email address and we will send you a link to reset your password.',
    email: 'Email',
    button: 'Request Password Reset',
    backtosign: 'Back to Sign In',
  },
  login: {
    title: 'Login',
    username: 'username',
    email: 'Email',
    password: 'Password',
    button: 'Sign In now',
    orsign: 'Or sign in with',
    forgot: 'Forgot password?',
    noaccount: "Don't have an account?",
    create: 'Create one here',
    error: 'The email / password combination is invalid',
  },
  register: {
    title: 'Create Account',
    name: 'Full name',
    email: 'Email',
    password: 'Password',
    button: 'Create Account',
    orsign: 'Or sign up with',
    agree: 'By signing up, you agree to the',
    account: 'Already have an account?',
    signin: 'Sign In',
  },
  reset: {
    title: 'Enter new password',
  },
  utility: {
    maintenance: 'In Maintenance',
  },
  faq: {
    call: 'Have other questions? Please reach out ',
  },
  menu: {
    gebaeude: 'Buildings',
    fernwaerme: 'District Heating',
    auswertung: 'Evaluation',
    oewinfra: 'Infrastructure',
    oewnvt: 'NVT',
    existence: 'existence',
    test: 'Test',
    search: 'Search...',
    dashboard: 'Dashboard',
    logout: 'Logout',
    profile: 'Profile',
    blank: 'Blank Page',
    pages: 'Pages',
    others: 'Others',
    email: 'Email',
    chat: 'Chat',
    news: 'News',
    filesharing: 'Filesharing',
    oewBreitband: 'OEW',
    board: 'Task Board',
    users: 'Users',
    usersList: 'List',
    usersEdit: 'Edit',
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify Email',
    authForgot: 'Forgot Password',
    authReset: 'Reset Password',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    levels: 'Menu Levels',
    disabled: 'Menu Disabled',
    docs: 'Documentation',
    feedback: 'Feedback',
    support: 'Support',
  },
  // Vuetify components translations
  $vuetify: {
    ...en,
  },
};
