import { infraModules } from '@/configs/infra-modules';
import mapCompass from '@/assets/svg/map-compass.svg';
import scenariosIcon from '@/assets/svg/scenarios-icon.svg';
import wireTileIcon from '@/assets/svg/wire-tile-icon.svg';
import statisticsIcon from '@/assets/svg/statistics-icon.svg';
import parameterIcon from '@/assets/svg/parameter-icon.svg';
import scenarioFrameIcon from '@/assets/svg/scenario-frame-icon.svg';
import materialConceptIcon from '@/assets/svg/material-concept-icon.svg';
import premisesIcon from '@/assets/svg/premises-icon.svg';
import projectsIcon from '@/assets/svg/projects-icon.svg';
import privacyIcon from '@/assets/svg/privacy-icon.svg';

const rawCardData = [
  {
    title: 'Kartenansicht & Editor',
    id: 'mapView',
    description: 'Geografische Verortung der Analysen in der Kartenansicht.',
    icon: mapCompass,
    row: 1,
    order: 1,
    routeName: 'mapView',
    module: infraModules.baseMap,
  },
  {
    title: 'Auswertungen & Dashboard',
    id: 'dashboards',
    description:
      'Grafische Auswertungen zu Bestands- und ' +
      'Potenzialanalyse sowie zu Zielszenarien.',
    icon: statisticsIcon,
    row: 1,
    order: 5,
    routeName: 'Dashboards',
    module: infraModules.dashboard,
  },
  {
    title: 'Parameter',
    id: 'parameter',
    description: 'Übersicht aller Parameter.',
    icon: parameterIcon,
    row: 2,
    order: 1,
    children: [
      {
        title: 'Szenariorahmen',
        description:
          'Erstellung des Szenariorahmens zur Beschreibung ' +
          'der Entwicklung von Technologien, Preise etc.',
        icon: scenarioFrameIcon,
        routeName: 'scenarioFrame',
        module: infraModules.scenarioFrames,
      },
      {
        title: 'Materialkonzept',
        description:
          'Bestimmung von Rohrtypen, Materialien etc. zur Berechnung der Strömungsdynamik.',
        icon: materialConceptIcon,
        routeName: 'MaterialConceptList',
        module: infraModules.scenarioFrames,
      },
      {
        id: 'premises',
        title: 'Prämissen',
        description: 'für die Wirtschaftslichkeitsberechnung.',
        icon: premisesIcon,
        routeName: 'premises',
        module: infraModules.wire,
      },
    ],
  },
  {
    title: 'Szenarien',
    description:
      'Erstellen und Berechnen individueller Szenarien ' +
      'zur Erreichung der Klimaneutralität.',
    icon: scenariosIcon,
    row: 2,
    order: 2,
    routeName: 'scenarios',
    module: infraModules.scenarios,
  },
  {
    title: 'Projektgebiete',
    description: 'Tabellarische Übersicht und Verwaltung',
    icon: projectsIcon,
    row: 2,
    order: 3,
    routeName: 'projects',
    module: infraModules.projectPlaner,
  },
  {
    title: 'Wirtschaftlichkeit',
    description:
      'Erstellen und Neuberechnen von Wirtschaftlichkeits-rechnungen',
    icon: wireTileIcon,
    row: 2,
    order: 4,
    routeName: 'EconomicEfficiency',
    module: infraModules.wire,
  },
  {
    title: 'Datenbereich',
    description: 'Verwalten Sie Ihre Daten hier.',
    row: 2,
    order: 5,
    routeName: 'DataSpace',
    icon: privacyIcon,
    module: infraModules.baseMap,
  },
];

export { rawCardData };
