import axiosBase from 'axios';

const axiosConfig = {
  withCredentials: true,
};

const location = window.location.hostname;
if (!window.location.hostname.split('.').includes('localhost')) {
  axiosConfig.baseURL = `https://${location}`;
  window['TILESERVER_URL'] = `https://${location}`;
} else {
  axiosConfig.baseURL = '';
  window['TILESERVER_URL'] = `http://${location}:8080`;
}

const axiosInstance = axiosBase.create(axiosConfig);

function axios(config) {
  return axiosInstance.request(config);
}

export function downloadFile(url, filename = null, headers = {}) {
  axiosBase
    .get(url, {
      responseType: 'blob',
      headers: headers,
    })
    .then((response) => {
      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = blobUrl;
      // setAttribute to 'download': Direct download vs. opening a new tab
      link.setAttribute('download', filename || getFileName(response));
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
}

function getFileName(response) {
  const headerString = response.headers['content-disposition'];
  const filename = headerString.split('filename=')[1];
  return filename || 'Datei';
}

export { axios };
export { axiosInstance };

export default axios;
