import { HEATING_TECHNOLOGY_LEGEND, SECTOR_LEGEND } from './legendSettings';
import { infraModules } from '@/configs/infra-modules';
import {
  darkenColor,
  energySourceColors,
  priorityAreaColors,
  sectorColors,
} from '../layers/baseData/map-colors';

export const LAYER_KEY__BUILDINGS = 'buildingmodel_buildingaggregate';

export default {
  [LAYER_KEY__BUILDINGS]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      app_name: 'buildingmodel',
      router_name: 'buildingAggregate',
      key_name: 'alkis_id',
      type: 'polygon',
      subType: 'fillExtrusion',
      id: LAYER_KEY__BUILDINGS,
      sourceLayer: LAYER_KEY__BUILDINGS,
      layerId: LAYER_KEY__BUILDINGS,
      visibility: 'visible',
      dependsOnScenario: true,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Gebäude',
      subtitles: [
        { key: 'alkis_id' },
        { key: 'postcode' },
        { key: 'district_name' },
        { key: 'street_with_number' },
      ],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Gebäudeinformationen',
              active: true,
              items: [
                {
                  key: 'function',
                  unit: '',
                },
                {
                  key: 'iwu_type',
                  unit: '',
                },
                {
                  key: 'sector',
                  unit: '',
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'construction_method',
                  unit: '',
                },
                {
                  key: 'construction_year',
                  unit: '',
                  forceString: true,
                },
                {
                  key: 'range_construction_year',
                  unit: '',
                  forceString: true,
                },
              ],
            },
            {
              title: 'Gebäudenutzflächen',
              active: false,
              items: [
                {
                  key: 'area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'height_m',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'floor_count',
                  unit: '',
                  decimal: 0,
                },
                {
                  key: 'usable_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'residential_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'non_residential_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Nutzungsstruktur',
              active: false,
              items: [
                {
                  key: 'units_residential',
                  unit: '',
                  decimal: 0,
                },
                {
                  key: 'number_residents',
                  unit: '',
                },
                {
                  key: 'units_commercial',
                  unit: '',
                  decimal: 0,
                },
                {
                  key: 'is_high_rise',
                  unit: '',
                },
                {
                  key: 'is_municipal',
                  unit: '',
                },
              ],
            },
          ],
        },
        {
          title: 'Wärme',
          active: false,
          module: infraModules.scenarioFrames,
          items: [
            {
              title: 'Wärmebedarf',
              active: true,
              items: [
                {
                  key: 'heat_demand_kwh',
                  unit: 'kWh/a',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kwh_usable_area_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kwh_per_resident',
                  unit: 'kWh/EW',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kw',
                  unit: 'kW',
                  decimal: 0,
                },
                {
                  key: 'heating_carrier',
                  unit: '',
                },
                {
                  key: 'refurbishment_status',
                  unit: '',
                },
                {
                  key: 'refurbishment_potential',
                  unit: '%',
                  decimal: 1,
                },
              ],
            },
            {
              title: 'Emissionen',
              active: false,
              items: [
                {
                  key: 'co2_emissions',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'co2_emissions_per_resident',
                  unit: 't CO₂/EW',
                  decimal: 1,
                },
                {
                  key: 'co2_emissions_savings',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'co2_emissions_savings_percentage',
                  unit: '%',
                  decimal: 0,
                  multiply: 100,
                },
              ],
            },
          ],
        },
        {
          title: 'Zielszenarien',
          active: false,
          items: [
            {
              title: 'Vollkostenvergleich',
              active: false,
              items: [
                {
                  key: 'full_costs_gas',
                  unit: '€/a',
                  decimal: 0,
                },
                {
                  key: 'full_costs_district',
                  unit: '€/a',
                  decimal: 0,
                },
                {
                  key: 'full_costs_pellet',
                  unit: '€/a',
                  decimal: 0,
                },
                {
                  key: 'full_costs_hydrogen',
                  unit: '€/a',
                  decimal: 0,
                },
                {
                  key: 'full_costs_heat_pump',
                  unit: '€/a',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Emissionsvergleich',
              active: false,
              items: [
                {
                  key: 'emissions_gas',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'emissions_district',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'emissions_pellet',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'emissions_hydrogen',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
                {
                  key: 'emissions_heat_pump',
                  unit: 't CO₂/a',
                  decimal: 1,
                },
              ],
            },
            {
              title: 'CO₂-Vermeidungskostenvergleich',
              active: false,
              items: [
                {
                  key: 'co2_avoidance_costs_district',
                  unit: '€/t CO₂',
                  decimal: 0,
                },
                {
                  key: 'co2_avoidance_costs_pellet',
                  unit: '€/t CO₂',
                  decimal: 0,
                },
                {
                  key: 'co2_avoidance_costs_hydrogen',
                  unit: '€/t CO₂',
                  decimal: 0,
                },
                {
                  key: 'co2_avoidance_costs_heat_pump',
                  unit: '€/t CO₂',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillExtrusionColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
          ['boolean', ['feature-state', 'hover'], false],
          '#19cbf5',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          ['boolean', ['feature-state', 'highlight'], false],
          '#00A1D1',
          '#888888',
        ],
        fillExtrusionHeight: ['get', 'height_m'],
        fillExtrusionOpacity: 0.8,
      },
      sector: {
        'fill-extrusion-color': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
          ['boolean', ['feature-state', 'hover'], false],
          '#19cbf5',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          ['boolean', ['feature-state', 'highlight'], false],
          '#00A1D1',
          [
            'match',
            ['get', 'sector'],
            0,
            sectorColors.COM,
            1,
            sectorColors.PUB,
            2,
            sectorColors.HH,
            4,
            sectorColors.IND,
            sectorColors.UNK,
          ],
        ],
        'fill-extrusion-height': ['get', 'height_m'],
        'fill-extrusion-opacity': 0.8,
      },
      heating_technology(year) {
        return {
          'fill-extrusion-color': [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            // '#5c0000',
            [
              'match',
              ['get', `heating_carrier_${year}`],
              'GAS',
              darkenColor(energySourceColors.GAS),
              'ELE',
              darkenColor(energySourceColors.ELE),
              'OIL',
              darkenColor(energySourceColors.OIL),
              'DIS',
              darkenColor(priorityAreaColors.DIS),
              'PMP',
              darkenColor(energySourceColors.PMP),
              'H2',
              darkenColor(energySourceColors.H2),
              'COA',
              darkenColor(energySourceColors.COA),
              'BIO',
              darkenColor(energySourceColors.BIO),
              'SOL',
              darkenColor(energySourceColors.SOL),
              'NH',
              darkenColor(energySourceColors.NULL),
              darkenColor(energySourceColors.UNK),
            ],
            ['boolean', ['feature-state', 'hover'], false],
            '#19cbf5',
            ['boolean', ['feature-state', 'marked'], false],
            '#00E5FF',
            ['boolean', ['feature-state', 'highlight'], false],
            '#00A1D1',
            [
              'match',
              ['get', `heating_carrier_${year}`],
              'GAS',
              energySourceColors.GAS,
              'ELE',
              energySourceColors.ELE,
              'OIL',
              energySourceColors.OIL,
              'DIS',
              priorityAreaColors.DIS,
              'PMP',
              energySourceColors.PMP,
              'H2',
              energySourceColors.H2,
              'COA',
              energySourceColors.COA,
              'BIO',
              energySourceColors.BIO,
              'SOL',
              energySourceColors.SOL,
              'NH',
              energySourceColors.NULL,
              energySourceColors.UNK,
            ],
          ],
          'fill-extrusion-height': ['get', 'height_m'],
          'fill-extrusion-opacity': 0.8,
        };
      },
      heat_demand(year) {
        return {
          'fill-extrusion-color': [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#5c0000',
            ['boolean', ['feature-state', 'hover'], false],
            '#19cbf5',
            ['boolean', ['feature-state', 'marked'], false],
            '#00E5FF',
            ['boolean', ['feature-state', 'highlight'], false],
            '#00A1D1',
            [
              'interpolate',
              ['linear'],
              ['get', `heat_demand_kwh_${year}`],
              0,
              '#fee8c8',
              100000,
              '#e34a33',
            ],
          ],
          'fill-extrusion-height': ['get', 'height_m'],
          'fill-extrusion-opacity': 0.8,
        };
      },
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: '#888888',
            name: 'Gebäude',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      sector: SECTOR_LEGEND('Gebäude nach Sektoren'),
      heating_technology: HEATING_TECHNOLOGY_LEGEND(
        'Gebäude nach Heiztechnologie',
      ),
      heat_demand: {
        type: 'gradient',
        name: 'Gebäude nach Wärmebedarf',
        unit: 'MWh/a',
        items: {
          min: {
            value: 0,
            color: '#fee8c8',
          },
          max: {
            value: 100,
            color: '#e34a33',
          },
        },
      },
    },
  },
};
