<template>
  <div class="flex flex-col w-full items-center py-7 chartItem">
    <h5 class="mb-7 text-center">{{ chartTitle }}</h5>
    <v-chart
      :option="option"
      autoresize
      :style="
        customMinHeight
          ? sortedChartData.length !== 0
            ? 'height:' + sortedChartData.length * customMinHeight + 'px'
            : ''
          : ''
      "
    />
  </div>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    chartTitle: {
      type: String,
      default: '',
    },
    multiply: {
      type: Number,
      default: null,
    },
    customMinHeight: {
      type: Number,
    },
    gridTop: {
      type: String,
      default: 'center',
    },
    gridBottom: {
      type: String,
      default: '',
    },
    filterZeroValues: {
      type: Boolean,
      default: true,
    },
    decimalPlaces: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    sortedChartData() {
      let data = this.chartData.map((item) => ({
        ...item,
        value: this.multiply ? item.value * this.multiply : item.value,
      }));

      // filter 0 values
      data = this.filterZeroValues
        ? data.filter((item) => item.value !== 0)
        : data;

      // sort by name
      data.sort((b, a) => a.name.localeCompare(b.name));
      return data;
    },
    option() {
      const sortedChartData = this.sortedChartData;
      return {
        grid: {
          left: 'center',
          top: '0px',
          bottom: '0px',
          containLabel: true,
          width: '70%',
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: sortedChartData.map((item) => item.name),
          axisLabel: {
            fontSize: 12,
            color: 'black',
            fontFamily: 'Inter, sans-serif',
            align: 'right',
            fontWeight: 500,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            data: sortedChartData.map((item) => ({
              ...item,
              emphasis: {
                itemStyle: {
                  color: item.color,
                },
              },
            })),
            label: {
              show: true,
              position: 'right',
              offset: [0, -0.5],
              formatter: function (params) {
                const value = Math.round(params.data.value * 100) / 100;
                if (value < 1) {
                  return value.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                } else {
                  return value.toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                }
              },
              color: 'black',
              fontWeight: '600',
              fontSize: 12,
            },
            barWidth: 24,
            itemStyle: {
              color: function (params) {
                const colorList = sortedChartData.map((item) => item.color);
                return colorList[params.dataIndex];
              },
            },
          },
        ],
      };
    },
  },
};
</script>
