import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';

export default {
  LOGIN: (_, user) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/login/',
        data: user,
        method: 'POST',
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  LOGOUT: ({ commit }) =>
    new Promise((resolve) => {
      axios({
        url: '/api/logout/',
        method: 'GET',
      }).then((resp) => {
        commit('SET_LOGOUT');
        resolve(resp);
      });
      resolve();
    }),
  PATCH_PROFILE_DATA: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/profile/',
        data: user.accountForm,
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('SET_PROFILE_DATA', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  PATCH_PASSWORD: (_, password) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/profile/password/',
        data: {
          passwordOld: password.passwordOld,
          passwordNew: password.passwordNew,
        },
        method: 'PATCH',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_PROFILE_DATA: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({ url: '/api/profile/', method: 'GET' })
        .then((resp) => {
          commit('SET_PROFILE_DATA', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_NOTIFICATIONS: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({ url: '/api/notifications/', method: 'GET' })
        .then((resp) => {
          commit('SET_NOTIFICATION_DATA', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  CHECK_CLIENT_HASH: ({ commit, state }) =>
    new Promise((resolve, reject) => {
      axios({ url: '/build-info.json', method: 'GET' })
        .then((resp) => {
          if (resp.data['build-id'] !== import.meta.env.VITE_BUILD_ID) {
            console.log('client update: forcing browser refresh');
            window.location.reload();
          }
          resolve(resp);
        })
        .catch((err) => {
          state.appHash = '';
          reject(err);
        });
    }),
};
