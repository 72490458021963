<template>
  <svg
    width="53"
    height="62"
    viewBox="0 0 53 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_33_5565)">
      <path
        d="M0 0V38.7422H0.0237725C0.0237725 51.5805 11.8741 62 26.4753 62C41.0765 62 52.9268 51.5805 52.9268 38.7422H52.8789V0H0Z"
        fill="#ECEFF1"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_5565">
        <rect width="52.9268" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
