import Vuetify from '../../../plugins/vuetify';

export default {
  /**
   * Main Toast
   */
  SHOWTOAST: (state, toast) => {
    const { color, timeout, message, outlined, multiLine, title, textColor } =
      toast;
    state.toast = {
      message,
      color,
      title,
      timeout,
      show: true,
      outlined,
      multiLine,
      textColor,
    };
  },
  HIDETOAST: (state) => {
    state.toast.show = false;
  },
  RESETTOAST: (state) => {
    state.toast = {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    };
  },
  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === 'dark';
    state.globalTheme = theme;
  },
  setMenuTheme: (state, theme) => {
    state.menuTheme = theme;
  },
  setToolbarTheme: (state, theme) => {
    state.toolbarTheme = theme;
  },
  setDrawerState: (state, payload) => {
    state.drawer = !payload;
  },
};
