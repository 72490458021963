import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import { getMap } from '@/mapbox/main';
import { BUILDINGS_WITHIN_HEATPROJECT } from '@/features/draw/constants';
import { GeoJsonLineString } from '@/features/draw/utilities';
import { LAYERS } from '@/features/heat-project/constants';
import { HeatProjectResult } from '@/features/heat-project/heat-project-result';
import { connectHeatSourceInterface } from '@/features/heat-project/connect-heat-source-interface';
import { connectBuildingInterface } from '@/features/heat-project/connect-building-interface';
import { HeatProjectParameter } from '@/features/heat-project/heat-project-parameter';
import NumberField from '@/fieldClasses/NumberField';
import MapboxGlDraw from '@mapbox/mapbox-gl-draw';

export default class HeatProject {
  static OPTIONS = null;
  static OPTIONS_LOADED = false;

  constructor(
    id = null,
    name = '',
    realisationYear = '',
    scenario = '',
    connectionRate = 1,
    simultaneityFactor = 0.7,
    preTemperatur = 90,
    returnTemperatur = 50,
    flowVelocity = 1.3,
    materialParameterSet = null,
  ) {
    if (!HeatProject.OPTIONS_LOADED) {
      HeatProject.OPTIONS_LOADED = true;
      this._fetchOptions();
    }
    // Create Edit Data (POST; PUT)
    this.id = id;
    this.scenario = scenario;
    this.name = name;
    this.year = realisationYear;
    this.connectionRate = connectionRate;
    this.simultaneityFactor = simultaneityFactor;
    this.materialParameterSet = materialParameterSet;
    this.preTemperatur = preTemperatur;
    this.returnTemperatur = returnTemperatur;
    this.flowVelocity = flowVelocity;
    this.minimumBuildingArea = 0;

    // result data (GET): gets initialized from backend data
    this.distributionNetworkFeatures = [];
    this.grouped_pipe_length = [];

    this.centroid = null;
    this.updated_at = null;
    this.scenario_updated = false;
    this.heatsource_updated = false;
    this.public_building_prio = false;
    this.materialSetName = null;

    this.connectHeatSourceInterface = new connectHeatSourceInterface();
    this.connectBuildingInterface = new connectBuildingInterface(
      scenario,
      realisationYear,
    );
    this.heatProjectParameter = new HeatProjectParameter();
    this.resultsConnectionRate = new HeatProjectResult();
    this.isEditable = true;

    return this;
  }

  initFromRequest(payload) {
    this.id = payload.id;
    this.scenario = payload.scenario;
    this.name = payload.name;
    this.year = payload.project_year;
    this.materialParameterSet = payload.material;
    this.materialSetName = payload.material_name;
    this.minimumBuildingArea = payload.minimal_building_area;
    this.centroid = payload.centroid;
    this.updated_at = payload.updated_at;
    this.scenario_updated = payload.scenario_updated;
    this.heatsource_updated = payload.heatsource_updated;
    this.grouped_pipe_length = payload.grouped_pipe_length;
    this.public_building_prio = payload.public_building_prio;
    this.connectionRate = payload.connection_rate;
    this.simultaneityFactor = payload.simultaneity_factor;
    this.preTemperatur = payload.pre_temperatur;
    this.returnTemperatur = payload.return_temperatur;
    this.flowVelocity = payload.flow_velocity;
    this.heatProjectParameter.opex_costs_euro_mwh = payload.opex_costs_euro_mwh;
    this.heatProjectParameter.interest_rate = payload.interest_rate;
    this.heatProjectParameter.working_life_sales_year =
      payload.working_life_sales_year;
    this.heatProjectParameter.funding_rate_percentage =
      payload.funding_rate_percentage;
    this.heatProjectParameter.sales_costs_euro_ha = payload.sales_costs_euro_ha;
    this.heatProjectParameter.revenue = payload.revenue;
    this.scenario_name = payload.scenario_name;
    this.isEditable = payload.is_editable;

    this.connectHeatSourceInterface = new connectHeatSourceInterface();
    this.connectBuildingInterface = new connectBuildingInterface(
      this.scenario,
      this.year,
    );

    // get diameters according to pipe ids in order to set line width of network
    // segments according to its outer diameter
    const pipesDiameters = payload.pipes_diameters;
    // save outer diameter values in Set in order to add
    // max value to heat source connection
    const innerDiameters = new Set();
    // set distribution network features
    if (payload.distribution_network) {
      for (const segment of payload.distribution_network) {
        const inner_diameter = pipesDiameters.find(
          (e) => e.id === segment.pipe_determined,
        ).inner_diameter;
        innerDiameters.add(inner_diameter);
        this.distributionNetworkFeatures.push(
          GeoJsonLineString(
            segment.geometry.coordinates,
            {
              id: payload.id,
              type: LAYERS.DISTRIBUTION_NETWORK,
              pipe_determined: segment.pipe_determined,
              pipe_edited: segment.pipe_edited,
              segment_id: segment.id,
              inner_diameter,
            },
            segment.id,
          ),
        );
      }
    }
    // set building network and point features
    if (payload.estate_connections) {
      this.connectBuildingInterface.initData(
        payload.estate_connections,
        pipesDiameters,
        payload.id,
      );
    }

    this.initialBuildingsCount = this.getBuildingIds.length;

    // set heat sources
    if (payload.heat_sources) {
      this.connectHeatSourceInterface.createHeatSources(payload.heat_sources);
    }
    // set heat source network features
    if (payload.heat_source_network) {
      this.connectHeatSourceInterface.createHeatSourceNetwork(
        payload.heat_source_network,
        pipesDiameters,
        payload.id,
      );
    }
    // init connection rate sensible result class
    if (payload.results) {
      this.resultsConnectionRate.initResult(payload.results, this.heatOutput);
    }
  }

  get options() {
    return HeatProject.OPTIONS;
  }

  set realisationYear(val) {
    this.year = val;
    this.connectBuildingInterface.year = val;
  }

  get realisationYear() {
    return this.year;
  }

  get objectPanelConfig() {
    const resultInfoPanelChipData =
      this.resultsConnectionRate.resultInfoPanelChipData(
        this.connectionRate,
        this.simultaneityFactor,
        this.options['simultaneity_factor'],
      );
    return {
      name: 'Projektgebiet Cluster',
      updatedInfo: [
        {
          value: this.scenario_updated,
          text: 'Szenario wurde aktualisiert',
        },
        {
          value: this.heatsource_updated,
          text: 'Wärmequelle wurde aktualisiert',
        },
      ],
      id: this.id,
      subtitles: [
        { value: this.name, ...this.options['name'] },
        { value: this.year, ...this.options['project_year'] },
        {
          value: this.getBuildingIds.length,
          ...this.options['count_buildings'],
        },
      ],
      scenarioName: this.scenario_name,
      enableDelete: true,
      enableEdit: this.isEditable,
      customDeleteWarning:
        'Durch Löschung dieses Projekts werden automatisch damit verknüpfte Wirtschaftlichkeitsrechnungen\ngelöscht.',
      customEditFunction(store) {
        store.commit('SET_MAP_ACTIONS', {
          processId: 'heatProject',
          editObjectId: this.id,
        });
      },
      customDeleteFunction(store) {
        store.dispatch('heatProject/DELETE_HEAT_PROJECT', this.id);
      },
      chips: [
        resultInfoPanelChipData,
        {
          title: 'Wärmequellen',
          active: false,
          items: this.getHeatSources.map((e) => ({
            title: e.name,
            active: false,
            items: e.objectPanelConfig.chips[0].items[0].items,
          })),
        },
        {
          title: 'Projektparameter',
          active: false,
          items: [
            {
              title: 'Strömungsmechanik',
              active: false,
              items: [
                {
                  value: this.materialSetName,
                  unit: '',
                  ...this.options['material_name'],
                },
                NumberField.fieldData(
                  this.simultaneityFactor * 100,
                  this.options['simultaneity_factor'],
                  '%',
                ),
                NumberField.fieldData(
                  this.preTemperatur,
                  this.options['pre_temperatur'],
                  '°C',
                  1,
                ),
                NumberField.fieldData(
                  this.returnTemperatur,
                  this.options['return_temperatur'],
                  '°C',
                  1,
                ),
                NumberField.fieldData(
                  this.flowVelocity,
                  this.options['flow_velocity'],
                  'm/s',
                  1,
                ),
              ],
            },
            {
              title: 'Ökonomische Parameter',
              active: false,
              items: [
                NumberField.fieldData(
                  this.heatProjectParameter.opex_costs_euro_mwh,
                  this.options['opex_costs_euro_mwh'],
                  '€/MWh',
                ),
                NumberField.fieldData(
                  this.heatProjectParameter.interest_rate,
                  this.options['interest_rate'],
                  '%',
                  4,
                  100,
                ),
                NumberField.fieldData(
                  this.heatProjectParameter.working_life_sales_year,
                  this.options['working_life_sales_year'],
                  'Jahre',
                ),
                NumberField.fieldData(
                  this.heatProjectParameter.funding_rate_percentage,
                  this.options['funding_rate_percentage'],
                  '%',
                  2,
                  100,
                ),
                NumberField.fieldData(
                  this.heatProjectParameter.sales_costs_euro_ha,
                  this.options['sales_costs_euro_ha'],
                  '€/HA',
                  2,
                ),
                NumberField.fieldData(
                  this.heatProjectParameter.revenue,
                  this.options['revenue'],
                  '€/MWh',
                  2,
                ),
              ],
            },
          ],
        },
      ],
    };
  }

  get getBuildingIds() {
    return Array.from(this.connectBuildingInterface.buildingIds);
  }

  get getHeatSources() {
    return this.connectHeatSourceInterface.heatSources;
  }

  get getHeatSourceIds() {
    return this.connectHeatSourceInterface.heatSources.map((e) => e.id);
  }

  get isValid() {
    return (
      [
        this.scenario,
        this.name,
        this.year,
        this.connectionRate,
        this.simultaneityFactor,
        this.preTemperatur,
        this.returnTemperatur,
        this.flowVelocity,
        this.materialParameterSet,
      ].findIndex((e) => e === null || e === '') === -1 &&
      this.connectBuildingInterface.isValid &&
      this.connectHeatSourceInterface.isValid
    );
  }

  get networkFeatures() {
    return [
      ...this.distributionNetworkFeatures,
      ...this.connectBuildingInterface.networkFeatures,
      ...this.connectHeatSourceInterface.networkFeatures,
    ];
  }

  get centroidFeature() {
    return {
      type: 'Feature',
      geometry: this.centroid,
      id: this.id,
      properties: {
        id: this.id,
      },
    };
  }

  get heatOutput() {
    let heatOutput = 0;
    this.getHeatSources.map((hs) => (heatOutput += parseFloat(hs.heatOutput)));
    return heatOutput;
  }

  get heatLoadWithFactor() {
    return (
      this.connectBuildingInterface.heatLoad *
      this.simultaneityFactor *
      this.connectionRate
    );
  }

  get heatDemandWithFactor() {
    return this.connectBuildingInterface.heatDemand * this.connectionRate;
  }

  _fetchOptions() {
    axios({
      url: '/api/heatprojects/HeatProject/',
      method: 'OPTIONS',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      HeatProject.OPTIONS = resp.data.actions.POST;
    });
  }

  /**
   * is executed when draw create event is triggered in order to synchronise
   * heat project object with editing on map
   */
  onDrawCreate(e) {
    const features = e.features;
    const updateHeatDemand = features.findIndex(
      (e) => e.properties.type === LAYERS.HOME_CONNECTION,
    );
    for (const feature of features) {
      if (feature.properties.type === BUILDINGS_WITHIN_HEATPROJECT) {
        this.connectBuildingInterface.getBuildingsWithin(
          feature,
          this.minimumBuildingArea,
        );
      }
      if (feature.properties.type === LAYERS.HOME_CONNECTION) {
        this.connectBuildingInterface.connectViaDraw(feature);
      }
      if (feature.properties.type === LAYERS.HEAT_SOURCE_CONNECTION) {
        this.connectHeatSourceInterface.connectViaDraw(feature);
      }
    }
    if (updateHeatDemand >= 0) this.connectBuildingInterface.fetchHeatDemand();
  }

  /**
   * is executed when draw delete event is triggered in order to synchronise
   * heat project object with editing on map
   */
  onDrawDelete(e) {
    const features = e.features;
    const updateHeatDemand = features.findIndex(
      (e) => e.properties.type === LAYERS.HOME_CONNECTION,
    );
    for (const feature of features) {
      if (feature.properties.type === LAYERS.HOME_CONNECTION) {
        this.connectBuildingInterface.disconnectViaDraw(feature);
      }
      if (feature.properties.type === LAYERS.HEAT_SOURCE_CONNECTION) {
        this.connectHeatSourceInterface.disconnectViaDraw(feature);
      }
    }
    if (updateHeatDemand >= 0) this.connectBuildingInterface.fetchHeatDemand();
  }

  /**
   * init eventhooks for map interaction to select buildings.
   */
  addDrawEventHooks() {
    this.onDrawCreate = this.onDrawCreate.bind(this);
    getMap().on(MapboxGlDraw.constants.events.CREATE, this.onDrawCreate);
    this.onDrawDelete = this.onDrawDelete.bind(this);
    getMap().on(MapboxGlDraw.constants.events.DELETE, this.onDrawDelete);
  }

  /**
   * remove eventhooks for map interaction
   */
  removeEventHooks() {
    this.connectBuildingInterface.removeEventHooks();
    this.connectHeatSourceInterface.removeEventHooks();
    getMap().off(MapboxGlDraw.constants.events.CREATE, this.onDrawCreate);
    getMap().off(MapboxGlDraw.constants.events.DELETE, this.onDrawDelete);
  }

  /**
   * construct POST payload for heat project calc API.
   */
  constructCreatePayload() {
    return {
      name: this.name,
      project_year: this.year,
      scenario: this.scenario,
      building_aggregates: this.getBuildingIds,
      heat_sources: this.connectHeatSourceInterface.constructCreatePayload(),
      connection_rate: this.connectionRate,
      simultaneity_factor: this.simultaneityFactor,
      material: this.materialParameterSet,
      pre_temperatur: this.preTemperatur,
      return_temperatur: this.returnTemperatur,
      flow_velocity: this.flowVelocity,
      public_building_prio: this.public_building_prio,
      minimal_building_area: this.minimumBuildingArea,
      opex_costs_euro_mwh: this.heatProjectParameter.opex_costs_euro_mwh,
      interest_rate: this.heatProjectParameter.interest_rate,
      working_life_sales_year:
        this.heatProjectParameter.working_life_sales_year,
      funding_rate_percentage:
        this.heatProjectParameter.funding_rate_percentage,
      sales_costs_euro_ha: this.heatProjectParameter.sales_costs_euro_ha,
      revenue: this.heatProjectParameter.revenue,
    };
  }

  /**
   * construct PUT payload for heat project calc API.
   */
  constructUpdatePayload(projectFeatures) {
    const payload = {
      name: this.name,
      project_year: this.year,
      scenario: this.scenario,
      simultaneity_factor: this.simultaneityFactor,
      heat_sources: this.getHeatSources.map((e) => e.id),
      heat_source_assignments: [],
      estate_connections: [],
      distribution_network: [],
      material: this.materialParameterSet,
      connection_rate: this.connectionRate,
      opex_costs_euro_mwh: this.heatProjectParameter.opex_costs_euro_mwh,
      interest_rate: this.heatProjectParameter.interest_rate,
      working_life_sales_year:
        this.heatProjectParameter.working_life_sales_year,
      funding_rate_percentage:
        this.heatProjectParameter.funding_rate_percentage,
      sales_costs_euro_ha: this.heatProjectParameter.sales_costs_euro_ha,
      revenue: this.heatProjectParameter.revenue,
    };
    const homeConnections = projectFeatures.filter(
      (e) => e.properties.type === LAYERS.HOME_CONNECTION,
    );
    for (const feature of projectFeatures) {
      const type = feature.properties.type;
      if (type === LAYERS.HEAT_SOURCE_CONNECTION) {
        payload.heat_source_assignments.push(
          this.connectHeatSourceInterface.constructUpdatePayload(feature),
        );
      }
      if (type === LAYERS.DISTRIBUTION_NETWORK) {
        payload.distribution_network.push({
          geometry: feature.geometry,
          pipe_edited: feature.properties.pipe_edited,
          project: this.id,
          id: feature.properties.segment_id || null,
          tmp_map_id: feature.id,
        });
      }
      if (type === LAYERS.HOME_CONNECTION_POINT) {
        payload.estate_connections.push(
          this.connectBuildingInterface.constructUpdatePayload(
            feature,
            homeConnections,
          ),
        );
      }
    }
    return payload;
  }

  /**
   * Remove map events und reset building feature states
   */
  destroy() {
    // check not needed? at least it helps with reliably unmarking buildings
    // if (getMap().isStyleLoaded()) {
    this.removeEventHooks();
    getMarked().resetFeatureState();
    // }
  }
}
