<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V15.3057C19.5586 15.1092 19.0698 15 18.5556 15C17.9924 15 17.4598 15.1309 16.9865 15.364C17.3791 14.5105 17.5224 13.3503 16.964 11.828C16.191 9.72059 16.1995 8.79853 16.5295 7.33333C16.5731 7.1399 16.3399 7.00631 16.2019 7.14865C16.1426 7.20974 16.0842 7.27125 16.0268 7.33333C14.9196 8.53053 14.1836 9.94104 15.0415 12.6882C15.8584 15.304 15.5406 16.0838 15.2105 16.8936L15.1673 17C15.1284 17.0965 15.1904 17.1822 15.2736 17.1855C15.0973 17.6071 15 18.07 15 18.5556C15 19.0698 15.1092 19.5586 15.3057 20H5C4.44772 20 4 19.5523 4 19V5ZM12.706 5.77778C12.266 7.73137 12.2547 8.96079 13.2854 11.7706C14.7267 15.6998 12.6615 17.8199 11.4679 18.6667C11.348 18.7518 11.2368 18.824 11.1389 18.8839C10.9842 18.9786 10.8219 18.8349 10.8898 18.6667C10.9087 18.6197 10.9277 18.573 10.9467 18.5264L10.9471 18.5253L10.9473 18.5251L10.9474 18.5248C11.3875 17.445 11.8112 16.4053 10.722 12.9176C9.57813 9.25472 10.5594 7.37404 12.0357 5.77778C12.1123 5.695 12.1902 5.61299 12.2692 5.53154C12.4532 5.34175 12.7641 5.51987 12.706 5.77778ZM8.96405 11.828C8.19105 9.72059 8.1995 8.79853 8.52951 7.33333C8.57307 7.1399 8.33991 7.00631 8.20188 7.14865C8.14264 7.20974 8.08422 7.27125 8.02681 7.33333C6.91958 8.53053 6.1836 9.94104 7.04151 12.6882C7.85843 15.304 7.5406 16.0838 7.21052 16.8936L7.16732 17C7.11644 17.1262 7.23816 17.234 7.35421 17.1629C7.42764 17.118 7.511 17.0638 7.60096 17C8.49614 16.3649 10.045 14.7748 8.96405 11.828Z"
      :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 21.2C19.9912 21.2 21.2 19.9912 21.2 18.5C21.2 17.0089 19.9912 15.8 18.5 15.8C17.0089 15.8 15.8 17.0089 15.8 18.5C15.8 19.9912 17.0089 21.2 18.5 21.2ZM18.5001 19.4C18.9972 19.4 19.4001 18.997 19.4001 18.5C19.4001 18.0029 18.9972 17.6 18.5001 17.6C18.003 17.6 17.6001 18.0029 17.6001 18.5C17.6001 18.997 18.003 19.4 18.5001 19.4Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
