<template>
  <VuetifyDialog
    v-model="dialog"
    title="Details Projektplanung"
    :persistent="true"
  >
    <template v-if="heatProjectParameter.parameterSets" #activatorItem>
      <ButtonEl
        text="Mehr Details"
        variant="secondary"
        size="s"
        icon="edit_note"
        color="neutral"
        class="w-fit ml-auto"
      />
    </template>
    <template #content>
      <div class="flex flex-col gap-5">
        <!-- top-->
        <div
          v-if="!isEdit"
          class="flex flex-col gap-1.5 bg-subtle p-1.5 rounded-[4px]"
        >
          <transition mode="out-in" name="fade">
            <div
              v-if="heatProjectParameter.isUniqueParameterSet"
              class="flex flex-col gap-2.5"
            >
              <div class="grid grid-cols-2 gap-2.5 items-end">
                <FormValidator v-model="formIsValid">
                  <InputEl
                    v-model="heatProjectParameter.nameNewSet"
                    label="Name neues Projektparameterset"
                    size="m"
                    :rules="{
                      unique: [heatProjectParameter.parameterSetNames],
                    }"
                    placeholder="Bitte eingeben"
                  />
                </FormValidator>
                <CheckboxEl
                  v-model="heatProjectParameter.isStandardNewSet"
                  class="mb-2"
                >
                  <div class="caption-1">Als Standard festlegen</div>
                </CheckboxEl>
              </div>
              <div class="grid grid-cols-2 gap-2.5">
                <ButtonEl
                  :disabled="
                    !formIsValid || heatProjectParameter.hasNoEmptyFields
                  "
                  icon="add_box"
                  variant="secondary"
                  text="Projektparameterset speichern"
                  size="m"
                  icon-type="round"
                  @click="heatProjectParameter.createParameterSet()"
                />
                <ButtonEl
                  icon="restart_alt"
                  variant="secondary"
                  text="Zurücksetzen"
                  size="m"
                  icon-type="round"
                  @click="heatProjectParameter.resetFields()"
                />
              </div>
            </div>
            <DropDownAdvanced
              v-else
              v-model="heatProjectParameter.parameterSetSelected"
              class="min-w-[520px] w-full"
              size="m"
              items-data-key="name"
              value="id"
              :items-data="heatProjectParameter.sortedParameterSets"
              label="Projektparameterset"
              @update:standard-value="updateStandard"
              @trigger-delete="triggerDelete"
            />
          </transition>
        </div>

        <!-- input fields -->
        <div v-for="row in inputData" :key="row" class="grid grid-cols-2 gap-5">
          <div v-for="column in row" :key="column" class="flex flex-col gap-2">
            <h6 class="text-title-color2">{{ column.heading }}</h6>
            <div class="flex flex-col gap-1.5">
              <InputEl
                v-for="field in column.fields"
                :key="field"
                v-model="field.data"
                :label="field.label"
                :suffix="field.suffix"
                :input-type="field.inputType"
                size="m"
                placeholder="Bitte eingeben"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="flex justify-between gap-2 w-full">
        <ButtonEl
          text="Abbrechen"
          variant="secondary"
          @click="dialog = false"
        />
        <ButtonEl
          text="Anwenden"
          :disabled="heatProjectParameter.hasEmptyFields"
          @click="save"
        />
      </div>
    </template>
  </VuetifyDialog>

  <!--  delete dialog -->
  <VuetifyDialog
    v-if="showDeleteDialog"
    v-model="showDeleteDialog"
    :title="`Parameterset &quot;${elementToDelete.name}&quot; wirklich löschen?`"
    class="mt-auto"
  >
    <template #content>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </template>
    <template #actions>
      <ButtonEl text="Abbrechen" variant="secondary" />
      <ButtonEl
        text="Löschen"
        color="error"
        variant="secondary"
        @click="deleteSet"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import DropDownAdvanced from '../../../components/storybook/src/stories/DropDownAdvanced/DropDownAdvanced.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import { cloneDeep } from 'lodash';

const props = defineProps({
  heatProject: {
    type: Object,
    default: null,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:heatProjectParameter']);

const heatProjectParameter = ref(
  cloneDeep(props.heatProject.heatProjectParameter),
);

const dialog = ref(false);
const showDeleteDialog = ref(false);
const elementToDelete = ref(null);
const formIsValid = ref(false);

const inputData = ref([
  {
    0: {
      heading: 'Variable Kosten',
      fields: [
        {
          label: 'Betriebskosten',
          data: computed({
            get: () => heatProjectParameter.value.opex_costs_euro_mwh,
            set: (value) =>
              (heatProjectParameter.value.opex_costs_euro_mwh = value),
          }),
          inputType: 'float',
          suffix: '€/MWh',
        },
        {
          label: 'Vertriebskosten',
          data: computed({
            get: () => heatProjectParameter.value.sales_costs_euro_ha,
            set: (value) =>
              (heatProjectParameter.value.sales_costs_euro_ha = value),
          }),
          inputType: 'float',
          suffix: '€/HA',
        },
      ],
    },
    1: {
      heading: 'CAPEX',
      fields: [
        {
          label: 'Kapitalzins',
          data: computed({
            get: () => heatProjectParameter.value.interest_rate,
            set: (value) => (heatProjectParameter.value.interest_rate = value),
          }),
          inputType: 'percent',
          suffix: '%',
        },
        {
          label: 'Förderquote',
          data: computed({
            get: () => heatProjectParameter.value.funding_rate_percentage,
            set: (value) =>
              (heatProjectParameter.value.funding_rate_percentage = value),
          }),
          inputType: 'percent',
          suffix: '%',
        },
      ],
    },
  },
  {
    0: {
      heading: 'Tilgungsdauer',
      fields: [
        {
          label: 'Tilgungsdauer für Vertriebskosten',
          data: computed({
            get: () => heatProjectParameter.value.working_life_sales_year,
            set: (value) =>
              (heatProjectParameter.value.working_life_sales_year = value),
          }),

          inputType: 'integer',
          suffix: 'Jahre',
        },
      ],
    },
    1: {
      heading: 'Erlöse',
      fields: [
        {
          label: 'Durchschnittl. Fernwärmeerlös',
          data: computed({
            get: () => heatProjectParameter.value.revenue,
            set: (value) => (heatProjectParameter.value.revenue = value),
          }),
          inputType: 'float',
          suffix: '€/MWh',
        },
      ],
    },
  },
]);

onMounted(() => {
  if (!props.isEdit) fetchParameterSets();
});

function fetchParameterSets() {
  axios({
    url: '/api/heatprojects/parameter-set/',
    method: 'GET',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  })
    .then((resp) => {
      heatProjectParameter.value.parameterSets = resp.data;
      const standardSet = heatProjectParameter.value.parameterSets.find(
        (set) => set.standard,
      );
      if (standardSet) {
        heatProjectParameter.value.updateParameters(standardSet);
      }
    })
    .catch((error) => {
      console.error('Error fetching parameter sets:', error);
    });
}

async function updateStandard(item) {
  try {
    await heatProjectParameter.value.updateStandardStatus(item);
  } catch (error) {
    console.error('Error updating standard status:', error);
  }
}

function save() {
  dialog.value = false;
  emit('update:heatProjectParameter', heatProjectParameter.value);
}

function triggerDelete(item) {
  elementToDelete.value = item;
  showDeleteDialog.value = true;
}

function deleteSet() {
  heatProjectParameter.value.deleteParameterSet(elementToDelete.value.id);
  showDeleteDialog.value = false;
}
</script>
