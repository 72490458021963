<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6231_10292)">
      <path
        d="M17.4998 0L17.3398 0.03L11.9998 2.1L5.99976 0L0.359756 1.9C0.149756 1.97 -0.000244141 2.15 -0.000244141 2.38V17.5C-0.000244141 17.78 0.219756 18 0.499756 18L0.659756 17.97L5.99976 15.9L11.9998 18L17.6398 16.1C17.8498 16.03 17.9998 15.85 17.9998 15.62V0.5C17.9998 0.22 17.7798 0 17.4998 0ZM11.9998 16L5.99976 13.89V2L11.9998 4.11V16Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
