export const energySourceColors = {
  // Kohlekessel
  COA: '#3E2723',
  // Erdgaskessel
  GAS: '#BCAAA4',
  // Ölkessel
  OIL: '#8D6E63',
  // Biomassekessel
  BIO: '#9E9D24',
  // Biogas
  BGA: '#CDDC39',
  // Synthetische Energieträger (Wasserstoff) Heizkessel
  H2: '#009688',
  // Strom Direktheizung
  ELE: '#2979FF',
  // Wärmepumpe
  PMP: '#00E5FF',
  // Solarthermie
  SOL: '#FBC02D',
  // Geothermie
  GEO: '#F57C00',
  // Oberflächengewässer
  H2O: '#B3E5FC',
  // Industrielle Abwärme
  WAS: '#EA80FC',
  // Unbekannt
  UNK: '#E0E0E0',
  // Keine Heizung
  NULL: '#FAFAFA',
};

export const energyTypeColors = {
  // Grüne Gase (Wasserstoff)
  BOI: '#009688',
  // Erneuerbar
  CHP: '#9E9D24',
  // Elektrifizierung
  PMP: '#00E5FF',
  // Wärmenetze
  DHU: '#7C4DFF',
  // Unbekannt
  UKN: '#E0E0E0',
};

// aggregation layers START

export const heatingCarrierColors = {
  // Fossil
  FOS: 'rgba(62, 39, 35, 0.7)',
  // Erneuerbar
  REN: 'rgba(205, 220, 57, 0.7)',
  // Wärmenetze
  HNT: 'rgba(124, 77, 255, 0.7)',
  // Elektrifizierung
  ELE: 'rgba(0, 229, 255, 0.7)',
  // Grüne Gase
  GRG: 'rgba(0, 150, 136, 0.7)',
  // Unbekannt
  UKN: 'rgba(224, 224, 224, 0.7)',
};

export const priorityAreaColors = {
  // Dezentrale Erzeugung
  dezentral: 'rgba(0, 229, 255, 0.7)',
  // Wärmenetze
  DIS: 'rgba(124, 77, 255, 0.7)',
  // Grüne Gase
  H2: 'rgba(0, 150, 136, 0.7)',
  // Unbekannt
  UKN: 'rgba(224, 224, 224, 0.7)',
};

export const sectorColors = {
  //   Private Haushalte
  HH: '#00B979',
  //   Öffentlich
  PUB: '#FFB100',
  //   Gewerbe, Handel und Dienstleistungen
  COM: '#BC0606',
  //   Industrie
  IND: '#224BA3',
  //   Sonstige
  UNK: '#CED1DA',
};

// aggregation layers END

export function darkenColor(color, percent = 70) {
  // Function to darken an RGB color
  function darkenRGB(r, g, b, percent) {
    const factor = (100 - percent) / 100;
    r = Math.round(r * factor);
    g = Math.round(g * factor);
    b = Math.round(b * factor);

    // Ensure the values are within the valid range [0, 255]
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    return [r, g, b];
  }

  // Function to convert a number to a 2-digit hex string
  function toHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  if (color.startsWith('#')) {
    // Handle hex color input
    color = color.replace(/^#/, '');
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    [r, g, b] = darkenRGB(r, g, b, percent);

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  } else if (color.startsWith('rgba')) {
    // Handle rgba color input
    const rgbaMatch = color.match(/rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/);
    if (rgbaMatch) {
      let r = parseInt(rgbaMatch[1]);
      let g = parseInt(rgbaMatch[2]);
      let b = parseInt(rgbaMatch[3]);

      [r, g, b] = darkenRGB(r, g, b, percent);

      return `rgba(${r}, ${g}, ${b}, 1)`;
    }
  } else if (color.startsWith('rgb')) {
    // Handle rgb color input
    const rgbMatch = color.match(/rgb\((\d+), (\d+), (\d+)\)/);
    if (rgbMatch) {
      let r = parseInt(rgbMatch[1]);
      let g = parseInt(rgbMatch[2]);
      let b = parseInt(rgbMatch[3]);

      [r, g, b] = darkenRGB(r, g, b, percent);

      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  throw new Error('Unsupported color format. Please use hex, rgb, or rgba.');
}

export function hexToRgba(hex, opacity = 70) {
  // Ensure hex is a valid hex color code
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error('Invalid hex color code');
  }

  let c;
  if (hex.length === 4) {
    c = hex.substring(1).split('');
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  } else {
    c = hex.substring(1).split('');
  }

  const r = parseInt(c[0] + c[1], 16);
  const g = parseInt(c[2] + c[3], 16);
  const b = parseInt(c[4] + c[5], 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
