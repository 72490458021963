<template>
  <RouterLink class="home-icon" to="/">
    <img src="@/assets/svg/infra-logo-small.svg" alt="logo" />
  </RouterLink>
</template>

<style lang="scss" scoped>
.home-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: linear-gradient(205.52deg, #001257 19.97%, #0692bc 103.36%);
}
</style>
