import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

import {
  LAYER_KEY__HEAT_PROJECT_CENTROID,
  LAYER_KEY__HEAT_PROJECT_ESTATE,
  LAYER_KEY__HEAT_PROJECT_NETWORK,
} from '@/configs/layers/heatProject';
import {
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
} from '@/configs/layers/constants.js';

import { setGeoJsonData } from '@/mapbox/main';

import { highlightBuildings } from '@/features/heat-project/highlight-buildings';
import highlightHeatSources from '@/features/heat-project/highlight-heat-sources';
import { projectNotInYearRange } from '@/features/heat-project/project-not-in-year-range';

function updateProjectLayers(getters, rootState) {
  setGeoJsonData(
    LAYER_KEY__HEAT_PROJECT_NETWORK,
    getters.heatProjectNetworkAsFC,
  );
  setGeoJsonData(
    LAYER_KEY__HEAT_PROJECT_CENTROID,
    getters.heatProjectCentroidAsFC,
  );
  setGeoJsonData(
    LAYER_KEY__HEAT_PROJECT_ESTATE,
    getters.heatProjectEstateConnections,
  );
  projectNotInYearRange(rootState.map.scenarioYear);
  highlightBuildings(true);
}

function updateHeatSourceLayers(getters) {
  setGeoJsonData(LAYER_KEY__HEAT_SOURCE_INVENTORY, getters.getHeatSourcesAsFC);
  setGeoJsonData(LAYER_KEY__HEAT_SOURCE_POTENTIAL, getters.getHeatSourcesAsFC);
  setGeoJsonData(
    LAYER_KEY__HEATING_CENTER_INVENTORY,
    getters.getHeatSourcesAsFC,
  );
  setGeoJsonData(
    LAYER_KEY__HEATING_CENTER_POTENTIAL,
    getters.getHeatSourcesAsFC,
  );
  setGeoJsonData(
    LAYER_KEY__GRID_FEED_POINT_INVENTORY,
    getters.getHeatSourcesAsFC,
  );
  setGeoJsonData(
    LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
    getters.getHeatSourcesAsFC,
  );
}

export default {
  GET_HEAT_SOURCE: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/heat-source/',
        method: 'GET',
      })
        .then((resp) => {
          for (const heatSource of resp.data) {
            commit('ADD_HEAT_SOURCE', heatSource);
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_HEATING_CENTER: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/heating-center/',
        method: 'GET',
      })
        .then((resp) => {
          for (const heatSource of resp.data) {
            commit('ADD_HEAT_SOURCE', heatSource);
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_GRID_FEED_POINT: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/grid-feed-point/',
        method: 'GET',
      })
        .then((resp) => {
          for (const heatSource of resp.data) {
            commit('ADD_HEAT_SOURCE', heatSource);
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  POST_HEAT_SOURCE: ({ commit, getters }, heatSource) =>
    new Promise((resolve, reject) => {
      const data = heatSource.constructPayload();
      axios({
        url: heatSource.systemURL,
        method: 'POST',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('ADD_HEAT_SOURCE', resp.data);
          updateHeatSourceLayers(getters);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_HEAT_SOURCE: ({ commit, getters, state }, heatSourceId) =>
    new Promise((resolve, reject) => {
      const heatSource = state.heatSources.find((e) => e.id === heatSourceId);
      const url = heatSource.systemURL;
      axios({
        url: `${url}${heatSourceId}`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('REMOVE_HEAT_SOURCE', heatSourceId);
          updateHeatSourceLayers(getters);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  PUT_HEAT_SOURCE: ({ commit, getters }, heatSource) =>
    new Promise((resolve, reject) => {
      const data = heatSource.constructPayload();
      axios({
        url: `${heatSource.systemURL}${heatSource.id}/`,
        method: 'PUT',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('UPDATE_HEAT_SOURCE', resp.data);
          updateHeatSourceLayers(getters);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_HEAT_PROJECT_MAP_DATA: ({ commit, getters, rootState }, projectIds) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/project-geo-data/list/',
        method: 'GET',
        params: { project_ids: projectIds },
      })
        .then((resp) => {
          commit('RESET_HEAT_PROJECT');
          for (const heatProject of resp.data.data) {
            commit('ADD_HEAT_PROJECT', heatProject);
          }
          updateProjectLayers(getters, rootState);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  POST_HEAT_PROJECT: ({ commit, getters }, heatProject) =>
    new Promise((resolve, reject) => {
      const data = heatProject.constructCreatePayload();
      axios({
        url: '/api/heatprojects/HeatProject/',
        method: 'POST',
        data,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_HEAT_PROJECT: ({ commit, getters, rootState }, heatProjectId) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/heatprojects/HeatProject/${heatProjectId}/`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('REMOVE_HEAT_PROJECT', heatProjectId);
          updateProjectLayers(getters, rootState);
          highlightHeatSources(true);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  PUT_HEAT_PROJECT: (
    { commit, getters, rootState },
    { heatProject, payload },
  ) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/heatprojects/HeatProject/${heatProject.id}/`,
        method: 'PUT',
        data: payload,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('REMOVE_HEAT_PROJECT', resp.data.id);
          updateProjectLayers(getters, rootState);
          highlightHeatSources(true);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_HEAT_PROJECT_SELECTABLES: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/overview/',
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_HEAT_PROJECT_SELECTABLES', resp.data.projects);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_PIPE_SELECTABLES: ({ commit }, params) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/pipes/',
        method: 'GET',
        params,
      })
        .then((resp) => {
          commit('SET_PIPE_SELECTABLES', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_HEAT_PROJECTS: ({ commit }, params) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/heatprojects/HeatProject/',
        method: 'GET',
        params: {
          page: params.page,
          page_size: params.page_size,
          created_start: params.created_start,
          created_end: params.created_end,
          scenario_id: params.scenario_id,
          project_name: params.project_name,
          sortBy: params.sortBy,
          sortOrder: params.sortOrder,
        },
      })
        .then((resp) => {
          commit('SET_HEAT_PROJECTS', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
