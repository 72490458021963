<template>
  <div>
    <transition :name="!onHub ? 'fade-vertical' : null">
      <div
        v-if="onHub ? showSelector && showMuScSelector : showMuScSelector"
        :class="{
          'rounded-b-none': isExpanded,
          'not-on-hub': !onHub,
        }"
        class="bg-default standard-elevation-1 rounded-[8px]"
      >
        <div
          v-if="perspectiveSet === 'true'"
          class="relative w-full flex p-2 gap-2"
        >
          <div class="flex standard-elevation-0-dark rounded-[6px]">
            <ButtonEl
              v-for="(button, index) in buttonsConfig"
              :key="index"
              :icon="button.icon"
              :icon-fill="iconFill(button.type)"
              :variant="button.variant"
              :class="button.classes"
              :text="onHub && button.text"
              :icon-type="button.iconType"
              @click="changeButtonVariant(button.type)"
            />
          </div>
          <MuniScenSelector
            :denominator
            :scenarios
            :scenario-selected
            :municipalities
            :municipality-selected
            :reduce-scenario-info
            :municipality-count
            @expanded="isExpanded = $event"
            @select-scenario="changeScenario($event)"
            @select-municipality="changeMunicipality($event)"
          />
          <ButtonEl
            v-if="!onHub"
            icon="close"
            color="neutral"
            variant="tertiary"
            @click="toggleMuScSelector"
          />
          <slot />
        </div>
      </div>
    </transition>
    <GeoMunicipalityChartWrapper
      v-if="showGeoChart"
      :municipality-selected
      :municipalities
      :scenario-selected
      @update:selected-municipality="changeMunicipality($event)"
    />
  </div>

  <VuetifyDialog
    v-if="showModal && showSelector && dataLoaded"
    v-model="showModal"
    :hide-close-button="true"
    :persistent="true"
    :overflow-visible="true"
    custom-classes="rounded-[8px]"
    title="Perspektivmodus einstellen"
  >
    <template #content>
      <div v-if="validBaseData" class="flex flex-col gap-3">
        <span>
          Bitte wählen Sie Ihre Perspektive, eine Gemeinde und ein Szenario aus.
          <br />
          Diese Einstellungen werden gespeichert und können jederzeit angepasst
          werden.
        </span>
        <RadioButtonGroup
          v-model="denominator"
          :items-data="buttonsConfig"
          items-data-key="text"
          value="type"
          class="flex-col gap-[0px]"
          :small="true"
        />
        <div
          class="flex gap-3"
          :class="
            denominator === 'municipality' ? 'flex-col' : 'flex-col-reverse'
          "
        >
          <DropDown
            v-model="municipalitySelected"
            :items-data="municipalities"
            :label="getLabel('municipality')"
            items-data-key="name"
            value="municipality_key"
            @update:model-value="changeMunicipality($event)"
          />
          <DropDown
            :model-value="scenarioSelected"
            :items-data="scenarios"
            :label="getLabel('scenario')"
            items-data-key="name"
            value="scenario_id"
            @update:model-value="changeScenario($event)"
          />
        </div>
      </div>
      <div v-else>
        <span>
          Das Onboarding ist noch nicht Abgeschlossen. Bitte fahren Sie mit dem
          Prozess fort <br />
          und wählen Sie anschließend eine Gemeinde und ein Szenario hier im
          Hub.
        </span>
      </div>
    </template>
    <template #actions>
      <ButtonEl
        v-if="validBaseData"
        :disabled="!municipalitySelected || !scenarioSelected"
        text="Speichern und weiter"
        icon="arrow_right_alt"
        data-test="accept-initial-selection-modal"
        :icon-right="true"
        :full-width="true"
        @click="savePerspective(true)"
      />
      <ButtonEl
        v-else
        text="Starte onboarding"
        icon="arrow_right_alt"
        data-test="accept-initial-selection-modal"
        :icon-right="true"
        :full-width="true"
        @click="savePerspective(false)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import axios from '@/utils/axiosHelper';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import MuniScenSelector from '@/components/municipality-scenario-selector/MuniScenSelector.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import GeoMunicipalityChartWrapper from '@/apps/analysis-dashboard/charts/GeoMunicipalityChartWrapper.vue';

const props = defineProps({
  onHub: {
    type: Boolean,
    default: false,
  },
  showSelector: {
    type: Boolean,
    default: false,
  },
  reduceScenarioInfo: {
    type: Boolean,
    default: false,
  },
  showGeoChart: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const emit = defineEmits([
  'change-municipality',
  'change-scenario',
  'perspectiveSet',
  'scenario-count',
]);

const isExpanded = ref(false);
const showMuScSelector = computed(() => store.getters.getMuScSelector);

function toggleMuScSelector() {
  store.commit('TOGGLE_MU_SC_SELECTOR', false);
}

const buttonsConfig = ref([
  {
    type: 'municipality',
    icon: 'pin_drop',
    variant: 'primary',
    classes: 'rounded-r-none',
    iconType: 'round',
    text: 'Nach Gemeinde',
  },
  {
    type: 'scenario',
    icon: 'timeline',
    variant: 'tertiary',
    classes: 'rounded-l-none',
    iconType: 'filled',
    text: 'Nach Szenario',
  },
]);

const denominator = ref('municipality');
const dataLoaded = ref(false);

function changeButtonVariant(type) {
  buttonsConfig.value.forEach((button) => {
    button.variant = button.type === type ? 'primary' : 'tertiary';
  });
  if (type !== localStorage.getItem('denominator')) {
    changeDenominator(type);
  }
}

function iconFill(type) {
  return buttonsConfig.value.find((button) => button.type === type).variant ===
    'primary'
    ? 'text-white'
    : null;
}

async function resetSelection() {
  await fetchMunicipalities('all');
  await fetchScenarios(municipalitySelected.value);
  localStorage.setItem('denominator', 'municipality');
  denominator.value = 'municipality';
  if (municipalities.value.length > 1) municipalitySelected.value = 'all';
}

onMounted(async () => {
  try {
    const dataFetched = await initializeDenominator();
    if (!dataFetched) await fetchInitialData();
  } catch (error) {
    await resetSelection();
    console.error('Error during initialization:', error);
  }
  dataLoaded.value = true;
});

async function initializeDenominator() {
  const denominatorStore = localStorage.getItem('denominator');
  if (denominatorStore === null || typeof denominatorStore === 'undefined') {
    await resetSelection();
    return true;
  } else {
    denominator.value = denominatorStore;
    return false;
  }
}

async function fetchInitialData() {
  municipalitySelected.value = localStorage.getItem('municipality') || 'all';
  scenarioSelected.value = getScenarioFromLocaleStorage();
  await Promise.all([
    fetchScenarios(municipalitySelected.value),
    fetchMunicipalities(
      denominator.value === 'municipality' ? 'all' : scenarioSelected.value,
    ),
  ]);
}

// 1. logic for button interactions
const municipalities = ref([]);
const municipalitySelected = ref(null);
const scenarios = ref([]);
const scenarioSelected = ref(null);
const validBaseData = computed(
  () => scenarios.value.length && municipalities.value.length,
);

function getScenarioFromLocaleStorage() {
  const scenario = localStorage.getItem('scenario');
  if (scenario && scenario !== 'NaN') {
    return Number.parseInt(localStorage.getItem('scenario'));
  }
  return null;
}

function changeDenominator(denominatorItem) {
  denominator.value = denominatorItem;
  localStorage.setItem('denominator', denominatorItem);
  if (denominatorItem === 'scenario') {
    const scenario = getScenarioFromLocaleStorage();
    fetchMunicipalities(scenario);
    fetchScenarios('all');
  }
  if (denominatorItem === 'municipality') {
    const municipality = localStorage.getItem('municipality');
    fetchScenarios(municipality);
    fetchMunicipalities('all');
  }
}

async function fetchMunicipalities(scenario) {
  return axios({
    method: 'GET',
    url: `/api/buildingmodel/municipality-selection/${scenario}/`,
  }).then((resp) => {
    municipalities.value = resp.data.data.toSorted(compareMuncicipalityItems);

    // Check if 'Gesamtgebiet' should be added
    if (
      municipalities.value.length > 1 &&
      municipalities.value[0].municipality_key !== 'all'
    ) {
      municipalities.value.unshift({
        name: 'Gesamtgebiet',
        municipality_key: 'all',
      });
    }
    // Find the index of the selected municipality
    const index = municipalities.value.findIndex(
      (e) => e.municipality_key === municipalitySelected.value,
    );
    if (index >= 0) {
      // The selected municipality is in the list
      municipalitySelected.value = municipalities.value[index].municipality_key;
      return;
    }
    if (municipalities.value.length === 1) {
      // If there is only one municipality, select it
      municipalitySelected.value = municipalities.value[0].municipality_key;
      return;
    }
    // Default to 'all' if not found or multiple options are available
    municipalitySelected.value = 'all';
  });
}

async function changeMunicipality(municipality) {
  if (denominator.value === 'municipality') await fetchScenarios(municipality);
  municipalitySelected.value = municipality;
}

watch(municipalitySelected, (newVal, oldVal) => {
  localStorage.setItem('municipality', newVal);
  if (oldVal !== null) emit('change-municipality', newVal);
});

// 1.1 logic for scenario selection

async function fetchScenarios(municipality) {
  return axios({
    url: `/api/scenario-select/${municipality}/`,
    method: 'GET',
  }).then((resp) => {
    scenarios.value = resp.data.data.toSorted(compareItems);
    emit('scenario-count', scenarios.value.length);
    const index = scenarios.value.findIndex(
      (e) => e.scenario_id === scenarioSelected.value,
    );
    if (index >= 0) return;
    scenarioSelected.value = scenarios.value[0]?.scenario_id;
  });
}

function changeScenario(scenario) {
  if (denominator.value === 'scenario') fetchMunicipalities(scenario);
  scenarioSelected.value = scenario;
  emit('change-scenario', scenario);
}

const perspectiveSet = ref(localStorage.getItem('perspectiveSet'));

const showModal = computed(() => perspectiveSet.value !== 'true');

function savePerspective(validSelection) {
  perspectiveSet.value = 'true';
  localStorage.setItem('perspectiveSet', 'true');
  emit('perspectiveSet');
  if (validSelection) {
    emit('change-scenario', scenarioSelected.value);
    emit('change-municipality', municipalitySelected.value);
  }
}

function getLabel(type) {
  const isMunicipality = denominator.value === 'municipality';
  const isTypeMunicipality = type === 'municipality';

  return isMunicipality
    ? isTypeMunicipality
      ? 'Gemeinde'
      : `Verknüpfte Szenarien (${scenarios.value.length})`
    : isTypeMunicipality
      ? `Betroffene Gemeinden (${municipalityCount.value})`
      : 'Szenario';
}

function compareMuncicipalityItems(a, b) {
  // Gesamtgebiet at first place
  if (a.municipality_key === 'all') return -1;
  if (b.municipality_key === 'all') return 1;

  return compareItems(a, b);
}

function compareItems(a, b) {
  return a.name.localeCompare(b.name);
}

const municipalityCount = computed(() => {
  return municipalities.value.length === 1
    ? 1
    : municipalities.value.length - 1;
});

watch(scenarioSelected, (scenario) => {
  localStorage.setItem('scenario', scenario);
  // If a municipality change leads also to a selected scenario change
  if (denominator.value === 'municipality') {
    emit('change-scenario', scenarioSelected.value);
  }
});

watch(showMuScSelector, () => {
  isExpanded.value = false;
});

watch(denominator, (denominatorItem) => {
  changeButtonVariant(denominatorItem);
});
</script>

<style scoped lang="scss">
.not-on-hub {
  @apply absolute left-1/2 -top-2 transform -translate-x-1/2;
  @media (max-width: 1320px) {
    @apply top-12;
  }
}

.fade-vertical-enter-active,
.fade-vertical-leave-active {
  transition: all 0.5s ease;
}

.fade-vertical-enter-from,
.fade-vertical-leave-to {
  top: -16px;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}

.fade-vertical-enter-to,
.fade-vertical-leave-from {
  top: -8px;
  left: 50%;
  transform: translateY(0) translateX(-50%);
}
</style>
