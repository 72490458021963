export class Municipality {
  constructor(
    municipality_id,
    municipality_name,
    scenario_frame,
    scenario_frame_name,
    county_id,
  ) {
    this.municipality_id = municipality_id;
    this.municipality_name = municipality_name;
    this.scenario_frame = scenario_frame;
    this.scenario_frame_name = scenario_frame_name;
    this.county_id = county_id;
  }
}

export default class municipalitySelectionInterface {
  constructor(municipalities = []) {
    this.set(municipalities);
  }

  set(municipalities) {
    this.municipalities = {};
    for (const municipality of municipalities) {
      this.add(municipality);
    }
  }

  add(municipality) {
    this.municipalities[municipality.municipality_id] = new Municipality(
      municipality.municipality_id,
      municipality.municipality_name,
      municipality.scenario_frame,
      municipality.scenario_frame_name,
      municipality.county_id,
    );
  }

  remove(municipality) {
    delete this.municipalities[municipality.municipality_id];
  }

  toggle(municipality) {
    if (this.municipalities[municipality.municipality_id]) {
      this.remove(municipality);
    } else {
      this.add(municipality);
    }
  }

  removeAll() {
    this.municipalities = {};
  }

  updateFromList(items) {
    const keys = new Set(items.map((e) => e.municipality_id));
    for (const item of items) {
      if (
        !Object.prototype.hasOwnProperty.call(
          this.municipalities,
          item.municipality_id,
        )
      ) {
        this.add(item);
      }
    }
    for (const key in this.municipalities) {
      if (!keys.has(key)) {
        delete this.municipalities[key];
      }
    }
  }

  updateScenarioFrames(municipalityKeys, frameId, frameName) {
    for (const key of municipalityKeys) {
      this.municipalities[key].scenario_frame = frameId;
      this.municipalities[key].scenario_frame_name = frameName;
    }
  }

  get asList() {
    return Object.values(this.municipalities);
  }

  hasValid() {
    return this.asList.some((m) => Boolean(m.scenario_frame_name));
  }

  hasAtLeastOneMunicipality() {
    return Object.values(this.municipalities).length > 0;
  }

  isValid() {
    return (
      this.hasAtLeastOneMunicipality() &&
      Object.values(this.municipalities).every((m) => m.scenario_frame)
    );
  }

  removeMunicipalitiesWithoutFrame() {
    const municipalities = this.asList.filter((m) => !m.scenario_frame_name);
    for (const municipality of municipalities) {
      delete this.municipalities[municipality.municipality_id];
    }
  }
}
