<template>
  <div
    :class="{ 'left-5': !getMainMenuShow, 'left-[400px]': getMainMenuShow }"
    class="bg-white rounded-[8px] top-[85px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-4 standard-elevation-1"
  >
    <h4 class="text-infra-highlight-500">Restriktion hinzufügen</h4>

    <DropDown
      v-model="constraints.priorityAreaType"
      :items-data="priorityAreas"
      label="Vorranggebiet"
      items-data-key="display_name"
      placeholder="Bitte wählen"
      :rules="{ required: true }"
    />

    <div class="grid grid-cols-2 gap-2.5">
      <ButtonEl variant="secondary" text="Abbrechen" @click="abort" />
      <ButtonEl
        :disabled="!constraints.isValid"
        text="Speichern"
        @click="save"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { axios } from '@/utils/axiosHelper';
import BlockExpansionConstraints from './assign-priority-area';
import cookie from 'vue-cookies';
import { SWITCH_ID__BLOCK } from '@/configs/layer-panel/aggregation';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const store = useStore();

const constraints = ref(new BlockExpansionConstraints());

const getMainMenuShow = computed(() => {
  return store.getters.getMainMenuShow;
});
const priorityAreas = computed(() => {
  const scenarioDistrictOptions =
    store.getters['scenario/getScenarioDistrictOptions'];
  if (!scenarioDistrictOptions || !scenarioDistrictOptions.priority_area)
    return [];
  return scenarioDistrictOptions.priority_area.choices || [];
});

onMounted(() => {
  constraints.value.initMultiSelect();
  store.dispatch('scenario/GET_SCENARIO_DISTRICT_OPTIONS');
  store.commit('map/CHANGE_SWITCH', {
    switches: [SWITCH_ID__BLOCK],
    active: true,
  });
});

onBeforeUnmount(() => {
  constraints.value.removeMultiSelect();
});

function abort() {
  store.commit('SET_MAP_ACTIONS', null);
}

function save() {
  const payload = constraints.value.payload;
  payload.scenario = store.getters['scenario/getSelectedScenario'].scenario_id;
  axios({
    url: '/api/scenarios/property-areas/update-main-results/',
    method: 'POST',
    data: payload,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then((_) => {
    store.commit('SET_MAP_ACTIONS', null);
  });
}
</script>
