<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12.0006C8 10.763 8.49167 9.57598 9.36684 8.70081C10.242 7.82564 11.429 7.33398 12.6667 7.33398C13.9733 7.33398 15.1527 7.87264 16 8.73798V0.667312C16 0.565983 15.9769 0.465983 15.9325 0.37494C15.888 0.283898 15.8233 0.204217 15.7433 0.141979C15.6637 0.0791254 15.5707 0.0354794 15.4715 0.0144353C15.3723 -0.00660878 15.2696 -0.00448373 15.1713 0.0206453L10.0267 1.30665L6.21067 0.035312C6.09059 -0.00610905 5.96096 -0.0112106 5.838 0.0206453L0.504669 1.35398C0.360504 1.39009 0.232541 1.47334 0.141106 1.59051C0.0496703 1.70767 6.06341e-06 1.85203 2.15645e-06 2.00064V14.0006C-0.000256051 14.1021 0.0226767 14.2022 0.0670477 14.2934C0.111419 14.3846 0.176053 14.4645 0.256011 14.5269C0.33597 14.5893 0.429135 14.6325 0.528387 14.6534C0.627638 14.6743 0.730349 14.6722 0.828669 14.6473L5.97333 13.3613L8.56267 14.224C8.19227 13.5415 7.99882 12.7771 8 12.0006Z"
      :fill="fill" />
    <path
      d="M12.6667 8.66731L13.6967 10.7546L16 11.0893L14.3334 12.714L14.7267 15.008L12.6667 13.9246L10.6067 15.008L11 12.714L9.33337 11.0893L11.6367 10.7546L12.6667 8.66731Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
