<template>
  <div class="flex w-full caption-1">
    <span class="whitespace-nowrap">{{ item.unit }}</span>
    <div class="flex justify-end w-fit ml-auto white-space: normal">
      <!--      if item.value has more than 15 characters, cut it-->
      <div v-if="isLoading">
        <skeleton-loader-custom :custom-width="50" />
      </div>
      <div v-else>
        <div
          v-if="item.class === 'powerplant'"
          class="flex justify-end w-fit ml-auto break-words"
        >
          {{ item.value }}
        </div>
        <div v-else>
          <div v-if="item.value.length < 15" class="">
            {{ item.value }}
          </div>
          <div v-else>
            <v-tooltip :text="item.value">
              <template #activator="{ props }">
                <div v-bind="props">
                  {{ sliceString() }}
                </div>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoaderCustom from './SkeletonLoaderCustom.vue';

export default {
  components: { SkeletonLoaderCustom },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      currentInput: null,
    };
  },
  watch: {
    isLoading() {
      this.currentInput = null;
    },
  },
  methods: {
    sliceString() {
      const itemValue = this.item.value;
      if (itemValue.length > 15) return `${itemValue.slice(0, 14)}...`;
      else return itemValue;
    },
  },
};
</script>

<style lang="scss"></style>
