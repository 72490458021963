import { cloneDeep } from 'lodash';
import layers from '@/configs/layers';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { layerPanelConfig } from '@/configs/layer-panel';

export const state = {
  showInfoPanel: false,
  showSlider: false,
  scenarioYear: null,
  layerConfigs: cloneDeep(layers),
  layerPanelConfig: cloneDeep(layerPanelConfig),
  emptyModels: [],
  FlyTo: {
    zoom: 12,
    lng: 10.4515,
    lat: 51.1656,
    essential: true,
    maxZoom: 16.9,
  },
  filters: {},
  yearDependantFilterData: {},
  propertyDataIsLoading: false,
  satelliteIsActive: false,
  containerHeight: 500,
  refreshFeature: false,
  measuringActive: false,
  mapLoaded: false,
  // We need to cache the last activated switch in order to prevent switches
  // that are deactivated by it to revert layer actions emitted initially
  cachedSwitch: null,
  customTilesets: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
