<template>
  <div class="card-container">
    <template v-if="isAnimated">
      <template v-for="(item, index) in cardData" :key="index">
        <div
          v-if="isCardActive(item)"
          class="bg-default w-full transition-card flex justify-center items-center p-5 rounded-[10px]"
        >
          <img :src="item.icon" alt="card-img" class="w-full max-h-24" />
        </div>
      </template>
    </template>
    <template v-else>
      <template
        v-for="item in [...cardData].sort((a, b) => a.order - b.order)"
        :key="item"
      >
        <CardItem
          v-if="isCardActive(item)"
          :small="smallCard"
          :disabled="
            scenarioCount === 0 &&
            (item.id === 'mapView' || item.id === 'dashboards')
          "
          :is-parameter="item.id === 'parameter'"
          :card-data="item"
        />
      </template>
    </template>
  </div>
</template>

<script setup>
import { useModuleStore } from '@/configs/module-store';
import { onMounted, ref } from 'vue';
import CardItem from '@/components/global/CardItem.vue';

const moduleStore = useModuleStore();
const props = defineProps({
  cardData: Array,
  smallCard: Boolean,
  scenarioCount: {
    type: Number,
    default: 0,
  },
});
const isAnimated = ref(true);
const emit = defineEmits(['animation-finished']);

onMounted(() => {
  animateCards();
});

function animateCards() {
  setTimeout(() => {
    const container = document.querySelector('.transition-container');
    container.classList.add('container-large');

    const cards = document.querySelectorAll('.transition-card');
    cards.forEach((card) => {
      card.classList.add(`${props.smallCard ? 'card-small' : 'card-large'}`);
    });

    setTimeout(() => {
      isAnimated.value = false;
      emit('animation-finished');
    }, 300);
  }, 300);
}

function isCardActive(card) {
  return moduleStore.isActive(card.module);
}
</script>

<style lang="scss">
.transition-container {
  height: 200px;
  width: 400px;
  margin: 0 auto;
  transition:
    height 0.3s,
    width 0.3s;
}

.container-large {
  width: 90%;
  max-width: 1100px;
}

.card-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  gap: 0.625rem;
  position: relative;
}

.transition-card {
  height: 100px;
  transition: height 0.4s;
}

.card-small {
  height: 190px;
}

.card-large {
  height: 260px;
}
</style>
