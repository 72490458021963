// Formats Numbers to German conventions
export function formatNumber(obj, decimal) {
  if (typeof obj === 'string' || obj instanceof String) {
    return obj;
  }
  return obj
    .toFixed(decimal)
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function formatNumberMultiply(obj, decimal, multiply) {
  if (typeof obj === 'string' || obj instanceof String) {
    return obj;
  }
  return (obj * multiply)
    .toFixed(decimal)
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

// Formats Dates to German conventions
export function formatISODateAndTime(dateString) {
  const date = new Date(dateString);

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  return date.toLocaleString('de-DE', options);
}

function formatTimeUnit(unit) {
  return unit.toString().padStart(2, '0');
}

export function subtractISODate(startTime, endTime, errorMessage) {
  if (startTime && endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const deltaMiliseconds = endDate - startDate;
    const deltaSeconds = Math.floor(deltaMiliseconds / 1000);

    const hours = Math.floor(deltaSeconds / 3600);
    const minutes = Math.floor((deltaSeconds % 3600) / 60);
    const seconds = deltaSeconds % 60;

    const formattedHours = formatTimeUnit(hours);
    const formattedMinutes = formatTimeUnit(minutes);
    const formattedSeconds = formatTimeUnit(seconds);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} (hh:mm:ss)`;
  } else {
    return errorMessage;
  }
}
