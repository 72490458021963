import { ref } from 'vue';

export function usePreventDoubleClick(emit, delay = 300) {
  const justClicked = ref(false);

  function onClick(e) {
    emit('click', e);

    // Prevent DoubleClicking
    justClicked.value = true;
    setTimeout(() => (justClicked.value = false), 300);
  }

  return { onClick, justClicked };
}
