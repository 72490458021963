<template>
  <div>
    <TheLabel :label="label" />
    <div :class="['dropdown-parent', dropDownWrapperClasses]">
      <div ref="dropdownAdvancedContainer" class="relative w-full">
        <div
          ref="dropDownAdvancedContainerInner"
          class="container-inner"
          @click="toggleDropdown">
          <span>{{ displayValue }}</span>
          <span
            v-if="localStandardItem.name === displayValue"
            class="standard-status">
            standard
          </span>
          <IconWrapper
            :icon="
              customExpandIcon === null ? 'arrow_drop_down' : customExpandIcon
            "
            :size="expandIconSizes"
            :class="[
              { 'rotate-180': showDropdown && !customExpandIcon },
              'duration-300 ml-auto',
            ]"
            type="round" />
        </div>
        <div
          v-if="showDropdown"
          class="list-container"
          :class="listContainerClasses">
          <div
            ref="dropDownList"
            class="list-item-wrapper"
            :style="{
              'max-height': `${maxHeightDropdown}px`,
            }"
            @wheel.stop>
            <div
              v-for="(item, index) in itemsData"
              :key="index"
              class="single-list-item gap-1 justify-between"
              role="menuitem"
              @click="
                selectItem(item);
                toggleDropdown;
              ">
              <div class="flex items-center w-full gap-2">
                <RadioButton
                  v-model="item.standard"
                  @click.stop="updateStandard(item)" />
                <span class="item-name">
                  {{ itemsDataKey ? item[itemsDataKey] : item }}
                </span>
                <span v-if="localStandardItem === item" class="standard-status"
                  >standard</span
                >
                <IconWrapper
                  v-if="localStandardItem !== item"
                  icon="delete_forever"
                  fill="text-disabled-error"
                  class="delete-icon ml-auto cursor-pointer"
                  type="filled"
                  @click.stop="handleDelete(item)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';
import { computed, ref, watch } from 'vue';
import RadioButton from '../RadioButton/RadioButton.vue';
import { useListeners } from '../../composables/useListeners';

useListeners({
  click: closeDropdown,
});

const props = defineProps({
  size: {
    type: String,
    default: 'l',
    validate: (value) => ['s', 'm', 'l', 'xl', 'xxl'].includes(value),
  },
  itemsData: {
    type: Array,
    required: true,
  },
  itemsDataKey: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  openToTop: {
    type: Boolean,
    default: false,
  },
  customBackground: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number, Object, Array],
    default: null,
  },
  standardValue: {
    type: [String, Number, Object, Array],
    default: null,
  },
  value: {
    type: String,
    default: 'value',
  },
  customExpandIcon: {
    type: String,
    default: null,
  },
  showDeleteDialog: {
    type: Boolean,
  },
  deleteValue: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:standardValue',
  'triggerDelete',
]);

const showDropdown = ref(false);
const maxHeightDropdown = ref(250);
const dropdownAdvancedContainer = ref(null);
const localStandardItem = ref(null);

const dropDownWrapperClasses = computed(() => ({
  'dropdown-size-s': props.size === 's',
  'dropdown-size-m': props.size === 'm',
  'dropdown-size-l': props.size === 'l',
  'dropdown-size-xl': props.size === 'xl',
  'dropdown-size-xxl': props.size === 'xxl',
  disabled: props.disabled,
}));

const expandIconSizes = computed(() =>
  ['xl', 'xxl'].includes(props.size) ? 32 : 24,
);

const listContainerClasses = computed(() => ({
  openedDefault: !props.openToTop,
  openToTop: props.openToTop,
}));

const displayValue = computed(() => {
  const selectedItem = props.itemsData.find(
    (item) => item?.[props.value] === props.modelValue,
  );
  return selectedItem
    ? props.itemsDataKey
      ? selectedItem[props.itemsDataKey]
      : selectedItem
    : '';
});

function toggleDropdown() {
  showDropdown.value = !showDropdown.value;
}

function selectItem(item) {
  emit('update:modelValue', item[props.value] ?? item);
  showDropdown.value = false;
}

function updateStandard(item) {
  localStandardItem.value = item;
  emit('update:standardValue', item.id);
}

function handleDelete(item) {
  emit('triggerDelete', item);
}

function closeDropdown() {
  if (!dropdownAdvancedContainer.value.contains(event.target)) {
    showDropdown.value = false;
  }
}

watch(
  () => props.itemsData,
  (newItems) => {
    localStandardItem.value = newItems.find((item) => item.standard) || null;
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.dropdown-parent {
  @apply text-neutral cursor-pointer;
  &.disabled {
    pointer-events: none;
    @apply text-disabled-neutral;
    .container-inner {
      @apply bg-subtle border-line-disabled-neutral;
    }
  }

  &.dropdown-size-s {
    @extend .caption-2;

    .container-inner {
      height: 24px;
      padding: 6px;
      border-radius: 3px !important;

      &.opened {
        padding: 6px 5px;
      }
    }

    .list-container {
      border-radius: 3px;
    }

    .single-list-item {
      //height: 20px;
      padding: 5px;
    }
  }

  &.dropdown-size-m {
    @extend .body-2;

    .container-inner {
      height: 32px;
      padding: 8px;
      border-radius: 3px;

      &.opened {
        padding: 8px 7px;
      }
    }

    .list-container {
      border-radius: 3px;
    }

    .single-list-item {
      padding: 12px;

      .item-name {
        @extend .body-2;
      }
    }
  }

  &.dropdown-size-l {
    @extend .body-1;

    .container-inner {
      height: 40px;
      padding: 10px;
      border-radius: 3px;

      &.opened {
        padding: 10px 9px;
      }
    }

    .list-container {
      border-radius: 3px !important;
      box-shadow:
        0 0px 5px 0 rgba(0, 0, 0, 0.05),
        0 1px 4px 0 rgba(0, 0, 0, 0.05);
    }

    .single-list-item {
      line-height: 2.5rem;
      padding: 9px;
    }

    .delete-icon {
      padding-bottom: 1.7rem;
    }
  }

  &.dropdown-size-xl {
    @extend .body-1;

    .container-inner {
      height: 48px;
      padding: 8px 12px;
      border-radius: 3px;
      padding-right: 3px !important;

      &.opened {
        padding: 8px 11px;
        padding-right: 2px !important;
      }
    }

    .list-container {
      border-radius: 3px;
    }

    .single-list-item {
      height: 44px;
      padding: 11px;
    }
  }

  &.dropdown-size-xxl {
    @extend .subtitle-1;

    .container-inner {
      height: 48px;
      padding: 8px 15px;
      border-radius: 3px;
      padding-right: 7px !important;

      &.opened {
        padding: 8px 14px;
        padding-right: 6px !important;
      }
    }

    .list-container {
      border-radius: 3px;
    }

    .single-list-item {
      height: 44px;
      padding: 14px;
    }
  }
}

.standard-status {
  @extend .caption-3;
  @apply uppercase text-title-color1 ml-2;
}

.container-inner {
  @apply flex justify-start bg-active-area border-line-neutral
  items-center
  w-full border gap-1;
  &.invalid {
    @apply border-red-500;
  }

  &:hover {
    @apply border-line-active-neutral;
  }

  &.opened {
    border-width: 1px;
    @apply border-line-active-neutral;
    &.openedDefault {
      @apply rounded-b-none;
    }

    &.openToTop {
      @apply rounded-t-none;
    }
  }
}

.dropdown-radio input[type='radio'] {
  accent-color: rgb(1, 158, 181) !important;
  cursor: pointer;
}

.list-container {
  border-width: 1px;
  @apply absolute z-10 left-0 w-full dropdown-animation
  border-line-neutral overflow-hidden;
  &.openedDefault {
    @apply border-t-0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .list-item-wrapper {
      @apply flex-col;
      font-weight: 500;
    }
  }

  &.openToTop {
    @apply border-b-0;

    .list-item-wrapper {
      @apply flex-col-reverse;
    }
  }

  .list-item-wrapper {
    @apply overflow-y-auto flex;
  }
}

.single-list-item {
  @apply flex items-center hover:bg-subtle bg-default;
  &.isSelected {
    @apply bg-subtle;
  }
}

.dropdown-animation {
  animation: dropdown-animation 0.2s ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
