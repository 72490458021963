<template>
  <div
    class="rounded-[8px] z-[1] top-0 w-52 relative overflow-hidden bg-white standard-elevation-1"
  >
    <div class="h-full">
      <h4 class="p-2.5 w-full text-infra-highlight-500">
        {{ legend }}
      </h4>
      <div
        :style="'max-height:' + (getContainerHeight + 20) + 'px'"
        class="overflow-y-auto hide-scrollbar"
      >
        <div class="p-2.5">
          <div class="flex flex-col gap-2.5">
            <div
              v-for="parentItem in getLegend"
              :key="parentItem.key"
              class="standard-elevation-0-dark p-2.5 rounded flex flex-col gap-1"
            >
              <div class="body-2 text-infra-highlight-900">
                {{ parentItem.name }}
              </div>
              <!--              <div class="flex flex-col gap-1">-->
              <!-- Non-gradient elements without categoryId -->
              <div
                v-for="item in getFilteredItems(
                  parentItem.name,
                  false,
                  parentItem.items,
                  'icon',
                )"
                :key="item.key"
              >
                <div class="flex flex-col gap-1">
                  <LegendItem
                    :item="item"
                    :icon-styles="iconStyles"
                    :icon-styles-child="iconStylesChild"
                  />
                </div>
              </div>

              <!-- Non-gradients with subcategories: Loop over subcategories -->
              <div
                v-for="subtitle in parentItem.categoryIdArray"
                :key="subtitle.key"
                class="pt-2"
              >
                <div>
                  <!-- Render a subtitle -->
                  <div
                    v-for="titleObj in parentItem.titleMap"
                    :key="Object.keys(titleObj)[0]"
                  >
                    <div v-if="subtitle in titleObj">
                      <div class="body-2 text-gray-500">
                        {{ titleObj[subtitle] }}
                      </div>
                      <!-- Render items associated with the subtitle -->
                      <div
                        v-for="item in getFilteredItems(
                          parentItem.name,
                          true,
                          parentItem.items,
                          'icon',
                        )"
                        :key="item.key"
                      >
                        <div v-if="item.id === subtitle">
                          <LegendItem
                            :item="item"
                            :icon-styles="iconStyles"
                            :icon-styles-child="iconStylesChild"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              </div>-->

              <div>
                <!-- Gradients without subcategories -->
                <div
                  v-for="item in getFilteredItems(
                    parentItem.name,
                    false,
                    parentItem.items,
                    'max',
                  )"
                  :key="item.key"
                >
                  <GradientItem
                    :unit="parentItem.unit"
                    :min-value="item.min.value"
                    :min-value-ignore-format="item.min.noFormat"
                    :max-value="item.max.value"
                    :max-value-ignore-format="item.max.noFormat"
                    :min-color="item.min.color"
                    :max-color="item.max.color"
                  />
                </div>

                <!-- Gradients with subcategories: Loop over subtitles -->
                <div
                  v-for="subtitle in parentItem.gradientTitles"
                  :key="subtitle.key"
                >
                  <div>
                    <!-- Render subtitle -->
                    <div class="body-2 text-gray-500 pt-2">
                      {{ subtitle }}
                    </div>

                    <!-- Render items associated with subtitle -->
                    <div
                      v-for="item in getFilteredItems(
                        parentItem.name,
                        true,
                        parentItem.items,
                        'max',
                      )"
                      :key="item.key"
                    >
                      <div v-if="item.id === subtitle">
                        <GradientItem
                          :unit="item.unit"
                          :min-value="item.min.value"
                          :max-value="item.max.value"
                          :min-color="item.min.color"
                          :max-color="item.max.color"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneObject from '@/utils/objectUtils';
import LegendItem from './LegendItem.vue';
import GradientItem from './GradientItem.vue';

export default {
  components: {
    LegendItem,
    GradientItem,
  },
  emits: ['setLegendIconState', 'showLegend'],
  data() {
    return {
      legend: 'Legende',
    };
  },
  computed: {
    ...mapGetters('map', ['getLegendEntries']),
    ...mapGetters('map', ['getContainerHeight']),
    getLegend() {
      const legendEntries = this.setLegendData(this.getLegendEntries);
      this.setLegendIconState(legendEntries.length > 0);
      this.showLegend(legendEntries.length > 0);
      return legendEntries;
    },
  },
  methods: {
    getFilteredItems(parentItemName, withIds, array, field) {
      // Filter the items based on if is present
      // The additional field distinguishes between gradients and non-gradients
      return withIds
        ? array.filter((item) => 'id' && field in item)
        : array.filter((item) => !('id' in item) && field in item);
    },
    checkArray(legend, array) {
      // Ensure the specified property in `legend` is an array, otherwise initialize it
      if (!Array.isArray(legend[array])) {
        legend[array] = []; // Initialize if not an array
      }
    },
    setLegendIconState(entriesExist) {
      this.$emit('setLegendIconState', !!entriesExist);
    },
    showLegend(entriesExist) {
      this.$emit('showLegend', entriesExist);
    },
    setLegendData(legends) {
      const outLegends = {};

      for (let i = 0; i < legends.length; i += 1) {
        const elem = legends[i];
        const items = elem.items;

        if (elem.name in outLegends) {
          // If name category exists in outLegends
          const legend = outLegends[elem.name]; // get existing legend
          if (elem.type === 'gradient') {
            if (elem.subTitle) {
              items.forEach((item) => {
                legend.items.push(item); // Add items to the existing legend
              });
              this.checkArray(legend, 'gradientTitles');
              legend.gradientTitles.push(elem.subTitle);
            } else {
              // Gradients without `subTitle` have an `items` object instead of array -> converting necessary for rendering
              // This scenario didn't happen yet, since gradients with an existing name always have a `subTitle`
              legend.items = [elem.items];
            }
          } else if (!(elem.type === 'gradient')) {
            if (elem.categoryId) {
              if (!(elem.categoryId in legend)) {
                // Ensure `categoryIdArray` is an array, otherwise initialize it
                this.checkArray(legend, 'categoryIdArray');
                legend.categoryIdArray.push(elem.categoryId);

                // Create a mapping between `categoryId` and `subTitle`
                this.checkArray(legend, 'titleMap');
                legend.titleMap.push({ [elem.categoryId]: elem.subTitle });

                items.forEach((item) => {
                  legend.items.push(item); // Add items to the existing legend
                });
              }
            } else {
              // If no `categoryId`, add items to the main `name` category
              items.forEach((item) => {
                legend.items.push(item);
              });
            }
          }
        } else {
          // Name not yet in `outLegends`
          const newLegend = cloneObject(elem); // Clone element to avoid direct references
          outLegends[elem.name] = { ...newLegend };
          const legend = outLegends[elem.name];
          if (elem.type === 'gradient') {
            if (elem.subTitle) {
              // Create an array for gradient subtitles and push the `subTitle`
              legend.gradientTitles = [elem.subTitle];
            } else {
              // Gradients without `subTitle` have an `items` object instead of array -> converting necessary for rendering
              legend.items = [elem.items];
            }
            delete legend.subTitle;
            delete legend.type;
            // Gradient with a different object structure (array with gradient and non-gradient objects)
            // Somehow `name` for these objects always appears as new in `outLegends`
          } else if (elem.items[0].type === 'gradient') {
            if (elem.subTitle) {
              legend.items = [elem.items[0].items];
              legend.gradientTitles = [elem.subTitle];
            }
            elem.items.forEach((item) => {
              if (item.icon) {
                legend.items.push(item);
              }
            });
          } else if (!(elem.type === 'gradient')) {
            if (elem.categoryId) {
              // If there's a `categoryId`, add it to newly initialized array
              legend.categoryIdArray = [elem.categoryId];
              // Map `categoryId` and `subTitle`together
              const titlePair = { [elem.categoryId]: elem.subTitle };
              legend.titleMap.push(titlePair);

              // Delete fields which are no longer needed
              delete legend.subTitle;
              delete legend.categoryId;
            } else {
              // Add new name category without subcategories
              outLegends[elem.name] = cloneObject(elem);
            }
          }
        }
      }
      return Object.values(outLegends);
    },

    iconStyles(icon) {
      if (icon === 'mdi-checkbox-blank-circle') {
        return 'rounded-full';
      }
      if (icon === 'mdi-minus') {
        return 'rounded mt-[5px]';
      }
      if (icon === 'mdi-square') {
        return 'rounded-[2px]';
      }
    },
    iconStylesChild(icon) {
      if (icon === 'mdi-checkbox-blank-circle') {
        return 'rounded-full w-3 h-3';
      }
      if (icon === 'mdi-minus') {
        return 'rounded w-3 h-0.5';
      }
      if (icon === 'mdi-square') {
        return 'rounded-[2px] w-3 h-3';
      }
    },
  },
};
</script>
