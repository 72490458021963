import {
  LAYER_KEY__HEAT_PROJECT_CENTROID,
  LAYER_KEY__HEAT_PROJECT_ESTATE,
  LAYER_KEY__HEAT_PROJECT_NETWORK,
} from '@/configs/layers/heatProject';
import { state } from '@/features/heat-project/store';
import { getMap } from '@/mapbox/main';

/**
 * highlight all buildings in array for scenario
 * @param  {Integer} year year for setting all future projects feature state
 */
export function projectNotInYearRange(year) {
  const sources = [
    LAYER_KEY__HEAT_PROJECT_NETWORK,
    LAYER_KEY__HEAT_PROJECT_ESTATE,
    LAYER_KEY__HEAT_PROJECT_CENTROID,
  ];
  const heatProjects = state.heatProjects.filter(
    (e) => e.realisationYear > year,
  );
  const projectIds = heatProjects.map((e) => e.id);
  for (const source of sources) {
    const rawSource = getMap().getSource(source);
    if (typeof rawSource === 'undefined') return;
    const features = rawSource.serialize().data.features;
    features.forEach((e) => {
      const feature = { id: e.id, source };
      const isNotInYearRange = projectIds.includes(e.properties.id);
      getMap().setFeatureState(feature, {
        ['not-in-year-range']: isNotInYearRange,
      });
    });
  }
}
