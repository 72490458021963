import MapboxGlDraw from '@mapbox/mapbox-gl-draw';
import lineSplit from '@turf/line-split';
import booleanIntersects from '@turf/boolean-intersects';
import { GeoJsonLineString } from '@/features/draw/utilities';
import { modes } from '@/features/draw/constants';

const cutLinesMode = Object.assign({}, MapboxGlDraw.modes.draw_line_string);

cutLinesMode.onSetup = function (opts) {
  // bind context to "parent" setup
  const onSetup = MapboxGlDraw.modes.draw_line_string.onSetup.bind(this, opts);
  const state = onSetup();
  this.map.fire(MapboxGlDraw.constants.events.MODE_CHANGE, {
    mode: modes.DRAW_CUT_LINES,
  });
  state.toCut = opts.toCut;
  return state;
};

cutLinesMode.clickAnywhere = function (state, e) {
  const clickAnywhere = MapboxGlDraw.modes.draw_line_string.clickAnywhere.bind(
    this,
    state,
    e,
  );
  clickAnywhere();
  if (state.currentVertexPosition === 2) {
    const lines = this.map.queryRenderedFeatures({
      layers: state.toCut.layers,
      filter: state.toCut.filter,
    });
    const cutter = GeoJsonLineString(state.line.coordinates, {});
    const ids = [];
    for (const f of lines) {
      if (ids.includes(f.properties.id)) continue;
      if (booleanIntersects(f, state.line)) {
        const split = lineSplit(f, cutter);
        for (const s of split.features) {
          const { coordinates } = s.geometry;
          // manually copy user properties
          const properties = {
            // id might need to be set by draw-gl completely to avoid overwriting existing lines
            // id: i === 0 ? f.properties.user_id : null,
            type: f.properties.user_type,
          };
          const fragment = this.newFeature(
            GeoJsonLineString(coordinates, properties),
          );
          this.addFeature(fragment);
          ids.push(f.properties.id);
        }
        this.deleteFeature([f.properties.id], { silent: true });
      }
    }
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(MapboxGlDraw.constants.modes.SIMPLE_SELECT);
  }
};

export default cutLinesMode;
