<template>
  <ScenarioTabWrapper
    v-if="!readOnly"
    :apply-selection-enabled="
      technologyFieldsValid && props.scenario.municipalities.asList.length > 0
    "
    :show-button
    @selection-button-click="
      emitData(districtParameterizations.map((d) => d.district))
    "
  >
    <h5 class="text-title-color2">
      Diese Technologien müssen berücksichtigt werden:
    </h5>
    <div class="flex flex-col gap-3">
      <SwitchItem
        v-for="technology in scenarioTechnologyOptions"
        :key="technology.value"
        v-model="heatingTechnologies"
        :value="technology.value"
        :label="technology.display_name"
      />
    </div>
    <span v-if="!heatingTechnologies.length" class="left-1 text-xs text-error">
      Bitte wählen Sie mindestens eine Technologie aus
    </span>
  </ScenarioTabWrapper>
  <DetailSelection
    class="p-5"
    :apply-selection-enabled="technologyFieldsValid"
    :available-items="availableItems"
    :read-only
    :filtered-items="districtsForTechnologies"
    :selected-items="selectedItems"
    :headers="tableHeadersForTechnologies"
    :granular-selection-enabled="
      districtParameterizations.some((item) => {
        return item.heating_technologies?.length > 0;
      })
    "
    :key-name-for-checkbox="'district'"
    @detail-selection-visible="showButton = !$event"
    @update:filter="filters = $event"
    @item-click="selectedItems = [...$event]"
    @selection-button-click="emitData(selectedItems)"
  />
</template>

<script setup>
import { computed, ref } from 'vue';
import ScenarioTabWrapper from '@/apps/scenarios/components/shared/ScenarioTabWrapper.vue';
import SwitchItem from '@/components/storybook/src/stories/SwitchItem/SwitchItem.vue';
import DetailSelection from '@/apps/scenarios/components/shared/DetailSelection.vue';
import { useDetailFiltering } from '@/apps/scenarios/detailFiltering';

const props = defineProps({
  scenario: Object,
  activeTab: Number,
  scenarioTechnologyOptions: Object,
  districtParameterizations: Array,
  availableItems: Object,
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['setTechnology']);

const { selectedItems, filters, filteredItems } = useDetailFiltering(
  props.districtParameterizations,
  props.availableItems,
  ['heating_technologies'],
);

const showButton = ref(true);

const districtsForTechnologies = computed(() => {
  return filteredItems.value.map((district) => {
    const technologies = props.scenarioTechnologyOptions.reduce(
      (result, item) => {
        const technologies = district.heating_technologies;
        const isTechPresent = technologies?.includes(item.value)
          ? 'ja'
          : 'nein';
        const techStatus = technologies ? isTechPresent : '-';

        return { ...result, [item.value]: techStatus };
      },
      {},
    );
    return {
      ...district,
      ...technologies,
    };
  });
});
// table logic
const tableHeadersForTechnologies = computed(() => {
  const techHeaders = props.scenarioTechnologyOptions.map((item) => {
    return {
      title: `${item.display_name}`,
      key: `${item.value}`,
      width: '150px',
      align: 'left',
    };
  });
  return [
    {
      title: 'Stadtteil',
      key: 'district_name',
      width: '150px',
      align: 'left',
    },
    ...techHeaders,
  ];
});
// setting Values Logic
const heatingTechnologies = ref(
  props.scenarioTechnologyOptions.map((e) => e.value),
);

const technologyFieldsValid = computed(() => {
  return heatingTechnologies.value.length > 0;
});

function emitData(districtIds) {
  const updateDistrictParameter = {};
  for (const districtId of districtIds) {
    updateDistrictParameter[districtId] = {
      heating_technologies: heatingTechnologies.value,
    };
  }
  emit('setTechnology', updateDistrictParameter);
}
</script>
