export const tableHeaders = [
  {
    title: 'Bezeichnung\n' + 'Projektgebiet',
    key: 'name',
    align: 'start',
    width: '160px',
  },
  {
    title: 'Anzahl\n' + 'Gebäude',
    key: 'building_count',
    width: '100px',
    align: 'end',
  },
  {
    title: 'Wärmebedarf\n' + '(MWh) ',
    key: 'heat_demand_mwh',
    width: '140px',
    align: 'end',
    sortable: false,
  },
  {
    title: 'Wärmelinien\n-dichte (kWh/m)',
    key: 'linear_heat_density_kwh_m',
    align: 'end',
    width: '140px',
    sortable: false,
  },
  {
    title: 'Investitions\n-kosten (€)',
    key: 'invest_total',
    align: 'end',
    width: '140px',
    sortable: false,
  },
  {
    title: 'Deckungsbeitrag\n' + '(€)',
    key: 'contribution_margin',
    align: 'end',
    width: '140px',
    sortable: false,
  },
  {
    title: 'Hausanschluss-\n' + 'leitungen',
    key: 'building_connection_length_m',
    align: 'end',
    width: '140px',
    sortable: false,
  },
  {
    title: 'Verteilnetz',
    key: 'network_length_m',
    width: '100px',
    align: 'end',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    width: '100px',
    align: 'end',
    sortable: false,
  },
];

export const statsCards = [
  {
    title: 'Projektgebiete',
    icon: 'BlueprintFilledIcon',
    value: null,
    key: 'project_count',
    unit: '',
  },
  {
    title: 'Gebäude',
    icon: 'EditPointHouseIcon',
    value: null,
    key: 'building_count_sum',
    unit: '',
  },
  {
    title: 'Wärmebedarf',
    icon: 'EditPointHeatSourceFilledIcon',
    value: null,
    key: 'heat_demand_mwh_sum',
    unit: 'MWh(th)',
  },
  {
    title: 'Hausanschluss-\nleitungen (m)',
    icon: 'HomeLineIcon',
    value: null,
    key: 'building_connection_length_m_sum',
    unit: 'm',
  },
  {
    title: 'Verteilnetz',
    icon: 'HeatSourceLineIconFilled',
    value: null,
    key: 'network_length_expansion_m_sum',
    unit: 'm',
  },
  {
    title: 'Investitionskosten\nGesamt',
    icon: 'EuroShape',
    value: null,
    key: 'invest_total_sum',
    unit: '€',
  },
];
