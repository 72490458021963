<template>
  <div class="flex items-center">
    <img
      src="@/assets/svg/scenario-frame-icon.svg"
      class="mr-6 w-[64px]"
      alt="icon"
    />
    <h2>Szenariorahmen</h2>
  </div>
  <skeleton-loader-list v-if="!dataLoaded" />
  <div v-else>
    <ScenarioFrameList
      :scenario-frames="scenarioFramesBaseData"
      class="mb-[20px]"
      @edit-frame="editScenarioFrame($event)"
      @update-base-data="fetchFrameBaseData"
      @abort="abort"
    />
    <ScenarioFrameCreate
      v-if="!isEditing"
      :scenario-frames="scenarioFramesBaseData"
      @edit-frame="editScenarioFrame($event)"
      @add-frame="fetchFrameBaseData()"
    />
    <ScenarioFrameEdit
      v-else
      :edit-scenario-id="editScenarioFrameId"
      @abort="abort"
      @update-base-data="fetchFrameBaseData"
    />
  </div>
</template>

<script setup>
import ScenarioFrameList from './ScenarioFrameList.vue';
import ScenarioFrameCreate from './ScenarioFrameCreate.vue';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import { axios } from '@/utils/axiosHelper';
import { onMounted, ref } from 'vue';
import ScenarioFrame from '@/features/scenario-frame/scenario-frame';
import ScenarioFrameEdit from '@/features/scenario-frame/ScenarioFrameEdit.vue';

onMounted(async () => {
  fetchFrameBaseData();
  await ScenarioFrame.fetchOptions();
});

const scenarioFramesBaseData = ref([]);
const dataLoaded = ref(false);
const editScenarioFrameId = ref();
const isEditing = ref(false);

function editScenarioFrame(frameId) {
  editScenarioFrameId.value = frameId;
  isEditing.value = true;
}

function fetchFrameBaseData() {
  axios({ url: '/api/scenario-frames/base-data/', method: 'GET' }).then((r) => {
    scenarioFramesBaseData.value = r.data.frames;
    dataLoaded.value = true;
  });
}

function abort() {
  isEditing.value = false;
  editScenarioFrameId.value = null;
}
</script>
