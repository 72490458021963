import { infraModules } from '@/configs/infra-modules';

export const dashboards = [
  {
    key: 'community-structure',
    name: 'Gemeindestruktur',
    dispatch: 'dashboard/GET_COMMUNITY_DATA',
    module: infraModules.baseMap,
    showOptions: true,
  },
  {
    key: 'energy-balance',
    name: 'Energie- und Treibhausgasbilanz',
    dispatch: 'dashboard/GET_ENERGY_DATA',
    module: infraModules.scenarios,
    showOptions: true,
  },
  {
    key: 'district-heating-potentials',
    name: 'Fernwärme Potenziale',
    dispatch: 'dashboard/GET_DISTRICT_HEATING_POTENTIALS_DATA',
    module: infraModules.scenarios,
    showOptions: true,
  },
  {
    key: 'potentials',
    name: 'Potenziale',
    dispatch: 'dashboard/GET_POTENTIAL_DATA',
    module: infraModules.potentials,
    showOptions: false,
  },
  {
    key: 'chronological-evaluation',
    name: 'Zeitliche Auswertung',
    dispatch: 'dashboard/GET_ENERGY_DATA',
    module: infraModules.scenarios,
    showOptions: true,
  },
];
