import axios from 'axios';

export default {
  FETCH_PLACES: ({ commit }, input) =>
    new Promise((resolve, reject) => {
      axios({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=pk.eyJ1IjoibWFwZnJlZWxiZCIsImEiOiJjandzem95eW8wa2FhM3lxdjdxdjNlbjliIn0.JVEcWEoquKW1_4p6hBPTNg&cachebuster=1621432191888&autocomplete=true&country=de&language=de`,
        method: 'GET',
      })
        .then((resp) => {
          commit('GET_PLACES', resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DISPLAY_SEARCHBAR: ({ commit }, payload) =>
    new Promise(() => {
      commit('SET_SEARCHBAR', payload);
    }),
};
