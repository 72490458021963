import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

export default {
  GET_SCENARIOS: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/scenarios/',
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_SCENARIOS', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  GET_SCENARIO_DISTRICT_OPTIONS: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/parameterization-by-district/',
        method: 'OPTIONS',
      })
        .then((resp) => {
          commit('SET_SCENARIO_DISTRICT_OPTIONS', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  POST_SCENARIO: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/scenarios/',
        method: 'POST',
        data: payload,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('ADD_SCENARIO', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  PUT_SCENARIO: ({ commit }, scenario) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/scenarios/recalculate/${scenario.scenario_id}`,
        method: 'PUT',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('UPDATE_SCENARIO_PROGRESS', {
            sid: scenario.scenario_id,
            progress: 2,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  DELETE_SCENARIO: ({ commit }, scenarioId) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/scenarios/${scenarioId}`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      })
        .then((resp) => {
          commit('REMOVE_SCENARIO', scenarioId);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
