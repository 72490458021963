<template>
  <div
    class="flex justify-between w-full items-center p-5 h-full bg-default relative"
  >
    <div v-if="!loaded" class="absolute w-full flex justify-center">
      <VProgressCircular
        class="text-title-color1"
        size="60"
        width="8"
        indeterminate
      />
    </div>
    <div class="w-[500px] h-[550px]">
      <GeoMunicipalityChart
        :read-only
        :selected-municipalities="scenario.municipalities.asList"
        :available-municipalities="availableMunicipalities"
        :select-all-from-the-start="municipalities.length === 1"
        @update:selected-municipalities="scenario.municipalities.toggle($event)"
        @update:municipalities="init"
        @loaded="loaded = true"
      />
    </div>
    <div v-if="loaded" class="flex flex-col w-[320px] items-start h-[420px]">
      <h6 class="mb-10 text-center text-title-color2">
        Dieses Szenario {{ readOnly ? 'wurde' : 'wird' }} berechnet für:
      </h6>
      <DropDownMultiple
        v-if="municipalities.length > 1 && availableFederalStates.length > 0"
        v-model="selectedFederalStates"
        :read-only
        label="Bundesland"
        class="w-[320px] mb-10"
        :items="availableFederalStates"
        data-key="federal_state_name"
        value-key="federal_state_id"
        item-type-all="Bundesländer"
        :ensure-one-selected="true"
      />
      <DropDownMultiple
        v-if="municipalities.length > 1 && availableCounties.length > 0"
        v-model="selectedCounties"
        :read-only
        label="Landkreise"
        class="w-[320px] mb-10"
        :items="availableCounties"
        data-key="county_name"
        value-key="county_id"
        item-type-all="Landkreise"
        :ensure-one-selected="true"
      />
      <DropDownMultiple
        v-model="selectedMunicipalitiesDopdown"
        :ensure-one-selected="municipalities.length === 1"
        :read-only
        label="Gemeinden"
        class="w-[320px] mb-10"
        :clearable="true"
        :items="availableMunicipalities"
        data-key="name"
        value-key="municipality_key"
        placeholder="Bitte auswählen"
        data-test="municipality-dropdown"
        @update:model-value="syncScenarioFromDropdown($event)"
      />
      <p class="caption-1 text-title-neutral">
        Einstellung gilt für alle Stadtteile
      </p>
    </div>
    <VuetifyDialog
      v-model="confirmDialogOpen"
      :activator-full-width="false"
      :actions-centered="true"
      title="Erweiterung der Gemeindeauswahl"
    >
      <template #content>
        <div class="flex w-[560px] gap-5 mt-5">
          <IconWrapper
            icon="warning"
            :size="92"
            type="round"
            fill="text-inverted-color2"
          />
          <div class="flex flex-col gap-5 mb-5">
            <p>
              Für die ursprüngliche Gebietsauswahl wurden bereits Parameter wie
              Szenariorahmen, Effizienzmaßnahmen usw. zugewiesen.
            </p>
            <p>
              Durch die neue Gebietsauswahl ist es notwendig, auch den neuen
              Teilgebieten Parameter zuzuweisen.
            </p>
            <p>
              Sie können diese in den jeweiligen Tabs über den Filter “Gemeinden
              ohne Wert” anzeigen und bearbeiten.
            </p>
            <div class="flex pl-5 mt-5">
              <CheckboxEl v-model="disableDialog" class="mr-2" />
              <p class="caption-1">
                Diese Meldung in Zukunft nicht mehr anzeigen
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <ButtonEl
          variant="secondary"
          text="Auswahl Abbrechen"
          @click="revertSelection"
        />
        <ButtonEl text="Weiter mit Gemeindeauswahl" @click="closeDialog" />
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import cookie from 'vue-cookies';
import GeoMunicipalityChart from '@/apps/analysis-dashboard/charts/GeoMunicipalityChart.vue';
import DropDownMultiple from '@/components/storybook/src/stories/dropDownMultiple/DropDownMultiple.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  scenario: {
    type: Object,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const loaded = ref(false);

const emit = defineEmits(['initialize-districts']);

function init(items) {
  municipalities.value = [...items];
  selectedFederalStates.value = availableFederalStates.value;
  selectedCounties.value = availableCounties.value;
  syncDropdownSelect();

  // Default select for one municipality
  if (items.length === 1) {
    props.scenario.municipalities.updateFromList(items);
  }
}

function syncDropdownSelect() {
  const municipalities = [];
  for (const municipality of props.scenario.municipalities.asList) {
    municipalities.push({
      municipality_key: municipality.municipality_id,
      name: combineNameKey(
        municipality.municipality_name,
        municipality.municipality_id,
      ),
    });
  }
  selectedMunicipalitiesDopdown.value = municipalities;
}

function syncScenarioFromDropdown(municipalitiesDrodown) {
  // geo unselect all, then geo select according to selected in dropdown
  const keys = new Set(municipalitiesDrodown.map((e) => e.municipality_key));
  const updateMunicipalities = [];
  for (const m of municipalities.value) {
    if (keys.has(m.municipality_key)) {
      m.municipality_id = m.municipality_key;
      updateMunicipalities.push(m);
    }
  }
  props.scenario.municipalities.updateFromList(updateMunicipalities);
}

// Dropdown select options
const selectedFederalStates = ref([]);
const availableFederalStates = computed(() => {
  const states = [];
  for (const municipality of municipalities.value) {
    const stateNotYetAdded = !states
      .map((e) => e.federal_state_id)
      .includes(municipality.federal_state_id);

    if (municipality.federal_state_id && stateNotYetAdded)
      states.push({
        federal_state_name: municipality.federal_state_name,
        federal_state_id: municipality.federal_state_id,
      });
  }
  return states;
});

const selectedCounties = ref([]);
const availableCounties = computed(() => {
  const counties = [];
  for (const municipality of municipalities.value) {
    const countyNotYetAdded = !counties
      .map((e) => e.county_id)
      .includes(municipality.county_id);
    const countyInSelectedStates = selectedFederalStates.value
      .map((e) => e.federal_state_id)
      .includes(municipality.federal_state_id);

    if (municipality.county_id && countyNotYetAdded && countyInSelectedStates)
      counties.push({
        county_name: municipality.county_name,
        county_id: municipality.county_id,
      });
  }
  return counties;
});

const municipalities = ref([]);
const selectedMunicipalitiesDopdown = ref([]);
const availableMunicipalities = computed(() => {
  const municipalitiesData = [];
  for (const municipality of municipalities.value) {
    if (
      selectedFederalStates.value
        .map((e) => e.federal_state_id)
        .includes(municipality.federal_state_id) &&
      selectedCounties.value
        .map((e) => e.county_id)
        .includes(municipality.county_id)
    )
      municipalitiesData.push({
        municipality_key: municipality.municipality_key,
        name: combineNameKey(
          municipality.municipality_name,
          municipality.municipality_key,
        ),
      });
  }
  return municipalitiesData;
});

watch(
  () => props.scenario.municipalities.asList,
  (newVal, oldVal) => {
    syncDropdownSelect();
    const wasAdded = newVal.length > oldVal.length;
    if (
      wasAdded &&
      props.scenario.municipalities.hasValid() &&
      cookie.get('geo_chart_dialog_disabled') !== 'true'
    ) {
      confirmDialogOpen.value = true;
    }
    emit(
      'initialize-districts',
      props.scenario.municipalities.asList.map((item) => item.municipality_id),
    );
  },
);

// Dialog
const confirmDialogOpen = ref(false);
const disableDialog = ref(false);

function revertSelection() {
  props.scenario.municipalities.removeMunicipalitiesWithoutFrame();
  closeDialog();
}

function closeDialog() {
  if (disableDialog.value) {
    cookie.set('geo_chart_dialog_disabled', true);
  }
  confirmDialogOpen.value = false;
}

function combineNameKey(name, key) {
  return `${name} (${key})`;
}
</script>
