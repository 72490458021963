import mapboxgl from 'mapbox-gl';
import config from '@/configs';
import U from 'map-gl-utils';
import { getTileServerUrl } from '@/mapbox/createLayer';
import { removeMarker } from '../../mapbox/main';
import iconLayerImage from '@/assets/img/mapIcons/marker.png';

let featureId = null;
export const LAYER_KEY__BUILDING_UPDATE = 'building_aggregates';

export default function initMap(getBuilding, initialFilter, centroid) {
  const map = new mapboxgl.Map({
    ...config.constraints.mapView.mapConstraints,
    container: 'map-building-update',
  });
  const sourceLayer = LAYER_KEY__BUILDING_UPDATE;
  const layer = LAYER_KEY__BUILDING_UPDATE;
  U.init(map, mapboxgl);
  map.on('load', () => {
    map.U.addVector(layer, getTileServerUrl(sourceLayer));
    map.U.addFillExtrusionLayer(layer, layer, {
      visibility: 'visible',
      'fill-extrusion-color': [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#5c0000',
        '#888888',
      ],
      'fill-extrusion-height': ['get', 'height_m'],
      'fill-extrusion-opacity': 1,
      'source-layer': sourceLayer,
      ...(initialFilter && { filter: initialFilter }),
    });

    map.addSource('markerSource', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    // Step 3: Add an Icon Layer
    map.loadImage(iconLayerImage, (error, image) => {
      if (error) throw error;
      map.addImage('markerIcon', image);
      map.addLayer({
        id: 'marker',
        type: 'symbol',
        source: 'markerSource',
        layout: {
          'icon-image': 'markerIcon',
          'icon-size': 0.1,
          'icon-offset': [0, -165],
        },
      });
    });

    map.flyTo({
      zoom: 12,
      center: [centroid.lng, centroid.lat],
    });

    map.resize();
  });

  map.on('click', (e) => {
    removeMarker(map);
    if (featureId) {
      map.removeFeatureState({ source: layer, sourceLayer, id: featureId });
      featureId = null;
    }
    const feature = map.queryRenderedFeatures(e.point, { layers: [layer] })[0];
    if (typeof feature !== 'undefined') {
      featureId = feature.id;
      map.setFeatureState(
        { source: layer, sourceLayer, id: featureId },
        { clicked: true },
      );
      getBuilding(feature.properties.alkis_id);
    }
  });

  return map;
}
