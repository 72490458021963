import {
  LAYER_KEY__HEAT_PROJECT_NETWORK,
  LAYER_KEY__HEAT_PROJECT_CENTROID,
  LAYER_KEY__HEAT_PROJECT_ESTATE,
} from '../layers/heatProject';

import { highlightBuildings } from '@/features/heat-project/highlight-buildings';
import highlightHeatSources from '@/features/heat-project/highlight-heat-sources';

export const SWITCH_ID__HEAT_PROJECT_NETWORK = 'LC_HEAT_PROJECT_NETWORK';
export const SWITCH_ID__HEAT_PROJECT_ESTATE = 'LC_HEAT_PROJECT_ESTATE';
export const SWITCH_ID__HEAT_PROJECT_BUILDINGS = 'LC_HEAT_PROJECT_BUILDINGS';
export const SWITCH_ID__HEAT_PROJECT_CENTROID = 'LC_HEAT_PROJECT_CENTROID';
export const SWITCH_ID__HEAT_PROJECT_HEAT_SOURCES =
  'LC_HEAT_PROJECT_HEAT_SOURCES';

export default {
  title: 'Wärmeprojekte',
  items: [
    {
      title: 'Projektgebiet Cluster',
      switchId: SWITCH_ID__HEAT_PROJECT_CENTROID,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__HEAT_PROJECT_CENTROID],
      active: false,
      radioGroups: [],
    },
    {
      title: 'Netz',
      switchId: SWITCH_ID__HEAT_PROJECT_NETWORK,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__HEAT_PROJECT_NETWORK],
      active: false,
      radioGroups: [],
    },
    {
      title: 'Gebäude',
      switchId: SWITCH_ID__HEAT_PROJECT_BUILDINGS,
      itemClass: 'switch',
      customFunction: function (active) {
        highlightBuildings(active);
      },
      active: false,
      radioGroups: [],
    },
    {
      title: 'Hausanschlüsse',
      switchId: SWITCH_ID__HEAT_PROJECT_ESTATE,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__HEAT_PROJECT_ESTATE],
      active: false,
      radioGroups: [],
    },
    {
      title: 'Wärmequellen',
      switchId: SWITCH_ID__HEAT_PROJECT_HEAT_SOURCES,
      itemClass: 'switch',
      customFunction: function (active) {
        highlightHeatSources(active);
      },
      active: false,
      radioGroups: [],
    },
  ],
};
