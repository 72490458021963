<template>
  <AuthFormWrapper
    :title="title"
    :error="forgotError"
    :error-message="forgotErrorMessage"
  >
    <div class="body-1 text-neutral whitespace-normal mb-12">
      {{ t('forgot.subtitle') }}
    </div>
    <FormValidator v-model="isFormValid" class="gap-6">
      <InputEl
        v-model="username"
        :rules="{ required: true, email: true }"
        label="E-Mail-Adresse"
        placeholder="Bitte eingeben"
        class="w-full"
        @keyup.enter="confirmResetEmail"
        @update:model-value="forgotError = false"
      />
      <ButtonEl
        :text="t('forgot.button')"
        :full-width="true"
        :disabled="!isFormValid || isSubmitting"
        @click="confirmResetEmail"
      />
      <RouterLink to="/login">
        <ButtonEl
          variant="secondary"
          :text="t('forgot.backtosign')"
          :full-width="true"
          icon="west"
        />
      </RouterLink>
    </FormValidator>
  </AuthFormWrapper>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AuthFormWrapper from '@/apps/auth/AuthFormWrapper.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const { t } = useI18n();

const username = ref(null);
const isFormValid = ref(false);
const isSubmitting = ref(false);

// form error
const forgotError = ref(false);
const forgotErrorMessage = ref('');

const title = computed(() => t('forgot.title'));

function confirmResetEmail() {
  if (isFormValid.value) {
    isSubmitting.value = true;
    resetEmail();
  }
}

function resetEmail() {
  axios({
    url: '/api/forgot-password/',
    data: { username: username.value },
    method: 'POST',
  })
    .then(() => (isSubmitting.value = false))
    .catch((err) => {
      isSubmitting.value = false;
      forgotErrorMessage.value = err.response.data.message;
      forgotError.value = true;
    });
}
</script>
