<template>
  <div v-if="isCustomIcon" :class="customIconClasses" @click="onClick">
    <Component :is="icon" :style="measurements"/>
  </div>
  <div
    v-else
    :style="measurements"
    :class="iconWrapperClasses"
    @click="onClick">
    <span :class="iconClasses" :style="iconStyle">{{ icon }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePreventDoubleClick } from '../../composables/preventDoubleClick';

const props = defineProps({
  icon: {
    type: [String, Object],
    required: true,
  },
  fill: {
    type: String,
    default: 'currentColor',
  },
  hover: {
    type: String,
    default: 'currentColor',
  },
  size: {
    type: [Number, String],
    default: 24,
  },
  type: {
    type: String,
    default: null,
    validator: (value) =>
      ['filled', 'outlined', 'sharp', 'two-tone', 'round'].includes(value),
  },
});

const isCustomIcon = computed(() => typeof props.icon === 'object');

// For Material Icons
const iconWrapperClasses = computed(() => ({
  'pointer-events-none': justClicked.value,
}));

const iconClasses = computed(() => {
  return {
    'variation-settings': true,
    [props.fill]: true,
    [props.hover]: true,
    'material-icons-round material-symbols-rounded': props.type === null,
    'material-icons': props.type === 'filled',
    [`material-icons-${props.type} material-symbols-${props.type}`]: props.type,
  };
});

const iconStyle = computed(() => ({
  fontSize: `${props.size}px`,
}));

// For Custom Icons
const customIconClasses = computed(() => {
  return {
    [props.fill]: true,
    'pointer-events-none': justClicked.value,
  };
});
const measurements = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}));

const emits = defineEmits(['click']);
const { onClick, justClicked } = usePreventDoubleClick(emits);
</script>

<style scoped>
.variation-settings {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 20;
}
</style>
