import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import { barChartData } from '@/features/heat-project/charts/create-chart-data';
import NumberField from '@/fieldClasses/NumberField';

export class HeatProjectResult {
  static OPTIONS = null;
  static OPTIONS_LOADED = false;

  constructor() {
    this.heatOutput = 0;
    this.connectionRateResult = [];
    if (!HeatProjectResult.OPTIONS_LOADED) {
      HeatProjectResult.OPTIONS_LOADED = true;
      this._fetchOptions();
    }
    this.breakEven = { connectionRate: null, margin: null };
  }

  initResult(payload, heatOutput) {
    this.heatOutput = heatOutput;
    for (const connectionRateResult of payload) {
      const margin = connectionRateResult.contribution_margin;
      if (margin > 0) {
        if (this.breakEven.margin === null || margin < this.breakEven.margin) {
          this.breakEven.margin = margin;
          this.breakEven.connectionRate = connectionRateResult.connection_rate;
        }
      }
      this.connectionRateResult.push({
        connection_rate: connectionRateResult.connection_rate,
        building_connection_length_m:
          connectionRateResult.building_connection_length_m,
        network_length_m: connectionRateResult.network_length_m,
        network_length_without_existing_m:
          connectionRateResult.network_length_without_existing_m,
        invest_distribution_network:
          connectionRateResult.invest_distribution_network,
        invest_distribution_network_on_existing_network:
          connectionRateResult.invest_distribution_network_on_existing_network,
        invest_building_connections:
          connectionRateResult.invest_building_connections,
        invest_heat_substation: connectionRateResult.invest_heat_substation,
        invest_total: connectionRateResult.invest_total,
        contribution_margin: connectionRateResult.contribution_margin,
        contribution_margin_without_existing_network:
          connectionRateResult.contribution_margin_without_existing_network,
        contribution_margin_buildings:
          connectionRateResult.contribution_margin_buildings,
        cost_network: connectionRateResult.cost_network,
        specific_margin_eur_mwh: connectionRateResult.specific_margin_eur_mwh,
        specific_margin_eur_mwh_without_existing_network:
          connectionRateResult.specific_margin_eur_mwh_without_existing_network,
        specific_heat_price_eur_mwh:
          connectionRateResult.specific_heat_price_eur_mwh,
        specific_heat_price_eur_mwh_without_existing_network:
          connectionRateResult.specific_heat_price_eur_mwh_without_existing_network,
        specific_heat_costs_eur_mwh:
          connectionRateResult.specific_heat_costs_eur_mwh,
        specific_heat_costs_eur_mwh_without_existing_network:
          connectionRateResult.specific_heat_costs_eur_mwh_without_existing_network,
        linear_heat_density_kwh_m:
          connectionRateResult.linear_heat_density_kwh_m,
        linear_heat_density_kwh_m_without_existing_network:
          connectionRateResult.linear_heat_density_kwh_m_without_existing_network,
        heat_demand_mwh: connectionRateResult.heat_demand_mwh,
        heat_load_mw: connectionRateResult.heat_load_mw,
        grouped_pipe_length: connectionRateResult.grouped_pipe_length,
        network_losses_w_m: connectionRateResult.network_losses_w_m,
        network_losses_mwh_m: connectionRateResult.network_losses_mwh_m,
        network_losses_mwh_a: connectionRateResult.network_losses_mwh_a,
        network_losses_percent: connectionRateResult.network_losses_percent,
      });
    }
  }

  get options() {
    return HeatProjectResult.OPTIONS;
  }

  resultInfoPanelChipData(
    connectionRate,
    simultaneityFactor,
    simultaneityFactorOptions,
  ) {
    const Rate = parseFloat(connectionRate);
    const result = this.connectionRateResult.find(
      (r) => r.connection_rate === Rate,
    );

    return {
      title: 'Auswertung',
      active: true,
      items: [
        {
          title: 'Leistungdeckung',
          active: false,
          items: [
            {
              panelItemType: 'heatProjectChart',
              barChartData: barChartData(
                this.heatOutput,
                result?.heat_load_mw || 0,
              ),
            },
          ],
        },
        {
          title: 'Wärme',
          active: true,
          items: [
            NumberField.fieldData(
              result?.heat_demand_mwh || 0,
              this.options['heat_demand_mwh'],
              'MWh',
              2,
            ),
            NumberField.fieldData(
              result?.linear_heat_density_kwh_m || 0,
              this.options['linear_heat_density_kwh_m'],
              'kWh/m',
              2,
            ),
            NumberField.fieldData(
              result?.heat_load_mw / simultaneityFactor || 0,
              {
                type: 'float',
                required: false,
                read_only: false,
                label: 'Wärmelast vor Gleichzeitigkeit',
              },
              'MW',
              2,
            ),
            NumberField.fieldData(
              result?.heat_load_mw || 0,
              {
                type: 'float',
                required: false,
                read_only: false,
                label: 'Wärmelast nach Gleichzeitigkeit',
              },
              'MW',
              2,
            ),
            NumberField.fieldData(
              simultaneityFactor || 0,
              simultaneityFactorOptions,
              '%',
              2,
              100,
            ),
          ],
        },
        {
          title: 'Netzverluste',
          active: false,
          items: [
            NumberField.fieldData(
              result?.network_losses_mwh_a || 0,
              this.options['network_losses_mwh_a'],
              'MWh/a',
              2,
            ),
            NumberField.fieldData(
              result?.network_losses_mwh_m || 0,
              this.options['network_losses_mwh_m'],
              'MWh/m',
              2,
            ),
            NumberField.fieldData(
              result?.network_losses_w_m || 0,
              this.options['network_losses_w_m'],
              'W/m',
              2,
            ),
            NumberField.fieldData(
              result?.network_losses_percent || 0,
              this.options['network_losses_percent'],
              '%',
              2,
            ),
          ],
        },
        {
          title: 'Netzlängen',
          active: false,
          items: [
            NumberField.fieldData(
              result?.network_length_m || 0,
              this.options['network_length_m'],
              'm',
            ),
            NumberField.fieldData(
              result?.network_length_without_existing_m || 0,
              this.options['network_length_without_existing_m'],
              'm',
            ),
            NumberField.fieldData(
              result?.building_connection_length_m || 0,
              this.options['building_connection_length_m'],
              'm',
            ),
            {
              panelItemType: 'heatProjectDoughnutChart',
              chartData: result?.grouped_pipe_length || [],
            },
          ],
        },
        {
          title: 'Investitionskosten',
          active: false,
          items: [
            NumberField.fieldData(
              result?.invest_distribution_network || 0,
              this.options['invest_distribution_network'],
              'Tsd. €',
              0,
              0.001,
            ),
            NumberField.fieldData(
              result?.invest_building_connections || 0,
              this.options['invest_building_connections'],
              'Tsd. €',
              0,
              0.001,
            ),
            NumberField.fieldData(
              result?.invest_heat_substation || 0,
              this.options['invest_heat_substation'],
              'Tsd. €',
              0,
              0.001,
            ),
            NumberField.fieldData(
              result?.invest_total || 0,
              this.options['invest_total'],
              'Tsd. €',
              0,
              0.001,
            ),
            NumberField.fieldData(
              -1 * result?.invest_distribution_network_on_existing_network || 0,
              this.options['invest_distribution_network_on_existing_network'],
              'Tsd. €',
              0,
              0.001,
            ),
          ],
        },
        {
          title: 'Deckungsbeiträge',
          active: false,
          items: [
            NumberField.fieldData(
              result?.contribution_margin || 0,
              this.options['contribution_margin'],
              'Tsd. €/a',
              0,
              0.001,
            ),
            NumberField.fieldData(
              result?.specific_margin_eur_mwh || 0,
              this.options['specific_margin_eur_mwh'],
              '€/MWh',
              2,
            ),
            NumberField.fieldData(
              result?.specific_heat_costs_eur_mwh || 0,
              this.options['specific_heat_costs_eur_mwh'],
              '€/MWh',
              2,
            ),
            NumberField.fieldData(
              result?.specific_heat_price_eur_mwh || 0,
              this.options['specific_heat_price_eur_mwh'],
              '€/MWh',
              2,
            ),
          ],
        },
        {
          title: 'Deckungsbeiträge ohne Verteilnetz Bestand',
          active: false,
          items: [
            NumberField.fieldData(
              result?.contribution_margin_without_existing_network,
              this.options['contribution_margin_without_existing_network'],
              'Tsd. €/a',
              0,
              0.001,
              false,
            ),
            NumberField.fieldData(
              result?.specific_margin_eur_mwh_without_existing_network,
              this.options['specific_margin_eur_mwh_without_existing_network'],
              '€/MWh',
              2,
              1,
              false,
            ),
            NumberField.fieldData(
              result?.specific_heat_costs_eur_mwh_without_existing_network,
              this.options[
                'specific_heat_costs_eur_mwh_without_existing_network'
              ],
              '€/MWh',
              2,
              1,
              false,
            ),
            NumberField.fieldData(
              result?.specific_heat_price_eur_mwh_without_existing_network,
              this.options[
                'specific_heat_price_eur_mwh_without_existing_network'
              ],
              '€/MWh',
              2,
              1,
              false,
            ),
          ],
        },
      ],
    };
  }

  _fetchOptions() {
    axios({
      url: '/api/heatprojects/project-result/options/',
      method: 'GET',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      HeatProjectResult.OPTIONS = resp.data.options;
    });
  }
}
