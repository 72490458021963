<template>
  <ScenarioTabWrapper
    v-if="!readOnly"
    :apply-selection-enabled="
      props.scenario.municipalities.asList.length > 0 && efficiencyFieldsValid
    "
    :show-button
    @selection-button-click="
      emitData(districtParameterizations.map((d) => d.district))
    "
  >
    <div class="w-[50%]">
      <div class="flex justify-between mb-5">
        <InputEl
          v-model="refurbishmentValue"
          label="Sanierungstiefe"
          :rules="{ max: [120, 'float'] }"
          input-type="float"
          :suffix="refurbishmentUnit === 0 ? 'kWh/m²' : '%'"
          class="w-[50%]"
          data-test="refurbishment-value-input"
          tooltip-text="Zielwert, mit dem ein Gebäude als saniert gilt: Ziel-Wärmebedarf in kWh/m2 oder prozentuale Senkung des Wärmebedarfs"
        />
        <DropDown
          v-model="refurbishmentUnit"
          :items-data="scenarioDistrictOptions.refurbishment_unit.choices"
          :initial-selection="true"
          items-data-key="display_name"
          class="w-[49%]"
          label="Einheit Sanierungstiefe"
        />
      </div>
      <InputEl
        v-model="refurbishmentRate"
        label="Sanierungsquote"
        input-type="float"
        :rules="{ max: [100, 'float'] }"
        suffix="% p.a."
        data-test="refurbishment-rate-input"
        tooltip-text="Anteil der Gebäude, die jährlich saniert werden sollen"
      />
    </div>
  </ScenarioTabWrapper>
  <DetailSelection
    class="p-5"
    :apply-selection-enabled="efficiencyFieldsValid"
    :available-items="availableItems"
    :read-only
    :filtered-items="districtsforEfficiency"
    :selected-items="selectedItems"
    :headers="tableHeaders.efficiency"
    :granular-selection-enabled="
      districtParameterizations.some((item) => {
        return item.refurbishment_rate !== null;
      })
    "
    :key-name-for-checkbox="'district'"
    @detail-selection-visible="showButton = !$event"
    @update:filter="filters = $event"
    @item-click="selectedItems = [...$event]"
    @selection-button-click="emitData(selectedItems)"
  />
</template>

<script setup>
import { tableHeaders } from '@/apps/scenarios/scenario-create-data';
import { computed, ref } from 'vue';
import ScenarioTabWrapper from '@/apps/scenarios/components/shared/ScenarioTabWrapper.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import DetailSelection from '@/apps/scenarios/components/shared/DetailSelection.vue';
import { isNullOrUndefined } from '@/utils/generalUtils';
import { useDetailFiltering } from '@/apps/scenarios/detailFiltering';

const props = defineProps({
  scenario: Object,
  activeTab: Number,
  scenarioDistrictOptions: Object,
  districtParameterizations: Array,
  availableItems: Object,
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['setEfficiencyMeasures']);

const { selectedItems, filters, filteredItems } = useDetailFiltering(
  props.districtParameterizations,
  props.availableItems,
  ['refurbishment_value'],
);

const showButton = ref(true);

const districtsforEfficiency = computed(() => {
  return filteredItems.value.map((district) => {
    const { refurbishment_value, refurbishment_rate, refurbishment_unit } =
      district;
    const refurbishmentValue = isNullOrUndefined(refurbishment_value)
      ? '-'
      : `${refurbishment_value} ${refurbishment_unit === 0 ? 'kWh/m²' : '%'}`;
    const refurbishmentRate = isNullOrUndefined(refurbishment_rate)
      ? '-'
      : `${refurbishment_rate} % p.a.`;

    return {
      ...district,
      refurbishmentValue,
      refurbishmentRate,
    };
  });
});

// setting Values Logic
const refurbishmentUnit = ref(null);
const refurbishmentValue = ref(null);
const refurbishmentRate = ref(null);

const efficiencyFieldsValid = computed(() => {
  const refurbishmentValueValid =
    refurbishmentValue.value !== null &&
    refurbishmentValue.value <= 120 &&
    refurbishmentValue.value >= 0;

  const refurbishmentRateValid =
    refurbishmentRate.value !== null &&
    refurbishmentRate.value <= 100 &&
    refurbishmentRate.value >= 0;

  return refurbishmentValueValid && refurbishmentRateValid;
});

function emitData(districtIds) {
  const updateDistrictParameter = {};
  for (const districtId of districtIds) {
    updateDistrictParameter[districtId] = {
      refurbishment_unit: refurbishmentUnit.value,
      refurbishment_value: refurbishmentValue.value,
      refurbishment_rate: refurbishmentRate.value,
    };
  }
  emit('setEfficiencyMeasures', updateDistrictParameter);
}
</script>
