<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <slot />
  </form>
</template>

<script setup>
import { provide, ref, watchEffect } from 'vue';
import { useFormValidation } from '../../composables/formValidation';

const { isFormValid, updateValidationState } = useFormValidation();

const emit = defineEmits(['update:modelValue']);

const childrenValidators = ref(new Map());

function registerValidator(name, validator) {
  childrenValidators.value.set(name, validator);
}

function unregisterValidator(name) {
  childrenValidators.value.delete(name);
}

function validateChildren() {
  childrenValidators.value.forEach((value, key) => {
    updateValidationState(key, value);
  });
  emit('update:modelValue', isFormValid.value);
}

provide('registerValidator', registerValidator);
provide('unregisterValidator', unregisterValidator);

watchEffect(() => {
  validateChildren();
});
</script>
