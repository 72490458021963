<template>
  <FormValidator v-model="isFormValid" class="flex flex-col gap-3 w-[400px]">
    <InputEl
      v-model="username"
      :rules="{ required: true, email: true }"
      label="Username"
      placeholder="Bitte E-Mail-Adresse eingeben"
    />
    <InputEl
      v-model="email"
      :rules="{ required: true, email: true }"
      label="E-Mail-Adresse"
      placeholder="Bitte E-Mail-Adresse eingeben"
    />
    <div>
      <TheLabel label="Profilbild (maximal 1 MB)" />
      <file-input ref="avatarInput" :image="image" @emit-image="updateImage" />
    </div>
    <ButtonEl
      class="w-fit"
      :disabled="!isFormValid"
      text="Speichern"
      @click="submit"
    />
  </FormValidator>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import TheLabel from '@/components/storybook/src/stories/label/TheLabel.vue';
import FileInput from '@/components/fileInput/fileInput.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  profile: {
    type: Object,
    required: true,
    default: null,
  },
});

const store = useStore();
const router = useRouter();

const image = ref(null);
const isFormValid = ref(true);

const username = ref(props.profile.username);
const email = ref(props.profile.email);

// Methods
function submit() {
  if (image.value) {
    if (image.value.size > 1000000) {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message:
          'Tut uns leid! Das Profilbild sollte nicht größer als 1 MB sein.',
        multiLine: true,
        outlined: true,
      });
      return;
    }
  }
  update(username.value, email.value.toLowerCase(), image.value);
}

function updateImage(newImage) {
  image.value = newImage;
}

function update(username, email, imageFile) {
  const accountForm = new FormData();
  accountForm.append('file', imageFile);
  accountForm.append('username', username);
  accountForm.append('email', email);
  store.dispatch('auth/PATCH_PROFILE_DATA', { accountForm });
}
</script>
