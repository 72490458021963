import {
  LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA,
  LAYER_KEY__BKG_RASTER,
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__CUSTOM_AGGREGATION,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__DISTRICT_LINE,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__HEAT_DENSITY_STREETS,
  LAYER_KEY__MUNICIPALITY,
  LAYER_KEY__MUNICIPALITY_LINE,
} from '../layers/aggregation/aggregation';
import { infraModules } from '@/configs/infra-modules';
import { turnOffSwitches } from '../layers/aggregation/helper';
import {
  SWITCH_ID__DECENTRALIZED_DOMINANCE_BLOCK,
  SWITCH_ID__HEATNETWORK_DOMINANCE_BLOCK,
  SWITCH_ID__HYDROGEN_DOMINANCE_BLOCK,
  SWITCH_ID__SUMMARY_DOMINANCE_BLOCK,
} from '@/configs/layers/baseData/constants';

export const SWITCH_ID__BLOCK = 'LC_BLOCK';
export const SWITCH_ID__DISTRICT = 'LC_DISTRICT';
export const SWITCH_ID__MUNICIPALITY = 'LC_MUNICIPALITY';
export const SWITCH_ID__CUSTOM = 'LC_CUSTOM';
export const SWITCH_ID__COUNTY = 'LC_COUNTY';
export const SWITCH_ID__GOVERNMENT_DISTRICT = 'LC_GOV_DISTRICT';
export const SWITCH_ID__FEDERAL_STATE = 'LC_FEDERAL_STATE';
export const SWITCH_ID__BKG_RASTER = 'LC_BKG_RASTER';
export const SWITCH_ID__HEAT_DENSITY_STREETS = 'LC_HEAT_DENSITY_STREETS';
export const SWITCH_ID__AGGREGATION_LEVEL_CLIENT_DATA =
  'LC_AGGREGATION_LEVEL_CLIENT_DATA';

function radioGroup(layerKey) {
  const radioGroups = [
    {
      visible: false,
      activated: 0,
      radios: [
        {
          title: 'Keine Einfärbung',
          onLayers: [layerKey],
          layerState: 'default',
        },
        {
          title: 'Sektor',
          onLayers: [layerKey],
          layerState: 'sector',
        },
        {
          title: 'Wärmebedarf',
          onLayers: [layerKey],
          layerState: 'heat_demand',
          module: infraModules.districtHeating,
        },
        {
          title: 'Wärmeverbrauchsdichte',
          tooltip:
            'Wärmebedarf im Verhältnis zur Fläche der betrachteten Aggregationsebene',

          onLayers: [layerKey],
          layerState: 'heat_demand_density',
          // Set the key of the related feature property
          extraFilter:
            layerKey === LAYER_KEY__BLOCK && 'heat_demand_density_mwh_ha',
          unit: 'MWh/ha',
          module: infraModules.districtHeating,
        },
        {
          title: 'Wärmeliniendichte',
          tooltip: 'Wärmeabsatz im Verhältnis zur Länge der Wärmeleitung',
          onLayers: [layerKey],
          layerState: 'heat_density_line',
          // Set the key of the related feature property
          extraFilter:
            layerKey === LAYER_KEY__BLOCK && 'linear_heat_density_kwh_m',
          unit: 'kWh/m',
          module: infraModules.districtHeating,
        },
        {
          title: 'Emissionen',
          onLayers: [layerKey],
          layerState: 'emission',
          module: infraModules.scenarios,
        },
        {
          title: 'Emissionseinsparungen',
          onLayers: [layerKey],
          layerState: 'emission_saving',
          module: infraModules.scenarios,
        },
        {
          title: 'Versorgungsart (analytisch)',
          onLayers: [layerKey],
          layerState: 'heating_technology',
          module: infraModules.scenarios,
        },
      ],
    },
  ];
  if (layerKey === LAYER_KEY__BLOCK || layerKey === LAYER_KEY__DISTRICT) {
    radioGroups[0].radios.push({
      title: 'Vorranggebiete',
      onLayers: [layerKey],
      layerState: 'priority_area',
      module: infraModules.scenarios,
    });
  }
  if (layerKey === LAYER_KEY__BLOCK) {
    radioGroups[0].radios.push({
      title: 'Baualtersklassen',
      onLayers: [layerKey],
      layerState: 'construction_year',
      module: infraModules.scenarios,
    });
  }
  return radioGroups;
}

export default {
  title: 'Aggregationsebene',
  items: [
    {
      title: 'Block',
      onLayers: [
        LAYER_KEY__BLOCK,
        LAYER_KEY__MUNICIPALITY_LINE,
        LAYER_KEY__DISTRICT_LINE,
      ],
      switchId: SWITCH_ID__BLOCK,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__BLOCK),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__BLOCK),
      resetRadioOnActivate: true,
      onActivateSetLayout: 'default',
    },
    {
      title: 'Stadtteil',
      onLayers: [LAYER_KEY__DISTRICT, LAYER_KEY__MUNICIPALITY_LINE],
      switchId: SWITCH_ID__DISTRICT,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__DISTRICT),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__DISTRICT),
    },
    {
      title: 'Gemeinde',
      active: true,
      onLayers: [LAYER_KEY__MUNICIPALITY],
      switchId: SWITCH_ID__MUNICIPALITY,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__MUNICIPALITY),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__MUNICIPALITY),
    },
    {
      title: 'Landkreis',
      onLayers: [LAYER_KEY__COUNTY],
      disabledByEmptyModel: 'County',
      switchId: SWITCH_ID__COUNTY,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__COUNTY),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__COUNTY),
    },
    {
      title: 'Regierungsbezirk',
      onLayers: [LAYER_KEY__GOVERNMENT_DISTRICT],
      disabledByEmptyModel: 'GovernmentDistrict',
      switchId: SWITCH_ID__GOVERNMENT_DISTRICT,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__GOVERNMENT_DISTRICT),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__GOVERNMENT_DISTRICT),
    },
    {
      title: 'Bundesland',
      onLayers: [LAYER_KEY__FEDERAL_STATE],
      disabledByEmptyModel: 'FederalState',
      switchId: SWITCH_ID__FEDERAL_STATE,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__FEDERAL_STATE),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__FEDERAL_STATE),
    },
    {
      title: 'BKG-Raster',
      onLayers: [LAYER_KEY__BKG_RASTER, LAYER_KEY__MUNICIPALITY_LINE],
      disabledByEmptyModel: 'BKGRaster',
      switchId: SWITCH_ID__BKG_RASTER,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__BKG_RASTER),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__BKG_RASTER),
    },
    {
      title: 'Aggregationsebene Kundendaten',
      onLayers: [LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA],
      disabledByEmptyModel: 'ScalingLayer',
      switchId: SWITCH_ID__AGGREGATION_LEVEL_CLIENT_DATA,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA),
      turnOffSwitches: turnOffSwitches(
        SWITCH_ID__AGGREGATION_LEVEL_CLIENT_DATA,
      ),
    },
    {
      title: 'Benutzerdefinierte Fläche',
      onLayers: [LAYER_KEY__CUSTOM_AGGREGATION, LAYER_KEY__MUNICIPALITY_LINE],
      disabledByEmptyModel: 'CustomAggregation',
      switchId: SWITCH_ID__CUSTOM,
      itemClass: 'switch',
      radioGroups: radioGroup(LAYER_KEY__CUSTOM_AGGREGATION),
      turnOffSwitches: turnOffSwitches(SWITCH_ID__CUSTOM),
      resetRadioOnActivate: true,
      onActivateSetLayout: 'default',
    },
    {
      active: false,
      title: 'Straßenabschnitte',
      onLayers: [LAYER_KEY__HEAT_DENSITY_STREETS],
      switchId: SWITCH_ID__HEAT_DENSITY_STREETS,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Wärmeliniendichte',
              onLayers: [LAYER_KEY__HEAT_DENSITY_STREETS],
              layerState: 'default',
            },
            {
              title: 'Wärmebedarf',
              onLayers: [LAYER_KEY__HEAT_DENSITY_STREETS],
              layerState: 'heat_demand',
            },
          ],
        },
      ],
    },
  ],
};
