import { axios } from '@/utils/axiosHelper';

export default {
  GET_COORDINATES: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: '/api/config/mapSettings/',
      })
        .then((mapSettingsResp) => {
          commit('SET_DISABLE_SWITCHES', mapSettingsResp.data);
          resolve(mapSettingsResp);
        })
        .catch((err) => {
          console.error('Error fetching map settings:', err);
          reject(err);
        });
    }),
  GET_CUSTOM_TILESETS: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: '/api/customtilesets/',
      })
        .then((resp) => {
          commit('SET_CUSTOM_TILESETS', resp.data.results);
          resolve(resp.data.results);
        })
        .catch((err) => {
          console.error('Error fetching custom tilesets:', err);
          reject(err);
        });
    }),
};
