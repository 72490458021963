<template>
  <div
    :class="[
      {
        'min-h-[300px]': !small,
        'min-h-[220px]': small,
        isParameter: isParameter,
        parameterIsExpanded: parameterIsExpanded,
        'hover:scale-[1.01] w-full': !isParameter,
        'hover:bg-blue-grey-10 cursor-pointer duration-100 px-3':
          !parameterIsExpanded,
      },
      disabled ? 'pointer-events-none bg-line-neutral' : 'bg-default',
    ]"
    class="mx-auto flex flex-col items-center justify-center shadow-none transition-all rounded-[8px]"
    @click="cardClick"
  >
    <template v-if="isParameter && parameterIsExpanded">
      <div
        class="flex bg-white min-w-[220px] items-center -rotate-90 p-2.5 gap-3 absolute left-[-89px]"
      >
        <img class="w-[24px]" :src="cardData.icon" alt="card-img" />
        <h6 class="text-title-color1">
          {{ cardData.title }}
        </h6>
        <VIcon
          icon="mdi-close"
          class="text-title-color1 ml-auto"
          @click.stop="parameterIsExpanded = false"
        />
      </div>
      <div
        class="grid grid-flow-col auto-cols-fr items-center gap-3 min-h-[220px] w-full bg-canvas pl-[54px] pr-2.5"
      >
        <template v-for="item in cardData.children" :key="item">
          <div
            v-if="isChildCardActive(item)"
            class="hover:bg-subtle flex flex-col items-center justify-center px-2 w-full h-[200px] cursor-pointer bg-default rounded-[6px]"
            @click="item.action()"
          >
            <img
              class="mb-2 h-[30%] w-[48px]"
              :src="item.icon"
              alt="card-img"
            />
            <div class="flex flex-col items-center h-1/2 gap-1">
              <h4 class="mb-1 text-center text-title-color1">
                {{ item.title }}
              </h4>
              <div class="body-3 text-center text-neutral">
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <img
        class="h-[40%] pointer-events-none"
        :class="{
          'w-[64px]': small,
          'w-[96px]': !small,
          'opacity-50': disabled,
        }"
        :src="cardData.icon"
        alt="card-img"
      />
      <div
        :class="{ 'gap-1.5': small, 'gap-2 pt-4': !small }"
        class="flex flex-col items-center h-1/2 pointer-events-none"
      >
        <component
          :is="small ? 'h4' : 'h3'"
          class="mb-1 text-center whitespace-pre-wrap"
          :class="disabled ? 'text-disabled-neutral' : 'text-title-color1'"
          >{{ cardData.title }}
        </component>
        <div
          :class="[
            small ? 'body-3' : 'body-1',
            disabled ? 'text-disabled-neutral' : 'text-neutral',
          ]"
          class="text-center"
        >
          {{ cardData.description }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useModuleStore } from '@/configs/module-store';
import { useListeners } from '@/components/storybook/src/composables/useListeners';

const moduleStore = useModuleStore();

const props = defineProps({
  cardData: {
    type: Object,
  },
  small: {
    type: Boolean,
    default: false,
  },
  isParameter: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const parameterIsExpanded = ref(false);

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

function handleClickOutside(event) {
  const isParameterElement = event.target.closest('.isParameter');
  // Execute only if parameterIsExpanded is true and the click is outside the .isParameter element
  if (parameterIsExpanded.value && !isParameterElement) {
    parameterIsExpanded.value = false;
  }
}

function handleKeydown(event) {
  if (event.key === 'Escape' && parameterIsExpanded.value) {
    parameterIsExpanded.value = false;
  }
}

function cardClick() {
  if (props.isParameter) {
    parameterIsExpanded.value = true;
  } else {
    props.cardData.action();
  }
}

function isChildCardActive(card) {
  return moduleStore.isActive(card.module);
}
</script>

<style lang="scss" scoped>
.isParameter {
  max-width: 500px;
  width: 100%;
  transition: max-width 0.75s ease; // adjust duration and easing as needed
  overflow: hidden; // hide content during transition
}

.parameterIsExpanded {
  max-width: 100%;
  position: absolute;
  z-index: 100;
}
</style>
