import { cloneDeep } from 'lodash';
import { useMapStore } from '@/features/map/map-store';
import store from '@/store';

export function mergeFeatureDataAndLayerConfig(
  mainResult,
  yearlyResult,
  scenarioYear,
  panelConfig,
  scenarioName,
  options,
  isVersionLayer = false,
) {
  const year = scenarioYear;
  const panelData = cloneDeep(panelConfig);

  const mapStore = useMapStore();
  mapStore.featureUrlPath = mainResult.aggregate?.value;

  const scenarioYears = store.getters['scenario/getScenarioYears'];
  const baseYear = Math.min(...scenarioYears);

  // TODO: Fixme
  if (isVersionLayer) {
    mapStore.updateUrlPath = `/api/buildingmodel/buildingAggregates/${mainResult.alkis_id.value}/`;
  }
  const yearlyValues = yearlyResult.find((e) => e.year === year);
  let feature;

  // overwrite yearly results with main results in current year for buildingmodel
  if (isVersionLayer && year === baseYear) {
    feature = { ...yearlyValues, ...mainResult };
  } else {
    feature = { ...mainResult, ...yearlyValues };
  }

  panelData.subtitles.forEach((subtitle, index1) => {
    const key = subtitle.key;
    const option = options[key];
    const keyFromVersion =
      feature[key] != null && typeof feature[key].value !== 'undefined';

    let value = feature[key];

    if (keyFromVersion) value = feature[key].value;
    panelData.subtitles[index1] = {
      key,
      value,
      ...option,
    };
  });
  if (scenarioName != null) {
    panelData.subtitles.unshift({
      label: 'Szenario',
      value: scenarioName + ' ' + scenarioYear,
    });
  }

  // add chips
  panelData.chips.forEach((chip, index1) => {
    chip.items.forEach((category, index2) => {
      category.items.forEach((item, index3) => {
        const key = item.key;

        if (typeof feature[key] !== 'undefined') {
          const option = options[key];
          const field = panelData.chips[index1].items[index2].items[index3];

          let value = feature[key];
          const keyFromVersion =
            feature[key] !== null && typeof feature[key].value !== 'undefined';

          if (keyFromVersion) value = feature[key].value;

          if (['choice', 'list'].includes(option.type)) field.value = value;
          else field.value = formatNumber(item, value);

          if (keyFromVersion) {
            field.sourceVersion = feature[key].sourceVersion;
          }
          // merge options into field object
          panelData.chips[index1].items[index2].items[index3] = {
            ...field,
            ...option,
          };
        }
      });
    });
  });
  return cleanPanelData(panelData);
}

export function cleanPanelData(panelData) {
  const cleanedData = { ...panelData };
  cleanedData.chips = [];
  panelData.chips.forEach((chip) => {
    const expandables = [];
    chip.items.forEach((expandable) => {
      expandable.items = expandable.items.filter(
        (i) => i.value !== undefined || i.panelItemType,
      );
      if (expandable.items.length > 0) expandables.push(expandable);
    });
    chip.items = expandables;
    if (expandables.length > 0) {
      cleanedData.chips.push(chip);
    }
  });
  if (cleanedData.chips.length === 1) cleanedData.chips[0].active = true;
  return cleanedData;
}

function formatNumber(item, value) {
  if (value === null) return '-';
  if (typeof value === 'string' || value instanceof String || item.forceString)
    return `${value}`;
  return (item.multiply ? value * item.multiply : value).toLocaleString(
    'de-DE',
    {
      maximumFractionDigits: item.decimal,
      minimumFractionDigits: item.decimal,
    },
  );
}
