import { cloneDeep } from 'lodash';
import { state as heatProjectStates } from './store';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import { getMap } from '@/mapbox/main';
import {
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
} from '@/configs/layers/constants.js';
import { GeoJsonLineString } from '@/features/draw/utilities';
import { LAYERS } from '@/features/heat-project/constants';
import HeatSource from '@/features/heat-project/heat-source/heat-source';

const heatSourceLayer = [
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
];

export class connectHeatSourceInterface {
  heatSources = [];
  networkFeatures = [];

  createHeatSources(heatSources) {
    for (const heatSource of heatSources) {
      const addHeatSource = new HeatSource(
        heatSource.id,
        heatSource.name,
        heatSource.system_type,
        heatSource.plant_type,
        heatSource.energy_source,
        heatSource.asset_type,
        heatSource.thermal_power_mw,
        heatSource.generated_heat_quantity_mwh,
        heatSource.electrical_power_mw,
        heatSource.generated_electricity_mwh,
        heatSource.emissions_t_mwh,
        heatSource.updated_at,
        heatSource.geometry.coordinates,
      );
      addHeatSource.generationCost = heatSource.generation_cost;
      this.addHeatSource(addHeatSource);
    }
  }

  createHeatSourceNetwork(heatSourceNetwork, pipesDiameters, projectId) {
    // set heat source network features
    for (const network of heatSourceNetwork) {
      const inner_diameter = pipesDiameters.find(
        (e) => e.id === network.pipe_determined,
      )?.inner_diameter;
      this.networkFeatures.push(
        GeoJsonLineString(
          network.heat_source_connection.coordinates,
          {
            id: projectId,
            network_id: network.id,
            heat_source_id: network.heat_source,
            type: LAYERS.HEAT_SOURCE_CONNECTION,
            inner_diameter,
          },
          network.id,
        ),
      );
    }
  }

  get isValid() {
    return (
      this.heatSources
        .map((e) => e.generationCost)
        .findIndex((e) => e === null || e === '') === -1
    );
  }

  /**
   * is executed when draw create event is triggered in order to synchronise
   * heat source objects with editing on map
   */
  connectViaDraw(feature) {
    const heatSource = heatProjectStates.heatSources.find(
      (e) => e.id === feature.properties.heat_source_id,
    );
    this.addHeatSource(heatSource);
  }

  /**
   * is executed when draw delete event is triggered in order to synchronise
   * heat project object with editing on map
   */
  disconnectViaDraw(feature) {
    const heatSource = heatProjectStates.heatSources.find(
      (e) => e.id === feature.properties.heat_source_id,
    );
    this.addHeatSource(heatSource);
  }

  /**
   * remove heat source
   * @param  {HeatSource} heatSource
   */
  removeHeatSource(heatSource) {
    const index = this.heatSources.findIndex((e) => e.id === heatSource.id);
    if (index >= 0) {
      this.heatSources.splice(index, 1);
      for (const layer of heatSourceLayer) {
        getMarked().reverseFeatureState({
          id: heatSource.id,
          source: layer,
        });
      }
    }
  }

  /**
   * add heat source to project or remove if already present
   * @param  {HeatSource} heatSource
   */
  addHeatSource(heatSource) {
    const index = this.heatSources.findIndex((e) => e.id === heatSource.id);
    if (index >= 0) this.heatSources.splice(index, 1);
    else this.heatSources.push(cloneDeep(heatSource));
  }

  /**
   * Mark heatsources on map
   */
  markHeatSources() {
    const heatSourceIds = this.heatSources.map((e) => e.id);
    const features = getMap().queryRenderedFeatures({
      layers: heatSourceLayer,
    });
    features.forEach((e) => {
      if (heatSourceIds.includes(e.id)) {
        getMarked().setFeatureState([e], true);
      }
    });
  }

  /**
   * init eventhooks for
   * interaction to select heat sources.
   */
  initEventHooks() {
    // eventhooks for curser and layer UI
    this.removeHover = getMap().U.hoverPointer(heatSourceLayer);
    this.rightClickHeatSource = this.rightClickHeatSource.bind(this);
    getMap().on('contextmenu', this.rightClickHeatSource);
  }

  /**
   * remove eventhooks for map interaction
   */
  removeEventHooks() {
    if (this.removeHover) {
      this.removeHover();
      this.removeHover = null;
    }
    getMap().off('contextmenu', this.rightClickHeatSource);
  }

  /**
   * function bound to right-click event.
   * Queries heatsource layer and adds or removes features.
   * @param  {Object} e
   */
  rightClickHeatSource(e) {
    const features = getMap().queryRenderedFeatures(e.point, {
      layers: heatSourceLayer,
    });
    if (features.length > 0) {
      const feature = features[0];
      const heatSource = heatProjectStates.heatSources.find(
        (e) => e.id === feature.id,
      );
      this.addHeatSource(heatSource);
      getMarked().reverseFeatureState(feature);
    }
  }

  /**
   * construct CREATE payload for heat project calc API.
   */
  constructCreatePayload() {
    return this.heatSources.map((e) => ({
      id: e.id,
      generationCost: e.generationCost,
    }));
  }

  /**
   * construct PUT payload for heat project calc API.
   */
  constructUpdatePayload(feature) {
    const heatSourceId = feature.properties.heat_source_id;
    return {
      geometry: feature.geometry,
      heat_source_id: heatSourceId,
      id: feature.properties.id,
      generation_cost: this.heatSources.find((e) => e.id === heatSourceId)
        .generationCost,
    };
  }
}
