<template>
  <div
    class="skeleton-loader-item"
    :style="{
      width: props.width,
      height: props.height,
      background: props.background,
      borderRadius: props.borderRadius,
    }"></div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
    default: '36px',
  },
  background: {
    type: String,
    default: 'silver',
  },
  borderRadius: {
    type: String,
    default: '4px',
  },
});
</script>

<style lang="scss">
.skeleton-loader-item {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
