<template>
  <div
    ref="chart"
    :style="{ width: width + 'px', height: height + 'px' }"></div>
</template>

<script setup>
import * as echarts from 'echarts';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const chartInstance = ref(null);

const chart = ref(null);

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => {},
  },
  labels: {
    type: Array,
    default: () => [],
  },
  width: {
    type: Number,
    default: 326,
  },
  height: {
    type: Number,
    default: 220,
  },
  backgroundColor: {
    type: String,
    default: '#F8FDFF',
  },
});

onMounted(() => {
  chartInstance.value = echarts.init(chart.value);
  initChart();
});

onBeforeUnmount(() => {
  chartInstance.value.dispose();
});

watch(
  () => props.data,
  (newVal, oldVal) => {
    chartInstance.value.clear();
    initChart();
  },
  { deep: true },
);

function initChart() {
  const option = {
    backgroundColor: props.backgroundColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    legend: {
      show: false,
    },
    grid: {
      top: '5%',
      left: '5%',
      right: '3%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      splitLine: { show: false },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#90A4AE',
        },
      },
      axisTick: { show: false },
      data: props.data.visibleStacks.map((item, index) => {
        let value = item.value;
        // re add the delta to have data correctly shown
        if (index === 1 && props.data.visibleStacks[2].value < 0) {
          value += -1 * props.data.visibleStacks[2].value;
        }
        return props.labels[index] + ',' + value;
      }),
      axisLabel: {
        show: true,
        interval: 0,
        formatter: (value) => {
          const parts = value.split(',');
          return (
            '{a|' +
            parts[0] +
            '}\n' +
            '{b|' +
            Number(parts[1]).toLocaleString('de-DE', {
              maximumFractionDigits: 1,
            }) +
            ' MW}'
          );
        },
        rich: {
          a: {
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 16,
            opacity: 0.75,
            verticalAlign: 'middle',
            color: '#00030E',
          },
          b: {
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 16,
            verticalAlign: 'middle',
            color: '#00030E',
          },
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: { show: false },
      axisLine: { show: false }, // Hide axis line
      axisTick: { show: false }, // Hide axis tick marks
      splitLine: {
        show: true,
        lineStyle: {
          color: '#F9F9F9', // Set the color of the split lines
        },
      },
    },
    series: [
      {
        name: 'Placeholder',
        type: 'bar',
        stack: 'Total',
        barWidth: '32px',
        data: props.data.hiddenStacks,
      },
      {
        type: 'bar',
        stack: 'Total',
        label: {
          show: false,
        },
        data: props.data.visibleStacks,
      },
    ],
  };
  chartInstance.value.setOption(option);
}
</script>

<style scoped></style>
