<template>
  <VDialog
    v-model="mapActionsDialog"
    width="auto"
    :no-click-animation="true"
    scrim="#263238"
    scrim-opacity="0.2"
    transition="fade-transition"
  >
    <template #activator="{ props }">
      <div
        v-if="processOptions.some((item) => mapActionIsEnabled(item.module))"
        v-bind="props"
        class="standard-elevation-1 rounded-[8px]"
      >
        <ButtonEl
          data-test="grid-icon"
          icon="apps"
          icon-type="round"
          variant="tertiary"
          color="neutral"
        />
      </div>
    </template>
    <div
      class="grid grid-flow-col auto-cols-fr gap-0.5 rounded-[8px] standard-elevation-2 overflow-hidden"
    >
      <template v-for="item in processOptions" :key="item">
        <div
          v-if="mapActionIsEnabled(item.module)"
          :class="getClass(item)"
          class="w-36 h-36 flex flex-col gap-4 items-center justify-start p-4 pt-6"
          @click="setMapActions(item)"
        >
          <IconWrapper
            :icon="item.icon"
            :size="44"
            fill="text-title-color1"
            class="pointer-events-none"
            :class="{ 'opacity-50': item.disabled && !item.active }"
          />
          <div
            :class="{ 'opacity-50': item.disabled && !item.active }"
            class="body-2 text-neutral text-center"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
    </div>
  </VDialog>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useModuleStore } from '@/configs/module-store';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const moduleStore = useModuleStore();

const store = useStore();

const mapActionsDialog = ref(false);
const processOptions = computed(() => store.getters.processOptions);

function setMapActions(item) {
  store.commit('SET_MAP_ACTIONS', { processId: item.id });
  mapActionsDialog.value = false;
}

function getClass(item) {
  return item.disabled
    ? 'pointer-events-none bg-white'
    : (item.active ? 'bg-blue-grey-50' : 'bg-white hover:bg-blue-grey-50') +
        ' cursor-pointer';
}

function mapActionIsEnabled(module) {
  return moduleStore.isActive(module);
}
</script>
