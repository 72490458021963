import { axios } from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import { omit } from 'lodash';
import { LAYER_KEY__SCENARIO_NETWORK_CLUSTER } from '@/configs/layers/scenario';
import { fetchData } from './utils';

export class ScenarioNetworkClusterPanel {
  layerIds = [LAYER_KEY__SCENARIO_NETWORK_CLUSTER];

  async requestFeatureData(feature, scenarioId) {
    const featureId = feature.properties.cluster_id;
    const layerId = feature.layer.id;

    const resp = await fetchData(
      `/api/scenarios/${scenarioId}/networkCluster/${featureId}`,
    );

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    let mainResult = omit(featureData, ['option', 'yearlyresult', 'full_cost']);
    const buildings = featureData.buildings;

    mainResult = { ...mainResult, ...buildings };

    const fullCost = featureData.full_cost;
    const yearlyResult = [];

    for (const year of featureData.yearlyresult) {
      const fullCostYear = fullCost.find((e) => e.year === year.year);
      yearlyResult.push({ ...fullCostYear, ...year });
    }

    const panelConfig =
      store.state.map.layerConfigs[featureData.layerId].sidebar;

    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      store.state.scenario.scenarioSelected.name,
      featureData.options,
    );
  }
}
