import { computed, ref } from 'vue';
import { filterAndSort } from '@/apps/scenarios/scenario-helpers';

export function useDetailFiltering(
  data,
  availableItems,
  unsetFilterKeys,
  textFilterKey,
) {
  const filters = ref({
    textFilter: '',
    countiesFilter: availableItems.counties,
    municipalitiesFilter: availableItems.municipalities,
    unsetFilter: false,
  });
  const selectedItems = ref([]);

  const filteredItems = computed(() => {
    return filterAndSort(data, {
      unsetFilterKeys,
      textFilterKey,
      ...filters.value,
    });
  });

  return { selectedItems, filters, filteredItems };
}
