<template>
  <div v-if="loading" class="flex justify-center my-10">
    <VProgressCircular
      class="text-title-color1"
      size="30"
      width="4"
      indeterminate
    />
  </div>
  <div v-else class="flex flex-col gap-3 px-1 py-5">
    <div
      class="rounded-[6px] standard-elevation-1 flex items-center gap-1.5 p-3.5 text-title-color2 bg-default justify-between"
    >
      <div class="flex gap-[5px]">
        <HeatWaveIcon />
        <div class="body-1 text-title-color2">Gesamtpotential</div>
      </div>
      <h5>{{ projectData.total_heat_load_kw.toLocaleString('de-DE') }} kW</h5>
    </div>

    <div class="flex flex-col gap-2.5">
      <h5 class="text-title-color2 pb-2.5 pt-5">
        Nettobarwert, Sensitivität Trassenpreise bzw. Absatzmengen
      </h5>

      <!--table-->
      <WiReTableSensitivity :project-data="projectData" />

      <!--cards-->
      <div class="grid grid-cols-4 gap-2.5">
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="standard-elevation-1 p-6 rounded-[3px] flex flex-col items-center justify-between gap-6 bg-default"
        >
          <div class="flex flex-col gap-2.5 justify-between items-center">
            <IconWrapper
              :icon="card.icon"
              :size="32"
              fill="text-title-color2"
              type="filled"
            />
            <div class="body-1 text-color2">
              {{ card.title }}
            </div>
          </div>
          <h4 class="text-color2">
            {{
              card.value
                ? card.value + (card.suffix ? ' ' + card.suffix : '')
                : '--'
            }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import HeatWaveIcon from '@/components/storybook/src/assets/icons/HeatWaveIcon.vue';
import WiReTableSensitivity from '@/apps/economic-efficiency/WiReTableSensitivity.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

function isMainCard(title) {
  return title === 'Amortisationsdauer';
}

const emit = defineEmits(['update:calculateDisabled']);

onMounted(() => {
  emit('update:calculateDisabled', true);
});

const cards = [
  {
    title: 'Amortisationsdauer',
    icon: 'timeline',
    value: props.projectData.amortization_period,
    suffix: 'Jahre',
  },
  {
    title: 'Amortisation im Jahr',
    icon: 'area_chart',
    value: props.projectData.year_of_breakeven,
  },
  {
    title: 'IRR',
    icon: 'waterfall_chart',
    value: props.projectData.irr
      ? (props.projectData.irr * 100).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null,
    suffix: '%',
  },
  {
    title: 'Value Spread',
    icon: 'difference',
    value: props.projectData.value_spread
      ? (props.projectData.value_spread * 100).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null,
    suffix: '%',
  },
];
</script>
