<template>
  <div class="table-container-sensitivity standard-elevation-1">
    <table class="w-full">
      <thead>
        <tr>
          <th v-for="(header, index) in tableData.headers" :key="index">
            <component
              :is="index !== 2 ? 'div' : 'h5'"
              :class="{ 'body-1': index !== 2 }"
              class="text-title-color2 bg-default h-full py-1 px-3 flex items-center justify-end border-b border-line-neutral border-b-solid"
              >{{ header }}
            </component>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData.body" :key="index">
          <td
            v-for="(td, tdIndex) in row"
            :key="tdIndex"
            :class="{
              'text-right': tdIndex !== 0,
              'bg-subtle': index % 2 !== 0 && tdIndex === 0,
              'bg-default': index % 2 === 0 && tdIndex === 0,
              'bg-fill-success': index % 2 === 0 && tdIndex !== 0 && td > 0,
              'bg-button-secondary-hover-success':
                index % 2 !== 0 && tdIndex !== 0 && td > 0,
              'bg-fill-error': index % 2 === 0 && tdIndex !== 0 && td < 0,
              'bg-button-secondary-hover-error':
                index % 2 !== 0 && tdIndex !== 0 && td < 0,
              '!text-success': tdIndex !== 0 && td > 0,
              '!text-error': tdIndex !== 0 && td < 0,
              'text-title-color2': tdIndex === 0,
            }"
          >
            <component
              :is="
                (tdIndex === 0 || td === projectData.cash_value_eur) &&
                index === 1
                  ? 'h5'
                  : 'div'
              "
              :class="{
                'body-1': !(
                  (tdIndex === 0 || td === projectData.cash_value_eur) &&
                  index === 1
                ),
              }"
            >
              {{
                tdIndex !== 0
                  ? td.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }) + ' €'
                  : td
              }}
            </component>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
});

const tableData = {
  headers: [
    '',
    `Trassenpreis ${props.projectData.lower_sensitivity_network * 100}%`,
    'Referenz',
    `Trassenpreis +${props.projectData.upper_sensitivity_upper_network * 100}%`,
  ],
  body: [
    [
      `Absatz ${props.projectData.lower_sensitivity_heatdemand * 100}%`,
      ...props.projectData.cash_value_sensitivities[0],
    ],
    ['Referenz', ...props.projectData.cash_value_sensitivities[1]],
    [
      `Absatz +${props.projectData.upper_sensitivity_heatdemand * 100}%`,
      ...props.projectData.cash_value_sensitivities[2],
    ],
  ],
};
</script>

<style lang="scss">
.table-container-sensitivity {
  @apply rounded-[4px] overflow-hidden;

  table,
  th,
  td {
    text-align: left;
  }

  td {
    padding: 4px 12px;
  }

  th,
  td {
    height: 48px;
  }
}
</style>
