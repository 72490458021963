import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import VueCookies from 'vue-cookies';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import { axiosInstance } from '@/utils/axiosHelper';
import {
  clickOnMapInTest,
  clickOnMapInTestRight,
  setQueryRenderedFeatures,
  isStyleLoaded,
} from '@/utils/cypress-utils';

// PLUGINS
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/vue-i18n';
import ECharts from '@/plugins/echarts';

// STYLE
import '@/assets/styles/index.scss';
import { createPinia } from 'pinia';
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';

let pendingAxiosCalls = 0;

// Interceptor scripts for loading bar
axiosInstance.interceptors.request.use((config) => {
  pendingAxiosCalls += 1;
  if (!config.skipLoading) {
    store.commit('SET_LOADING', true);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    pendingAxiosCalls -= 1;
    if (pendingAxiosCalls === 0) store.commit('SET_LOADING', false);
    if (response.data.status === 'success' && response.data.message) {
      store.commit('layout/SHOWTOAST', {
        color: 'success',
        message: response.data.message,
        timeout: 3000,
        multiLine: true,
        outlined: true,
      });
    }
    if (response.data.status === 'fail' && response.data.message) {
      store.commit('layout/SHOWTOAST', {
        color: 'warning',
        message: response.data.message,
        timeout: 3000,
        multiLine: true,
        outlined: true,
      });
    }
    return response;
  },
  (error) => {
    pendingAxiosCalls -= 1;
    if (pendingAxiosCalls === 0) store.commit('SET_LOADING', false);
    if (error.response && error.response.status === 504) {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message:
          'Die Operation ist zu aufwändig, tut uns Leid. Für umfassende ' +
          'Analysen kontaktieren Sie uns bitte.',
        multiLine: true,
        outlined: true,
      });
    }
    if (error.response && error.response.status === 400) {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message:
          'Die Daten konnten nicht verarbeitet werden. Bitte überprüfen Sie ' +
          'ihre Eingaben.',
        multiLine: true,
        outlined: true,
      });
    }
    if (error.response && error.response.data.message) {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message: error.response.data.message,
        timeout: 3000,
        multiLine: true,
        outlined: true,
      });
    }
    return Promise.reject(error);
  },
);

const pinia = createPinia();

const app = createApp(App);

app.component('VChart', ECharts);

if (!window.location.hostname.split('.').includes('localhost')) {
  const dsn =
    'https://b949c0ab0bc7b4433dd4c10c060b0b95@o1327264.ingest.us.sentry.io/4507339385143296';
  let tracePropagationTargets = ['localhost'];
  const environment = window.location.hostname.includes('stage')
    ? 'stage'
    : 'production';
  if (environment === 'prod') {
    tracePropagationTargets.push(/^https:\/\/wp\.lbd\.network/);
  } else if (environment === 'stage') {
    tracePropagationTargets.push(/^https:\/\/wp-stage\.lbd\.network/);
  }
  const sampleRate = environment === 'stage' ? 1.0 : 0.1;
  Sentry.init({
    app,
    environment,
    dsn,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: sampleRate, // Capture transactions based on env
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .use(i18n)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VueCookies, { expires: '7d' })
  .use(pinia);

// Assign Vue instance to window object for Cypress testing
if (window.Cypress) {
  window.__vue_app__ = app;
  window.cypress_utils = {
    setQueryRenderedFeatures,
    clickOnMapInTest,
    clickOnMapInTestRight,
    isStyleLoaded,
  };
}

router.isReady().then(() => app.mount('#app'));
