import { axios } from '@/utils/axiosHelper';
// import cookie from 'vue-cookies';

export default {
  GET_ENERGY_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/dashboard_energybalance/scenarios/${payload.selectedScenarioID}`,
        method: 'GET',
        params: {
          district: payload.district,
          sectors: payload.sector,
          heatingCategories: payload.heatDemandCategories,
          constructionYearClass: payload.constructionYear,
          municipality_key: payload.municipality_key,
        },
      })
        .then((resp) => {
          commit('SET_ENERGY_DATA', resp.data['yearlyresult']);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  GET_DISTRICT_HEATING_POTENTIALS_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/district-heat-cluster-dashboard/scenarios/${payload.selectedScenarioID}`,
        method: 'GET',
        params: {
          district: payload.district,
          sectors: payload.sector,
          heatingCategories: payload.heatDemandCategories,
          constructionYearClass: payload.constructionYear,
          municipality_key: payload.municipality_key,
        },
      })
        .then((resp) => {
          commit('SET_DISTRICT_HEATING_POTENTIALS_DATA', resp.data['result']);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  GET_COMMUNITY_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: `/api/community_dashboard/scenarios/${payload.selectedScenarioID}`,
        method: 'GET',
        params: {
          district: payload.district,
          municipality_key: payload.municipality_key,
        },
      })
        .then((resp) => {
          commit('SET_COMMUNITY_DATA', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  GET_POTENTIAL_DATA: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/potential_dashboard/',
        method: 'GET',
        params: {
          district: payload.district,
          municipality_key: payload.municipality_key,
        },
      })
        .then((resp) => {
          commit('SET_POTENTIAL_DATA', resp.data['potentials']);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
