import {
  LAYER_KEY__WIND_POWER_PLANT,
  LAYER_KEY__SOLAR_FREE_POWER_PLANT,
  LAYER_KEY__BIOMASS_POWER_PLANT,
  LAYER_KEY__GEOTHERMAL_POWER_PLANT,
} from '../layers/constants';

import { getRadioGroups, getBioRadios } from '@/configs/layers/baseData/helper';

export const SWITCH_ID__WIND_POWER_PLANT = 'WIND_POWER_PLANT';
export const SWITCH_ID__SOLAR_FREE_POWER_PLANT = 'SOLAR_FREE_POWER_PLANT';
export const SWITCH_ID__BIOMASS_POWER_PLANT = 'BIOMASS_POWER_PLANT';
export const SWITCH_ID__GEOTHERMAL_POWER_PLANT = 'GEOTHERMAL_POWER_PLANT';

const radios = getBioRadios();

export default {
  title: 'Marktstammdaten',
  items: [
    {
      title: 'Windenergieanlagen',
      onLayers: [LAYER_KEY__WIND_POWER_PLANT],
      switchId: SWITCH_ID__WIND_POWER_PLANT,
      itemClass: 'switch',
      active: false,
      disabledByEmptyModel: 'WindPowerPlant',
      radioGroups: getRadioGroups(LAYER_KEY__WIND_POWER_PLANT),
    },
    {
      title: 'PV-Freiflächenanlagen',
      onLayers: [LAYER_KEY__SOLAR_FREE_POWER_PLANT],
      switchId: SWITCH_ID__SOLAR_FREE_POWER_PLANT,
      itemClass: 'switch',
      active: false,
      disabledByEmptyModel: 'SolarFreePowerPlant',
      radioGroups: getRadioGroups(LAYER_KEY__SOLAR_FREE_POWER_PLANT),
    },
    {
      title: 'Biomasseanlagen',
      onLayers: [LAYER_KEY__BIOMASS_POWER_PLANT],
      switchId: SWITCH_ID__BIOMASS_POWER_PLANT,
      itemClass: 'switch',
      active: false,
      disabledByEmptyModel: 'BiomassPowerPlant',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            ...radios,
            {
              title: 'KWK Thermische Leistung',
              onLayers: [LAYER_KEY__BIOMASS_POWER_PLANT],
              layerState: 'kwk',
            },
          ],
        },
      ],
    },
    {
      title: 'Geothermieanlagen',
      onLayers: [LAYER_KEY__GEOTHERMAL_POWER_PLANT],
      switchId: SWITCH_ID__GEOTHERMAL_POWER_PLANT,
      itemClass: 'switch',
      active: false,
      disabledByEmptyModel: 'GeothermalPowerPlant',
      radioGroups: getRadioGroups(LAYER_KEY__GEOTHERMAL_POWER_PLANT),
    },
  ],
};
