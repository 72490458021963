import { useModuleStore } from '@/configs/module-store';

export default {
  SET_LOGOUT: (state) => {
    state.user = {
      avatar: null,
      email: null,
      isSuperUser: false,
      fullName: null,
      username: null,
      permissions: {
        heatprojects_wirtschaftlichkeit: new Set(),
      },
    };
  },
  SET_PROFILE_DATA: (state, resp) => {
    state.user.avatar = resp.user.avatar;
    state.user.email = resp.user.email;
    state.user.isSuperUser = resp.user.is_superuser;
    state.user.fullName = resp.user.full_name;
    state.user.username = resp.user.username;

    for (const permission of resp.user.permissions) {
      const [app, perm] = permission.split('.');
      if (typeof state.user.permissions[app] !== 'undefined') {
        state.user.permissions[app].add(perm);
      }
    }

    const moduleStore = useModuleStore();
    moduleStore.updateModules(resp.active_modules);
  },
  SET_NOTIFICATION_DATA: (state, resp) => {
    state.user.notifyReleaseNotes = resp.notify_release_notes;
  },
};
