<template>
  <div class="w-full z-10 p-5 py-5">
    <div
      class="flex justify-between relative items-center standard-elevation-2 bg-white w-full h-[110px] p-2 rounded-[20px]"
    >
      <!-- client logo-->
      <LandingPageHeaderLogo />

      <!-- infra logo-->
      <img
        src="@/assets/svg/infra-icon.svg"
        alt="logo"
        class="mx-auto absolute left-1/2 -translate-x-1/2 w-[90px]"
      />

      <!-- userdata + logout-->
      <div class="flex gap-5 text-xs ml-auto h-fit">
        <div class="flex flex-col items-end justify-between">
          <div class="flex flex-col items-end text-neutral">
            <p class="body-3">Angemeldet:</p>
            <p class="subtitle-2">{{ profile.username }}</p>
          </div>
          <div class="relative">
            <span
              v-if="hasNewReleaseNotes"
              class="absolute top-0 right-0 z-[200] h-2 w-2 bg-spot-error rounded-full"
            ></span>
            <MoreOptions>
              <div
                class="absolute top-11 standard-elevation-2 right-0 rounded-[4px] overflow-hidden"
              >
                <RouterLink to="/profile" class="more-options-classes">
                  <div class="body-1 text-neutral">Profil</div>
                  <IconWrapper
                    icon="account_box"
                    :size="20"
                    fill="text-color2"
                    type="filled"
                  />
                </RouterLink>
                <div
                  class="more-options-classes"
                  data-test="documentation_download"
                >
                  <a
                    class="body-1 text-neutral"
                    href="/api/docs/documentation/download/"
                    target="_blank"
                    >Dokumentation</a
                  >
                  <IconWrapper
                    icon="description"
                    :size="20"
                    fill="text-color2"
                  />
                </div>
                <div
                  class="more-options-classes"
                  data-test="release_notes_download"
                >
                  <a
                    class="body-1 text-neutral"
                    href="/api/docs/release_notes/download/"
                    target="_blank"
                    @click="hasClickedReleaseNotes = true"
                    >Release Notes</a
                  >
                  <IconWrapper
                    icon="format_list_numbered"
                    :size="20"
                    fill="text-color2"
                  />
                  <span
                    v-if="hasNewReleaseNotes"
                    class="absolute right-1 mb-3 min-h-2 min-w-2 inline-flex items-center justify-center bg-red-600 rounded-full"
                  ></span>
                </div>
                <div class="bg-white py-1">
                  <div class="bg-grey mx-auto h-px" />
                </div>
                <div
                  class="more-options-classes"
                  data-test="logout"
                  @click="logout"
                >
                  <div class="body-1 text-neutral">Logout</div>
                  <IconWrapper icon="logout" :size="20" fill="text-color2" />
                </div>
              </div>
            </MoreOptions>
          </div>
        </div>
        <PersonalAvatar />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import LandingPageHeaderLogo from '@/apps/landing-page/components/LandingPageHeaderLogo.vue';
import MoreOptions from '@/components/storybook/src/stories/moreOptions/MoreOptions.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import PersonalAvatar from '@/components/global/PersonalAvatar.vue';

const store = useStore();
const router = useRouter();
const hasClickedReleaseNotes = ref(false);
const profile = computed(() => store.getters['auth/profil']);
const hasNewReleaseNotes = computed(
  () => profile.value.notifyReleaseNotes && !hasClickedReleaseNotes.value,
);

function logout() {
  store.dispatch('auth/LOGOUT').then(() => {
    router.push('/login');
  });
}
</script>

<style lang="scss" scoped>
.more-options-classes {
  @apply flex gap-2 items-center justify-between cursor-pointer p-2 bg-default hover:bg-active-area;
}
</style>
