<template>
  <div
    class="flex flex-col gap-2 standard-elevation-0-dark p-2.5 rounded-[6px]"
  >
    <div class="flex justify-between items-start">
      <h6 class="text-infra-highlight-800">{{ heatProject.name }}</h6>
      <slot name="options" />
    </div>
    <div class="flex gap-1 text-blue-grey-200">
      <div class="caption-1">Realisierung:</div>
      <div class="subtitle-3">{{ heatProject.realisationYear }}</div>
    </div>
    <div class="flex gap-1 text-blue-grey-200">
      <div class="caption-1">Szenario:</div>
      <div class="subtitle-3">{{ scenario }}</div>
    </div>
    <DetailsProjectPlanning
      v-if="showParamsModal"
      :is-edit="isEdit"
      :heat-project
      @update:heat-project-parameter="
        (params) => emit('update:heatProjectParameter', params)
      "
    />
  </div>
</template>

<script setup>
import DetailsProjectPlanning from '@/features/heat-project/components/DetailsProjectPlanning.vue';

const props = defineProps({
  scenario: {
    type: String,
    default: null,
  },
  heatProject: {
    type: Object,
    default: () => ({}),
  },
  showParamsModal: {
    type: Boolean,
    default: true,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:heatProjectParameter']);
</script>
