<template>
  <div
    v-show="showSearch === true && getResults !== null && getResults.length"
    :style="{ width: getSearchbarWidth + 'px' }"
    class="absolute search-results-custom top-[85px] left-5 rounded-[8px] w-[360px] box-border h-auto bg-white px-2 py-2 z-30"
  >
    <div
      v-for="results in getResults"
      :key="results.id"
      class="py-2 border-b last:border-0 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
      @click="
        flyToCenter(results.center[0], results.center[1]);
        closeSearchbar();
      "
    >
      <div class="px-2">
        <h3 class="text-sm font-medium">{{ results.text }}</h3>
        <p class="text-xs" style="margin: 0">{{ results.place_name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { flyTo, setMarker } from '@/mapbox/main';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const props = defineProps({ map: Object });
const emit = defineEmits(['coordinates']);

function closeSearchbar() {
  store.commit('search/RESET_PLACES', null);
}

function flyToCenter(lng, lat) {
  const config = {
    zoom: 16.9,
    lng,
    lat,
    maxZoom: 16.9,
  };
  flyTo(config, props.map ? props.map : undefined);
  setMarker([lng, lat], props.map ? props.map : undefined);
}

const getResults = computed(() => {
  return store.getters['search/getResults'];
});
const showSearch = computed(() => {
  return store.getters['search/showSearch'];
});
const getSearchbarWidth = computed(() => {
  return store.getters['search/getSearchbarWidth'];
});
</script>

<style scoped lang="scss">
.search-results-custom {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
