import mutations from './mutations';
import getters from './getters';

const state = {
  showTaskDetails: false,
  taskId: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
