<template>
  <div class="flex items-center">
    <img
      src="@/assets/svg/material-concept-icon.svg"
      class="mr-6 w-[64px]"
      alt="logo"
    />
    <h2 class="text-title-color2">Materialkonzept</h2>
  </div>

  <!-- table-->
  <VuetifyDataTable
    class="standard-elevation-1 rounded-[4px] overflow-hidden"
    :headers="tableHeadersMaterialConcept"
    :item-data="tableData.data"
    :show-pagination="false"
    :show-expand="false"
  >
    <template #is_released="{ item }">
      <div class="flex gap-[16px] justify-center">
        <IconWrapper
          v-if="item.is_released"
          icon="task"
          fill="text-spot-success"
        />
        <ButtonEl
          v-else
          text="Freigeben"
          color="color2"
          variant="secondary"
          size="m"
          :disabled="!checkApplicabilityStatus(item)"
          data-test="release-material-set"
          @click="
            releaseItem = item;
            releaseDialog = true;
          "
        />
      </div>
    </template>
    <template #heat_substation_eur_ha="{ item }">
      {{
        item.heat_substation_eur_ha.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) + ' €'
      }}
    </template>
    <template #functions="{ item }">
      <div class="flex justify-end gap-2">
        <IconWrapper
          :icon="item.is_released ? 'segment' : 'edit'"
          fill="text-title-color1"
          :type="item.is_released ? 'filled' : null"
          class="cursor-pointer opacity-80 hover:opacity-100"
          @click="editMaterialset(item)"
        />

        <IconWrapper
          v-if="!item.is_released"
          icon="delete_forever"
          fill="text-title-color1"
          class="cursor-pointer opacity-80 hover:opacity-100"
          @click="
            dialog = true;
            deleteItem = item.id;
          "
        />
      </div>

      <!-- delete dialog-->
      <VuetifyDialog
        v-if="deleteItem === item.id"
        v-model="dialog"
        :activator-full-width="false"
        :title="`Materialset &quot;${item.name}&quot; wirklich löschen?`"
      >
        <template #content>
          Dieser Vorgang kann nicht rückgängig gemacht werden.
        </template>
        <template #actions>
          <ButtonEl
            variant="secondary"
            text="Abbrechen"
            @click="dialog = false"
          />
          <ButtonEl
            text="Löschen"
            color="error"
            variant="secondary"
            @click="deleteMaterialset(item.id)"
          />
        </template>
      </VuetifyDialog>
    </template>
  </VuetifyDataTable>

  <MaterialsetCreate
    v-if="isLoaded && !addNewConcept"
    :material-names="materialNames"
    :material-select="materialSelect"
    @add-material-set="getMaterialSets($event)"
  />

  <MaterialsetEdit
    v-if="addNewConcept"
    :material-names="materialNames"
    :edit-item="editItem"
    :material-items="tableData.length"
    :pipe-options="pipeOptions"
    @abort="abort"
    @update-material-set="getMaterialSets($event)"
    @is-adding-pipe="isAddingPipe = $event"
  />

  <!--  release dialog-->
  <VuetifyDialog
    v-if="releaseItem"
    v-model="releaseDialog"
    :overflow-visible="true"
    :title="`Materialset ${releaseItem.name} freigeben?`"
  >
    <template #content>
      Nach Veröffentlichung des Materialsets kann dieses verwendet werden,
      <br />
      um die Rohrtypen der Projektplanung zu parametrisieren.
      <br />Eine nachträgliche Bearbeitung ist jedoch nicht mehr möglich.
    </template>
    <template #actions>
      <ButtonEl
        variant="secondary"
        text="Abbrechen"
        @click="releaseDialog = false"
      />
      <ButtonEl
        text="Freigeben"
        variant="secondary"
        color="success"
        data-test="release-material-set-confirm"
        @click="releaseMaterial(releaseItem.id)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { axios } from '@/utils/axiosHelper';
import { tableHeadersMaterialConcept } from './table-headers';
import cookie from 'vue-cookies';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import MaterialsetCreate from '@/apps/material-concept/MaterialsetCreate.vue';
import MaterialsetEdit from '@/apps/material-concept/MaterialsetEdit.vue';

// Refs
const dialog = ref(false);
const editItem = ref();
const deleteItem = ref();
const tableData = reactive({ data: [] });
const addNewConcept = ref(false);
const pipeOptions = ref(null);
const isLoaded = ref(false);
const releaseDialog = ref(false);
const releaseItem = ref(null);
const isAddingPipe = ref(false);

// Lifecycle Hooks
onMounted(() => {
  getMaterialSets();
  getPipeOptions();
});

const materialNames = computed(() => {
  return tableData.data.map((e) => e.name);
});

const materialSelect = computed(() => {
  return tableData.data.map((e) => ({
    name: e.name,
    id: e.id,
  }));
});

// Methods
function getMaterialSets(editItem = null) {
  axios({ url: '/api/heatprojects/material-set/', method: 'GET' }).then((r) => {
    tableData.data = r.data;
    isLoaded.value = true;
    if (editItem) editMaterialset(editItem);
  });
}

function abort() {
  addNewConcept.value = false;
  editItem.value = null;
}

function editMaterialset(item) {
  editItem.value = item;
  addNewConcept.value = true;
}

function releaseMaterial(materialId) {
  axios({
    url: `/api/heatprojects/material-set/${materialId}/`,
    method: 'PATCH',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: { is_released: true },
  }).then(() => {
    getMaterialSets();
    releaseDialog.value = false;
    abort();
  });
  releaseDialog.value = false;
}

function deleteMaterialset(id) {
  axios({
    url: `/api/heatprojects/material-set/${id}/`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    getMaterialSets();
    dialog.value = false;
    abort();
  });
}

async function getPipeOptions() {
  try {
    const response = await axios({
      url: '/api/heatprojects/pipes/',
      method: 'OPTIONS',
    });
    pipeOptions.value = response.data.actions.POST;
  } catch (error) {
    console.error('Error fetching pipe options:', error);
  }
}

function checkApplicabilityStatus(item) {
  const applicabilityCondition =
    item.pipe_set.some((pipe) => pipe.applicability === 2) ||
    (item.pipe_set.some((pipe) => pipe.applicability === 0) &&
      item.pipe_set.some((pipe) => pipe.applicability === 1));

  return applicabilityCondition && (!editItem.value || !isAddingPipe.value);
}
</script>
