<template>
  <div class="w-[460px] flex flex-col gap-5">
    <ReleaseInfo
      :project-data
      :release-status-label
      :can-change-release="userPermissions.canChangeRelease"
    />

    <ReleaseRequestInputs
      :project-data
      :user-permissions
      :release-status-label
      @update:data="localData = $event"
    />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import ReleaseInfo from '@/apps/economic-efficiency/edit-tab-items/freigabe/ReleaseInfo.vue';
import ReleaseRequestInputs from '@/apps/economic-efficiency/edit-tab-items/freigabe/ReleaseRequestInputs.vue';
import { statusMapping } from '@/apps/economic-efficiency/status-data';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => ({}),
  },
  userPermissions: {
    type: Object,
  },
});

const emit = defineEmits(['update:data']);

// Use computed to create a reactive copy of props.projectData
const localData = ref({ ...props.projectData });

const releaseStatusLabel = computed(() => {
  return statusMapping[props.projectData.release_status].label;
});

// Adjusted watcher to compare localData with props.projectData
watch(
  localData,
  (newVal) => {
    emit('update:data', newVal);
  },
  { deep: true },
);
</script>
