import { state } from './store/index';
import { state as mapState } from '../map/store/index';
import { getMap } from '@/mapbox/main';
import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';

class HighlightBuilding {
  /**
   * @param  {String} featureStateName
   */
  constructor(featureStateName) {
    this.features = [];
    this.featureState = featureStateName;
  }
  resetFeatureStates() {
    for (const feature of this.features) {
      getMap().setFeatureState(feature, { [this.featureState]: false });
    }
  }
  /**
   * highlight all buildings in array for scenario
   * @param  {Array} buildingIDs Array of building ids
   * @param  {Integer} scenario scenario id
   */
  setFeatureStates(buildingIDs, scenario) {
    const filter = ['in', 'alkis_id', ...buildingIDs];
    const sourceLayer = `${scenario}_${LAYER_KEY__BUILDINGS}`;
    const source = LAYER_KEY__BUILDINGS;
    const features = getMap().querySourceFeatures(source, {
      sourceLayer,
      filter,
    });
    features.forEach((e) => {
      const feature = { id: e.id, source, sourceLayer };
      this.features.push(feature);
      getMap().setFeatureState(feature, { [this.featureState]: true });
    });
  }
}

const HighlightBuildings = new HighlightBuilding('highlight');
const HoverBuildings = new HighlightBuilding('hover');

export function resetHighlightedBuildings() {
  HighlightBuildings.resetFeatureStates();
}

export function highlightBuildings(active) {
  HighlightBuildings.resetFeatureStates();
  if (active) {
    const year = mapState.scenarioYear;
    const heatProjects = state.heatProjects.filter(
      (e) => e.realisationYear <= year,
    );
    if (heatProjects.length === 0) return;
    const scenario = heatProjects[0].scenario;
    const buildingIDs = heatProjects.flatMap((e) => e.getBuildingIds);
    HighlightBuildings.setFeatureStates(buildingIDs, scenario);
  }
}

export function hoverBuildings(active, heatProjectId) {
  if (active) {
    const heatProject = state.heatProjects.find((e) => e.id === heatProjectId);
    const scenario = heatProject.scenario;
    const buildingIDs = heatProject.getBuildingIds;
    HoverBuildings.setFeatureStates(buildingIDs, scenario);
  } else {
    HoverBuildings.resetFeatureStates();
  }
}
