<template>
  <div
    v-if="show_breadcrumb"
    class="flex w-full justify-start items-center p-5 gap-4 breadcrumb-nav relative"
  >
    <ToHome v-if="!selectorExpanded" class="standard-elevation-1" />
    <div class="body-2 text-neutral flex">
      Sie sind hier:
      <RouterLink to="/">
        <div class="ml-1 w-fit">Hub</div>
      </RouterLink>
      <a
        v-for="item in breadcrumb_items"
        :key="item.path"
        :href="item.path"
        class="pl-1 flex gap-1"
      >
        &rsaquo;
        <div
          :class="{
            'border-b border-neutral':
              item.path === router.currentRoute.value.path,
          }"
        >
          {{ item.title }}
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import ToHome from '@/features/map/control-options/components/ToHome.vue';

const router = useRouter();

const selectorExpanded = ref(false);

const show_breadcrumb = computed(() => {
  return router.currentRoute.value.meta.hide_breadcrumb ? 0 : 1;
});

const breadcrumb_items = computed(() => {
  const items = [];
  router.currentRoute.value.matched.forEach((e) => {
    if (e.meta.title) {
      items.push({
        title: e.meta.title,
        path: e.path,
      });
    }
  });
  return items;
});
</script>
