import { reactive, computed } from 'vue';

export function useFormValidation() {
  const fields = reactive({});

  const isFormValid = computed(() => {
    // Ensure we have fields and all of them are valid
    return (
      Object.keys(fields).length > 0 &&
      Object.values(fields).every((valid) => valid)
    );
  });

  function updateValidationState(fieldName, isValid) {
    fields[fieldName] = isValid;
  }

  return { isFormValid, updateValidationState };
}
