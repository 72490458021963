export const tableHeadersNewMaterialset = [
  {
    title: 'Innendurchmesser',
    key: 'inner_diameter',
    align: 'start',
    sortable: false,
    width: '170px',
  },
  {
    title: 'Außendurchmesser',
    key: 'outer_diameter',
    align: 'start',
    sortable: false,
    width: '175px',
  },
  {
    title: 'Berücksichtigt für',
    key: 'applicability',
    align: 'start',
    sortable: false,
    width: '250px',
  },
  {
    title: 'Beschreibung',
    key: 'description',
    align: 'start',
    sortable: false,
  },
];

export const tableHeadersMaterialConcept = [
  {
    title: 'Materialset',
    key: 'name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Freigabe',
    key: 'is_released',
    sortable: false,
    align: 'center',
    width: '175px',
  },
  {
    title: 'Referenzjahr',
    key: 'reference_year',
    align: 'end',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Kosten Hausanschlussstation',
    key: 'heat_substation_eur_ha',
    align: 'end',
    sortable: false,
    width: '200px',
  },
  {
    title: 'Lebensdauer Rohre (Jahre)',
    key: 'working_life_pipes_year',
    align: 'end',
    sortable: false,
    width: '150px',
  },
  {
    title: '',
    key: 'functions',
    sortable: false,
    align: 'center',
    width: '125px',
  },
];
