<template>
  <div
    class="skeleton-row overflow-hidden"
    :class="`rounded-[${rounded}px]`"
  ></div>
</template>

<script>
export default {
  name: 'SkeletonLoaderItem',
  props: {
    gridCols: {
      type: Number,
      default: 3,
    },
    gridRows: {
      type: Number,
      default: 3,
    },
    rounded: {
      type: Number,
      default: 6,
    },
  },
};
</script>

<style scoped lang="scss">
//  skeleton-loader
.skeleton-loader-table {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .chartsWrapperSkeleton {
    display: grid;
    grid-gap: 2px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .chartItem {
      min-height: 400px;
      height: 100% !important;
    }
  }
}

.skeleton-row {
  width: 100%;
  height: 306px;
  border-radius: 6px;
  @apply bg-subtle;
  animation: skeleton-pulse 1.2s ease-in-out infinite;
  @apply my-4;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.55;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.55;
  }
}
</style>
