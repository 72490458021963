<template>
  <div
    class="min-h-screen bg-cover flex flex-col justify-center w-full"
    :style="{
      background: `url(${backgroundImage}) lightgray 50% / cover no-repeat`,
    }"
  >
    <LandingPageHeader />
    <div
      class="overflow-y-auto hide-scrollbar"
      style="max-height: calc(100vh - 150px)"
    >
      <div
        class="flex flex-col w-full max-w-[1200px] mx-auto px-5"
        style="min-height: calc(100vh - 150px)"
      >
        <div class="transition-container flex flex-col gap-2.5 h-fit my-auto">
          <template v-for="row in cardRows" :key="row">
            <div
              :class="{
                'bg-elevated p-5 rounded-[20px] standard-elevation-2 flex flex-col gap-5':
                  perspectiveSet === 'true',
              }"
            >
              <MuniScenSelectorWrapper
                v-if="row === 1"
                :on-hub="true"
                :show-selector="
                  (perspectiveSet !== 'true' && row === 1) ||
                  (row === 1 && showSelector)
                "
                @scenario-count="scenarioCount = $event"
                @perspective-set="perspectiveSet = 'true'"
              />
              <LandingPageCardRow
                v-if="perspectiveSet === 'true'"
                :card-data="cardData.filter((e) => e.row === row)"
                :small-card="row === 2"
                :scenario-count
                @animation-finished="showSelector = true"
              />
            </div>
          </template>
        </div>
        <FooterSection class="text-inverted-neutral" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import backgroundImage from '@/assets/img/background_authentication.webp';

import { rawCardData } from './raw-card-data';
import LandingPageHeader from '@/apps/landing-page/components/LandingPageHeader.vue';
import MuniScenSelectorWrapper from '@/components/municipality-scenario-selector/MuniScenSelectorWrapper.vue';
import LandingPageCardRow from '@/apps/landing-page/components/LandingPageCardRow.vue';
import FooterSection from '@/components/footer/FooterSection.vue';

const router = useRouter();
const store = useStore();

const cardData = rawCardData.map(attachNavigate);
const scenarioCount = ref(0);
const cardRows = computed(() => {
  const rows = new Set(cardData.map((card) => card.row));
  return Array.from(rows).sort();
});
const showSelector = ref(false);
const perspectiveSet = ref(localStorage.getItem('perspectiveSet'));

onMounted(() => {
  store.commit('TOGGLE_MU_SC_SELECTOR', true);
});

function attachNavigate(card) {
  return {
    ...card,
    action: () => router.push({ name: card.routeName }),
    children: card.children?.map(attachNavigate),
  };
}
</script>
