<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335ZM8.96374 7.82815C8.19074 5.72078 8.19919 4.79872 8.5292 3.33353C8.57277 3.14009 8.33961 3.00651 8.20157 3.14885C8.14233 3.20994 8.08392 3.27144 8.0265 3.33353C6.91928 4.53072 6.18329 5.94123 7.04121 8.68836C7.85812 11.3042 7.5403 12.084 7.21021 12.8938L7.16701 13.0002C7.11613 13.1264 7.23785 13.2342 7.3539 13.1631C7.42733 13.1182 7.51069 13.064 7.60066 13.0002C8.49583 12.3651 10.0447 10.775 8.96374 7.82815ZM11.7227 7.87116C11.143 6.29064 11.1493 5.59909 11.3968 4.50019C11.4295 4.35512 11.2546 4.25493 11.1511 4.36168C11.1067 4.4075 11.0629 4.45363 11.0198 4.50019C10.1894 5.39809 9.63739 6.45597 10.2808 8.51632C10.8935 10.4782 10.6551 11.063 10.4076 11.6704L10.3752 11.7502C10.337 11.8448 10.4283 11.9257 10.5153 11.8724C10.5704 11.8387 10.6329 11.7981 10.7004 11.7502C11.3718 11.2739 12.5334 10.0813 11.7227 7.87116ZM5.39682 4.50019C5.14931 5.59909 5.14298 6.29064 5.72273 7.87116C6.53343 10.0813 5.3718 11.2739 4.70041 11.7502C4.63294 11.7981 4.57042 11.8387 4.51535 11.8724C4.42831 11.9257 4.33702 11.8448 4.37518 11.7502L4.40758 11.6704C4.65514 11.063 4.89351 10.4782 4.28082 8.51632C3.63739 6.45597 4.18938 5.39809 5.01979 4.50019C5.06286 4.45363 5.10667 4.4075 5.1511 4.36168C5.25462 4.25493 5.42949 4.35512 5.39682 4.50019Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
