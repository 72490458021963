import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  user: {
    avatar: null,
    email: null,
    isSuperUser: false,
    fullName: null,
    username: null,
    permissions: {
      heatprojects_wirtschaftlichkeit: new Set(),
    },
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
