import { LAYER_KEY__BIOMASS_POWER_PLANT } from '@/configs/layers/constants';
import {
  layerValueRanges,
  clickedColor,
} from '@/configs/layers/baseData/constants';

export function layerConfigPoint(layerKey) {
  return {
    type: 'point',
    id: layerKey,
    app_name: 'basedata',
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: 'none',
    dependsOnScenario: false,
    applyYearlyFilter: false,
  };
}

function getDefaultStyle(color) {
  return {
    circleColor: [
      'case',
      ['boolean', ['feature-state', 'clicked'], false],
      clickedColor,
      color,
    ],
    circleRadius: 10,
    circleStrokeWidth: 2,
    circleStrokeColor: '#000000', // Default black if none of the above
  };
}

function getOperationalStatusStyle(color) {
  return {
    circleColor: [
      'case',
      ['boolean', ['feature-state', 'clicked'], false],
      clickedColor,
      color,
    ],
    circleRadius: 10,
    circleStrokeWidth: 2,
    circleStrokeColor: [
      'case',
      ['==', ['get', 'unit_state'], 0],
      '#fb8d00',
      ['==', ['get', 'unit_state'], 1],
      '#e53835',
      ['==', ['get', 'unit_state'], 2],
      '#44a047',
      ['==', ['get', 'unit_state'], 3],
      '#fdd836',
      '#000000', // Default if none of the above
    ],
  };
}

function getInterpolatedStyle(layerId, property) {
  const range = layerValueRanges[layerId] || layerValueRanges.default; // Default if unknown layerId
  return {
    circleColor: [
      'case',
      ['boolean', ['feature-state', 'clicked'], false],
      clickedColor,
      ['==', ['get', property], null],
      range.noValue,
      ['==', ['get', property], '-'],
      range.noValue,
      [
        'interpolate',
        ['linear'],
        ['get', property],
        range.min,
        range.startColor, // Interpolate from min to max
        range.max,
        range.endColor,
      ],
    ],
    circleRadius: 10,
    circleStrokeWidth: 2,
    circleStrokeColor: '#000000',
  };
}

export function getLayerStyle(defaultColor, layerId) {
  return {
    default: getDefaultStyle(defaultColor),
    operational_status: getOperationalStatusStyle(defaultColor),
    net_power: getInterpolatedStyle(layerId, 'capacity_kw'),
    kwk: getInterpolatedStyle('kwk', 'chp_thermal_capacity_kw'),
  };
}

export function getRadioGroups(layerKey) {
  return [
    {
      visible: false,
      activated: 0,
      radios: [
        {
          title: 'Keine Einfärbung',
          onLayers: [layerKey],
          layerState: 'default',
        },
        {
          title: 'Betriebsstatus',
          onLayers: [layerKey],
          layerState: 'operational_status',
        },
        {
          title: 'Nettonennleistung',
          onLayers: [layerKey],
          layerState: 'net_power',
        },
      ],
    },
  ];
}

export function getBioRadios() {
  const defaultRadioGroups = getRadioGroups(LAYER_KEY__BIOMASS_POWER_PLANT);
  return defaultRadioGroups[0].radios;
}

function getValue(id, value) {
  return layerValueRanges[id]?.[value] || null;
}

export function getLegend(
  color,
  name,
  operationalStatusTitle,
  netPowerTitle,
  id,
) {
  return {
    default: {
      name: 'Marktstammdaten',
      unit: '',
      items: [
        {
          color: color,
          name: name,
          icon: 'mdi-checkbox-blank-circle',
        },
      ],
    },
    operational_status: {
      name: 'Marktstammdaten',
      unit: '',
      categoryId: id,
      categoryIdArray: [],
      titleMap: [],
      subTitle: operationalStatusTitle,
      items: [
        {
          color: color,
          id: id,
          name: 'Vorübergehend Stillgelegt',
          icon: 'mdi-checkbox-blank-circle',
          borderColor: '#fb8d00',
        },
        {
          color: color,
          id: id,
          name: 'Endgültig Stillgelegt',
          icon: 'mdi-checkbox-blank-circle',
          borderColor: '#e53835',
        },
        {
          color: color,
          id: id,
          name: 'In Betrieb',
          icon: 'mdi-checkbox-blank-circle',
          borderColor: '#44a047',
        },
        {
          color: color,
          id: id,
          name: 'In Planung',
          icon: 'mdi-checkbox-blank-circle',
          borderColor: '#fdd836',
        },
      ],
    },
    net_power: {
      type: 'gradient',
      name: 'Marktstammdaten',
      subTitle: netPowerTitle,
      items: [
        {
          unit: 'kW',
          id: netPowerTitle,
          min: {
            value: getValue(id, 'min'),
            color: layerValueRanges[id]?.startColor || '', // Dynamically access 'startColor' using bracket notation
          },
          max: {
            value: getValue(id, 'max'),
            color: layerValueRanges[id]?.endColor || '', // Dynamically access 'startColor'
          },
        },
      ],
    },
    kwk: {
      type: 'gradient',
      name: 'Marktstammdaten',
      subTitle: 'Biomasse KWK Thermische Leistung',
      items: [
        {
          id: 'Biomasse KWK Thermische Leistung',
          unit: 'kW',
          min: {
            value: 0,
            color: layerValueRanges.bio.startColor,
          },
          max: {
            value: 1000,
            color: layerValueRanges.bio.endColor,
          },
        },
      ],
    },
  };
}
