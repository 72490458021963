export class AutoGeneratedPanel {
  constructor(name) {
    this.name = name;
  }

  layerIds = [];

  async requestFeatureData(feature) {
    return feature;
  }

  calculatePanelData(featureData, _, __) {
    const panelData = {
      name: this.name,
      subtitles: [],
      chips: [
        {
          title: 'Werte',
          active: true,
          items: [
            {
              title: 'Werte',
              active: true,
              items: Object.keys(featureData.properties).map((key) => ({
                label: key,
                key: key,
                read_only: true,
                required: false,
                type: 'string',
                unit: '',
                value: featureData.properties[key],
              })),
            },
          ],
        },
      ],
      isEditable: false,
      id: featureData.id,
    };

    return panelData;
  }
}
