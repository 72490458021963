import MapboxGlDraw from '@mapbox/mapbox-gl-draw';

export function addSnappingVertexLayer(map, layerId) {
  if (map.getSource(layerId)) return;
  map.addSource(layerId, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer({
    id: layerId,
    type: 'circle',
    source: layerId,
    paint: {
      'circle-color': 'transparent',
      'circle-radius': 5,
      'circle-stroke-width': 3,
      'circle-stroke-color': 'orange',
    },
  });
}

export function clearSnapCoord(map, layerId) {
  const source = map.getSource(layerId);
  if (source) {
    source.setData({ type: 'FeatureCollection', features: [] });
  }
}

/**
 * get Point in GeoJson format
 * @param  {Array.<Number>} coordinates
 * @param  {Object} properties containing properties as key, value pairs
 * @param  {Integer} id if passed, feature id is set accordingly
 */
export function GeoJsonPoint(coordinates, properties, id = null) {
  const feature = {
    type: MapboxGlDraw.constants.geojsonTypes.FEATURE,
    properties,
    geometry: {
      type: MapboxGlDraw.constants.geojsonTypes.POINT,
      coordinates,
    },
  };
  if (id) feature.id = id;
  return feature;
}

/**
 * get Line String in GeoJson format
 * @param  {Array} coordinates
 */
export const GeoJsonMultiLineString = (coordinates) => ({
  type: MapboxGlDraw.constants.geojsonTypes.FEATURE,
  properties: {},
  geometry: {
    type: MapboxGlDraw.constants.geojsonTypes.MULTI_LINE_STRING,
    coordinates,
  },
});

/**
 * get Line String in GeoJson format
 * @param  {Array.<Number>} coordinates
 * @param  {Object} properties containing properties as key, value pairs
 * @param  {Integer} id if passed, feature id is set accordingly
 */
export function GeoJsonLineString(coordinates, properties, id = null) {
  const feature = {
    type: MapboxGlDraw.constants.geojsonTypes.FEATURE,
    properties,
    geometry: {
      type: MapboxGlDraw.constants.geojsonTypes.LINE_STRING,
      coordinates,
    },
  };
  if (id) feature.id = id;
  return feature;
}

export const GeoJsonPolygon = (coordinates, properties) => ({
  type: MapboxGlDraw.constants.geojsonTypes.FEATURE,
  properties,
  geometry: {
    type: MapboxGlDraw.constants.geojsonTypes.POLYGON,
    coordinates,
  },
});

export function getSnapToFraction(features, line) {
  const { coordinates } = line;
  const { id } = line;
  let lineToSnapCoords = [];
  for (const feature of features) {
    if (feature.geometry.type.toLowerCase() === 'multilinestring') continue;
    // get all linestring already drawed except current
    if (feature.id !== id) {
      lineToSnapCoords.push(feature.geometry.coordinates);
    }
  }
  // add current feature if at least 3 vertexes exist
  if (coordinates.length > 3) {
    lineToSnapCoords.push(coordinates.slice(0, -2));
  }
  return GeoJsonMultiLineString(lineToSnapCoords);
}
