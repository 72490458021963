export const LAYER_KEY__HEAT_SOURCE_INVENTORY =
  'LAYER_KEY__HEAT_SOURCE_INVENTORY';
export const LAYER_KEY__HEAT_SOURCE_POTENTIAL =
  'LAYER_KEY__HEAT_SOURCE_POTENTIAL';
export const LAYER_KEY__HEATING_CENTER_INVENTORY =
  'LAYER_KEY__HEATING_CENTER_INVENTORY';
export const LAYER_KEY__HEATING_CENTER_POTENTIAL =
  'LAYER_KEY__HEATING_CENTER_POTENTIAL';
export const LAYER_KEY__GRID_FEED_POINT_INVENTORY =
  'LAYER_KEY__GRID_FEED_POINT_INVENTORY';
export const LAYER_KEY__GRID_FEED_POINT_POTENTIAL =
  'LAYER_KEY__GRID_FEED_POINT_POTENTIAL';

export const LAYER_KEY__WIND_POWER_PLANT = 'windpowerplant';
export const LAYER_KEY__SOLAR_FREE_POWER_PLANT = 'solarfreepowerplant';
export const LAYER_KEY__BIOMASS_POWER_PLANT = 'biomasspowerplant';
export const LAYER_KEY__GEOTHERMAL_POWER_PLANT = 'geothermalpowerplant';
