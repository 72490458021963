<template>
  <div class="flex items-center p-1">
    <input
      :id="id"
      type="radio"
      :checked="modelValue"
      :disabled="disabled"
      :class="[
        'form-radio',
        { checked: modelValue, unchecked: !modelValue },
        colorClasses,
      ]"
      :style="computedSizeStyle"
      @change="updateValue" />
    <label
      v-if="$slots.default"
      :for="id"
      :class="['ml-2 cursor-pointer', { 'text-gray-500': disabled }]"
      @click="onLabelClick">
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'text-title-color1',
  },
  size: {
    type: Number,
    default: 16,
  },
});

const emit = defineEmits(['update:modelValue']);

const id = Math.random().toString(36).substr(2, 9);

const colorClasses = computed(() => {
  return {
    [props.color]: props.color,
    [`border-${props.color.replace('text-', '')}`]: props.color,
  };
});

const computedSizeStyle = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}));

const updateValue = () => {
  emit('update:modelValue', true);
};

const onLabelClick = () => {
  if (!props.disabled) {
    emit('update:modelValue', true);
  }
};
</script>

<style scoped>
.form-radio {
  appearance: none;
  border: 1px solid;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  outline: none;
  transition:
    border-color 0.2s,
    background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-radio::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.form-radio.checked::before {
  background-color: currentColor;
}

.form-radio.unchecked {
  border-color: #e5e7eb; /* Lighter border color for unchecked state */
}

.form-radio.unchecked::before {
  background-color: transparent; /* Remove dot for unchecked state */
}

.form-radio:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
