<template>
  <div class="p-3 flex flex-col gap-1 top-0 w-full sticky bg-white z-10">
    <div class="flex justify-between items-center gap-2">
      <h4 class="text-infra-highlight-500 flex">
        {{ panelName }}
        <ToolTip
          v-if="
            updatedInfo &&
            updatedInfo.map((e) => e.value).some((v) => v === true)
          "
          tooltip-icon-fill="#00A1D1"
          tooltip-icon="warn-sign-filled"
        >
          <template #text>
            <ul class="list-disc list-inside">
              <li
                v-for="update in updatedInfo?.filter((e) => e.value)"
                :key="update.index"
              >
                <span>{{ update.text }}</span>
              </li>
            </ul>
          </template>
        </ToolTip>
      </h4>
      <img
        class="w-4 h-4 cursor-pointer"
        src="@/assets/svg/minus-icon.svg"
        alt="close"
        data-test="close-info-panel"
        @click="emit('closeClick')"
      />
    </div>
  </div>
</template>

<script setup>
import ToolTip from '@/components/storybook/src/stories/toolTip/ToolTip.vue';

const emit = defineEmits(['closeClick']);
const props = defineProps({ panelName: String, updatedInfo: Object });
</script>
