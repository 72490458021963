<template>
  <div class="flex flex-col justify-center">
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{
          !getDashboard3GroupBy
            ? 'Gebäude nach Heiztechnologie'
            : 'Gebäude nach Sektor'
        }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <skeleton-loader-item />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{
          !getDashboard3GroupBy
            ? 'Wärmebedarf nach Heiztechnologie in MWh'
            : 'Wärmebedarf nach Sektor in MWh'
        }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <skeleton-loader-item />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{
          !getDashboard3GroupBy
            ? 'Emissionen nach Heiztechnologie in t CO₂'
            : 'Emissionen nach Sektor in t CO₂'
        }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <skeleton-loader-item />
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonLoaderItem from '@/apps/analysis-dashboard/skeletonLoaderCustom/skeletonLoaderItem.vue';
import { mapGetters } from 'vuex';

export default {
  components: { SkeletonLoaderItem },
  computed: {
    ...mapGetters('dashboard', ['getDashboard3GroupBy']),
  },
};
</script>
