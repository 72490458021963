<template>
  <div class="flex flex-col gap-2" :style="cssVars">
    <slot name="top" />
    <div class="slider-container">
      <input
        v-model.number="internalValue"
        type="range"
        min="0"
        max="1"
        :step="step"
        class="slider"
        :style="{
          background: props.disabled ? props.disabledColor : sliderGradient,
        }"
        :disabled="disabled" />
      <div class="points-container">
        <div
          v-for="n in 11"
          :key="n"
          :class="{
            point: true,
            ...isNotInRange(n / 11),
          }"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
  },
  enabledMax: {
    type: Number,
    default: 1.1,
  },
  enabledMin: {
    type: Number,
    default: 0.1,
  },
  color: {
    type: String,
    default: '#00A1D1',
  },
  enablePositiveNegativeBreak: {
    type: Number,
    default: null,
  },
  id: {
    type: [Number, String],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledColor: {
    type: String,
    default: '#cccccc',
  },
});

const internalValue = ref(parseFloat(props.modelValue) || 0);
const step = 0.1;

const cssVars = computed(() => ({
  '--main-color': props.disabled ? props.disabledColor : props.color,
}));

// Store initial values of enabledMax and enabledMin for each id
const initialValues = ref({});

const sliderGradient = computed(() => {
  const percentage = internalValue.value * 100;
  return `linear-gradient(to right, ${props.color} ${percentage}%, #D9D9D9 ${percentage}%)`;
});

const initialEnabledMin = computed(() => {
  return initialValues.value[props.id]?.enabledMin ?? props.enabledMin;
});

const initialEnabledMax = computed(() => {
  return initialValues.value[props.id]?.enabledMax ?? props.enabledMax;
});

function setInitialValues(id, enabledMin, enabledMax) {
  if (!initialValues.value[id]) {
    initialValues.value[id] = {
      enabledMin,
      enabledMax,
    };
  }
}

function snapToPoints(newValue) {
  let cleanedValue = parseFloat(newValue).toFixed(1);

  // Ensure newValue is within enabledMin and enabledMax
  cleanedValue = Math.max(
    Math.min(cleanedValue, initialEnabledMax.value),
    initialEnabledMin.value,
  );

  if (internalValue.value !== cleanedValue) {
    internalValue.value = cleanedValue;
  }

  emit('update:modelValue', cleanedValue);
}

function isNotInRange(pointValue) {
  if (
    pointValue >= initialEnabledMax.value + step ||
    pointValue < initialEnabledMin.value
  ) {
    return { 'point-not-in-range': true };
  }

  if (props.enablePositiveNegativeBreak !== null) {
    return pointValue >= props.enablePositiveNegativeBreak
      ? { 'point-green': true }
      : { 'point-red': true };
  }

  return false;
}

watch(
  () => internalValue.value,
  (newValue) => {
    snapToPoints(newValue);
  },
);

watch(
  () => props.id,
  (newId) => {
    if (newId !== null) {
      setInitialValues(newId, initialEnabledMin.value, initialEnabledMax.value);
      snapToPoints(initialEnabledMax.value);
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.slider-container {
  width: 100%;
  margin: auto;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 4px;
  height: 4px; /* Adjusted height for the slider line */
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Size of the slider circle */
  height: 20px; /* Size of the slider circle */
  border-radius: 50%;
  background: var(--main-color);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  cursor: pointer;
}

.points-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
}

.point {
  width: 4px;
  height: 4px;
  background: var(--main-color);
  border-radius: 50%;
  margin-top: -2px;
}

.point-not-in-range {
  background: #d9d9d9;
}

.point-red {
  background: #bc0606;
}

.point-green {
  background: #00b979;
}
</style>
