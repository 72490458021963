<template>
  <!-- title-->
  <div class="flex items-center w-full mb-4">
    <img
      src="@/assets/svg/statistics-icon.svg"
      class="mr-6 w-[64px]"
      alt="statistics"
    />
    <h2>Auswertungen</h2>
  </div>

  <div class="flex flex-col gap-5 w-full">
    <div class="w-full flex flex-col gap-5">
      <div
        class="flex gap-4 justify-center w-full standard-elevation-1 rounded-[8px] pt-5"
      >
        <MuniScenSelectorWrapper
          :on-hub="true"
          :show-selector="true"
          :show-geo-chart="true"
          @change-municipality="changeMunicipality($event)"
          @change-scenario="changeScenario($event)"
        >
          <DashboardOptions
            v-if="activeTab !== 'potentials' || !dashbordOptions"
            :district="selectedDistrict"
            :districts="dashbordOptions ? dashbordOptions.district_names : []"
            @fetch-data="fetchChartData"
            @set-district="selectedDistrict = $event"
          />
        </MuniScenSelectorWrapper>
      </div>

      <div class="border-b-2">
        <VTabs v-model="activeTab" class="-mb-0.5" density="compact">
          <VTab
            v-for="tab in tabs"
            :key="tab.key"
            class="px-2 normal-case text-[18px] hover:text-title-color1 tracking-normal h-auto mr-3"
            :value="tab.key"
            :ripple="false"
            >{{ tab.name }}
          </VTab>
        </VTabs>
      </div>

      <DashboardYearSlider
        :scenario-name="scenario.name"
        :years="scenario.years"
        :dashboard-selected="activeTab"
        :get-energy-data="getEnergyData"
        :selected-district-name="selectedDistrictName"
      />

      <!-- analysis-dashboard -->
      <DashboardCommunityStructure
        v-if="activeTab === 'community-structure'"
        :data="getCommunityData"
      />

      <DashboardEnergyBalance
        v-if="activeTab === 'energy-balance'"
        :data="getEnergyDataByYear"
      />
      <DashboardDistrictHeatingPotentials
        v-if="activeTab === 'district-heating-potentials'"
        :data="getDistrictHeatingPotentialsDataByYear"
      />
      <DashboardChronologicalEvaluation
        v-if="activeTab === 'chronological-evaluation'"
        :data="
          getEnergyData ? getEnergyData.sort((a, b) => a.year - b.year) : []
        "
      />
      <DashboardPotentials
        v-if="activeTab === 'potentials'"
        :data="getPotentialData"
      />

      <SkeletonLoaderDashboard
        v-if="!checkDashBoardData && !chartDataIsLoaded"
        :dash-board-selected="activeTab"
      />

      <NoDataAvailable v-else-if="!checkDashBoardData && chartDataIsLoaded" />
    </div>
  </div>
</template>

<script setup>
import axios from '@/utils/axiosHelper';
import { dashboards } from './dashboard-config';
import { useStore } from 'vuex';
import { useModuleStore } from '@/configs/module-store';
import { computed, onMounted, ref, watch } from 'vue';
import MuniScenSelectorWrapper from '@/components/municipality-scenario-selector/MuniScenSelectorWrapper.vue';
import DashboardOptions from '@/apps/analysis-dashboard/components/DashboardOptions.vue';
import DashboardYearSlider from '@/apps/analysis-dashboard/components/DashboardYearSlider.vue';
import DashboardCommunityStructure from '@/apps/analysis-dashboard/components/DashboardCommunityStructure.vue';
import DashboardEnergyBalance from '@/apps/analysis-dashboard/components/DashboardEnergyBalance.vue';
import DashboardDistrictHeatingPotentials from '@/apps/analysis-dashboard/components/DashboardDistrictHeatingPotentials.vue';
import DashboardChronologicalEvaluation from '@/apps/analysis-dashboard/components/DashboardChronologicalEvaluation.vue';
import DashboardPotentials from '@/apps/analysis-dashboard/components/DashboardPotentials.vue';
import SkeletonLoaderDashboard from '@/apps/analysis-dashboard/skeletonLoaderCustom/SkeletonLoaderDashboard.vue';
import NoDataAvailable from '@/apps/analysis-dashboard/components/NoDataAvailable.vue';

const store = useStore();

const chartDataIsLoaded = ref(false);
const preventDistrictWatcher = ref(false);
const selectedDistrict = ref(null);
const scenario = ref({});
const dashbordOptions = ref(null);

const moduleIsActive = useModuleStore().isActive;
const tabs = computed(() =>
  dashboards
    .filter((d) => moduleIsActive(d.module))
    .map((item) => {
      return { name: item.name, key: item.key };
    }),
);
const activeTab = ref(tabs.value[0].key);
const selectedDistrictName = computed(() => {
  if (dashbordOptions.value && selectedDistrict.value) {
    return dashbordOptions.value.district_names.find(
      (e) => e.id === selectedDistrict.value,
    ).name;
  }
  return 'Alle';
});
const year = computed(() => store.state.map.scenarioYear);
const getEnergyData = computed(() => store.getters['dashboard/getEnergyData']);
const getDistrictHeatingPotentialsData = computed(
  () => store.getters['dashboard/getDistrictHeatingPotentialsData'],
);
const getCommunityData = computed(
  () => store.getters['dashboard/getCommunityData'],
);
const getPotentialData = computed(
  () => store.getters['dashboard/getPotentialData'],
);
const getEnergyDataByYear = computed(() =>
  getEnergyData.value?.find((e) => e.year === year.value),
);
const getDistrictHeatingPotentialsDataByYear = computed(() =>
  getDistrictHeatingPotentialsData.value?.find((e) => e.year === year.value),
);
const checkDashBoardData = computed(() => {
  switch (activeTab.value) {
    case 'community-structure':
      return !!getCommunityData.value;
    case 'energy-balance':
      return !!getEnergyDataByYear.value;
    case 'district-heating-potentials':
      return !!getDistrictHeatingPotentialsDataByYear.value;
    case 'chronological-evaluation':
      return !!getEnergyData.value;
    case 'potentials':
      return !!getPotentialData.value;
    default:
      return false;
  }
});

watch(
  () => activeTab.value,
  () => {
    selectedDistrict.value = null;
    const municipalityKey = localStorage.getItem('municipality');
    const scenarioId = Number.parseInt(localStorage.getItem('scenario'));
    fetchChartData(municipalityKey, scenarioId);
  },
);

watch(
  () => selectedDistrict.value,
  () => {
    if (!preventDistrictWatcher.value) {
      const municipalityKey = localStorage.getItem('municipality');
      const scenarioId = Number.parseInt(localStorage.getItem('scenario'));
      fetchChartData(municipalityKey, scenarioId);
    }
  },
);

async function fetchScenario(scenarioId) {
  await axios({ method: 'GET', url: `/api/scenarios/${scenarioId}/` }).then(
    (resp) => {
      scenario.value = resp.data;
      store.commit('map/SET_SCENARIO_YEAR', scenario.value.base_year_year);
    },
  );
}

onMounted(async () => {
  const municipalityKey = localStorage.getItem('municipality');
  const scenarioId = Number.parseInt(localStorage.getItem('scenario'));
  if (Number.isInteger(scenarioId)) {
    await fetchScenario(scenarioId);
    await init(municipalityKey, scenarioId);
    preventDistrictWatcher.value = false;
  }
});

async function init(municipalityKey, scenarioId) {
  await fetchOptions(municipalityKey, scenarioId);
  await fetchChartData(municipalityKey, scenarioId);
}

async function fetchChartData(municipalityKey, scenarioId) {
  const dashboard = dashboards.find((e) => e.key === activeTab.value);
  if (!dashboard) {
    console.error('Dashboard configuration not found');
    return;
  }
  try {
    chartDataIsLoaded.value = false;
    await store.dispatch(dashboard.dispatch, {
      selectedScenarioID: scenarioId,
      district: selectedDistrict.value,
      municipality_key: municipalityKey,
    });
    chartDataIsLoaded.value = true;
  } catch (error) {
    console.error('Error fetching chart data', error);
  }
}

async function fetchOptions(municipalityKey, scenarioId) {
  selectedDistrict.value = null;
  await axios({
    url: '/api/dashboard_energybalance/filter_options/',
    method: 'GET',
    params: { municipality_key: municipalityKey, scenario_id: scenarioId },
  }).then((resp) => {
    dashbordOptions.value = resp.data;
  });
}

// municipality scenario selection interaction
function changeMunicipality(municipality) {
  console.log('municipality', municipality);
  const scenarioId = Number.parseInt(localStorage.getItem('scenario'));
  console.log('scenarioId', scenarioId);
  init(municipality, scenarioId);
}

function changeScenario(scenario) {
  console.log('scenario', scenario);
  const municipalityKey = localStorage.getItem('municipality');
  fetchScenario(scenario);
  init(municipalityKey, scenario);
}
</script>

<style lang="scss" scoped>
.v-btn {
  @apply text-title-neutral;
}

.v-tab--selected {
  @apply text-title-color1;
}
</style>
