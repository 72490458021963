<template>
  <VuetifyDialog
    v-model="showNewFrameworkDialog"
    title="Neues Prämissenset anlegen"
    :overflow-visible="true"
  >
    <template #activatorItem>
      <ButtonEl
        icon="add"
        icon-fill="text-inverted-color1"
        text="Neues Prämissenset anlegen"
        data-test="create-premises"
      />
    </template>
    <template #content>
      <div class="flex flex-col gap-5">
        <div class="whitespace-pre-line">
          {{
            `Bitte benennen Sie das neue Prämissenset und wählen Sie einen
            Szenariorahmen, um vordefinierte Werte zu übernehmen.`
          }}
        </div>
        <FormValidator v-model="dialogInputIsValid" class="gap-2">
          <InputEl
            v-model="name"
            label="Name Prämissenset"
            placeholder="Bitte eingeben"
            :rules="{ required: true }"
            data-test="premise-name-input"
          />
          <DropDown
            v-model="basedOnFrameId"
            :items-data="
              scenarioFrames.map((e) => ({
                id: e.id,
                name: e.name,
              }))
            "
            items-data-key="name"
            value="id"
            :initial-selection="true"
            :rules="{ required: true }"
            label="Das Prämissenset basiert auf diesem Szenariorahmen:"
          />
        </FormValidator>
      </div>
    </template>
    <template #actions>
      <ButtonEl
        variant="secondary"
        text="Abbrechen"
        @click="showNewFrameworkDialog = false"
      />
      <ButtonEl
        text="Anlegen"
        data-test="premise-create-modal-approve"
        :disabled="!dialogInputIsValid"
        @click="
          createScenarioFrame(basedOnFrameId);
          showNewFrameworkDialog = false;
        "
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { ref } from 'vue';
import cookie from 'vue-cookies';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';

const emit = defineEmits(['abort', 'add', 'edit']);
const props = defineProps({ scenarioFrames: Array });

const dialogInputIsValid = ref(false);
const showNewFrameworkDialog = ref(false);
const basedOnFrameId = ref(null);
const name = ref(null);

function createScenarioFrame() {
  axios({
    url: '/api/heatprojects/wi-re/premises/',
    method: 'POST',
    data: {
      name: name.value,
      based_on_scenarioframe_id: basedOnFrameId.value,
    },
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then((r) => {
    emit('add', r.data);
    emit('edit', r.data.id);
  });
}
</script>
