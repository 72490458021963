<template>
  <div class="dataPanelCheckbox" style="align-items: flex-start !important">
    <div class="flex justify-end ml-auto font-bold">
      <div v-if="isLoading">
        <skeleton-loader-custom :custom-width="20" />
      </div>
      <div v-else>
        {{ displayValue }}
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoaderCustom from './SkeletonLoaderCustom.vue';

export default {
  components: { SkeletonLoaderCustom },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      checkBoxInput: this.item.value === 'true',
    };
  },
  computed: {
    displayValue() {
      if (this.item.value === 'true') return 'Ja';
      if (this.item.value === 'false') return 'Nein';
      return '-';
    },
  },
};
</script>

<style lang="scss">
.dataPanelCheckbox {
  .clusterCheckBox {
    .v-input__details {
      display: none !important;
    }

    .v-selection-control {
      min-height: 18px !important;
    }

    .v-selection-control__wrapper {
      width: 18px;
      height: 18px;
    }

    .v-selection-control__input {
      width: 18px;
      height: 18px;
    }

    .mdi-checkbox-blank-outline {
      background: #ffffff;
      box-shadow: 0 1px 2px #00125758 !important;
      border-radius: 3px;
      opacity: 1;
      min-width: 18px;
      height: 18px;
    }

    .mdi-checkbox-blank-outline:before {
      display: none;
    }

    .mdi-checkbox-marked {
      width: 18px;
      height: 18px;
    }

    input:checked {
      opacity: 0 !important;
    }

    .mdi-checkbox-marked:before {
      display: block;
      content: '';
      background-image: url('@/assets/svg/f-check.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: white;
      background-size: 60%;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      box-shadow: 0 1px 2px #00125758 !important;
    }
  }
}
</style>
