import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

export default {
  GET_MUNICIPALITIES: ({ commit }) =>
    new Promise((resolve, reject) => {
      axios({
        url: '/api/buildingmodel/municipality-selection/all/',
        method: 'GET',
      })
        .then((resp) => {
          commit('SET_MUNICIPALITIES', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
