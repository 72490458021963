<template>
  <div class="flex flex-col justify-between w-full gap-4">
    <div class="flex items-center mb-4">
      <img
        src="@/assets/svg/projects-icon.svg"
        class="mr-6 w-[64px]"
        alt="statistics"
      />
      <h2>Projektgebiete</h2>
    </div>

    <div class="flex flex-col gap-2.5 whitespace-nowrap w-[300px] mb-4">
      <h4 class="text-blue-grey-500">Szenario wählen</h4>
      <DropDown
        v-model="scenarioSelected"
        :items-data="scenarios"
        items-data-key="name"
        value="scenario_id"
      />
    </div>

    <div class="flex flex-col">
      <div
        class="grid auto-cols-fr grid-flow-col rounded-[3px] standard-elevation-0-dark"
      >
        <div
          v-for="card in cards"
          :key="card"
          class="flex flex-col gap-2 p-6 items-center justify-between min-h-[132px]"
        >
          <div class="flex flex-col items-center gap-1">
            <Component :is="card.icon" class="w-5 h-5" fill="#546E7A" />
            <div class="body-3 text-center whitespace-pre">
              {{ card.title }}
            </div>
          </div>
          <div class="subtitle-2">
            {{ formatNumber(card.value) + ' ' + card.unit }}
          </div>
        </div>
      </div>
      <div class="flex items-end justify-between gap-2 mt-4">
        <div class="w-[250px] relative">
          <InputEl
            v-model="filterInput"
            placeholder="Projektbezeichnung eingeben"
            size="m"
          />
          <close-icon
            v-if="filterInput"
            class="absolute right-1.5 translate-y-[-50%] top-1/2 w-5 cursor-pointer bg-white"
            @click="filterInput = null"
          />
        </div>

        <div class="flex items-end gap-2">
          <DatePicker
            v-model="startDate"
            :small="true"
            label="Erstellungsdatum"
            placeholder="von"
            :clearable="true"
          />
          <DatePicker
            v-model="endDate"
            :small="true"
            placeholder="bis"
            :clearable="true"
          />

          <ButtonEl
            icon="filter_alt"
            :icon-right="false"
            icon-type="filled"
            text="Filter setzen"
            class="mb-[1px]"
            size="m"
            @click="
              loadHeatProjects(
                {
                  page: page,
                  itemsPerPage: itemsPerPage,
                },
                true,
              )
            "
          />
        </div>
      </div>
    </div>

    <VuetifyDataTable
      class="rounded-[4px] standard-elevation-1 overflow-hidden table-project-areas-overview-custom"
      :headers="tableHeaders"
      :item-data="heatProjects"
      :total-items="projectsAggregatedData.project_count"
      :open-page-options-to-top="true"
      :show-expand="false"
      @update:options="loadHeatProjects"
    >
      <template #functions="{ item }">
        <div class="flex gap-2 justify-between w-fit">
          <IconWrapper
            class="cursor-pointer"
            icon="download"
            fill="text-color1"
            @click="downloadData(item.id)"
          />
          <MapIcon3
            class="cursor-pointer"
            fill="#007b8c"
            @click="goToMap(item.id)"
          />
          <TrashCanIcon
            class="functions-item-custom"
            fill="#007b8c"
            @click="toggleDelete(item.id)"
          />
        </div>
        <VuetifyDialog
          v-if="deleteProjectId === item.id"
          v-model="deleteDialog"
          :title="
            item.is_deletable
              ? `Projekt &quot;${item.name}&quot; wirklich
            löschen?`
              : `Löschen des Projektes &quot;${item.name}&quot; nicht möglich`
          "
        >
          <template #content>
            <span v-if="item.is_deletable"
              >Dieser Vorgang kann nicht rückgängig gemacht werden.</span
            >
            <span v-else
              >Eine Wirtschaftlichkeitsrechnung ist abhängig von diesem
              Projekt</span
            >
          </template>
          <template #actions>
            <ButtonEl
              variant="secondary"
              text="Abbrechen"
              @click="deleteDialog = false"
            />
            <ButtonEl
              v-if="item.is_deletable"
              text="Löschen"
              color="error"
              variant="secondary"
              @click="deleteProject(item.id)"
            />
          </template>
        </VuetifyDialog>
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import cookie from 'vue-cookies';
import { statsCards, tableHeaders } from '@/apps/projects/projects-data';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import CloseIcon from '@/components/storybook/src/assets/icons/close-icon.vue';
import DatePicker from '@/components/storybook/src/stories/datePicker/DatePicker.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import MapIcon3 from '@/components/storybook/src/assets/icons/MapIcon3.vue';
import TrashCanIcon from '@/components/storybook/src/assets/icons/TrashCanIcon.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';

const router = useRouter();
const store = useStore();

const deleteDialog = ref(false);
const deleteProjectId = ref(null);
const filterInput = ref(null);
const startDate = ref(null);
const endDate = ref(null);
const projectsAggregatedData = ref({});
const scenarios = ref([]);
const scenarioSelected = ref(null);

const cards = computed(() =>
  statsCards.map((card) => {
    card.value = projectsAggregatedData.value[card.key];
    return card;
  }),
);

const heatProjects = computed(() => {
  const projectData = store.getters['heatProject/getHeatProjectData'];
  const projectTableData = [];
  for (const project of projectData.filter((p) => p.progress === 0)) {
    const connectionRate = project.connection_rate;
    const result = project.results.find(
      (e) => e.connection_rate === connectionRate,
    );
    projectTableData.push({
      name: project.name,
      id: project.id,
      heat_demand_mwh: formatNumber(result.heat_demand_mwh),
      building_count: project.building_count,
      linear_heat_density_kwh_m: formatNumber(result.linear_heat_density_kwh_m),
      invest_total: formatNumber(result.invest_total),
      contribution_margin: formatNumber(result.contribution_margin),
      building_connection_length_m: formatNumber(
        result.building_connection_length_m,
      ),
      network_length_m: formatNumber(result.network_length_m),
    });
  }
  return projectTableData;
});

onMounted(async () => {
  await axios({
    url: `/api/scenario-select/all/`,
    method: 'GET',
  }).then((resp) => {
    scenarios.value = resp.data.data;
    const scenarioFromStore = localStorage.getItem('scenario');
    if (scenarioFromStore) {
      scenarioSelected.value = Number.parseInt(scenarioFromStore);
    } else {
      scenarioSelected.value = scenarios.value[0]?.scenario_id;
    }
  });
});

const page = ref(1);
const itemsPerPage = ref(10);

function downloadData(projectId) {
  window
    .open(`/api/heatprojects/project-download-csv/${projectId}/`, '_blank')
    .focus();
}

async function loadHeatProjects(options) {
  if (!scenarioSelected.value) return;
  try {
    const params = {
      page: options?.page || page.value,
      page_size: options?.itemsPerPage || itemsPerPage.value,
      created_start: stripTime(startDate.value),
      created_end: stripTime(endDate.value),
      scenario_id: scenarioSelected.value,
      project_name: filterInput.value,
      sortBy: options.sortBy?.[0]?.key,
      sortOrder: options.sortBy?.[0]?.order,
    };

    await store.dispatch('heatProject/GET_HEAT_PROJECTS', params);

    if (projectsAggregatedData.value.length) return;
    axios({
      url: `/api/heatprojects/projectplaner-aggregation/${scenarioSelected.value}`,
      method: 'GET',
    }).then((response) => {
      projectsAggregatedData.value = response.data.projects;
    });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

function formatNumber(value) {
  // Check if the value is null or undefined before formatting
  if (!value || isNaN(value)) {
    return '-';
  }
  // Continue with formatting if value is not null or undefined
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function goToMap(projectId) {
  localStorage.setItem('denominator', 'scenario');
  localStorage.setItem('municipality', 'all');
  localStorage.setItem('scenario', scenarioSelected.value);
  store.commit('heatProject/SET_LOAD_PROJECTS_ON_MAPLOAD', [projectId]);
  router.push(`/map/${scenarioSelected.value}`);
}

function toggleDelete(projectId) {
  deleteProjectId.value = projectId;
  deleteDialog.value = true;
}

function deleteProject(projectId) {
  axios({
    url: `/api/heatprojects/HeatProject/${projectId}/`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    deleteDialog.value = false;
    deleteProjectId.value = null;
    loadHeatProjects({}, true);
  });
}

function stripTime(date) {
  if (!date) return null;
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
}

watch(scenarioSelected, (newScenarioId) => {
  if (newScenarioId) {
    loadHeatProjects({}, true);
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

:deep(.table-project-areas-overview-custom) {
  thead > tr {
    height: 64px !important;

    .v-data-table-header__content {
      @extend h6;
      height: 100%;
      display: flex;
      align-items: center;
    }

    th:not(:first-child):not(:nth-child(2)) {
      .v-data-table-header__content span {
        white-space: break-spaces;
      }
    }
  }

  tbody > tr {
    td:first-child {
      @extend .subtitle-2;
    }

    td {
      @extend .body-2;
      @apply text-blue-grey-900;
    }
  }
}
</style>
