<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <VSlider
    v-model="selected"
    :ticks="Object.assign({}, years)"
    :max="years.length - 1"
    step="1"
    show-ticks="always"
    tick-size="4"
    :ripple="false"
    :hide-details="true"
    class="bg-white rounded-[8px]"
    data-test="year-slider"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  years: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['change-year']);

const store = useStore();
const selected = ref(0);
const year = computed(() => store.state.map.scenarioYear);

function setSelected(year) {
  selected.value = props.years.findIndex((e) => e === year);
}

onMounted(() => {
  if (year.value) {
    setSelected(year.value);
  } else {
    store.commit('map/SET_SCENARIO_YEAR', props.years[selected.value]);
  }
});

watch(selected, (val) => {
  store.commit('map/SET_SCENARIO_YEAR', props.years[val]);
});
watch(year, (val) => {
  setSelected(val);
});
</script>
