<template>
  <div class="flex gap-5 items-center bg-blue-grey-25 rounded-[4px] px-3">
    <h5 class="text-blue-grey-500">Gruppieren nach:</h5>
    <RadioButtonGroup
      v-model="groupBy"
      items-data-key="label"
      :reverse="true"
      :items-data="[
        { label: 'Energieträger', value: false },
        { label: 'Sektor', value: true },
      ]"
    />
  </div>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{ !groupBy ? 'Gebäude nach Heiztechnologie' : 'Gebäude nach Sektor' }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <BarChartStackedVertical
          :show-total="false"
          :chart-data="buildingData"
          class="mb-10"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{
          !groupBy
            ? 'Wärmebedarf nach Heiztechnologie in MWh'
            : 'Wärmebedarf nach Sektor in MWh'
        }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <BarChartStackedVertical
          :chart-data="demandData"
          :multiply="0.001"
          class="mb-10"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">
        {{
          !groupBy
            ? 'Emissionen nach Heiztechnologie in t CO₂'
            : 'Emissionen nach Sektor in t CO₂'
        }}
      </h3>
      <div class="data-row-items grid-cols-1">
        <BarChartStackedVertical :chart-data="emissionData" class="mb-10" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import BarChartStackedVertical from '@/apps/analysis-dashboard/charts/BarChartStackedVertical.vue';

const store = useStore();
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const demandData = computed(() => {
  let key = 'demand_by_ht';
  if (groupBy.value) key = 'demand_by_sector';
  return getGroupData(key);
});
const emissionData = computed(() => {
  let key = 'emissions_by_ht';
  if (groupBy.value) key = 'emissions_by_sector';
  return getGroupData(key);
});
const buildingData = computed(() => {
  let key = 'buildings_by_ht';
  if (groupBy.value) key = 'buildings_by_sector';
  return getGroupData(key);
});
const groupBy = computed({
  get: () => store.getters['dashboard/getDashboard3GroupBy'],
  set: (val) => store.commit('dashboard/SET_DASHBOARD3_GROUP_BY', val),
});

function getGroupData(key) {
  if (!props.data) return null;
  return props.data.map((e) => ({ year: e.year, data: e[key] }));
}
</script>
