<template>
  <div class="flex flex-col gap-5">
    <div class="relative">
      <template v-if="mapInstance">
        <SearchBar
          class="absolute top-5 left-5 z-[9] bg-white rounded-[6px] overflow-hidden"
        />
        <SearchResults :map="mapInstance" />
      </template>
      <div
        id="map-building-update"
        ref="map"
        :style="{ height: isExpanded ? '250px' : 'calc(100vh - 269px)' }"
        @wheel.stop
      />
    </div>
    <div v-if="isExpanded" class="flex flex-col gap-3">
      <VExpansionPanels
        v-model="activeExpandableIndex"
        variant="accordion"
        class="scenario-frame-expansion-panel gap-1 overflow-visible"
      >
        <VExpansionPanel v-for="(panel, index) in panels" :key="index">
          <VExpansionPanelTitle @click="clickIndex = -1">
            <h5>{{ panel.title }}</h5>
            <template #actions>
              <img src="@/assets/svg/enlarge-grey.svg" alt="enlarge" />
            </template>
          </VExpansionPanelTitle>
          <VExpansionPanelText>
            <div class="flex flex-col gap-2">
              <div
                v-for="(field, index2) in panel.fields"
                :key="index2"
                class="flex gap-2 items-end"
              >
                <template v-if="getData(field.dbColumnKey).choices">
                  <DropDown
                    v-model="getData(field.dbColumnKey).value"
                    :label="getData(field.dbColumnKey).label"
                    :items-data="getData(field.dbColumnKey).choices"
                    class="w-[350px]"
                    items-data-key="display_name"
                    @update:model-value="patchField(field.dbColumnKey, $event)"
                  />
                </template>
                <template
                  v-else-if="getData(field.dbColumnKey).type === 'boolean'"
                >
                  <div class="w-[350px] h-[40px]">
                    <CheckboxEl
                      v-if="field.patchable === true"
                      v-model="getData(field.dbColumnKey).value"
                      class="pt-3"
                      @update:model-value="
                        patchField(field.dbColumnKey, $event)
                      "
                    >
                      <div class="caption-1">
                        {{ getData(field.dbColumnKey).label }}
                      </div>
                    </CheckboxEl>
                    <CheckboxEl
                      v-else
                      v-model="getData(field.dbColumnKey).value"
                      class="pt-3"
                    >
                      <div class="caption-1">
                        {{ getData(field.dbColumnKey).label }}
                      </div>
                    </CheckboxEl>
                  </div>
                </template>
                <template v-else>
                  <InputEl
                    v-model="getData(field.dbColumnKey).value"
                    class="w-[350px]"
                    :input-type="getData(field.dbColumnKey).type"
                    :suffix="field.unit"
                    :label="getData(field.dbColumnKey).label"
                    @update:model-value="patchField(field.dbColumnKey, $event)"
                  />
                </template>
                <div class="w-3 h-3 mb-3.5 rounded-full relative">
                  <div
                    class="absolute button-2 uppercase p-[10px] rounded-[6px] top-1/2 whitespace-nowrap transform -translate-y-1/2 transition-opacity duration-200"
                    :class="[`bg-${mapVersionColor(field.dbColumnKey)}`]"
                  >
                    {{ mapVersionName(field.dbColumnKey) }}
                  </div>
                </div>
              </div>
            </div>
          </VExpansionPanelText>
        </VExpansionPanel>
      </VExpansionPanels>
      <ButtonEl
        text="Änderungen speichern"
        class="w-fit"
        @click="performUpdate"
      />
      <p>
        Die Schätzungen des Wärmebedarfs werden mithilfe der aggregierten
        Kundendaten verbessert, wenn diese verfügbar sind. Die Berechnung dieser
        Korrektur ist zeitaufwendig, da sie alle hochgeladenen Gebäude
        miteinbezieht. Passen Sie daher zuerst alle Gebäudedaten an und
        kontaktieren Sie anschließend eine Person mit Administrationsrechten.
        Diese kann dann die Korrektur für Sie durchführen.
      </p>
      <VuetifyDialog
        v-model="updateHeatDemandDialog"
        :activator-full-width="false"
        :title="`Wärmebedarf anpassen? `"
      >
        <template #content>
          Die veränderten Parameter verändern die Wärmebedarfsschätzung für
          dieses Gebäude. Gleichzeitig ist für dieses Gebäude ein Wärmebedarf
          hinterlegt, der statt der Wärmebedarfsschätzung verwendet wird. Was
          soll als Wärmebedarf verwendet werden? Weiterhin der hinterlegte
          Wärmebedarf oder die aktualisierte Wärmebdearfsschätzung?
        </template>
        <template #actions>
          <ButtonEl
            variant="secondary"
            text="Hinterlegter Wärmebedarf"
            @click="save(false)"
          />
          <ButtonEl
            text="Aktualisierte Wärmebedarfsschätzung"
            variant="secondary"
            @click="save(true)"
          />
        </template>
      </VuetifyDialog>
    </div>
  </div>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { cloneDeep } from 'lodash';
import { panels } from './components/building-update-panels';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import initMap from '@/features/building-versioning/init-map';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStore } from 'vuex';
import cookie from 'vue-cookies';
import SearchBar from '@/features/map/control-options/search/SearchBar.vue';
import SearchResults from '@/features/map/control-options/search/SearchResults.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';

const store = useStore();
const activeExpandableIndex = ref(0);

const versionMapper = [
  { color: 'green', name: 'Importiert' },
  { color: 'yellow', name: 'Angereichert' },
  { color: 'red', name: 'Überarbeitet' },
  { color: 'grey', name: 'Kombiniert' },
];

const mapInstance = ref(null);
const patchedFields = ref({});
const centroid = ref(null);
const updateHeatDemandDialog = ref(false);

const municipality = computed(() => store.state.municipality);

onMounted(async () => {
  // TODO: Add some filter here. Logic to be defined
  await getOptionsBuildings();
  await getCentroid(null);
  let initialFilter = null;

  if (!document.getElementById('map-building-update')) return;

  mapInstance.value = initMap(getBuilding, initialFilter, centroid.value);

  // show toast
  store.commit('layout/SHOWTOAST', {
    color: 'infra-highlight-500',
    message: 'Bitte wählen Sie ein Gebäude aus, um Änderungen vorzunehmen.',
    timeout: 9999999,
  });

  mapInstance.value.on('click', (e) => {
    clickedCoordinates.value = e.lngLat;
  });
});

onUnmounted(() => {
  store.commit('layout/HIDETOAST');
});

const clickIndex = ref(-1);
const fieldOptions = ref({});
const fieldData = ref({});
const initialData = ref(null);
const data = ref([]);

const isExpanded = computed(() => Object.keys(fieldData.value).length > 0);

function getData(key) {
  return fieldData.value[key];
}

function getBuilding(alkisId) {
  axios({
    url: `/api/buildingmodel/building/${alkisId}`,
    method: 'GET',
  }).then((resp) => {
    data.value = resp.data;
  });
}

function patchField(dbColumnKey, value) {
  patchedFields.value[dbColumnKey] = value;
}

function getCentroid(municipality, instantly = false) {
  return axios({
    method: 'GET',
    url: '/api/buildingmodel/municipality/centroid',
    params: { municipality_key: municipality },
  }).then((resp) => {
    centroid.value = resp.data;
    if (instantly) flyToCenter(centroid.value, 12);
  });
}

function mapVersionColor(dbColumnKey) {
  if (getData(dbColumnKey).sourceVersion === null) {
    return 'grey';
  }
  // if label is not null, return color of version
  const version = getData(dbColumnKey).sourceVersion;
  return versionMapper[version].color;
}

function mapVersionName(dbColumnKey) {
  if (getData(dbColumnKey).sourceVersion === null) {
    return 'Keine Daten';
  }
  // if label is not null, return name of version
  const version = getData(dbColumnKey).sourceVersion;
  return versionMapper[version].name;
}

watch(
  () => data,
  (value) => {
    if (!Object.keys(value.value).length) return;
    fieldData.value = {};
    initialData.value = null;
    patchedFields.value = {};
    const versions = combineBuildings(value.value, fieldOptions.value);
    initialData.value = cloneDeep(versions);
    for (const [key, field] of Object.entries(fieldOptions.value)) {
      if (field.read_only === false && typeof versions[key] !== 'undefined') {
        fieldData.value[key] = { ...field, ...versions[key] };
      }
    }
  },
  { deep: true },
);

const clickedCoordinates = ref(null);

watch(
  () => fieldData.value,
  (value) => {
    if (Object.keys(value).length > 0) {
      // hide toast
      store.commit('layout/HIDETOAST');
    }
    if (mapInstance.value && clickedCoordinates.value) {
      // Without setTimeout it dosn't center after resizing
      setTimeout(() => {
        // Resize the map to fit the new container size
        mapInstance.value.resize();
        // Re-center the map on the clicked point and zoom in
        flyToCenter(clickedCoordinates.value, 14.8);
      }, 5);
    }
  },
  { deep: true },
);

function flyToCenter(coordinates, zoom) {
  if (mapInstance.value) {
    mapInstance.value.flyTo({
      center: coordinates,
      zoom,
      essential: true,
    });
  }
}

function getOptionsBuildings() {
  return axios({
    url: '/api/buildingmodel/buildings/',
    method: 'OPTIONS',
  }).then((response) => {
    fieldOptions.value = response.data.actions.POST;
  });
}

function combineBuildings(features, options) {
  const VERSION_IMPORTED = 0;
  const VERSION_ENRICHED = 1;
  const VERSION_MANUAL = 2;
  const VERSION_COMBINED = 3;
  let imported = features.filter((b) => b.version === VERSION_IMPORTED);
  imported = imported.length === 1 ? imported[0] : null;
  let enriched = features.filter((b) => b.version === VERSION_ENRICHED);
  enriched = enriched.length === 1 ? enriched[0] : null;
  let manual = features.filter((b) => b.version === VERSION_MANUAL);
  manual = manual.length === 1 ? manual[0] : null;
  let combined = features.filter((b) => b.version === VERSION_COMBINED);
  combined = combined.length === 1 ? combined[0] : null;
  const sorted_versions = [imported, enriched, manual];

  const result = {};
  // iteratively overwrite attributes from each version
  for (const key of Object.keys(options)) {
    if (!Object.keys(features[0]).includes(key)) continue;
    for (const buildingVersion of sorted_versions) {
      if (buildingVersion === null) continue;
      // overwrite values if they are set
      if (buildingVersion[key] !== null && buildingVersion[key] !== '') {
        result[key] = {
          value: combined[key],
          sourceVersion: buildingVersion.version,
        };
      }
    }
    // add attributes that are empty in all versions
    if (!(key in result)) {
      result[key] = {
        value: null,
        sourceVersion: null,
      };
    }
  }
  return result;
}

function fieldsEffectingHeatDemand() {
  return panels.flatMap((panel) =>
    panel.fields
      .filter((field) => field.effectsHeatDemand)
      .map((field) => field.dbColumnKey),
  );
}

function performUpdate() {
  let stagedChanges = false;
  let effectsHeatDemand = false;
  let demandChanged = false;
  const fields = fieldsEffectingHeatDemand();
  for (const [key, value] of Object.entries(patchedFields.value)) {
    const dataField = initialData.value[key];
    if (value !== dataField.value) {
      stagedChanges = true;
      if (fields.includes(key)) effectsHeatDemand = true;
      if (key === 'demand_kwh') demandChanged = true;
    }
  }
  if (!stagedChanges) {
    store.commit('layout/SHOWTOAST', {
      color: 'infra-highlight-500',
      message: 'Bitte aktualisieren Sie zunächst die Gebäudedaten.',
      timeout: 3000,
    });
    return;
  }
  console.log(mapVersionName('demand_kwh'));
  if (!demandChanged && mapVersionName('demand_kwh') === 'Überarbeitet') {
    if (effectsHeatDemand) {
      updateHeatDemandDialog.value = true;
    } else {
      save(false);
    }
    return;
  }
  save(!demandChanged);
}

function save(useHeatDemandEstimation = false) {
  const payload = {
    use_heat_demand_estimation: useHeatDemandEstimation,
  };
  const alkisId = initialData.value['alkis_id'].value;
  for (const [key, value] of Object.entries(patchedFields.value)) {
    if (value !== initialData.value[key].value) payload[key] = value;
  }
  axios({
    url: `/api/buildingmodel/buildingAggregates/${alkisId}/`,
    method: 'PATCH',
    data: payload,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    getBuilding(alkisId);
    updateHeatDemandDialog.value = false;
  });
}
</script>
