<template>
  <div class="w-20 h-20 rounded-lg overflow-hidden">
    <img
      v-show="imageLoaded"
      class="overflow-hidden h-full w-full rounded-[8px]"
      :src="avatarURL"
      alt="avatar"
      @load="imageLoaded = true"
      @error="imageLoaded = false"
    />
    <img
      v-if="!imageLoaded"
      src="@/assets/img/default_avatar.png"
      alt="avatar"
      class="h-full w-full object-cover rounded-[8px]"
    />
  </div>
</template>

<script setup>
import { axiosInstance } from '@/utils/axiosHelper';
import { mapGetters } from 'vuex';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const store = useStore();
const imageLoaded = ref(false);

const profil = computed(() => store.getters['auth/profil']);
const avatarURL = computed(() => {
  return `${axiosInstance.defaults.baseURL}${profil.value.avatar}`;
});
</script>
