export default class DistrictParameterization {
  constructor(districtId, districtData, options, id = null) {
    this.optionalFields = [];
    this.requiredFields = [];
    for (const [key, value] of Object.entries(options)) {
      if (!value.read_only) {
        this[key] = null;
        if (value.required && key !== 'scenario') this.requiredFields.push(key);
        else this.optionalFields.push(key);
      }
    }
    this.id = id;
    this.district = districtId;
    // for filtering
    this.federal_state_name = districtData.federal_state_name;
    this.county_name = districtData.county_name;
    this.municipality_name = districtData.municipality_name;
    this.district_name = districtData.name || districtData.district_name;

    this.county_id = districtData.county_id;
    this.municipality_id = districtData.municipality_id;
  }

  initFromRequest(district) {
    this.refurbishment_rate = district['refurbishment_rate'];
    this.refurbishment_value = district['refurbishment_value'];
    this.refurbishment_unit = district['refurbishment_unit'];
    this.expansion_limit_heating_network =
      district['expansion_limit_heating_network'];
    this.expansion_limit_hydrogen_network =
      district['expansion_limit_hydrogen_network'];
    this.expansion_limit_decentral =
      district['expansion_limit_decentral'];
    this.expansion_limit_unit = district['expansion_limit_unit'];
    this.priority_area = district['priority_area'];
    this.heating_technologies = district['heating_technologies'];
  }

  get getPayload() {
    return {
      id: this.id,
      district_id: this.district,
      refurbishment_rate: this.refurbishment_rate,
      refurbishment_value: this.refurbishment_value,
      refurbishment_unit: this.refurbishment_unit,
      expansion_limit_heating_network: this.expansion_limit_heating_network,
      expansion_limit_hydrogen_network: this.expansion_limit_hydrogen_network,
      expansion_limit_decentral: this.expansion_limit_decentral,
      expansion_limit_unit: this.expansion_limit_unit,
      priority_area: this.priority_area,
      heating_technologies: this.heating_technologies,
    };
  }

  get isValid() {
    let valid = true;
    for (const key of this.requiredFields) {
      if (!this[key]) valid = false;
    }
    return valid;
  }

  hasAtLeastOneParameter() {
    return Boolean(
      this.refurbishment_rate ||
        this.refurbishment_value ||
        this.refurbishment_unit ||
        this.expansion_limit_heating_network ||
        this.expansion_limit_hydrogen_network ||
        this.expansion_limit_decentral ||
        this.expansion_limit_unit ||
        this.priority_area ||
        this.heating_technologies,
    );
  }
}
