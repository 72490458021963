import {
  LAYER_KEY__INVENTORY_GAS_NETWORK,
  LAYER_KEY__INVENTORY_HEAT_NETWORK,
  LAYER_KEY__EXISTING_NETWORK_AREAS,
  LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
  LAYER_KEY__INVENTORY_POWER_GRID,
  LAYER_KEY__INVENTORY_SEWAGE_NETWORK,
} from '../layers/inventoryNetwork';
import {
  heatSourceInventorySwitch,
  heatingCenterInventorySwitch,
  gridFeedPointInventorySwitch,
} from './heatSource';
import { infraModules } from '@/configs/infra-modules';

export const SWITCH_ID__INVENTORY_HEAT_NETWORK = 'LC_INVENTORY_HEAT_NETWORK';
export const SWITCH_ID__INVENTORY_GAS_NETWORK = 'LC_INVENTORY_GAS_NETWORK';
export const SWITCH_ID__INVENTORY_POWER_GRID = 'LC_INVENTORY_POWER_GRID';
export const SWITCH_ID__INVENTORY_SEWAGE_NETWORK =
  'LC_INVENTORY_SEWAGE_NETWORK';
export const SWITCH_ID__EXISTING_NETWORK_AREAS = 'LC_EXISTING_NETWORK_AREAS';
export const SWITCH_ID__EXISTING_HYDROGEN_NETWORK =
  'LC_EXISTING_HYDROGEN_NETWORK';

export default {
  title: 'Bestandsinfrastruktur',
  module: infraModules.scenarios,
  items: [
    {
      title: 'Fernwärme',
      disabledByEmptyModel: 'ExistingNetworkPart',
      onLayers: [LAYER_KEY__INVENTORY_HEAT_NETWORK],
      switchId: SWITCH_ID__INVENTORY_HEAT_NETWORK,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Keine Einfärbung',
              onLayers: [LAYER_KEY__INVENTORY_HEAT_NETWORK],
              layerState: 'default',
            },
            {
              title: 'Art',
              onLayers: [LAYER_KEY__INVENTORY_HEAT_NETWORK],
              layerState: 'type',
            },
            {
              title: 'Jahr der Inbetriebnahme',
              onLayers: [LAYER_KEY__INVENTORY_HEAT_NETWORK],
              layerState: 'commissioningYear',
            },
          ],
        },
      ],
    },
    {
      title: 'Erdgas',
      disabledByEmptyModel: 'ExistingGasNetworkPart',
      onLayers: [LAYER_KEY__INVENTORY_GAS_NETWORK],
      switchId: SWITCH_ID__INVENTORY_GAS_NETWORK,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Keine Einfärbung',
              onLayers: [LAYER_KEY__INVENTORY_GAS_NETWORK],
              layerState: 'default',
            },
            {
              title: 'Art',
              onLayers: [LAYER_KEY__INVENTORY_GAS_NETWORK],
              layerState: 'type',
            },
            {
              title: 'Jahr der Inbetriebnahme',
              onLayers: [LAYER_KEY__INVENTORY_GAS_NETWORK],
              layerState: 'commissioningYear',
            },
          ],
        },
      ],
    },
    {
      title: 'Strom',
      disabledByEmptyModel: 'ExistingPowerGridPart',
      onLayers: [LAYER_KEY__INVENTORY_POWER_GRID],
      switchId: SWITCH_ID__INVENTORY_POWER_GRID,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Keine Einfärbung',
              onLayers: [LAYER_KEY__INVENTORY_POWER_GRID],
              layerState: 'default',
            },
            {
              title: 'Jahr der Inbetriebnahme',
              onLayers: [LAYER_KEY__INVENTORY_POWER_GRID],
              layerState: 'commissioningYear',
            },
          ],
        },
      ],
    },
    {
      title: 'Abwasser',
      disabledByEmptyModel: 'ExistingSewageNetworkPart',
      onLayers: [LAYER_KEY__INVENTORY_SEWAGE_NETWORK],
      switchId: SWITCH_ID__INVENTORY_SEWAGE_NETWORK,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Keine Einfärbung',
              onLayers: [LAYER_KEY__INVENTORY_SEWAGE_NETWORK],
              layerState: 'default',
            },
            {
              title: 'Jahr der Inbetriebnahme',
              onLayers: [LAYER_KEY__INVENTORY_SEWAGE_NETWORK],
              layerState: 'commissioningYear',
            },
          ],
        },
      ],
    },
    heatSourceInventorySwitch,
    heatingCenterInventorySwitch,
    gridFeedPointInventorySwitch,
    {
      title: 'Wärmenetzgebiete',
      tooltip:
        'Flächenbezogene Informationen zum Wärmenetz, wenn eine leitungsliniengetreue Darstellung nicht möglich ist',
      disabledByEmptyModel: 'ExistingNetworkAreas',
      onLayers: [LAYER_KEY__EXISTING_NETWORK_AREAS],
      switchId: SWITCH_ID__EXISTING_NETWORK_AREAS,
      itemClass: 'switch',
    },
    {
      title: 'Wasserstoffnetzgebiete',
      tooltip:
        'Flächenbezogene Informationen zum Wasserstoffnetz, wenn eine leitungsliniengetreue Darstellung nicht möglich ist',
      disabledByEmptyModel: 'ExistingHydrogenNetwork',
      onLayers: [LAYER_KEY_EXISTING_HYDROGEN_NETWORK],
      switchId: SWITCH_ID__EXISTING_HYDROGEN_NETWORK,
      itemClass: 'switch',
    },
  ],
};
