import { state } from './store/index';
import store from '@/store';
import {
  SWITCH_ID__HEAT_SOURCE_INVENTORY,
  SWITCH_ID__HEAT_SOURCE_POTENTIAL,
  SWITCH_ID__HEATING_CENTER_POTENTIAL,
  SWITCH_ID__HEATING_CENTER_INVENTORY,
  SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
  SWITCH_ID__GRID_FEED_POINT_INVENTORY,
} from '@/configs/layer-panel/heatSource';
import {
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
} from '@/configs/layers/constants.js';
import { getMap } from '@/mapbox/main';

let heatSourceFeatureState = [];
const heatSourceLayers = [
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
];
/**
 * highlight all heat sources that are related to a project currently loaded
 * into the store
 * @param  {Boolean} active activate/deactivate highlighting heat sources
 */
export default function highlightHeatSources(active) {
  heatSourceFeatureState.forEach((e) => {
    getMap().setFeatureState(e, { highlight: false });
  });
  if (active) {
    store.commit('map/CHANGE_SWITCH', {
      switches: [
        SWITCH_ID__HEAT_SOURCE_INVENTORY,
        SWITCH_ID__HEAT_SOURCE_POTENTIAL,
        SWITCH_ID__HEATING_CENTER_POTENTIAL,
        SWITCH_ID__HEATING_CENTER_INVENTORY,
        SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
        SWITCH_ID__GRID_FEED_POINT_INVENTORY,
      ],
      active: true,
    });
    if (state.heatProjects.length > 0) {
      const heatSources = state.heatProjects.flatMap((e) => e.getHeatSources);
      heatSources.forEach((e) => {
        heatSourceLayers.forEach((l) => {
          heatSourceFeatureState.push({ id: e.id, source: l });
          getMap().setFeatureState(
            { id: e.id, source: l },
            { highlight: true },
          );
        });
      });
    }
  }
}
