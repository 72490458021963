import {
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
} from '../layers/constants';
import { infraModules } from '@/configs/infra-modules';

export const SWITCH_ID__HEAT_SOURCE_INVENTORY = 'LC_HEAT_SOURCE_INVENTORY';
export const SWITCH_ID__HEAT_SOURCE_POTENTIAL = 'LC_HEAT_SOURCE_POTENTIAL';
export const SWITCH_ID__HEATING_CENTER_POTENTIAL =
  'LC_HEATING_SOURCE_POTENTIAL';
export const SWITCH_ID__HEATING_CENTER_INVENTORY =
  'LC_HEATING_SOURCE_INVENTORY';
export const SWITCH_ID__GRID_FEED_POINT_POTENTIAL =
  'LC_GRID_FEED_POINT_POTENTIAL';
export const SWITCH_ID__GRID_FEED_POINT_INVENTORY =
  'LC_GRID_FEED_POINT_INVENTORY';

export const heatSourceInventorySwitch = {
  title: 'Wärmequellen',
  tooltip: 'Einzelne Anlage, die direkt Wärme in das Wärmenetz einspeisen kann',
  onLayers: [LAYER_KEY__HEAT_SOURCE_INVENTORY],
  switchId: SWITCH_ID__HEAT_SOURCE_INVENTORY,
  itemClass: 'switch',
  module: infraModules.projectPlaner,
  radioGroups: [
    {
      visible: false,
      activated: 0,
      radios: [
        {
          title: 'Keine Einfärbung',
          onLayers: [LAYER_KEY__HEAT_SOURCE_INVENTORY],
          layerState: 'default',
        },
        {
          title: 'Energieträger',
          onLayers: [LAYER_KEY__HEAT_SOURCE_INVENTORY],
          layerState: 'energySource',
        },
        {
          title: 'Anlagentyp',
          onLayers: [LAYER_KEY__HEAT_SOURCE_INVENTORY],
          layerState: 'sourceType',
        },
      ],
    },
  ],
};

export const heatSourcePotentialSwitch = {
  title: 'Wärmequellen',
  tooltip: 'Einzelne Anlage, die direkt Wärme in das Wärmenetz einspeisen kann',
  onLayers: [LAYER_KEY__HEAT_SOURCE_POTENTIAL],
  switchId: SWITCH_ID__HEAT_SOURCE_POTENTIAL,
  itemClass: 'switch',
  radioGroups: [
    {
      visible: false,
      activated: 0,
      radios: [
        {
          title: 'Keine Einfärbung',
          onLayers: [LAYER_KEY__HEAT_SOURCE_POTENTIAL],
          layerState: 'default',
        },
        {
          title: 'Energieträger',
          onLayers: [LAYER_KEY__HEAT_SOURCE_POTENTIAL],
          layerState: 'energySource',
        },
        {
          title: 'Anlagentyp',
          onLayers: [LAYER_KEY__HEAT_SOURCE_POTENTIAL],
          layerState: 'sourceType',
        },
      ],
    },
  ],
};

export const heatingCenterPotentialSwitch = {
  title: 'Heizzentrale',
  tooltip:
    'Fasst die Wärme aus mehreren Wärmequellen zusammen und speist diese in das Netz ein',
  onLayers: [LAYER_KEY__HEATING_CENTER_POTENTIAL],
  switchId: SWITCH_ID__HEATING_CENTER_POTENTIAL,
  itemClass: 'switch',
};

export const heatingCenterInventorySwitch = {
  title: 'Heizzentrale',
  tooltip:
    'Fasst die Wärme aus mehreren Wärmequellen zusammen und speist diese in das Netz ein',
  onLayers: [LAYER_KEY__HEATING_CENTER_INVENTORY],
  switchId: SWITCH_ID__HEATING_CENTER_INVENTORY,
  itemClass: 'switch',
  module: infraModules.projectPlaner,
};

export const gridFeedPointPotentialSwitch = {
  title: 'Aufspeisepunkte',
  tooltip:
    'Punkt für Anschluss von einem neuen Fernwärmenetz an das Bestandsnetz',
  onLayers: [LAYER_KEY__GRID_FEED_POINT_POTENTIAL],
  switchId: SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
  itemClass: 'switch',
};

export const gridFeedPointInventorySwitch = {
  title: 'Aufspeisepunkte',
  tooltip:
    'Punkt für Anschluss von einem neuen Fernwärmenetz an das Bestandsnetz',
  onLayers: [LAYER_KEY__GRID_FEED_POINT_INVENTORY],
  switchId: SWITCH_ID__GRID_FEED_POINT_INVENTORY,
  itemClass: 'switch',
  module: infraModules.projectPlaner,
};
