<template>
  <FormValidator v-model="isFormValid">
    <div class="input-container">
      <div
        v-for="group in inputGroups"
        :key="group.title"
        class="flex flex-col gap-5"
      >
        <h5 class="text-title-color2">{{ group.title }}</h5>
        <InputEl
          v-for="input in group.inputs"
          :key="input.id"
          v-model="localData[input.id]"
          :disabled="input.disabled || readOnly"
          :label="input.label"
          :suffix="input.suffix"
          :input-type="input.inputType"
          :rules="
            input.id === 'start_at_year'
              ? { range: [props.defaultStartYear, 2050] }
              : input.rules
          "
          :decimals="input.decimals"
          :apply-thousands-separator="input.applyThousandsSeparator"
        />
      </div>
    </div>
  </FormValidator>
  <div v-if="!readOnly" class="p-[10px] bg-subtle mt-5 rounded-md">
    <ButtonEl
      text="Jetzt berechnen"
      icon="table_view"
      class="w-fit float-right"
      :disabled="!isFormValid"
      @click="emit('triggerCalculation')"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { inputGroups } from '@/apps/economic-efficiency/field-data';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => ({}),
  },
  defaultStartYear: {
    type: Number,
    default: 2023,
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:data', 'triggerCalculation']);

// Use computed to create a reactive copy of props.projectData
const localData = ref({ ...props.projectData });
const isFormValid = ref(true);

// Adjusted watcher to compare localData with props.projectData
watch(
  localData,
  (newVal) => {
    emit('update:data', newVal);
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.input-container {
  display: grid;
  grid-gap: 40px; /* Adjust the gap size as needed */
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
