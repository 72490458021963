<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z"
      :fill="fill" />
    <path
      d="M2.5 15C3.88071 15 5 13.8807 5 12.5C5 11.1193 3.88071 10 2.5 10C1.11929 10 0 11.1193 0 12.5C0 13.8807 1.11929 15 2.5 15Z"
      :fill="fill" />
    <path
      d="M18.75 3.75H8.75C8.06 3.75 7.5 3.19125 7.5 2.5C7.5 1.80875 8.06 1.25 8.75 1.25H18.75C19.44 1.25 20 1.80875 20 2.5C20 3.19125 19.44 3.75 18.75 3.75Z"
      :fill="fill" />
    <path
      d="M18.75 8.75H8.75C8.06 8.75 7.5 8.19125 7.5 7.5C7.5 6.80875 8.06 6.25 8.75 6.25H18.75C19.44 6.25 20 6.80875 20 7.5C20 8.19125 19.44 8.75 18.75 8.75Z"
      :fill="fill" />
    <path
      d="M18.75 13.75H8.75C8.06 13.75 7.5 13.1912 7.5 12.5C7.5 11.8088 8.06 11.25 8.75 11.25H18.75C19.44 11.25 20 11.8088 20 12.5C20 13.1912 19.44 13.75 18.75 13.75Z"
      :fill="fill" />
    <path
      d="M18.75 18.75H8.75C8.06 18.75 7.5 18.1912 7.5 17.5C7.5 16.8088 8.06 16.25 8.75 16.25H18.75C19.44 16.25 20 16.8088 20 17.5C20 18.1912 19.44 18.75 18.75 18.75Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
