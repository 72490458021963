export default {
  getLoadingState: (state) => state.loading,
  getActiveProcessOptions: (state) =>
    state.processOptions.find((e) => e.active === true) || {},
  processOptions: (state) => state.processOptions,
  getMainMenuShow: (state) => state.showMainMenu,
  getHeatProjectEditing: (state) => state.editHeatProjectNetwork,
  getProjectMenuShow: (state) => state.showProjectMenu,
  getLogoUrl: (state) => state.logoUrl,
  getMuScSelector: (state) => state.showMuScSelector,
};
