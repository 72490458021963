import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  scenarios: [],
  scenarioSelected: { scenario_id: null },
  scenarioDistrictOptions: {},
  scenarioFrames: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
