<template>
  <div>
    <TheLabel :label="label" />
    <div :class="['dropdown-parent', dropDownWrapperClasses]">
      <div ref="dropdownContainer" class="relative w-full">
        <div
          ref="dropDownContainerInner"
          class="container-inner"
          :class="containerInnerClasses"
          @click="toggleDropdown">
          <div class="text-wrapper">
            {{ displayValue }}
          </div>
          <div class="flex items-center">
            <IconWrapper
              v-if="clearable && modelValue"
              icon="cancel"
              type="round"
              data-vitest="dropdown-cancel-icon"
              :size="cancelIconSizes"
              @click.stop="clearSelection" />
            <IconWrapper
              :icon="
                customExpandIcon === null ? 'arrow_drop_down' : customExpandIcon
              "
              :size="expandIconSizes"
              :class="[
                { 'rotate-180': showDropdown && !customExpandIcon },
                'duration-300',
              ]"
              type="round" />
          </div>
        </div>
        <div
          v-if="showDropdown"
          class="list-container"
          :class="listContainerClasses">
          <div
            ref="dropDownList"
            class="list-item-wrapper"
            :style="{
              'max-height': `${maxHeightDropdown}px`,
            }"
            @wheel.stop>
            <div
              v-for="(item, index) in itemsData"
              :key="index"
              class="single-list-item"
              :class="singleListItemClasses(item)"
              role="menuitem"
              @click="selectItem(item)">
              {{ itemsDataKey ? item[itemsDataKey] : item }}
            </div>
          </div>
        </div>
        <span
          v-if="errorText !== null"
          class="absolute bottom-[-18px] text-xs text-red-500">
          {{ errorText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';
import { useValidation } from '../../composables/formElementValidation';
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { useListeners } from '../../composables/useListeners';

useListeners({
  click: closeDropdown,
});

const { isValid, errorText, checkValidationRules, mapValidationRules } =
  useValidation();

const props = defineProps({
  size: {
    type: String,
    default: 'l',
    validate: (value) => ['s', 'm', 'l', 'xl', 'xxl'].includes(value),
  },
  itemsData: {
    type: [Array, Object],
    required: true,
  },
  itemsDataKey: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  openToTop: {
    type: Boolean,
    default: false,
  },
  customBackground: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number, Object, Array],
    default: null,
  },
  value: {
    type: String,
    default: 'value',
  },
  initialSelection: {
    type: Boolean,
    default: false,
  },
  customExpandIcon: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const showDropdown = ref(false);
const maxHeightDropdown = ref(125);
const modelValueChanged = ref(false);
const dropdownContainer = ref(null);

const dropDownWrapperClasses = computed(() => {
  return {
    'dropdown-size-s': props.size === 's',
    'dropdown-size-m': props.size === 'm',
    'dropdown-size-l': props.size === 'l',
    'dropdown-size-xl': props.size === 'xl',
    'dropdown-size-xxl': props.size === 'xxl',
    disabled: props.disabled,
  };
});

const containerInnerClasses = computed(() => {
  return {
    [props.customBackground]: props.customBackground,
    invalid: errorText.value,
    opened: showDropdown.value,
    openedDefault: showDropdown.value && !props.openToTop,
    openToTop: showDropdown.value && props.openToTop,
  };
});

const cancelIconSizes = computed(() => {
  switch (props.size) {
    case 's':
      return 16;
    case 'm':
      return 20;
  }
  return 24;
});

const expandIconSizes = computed(() => {
  switch (props.size) {
    case 'xl':
      return 32;
    case 'xxl':
      return 32;
  }
  return 24;
});

const listContainerClasses = computed(() => {
  return {
    openedDefault: !props.openToTop,
    openToTop: props.openToTop,
  };
});

function singleListItemClasses(item) {
  return {
    isSelected: isSelectedItem(item),
    'pointer-events-none text-disabled-neutral': item.disabled,
  };
}

const validators = computed(() => mapValidationRules(props.rules));
const displayValue = computed(() => {
  if (
    props.placeholder &&
    (props.modelValue === null ||
      props.modelValue === undefined ||
      props.modelValue === '')
  ) {
    return props.placeholder;
  }

  // If initialSelection is true and modelValue hasn't been changed by the user
  if (props.initialSelection && !modelValueChanged.value) {
    // Directly return the first item if itemsDataKey is null
    if (props.itemsDataKey === null) {
      // Ensure the entire item is returned
      return props.itemsData[0];
    } else {
      // Return the property of the first item specified by itemsDataKey or an empty string if not found
      return props.itemsData[0]?.[props.itemsDataKey] ?? '';
    }
  }

  // Finding and displaying the selected item
  const selectedItem = props.itemsData.find((item) => {
    // Handle the case when itemsDataKey is null by comparing the entire item
    if (props.itemsDataKey === null) {
      return item === props.modelValue;
    } else {
      // When itemsDataKey is not null, compare the specified property
      return item?.[props.value] === props.modelValue;
    }
  });

  // Return the appropriate value or item based on itemsDataKey
  if (selectedItem) {
    if (props.itemsDataKey === null) {
      // If itemsDataKey is null, ensure the entire item is returned
      return selectedItem;
    } else {
      // Return the property specified by itemsDataKey, if it exists
      return selectedItem[props.itemsDataKey] !== undefined
        ? selectedItem[props.itemsDataKey]
        : '';
    }
  }

  // Default case if no condition is met
  return '';
});

function toggleDropdown() {
  showDropdown.value = !showDropdown.value;
}

function closeDropdown(event) {
  if (!dropdownContainer.value.contains(event.target)) {
    showDropdown.value = false;
  }
}

function selectItem(item) {
  modelValueChanged.value = true;
  showDropdown.value = false;
  // Check if item[props.value] is undefined explicitly; otherwise, use it even if it's 0
  const valueToEmit =
    item[props.value] !== undefined ? item[props.value] : item;
  emit('update:modelValue', valueToEmit);
  checkValidationRules(validators.value, valueToEmit, true);
}

function clearSelection() {
  emit('update:modelValue', null);
  showDropdown.value = false;
  checkValidationRules(validators.value, null, true);
}

function validate(setErrorText, input) {
  checkValidationRules(validators.value, input, setErrorText);
  return isValid.value;
}

function isSelectedItem(item) {
  if (item?.[props.value] !== undefined) {
    return item[props.value] === props.modelValue;
  } else {
    return item === props.modelValue;
  }
}

const registerValidator = inject('registerValidator', null);
const unregisterValidator = inject('unregisterValidator', null);
const uniqueId = Math.random().toString(36).substring(2, 11);
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (unregisterValidator) {
    unregisterValidator(uniqueId);
  }
});

watch(
  () => props.itemsData,
  (val) => {
    if (props.initialSelection && !modelValueChanged.value) {
      const firstItemValue =
        props.itemsDataKey === null ? val[0] : val[0]?.[props.value];
      emit('update:modelValue', firstItemValue);
    }
  },
  { deep: true, immediate: true },
);

watch(
  () => props.modelValue,
  (newVal) => {
    if (registerValidator) {
      registerValidator(uniqueId, validate(isMounted.value, newVal));
    } else {
      validate(isMounted.value, newVal);
    }
  },
  { deep: true, immediate: true },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.dropdown-parent {
  @apply text-neutral cursor-pointer;
  &.disabled {
    pointer-events: none;
    @apply text-disabled-neutral;
    .container-inner {
      @apply bg-subtle border-line-disabled-neutral;
    }
  }

  &.dropdown-size-s {
    @extend .caption-2;

    .container-inner {
      height: 24px;
      padding: 6px;
      border-radius: 2px;

      &.opened {
        padding: 6px 5px;
      }
    }

    .list-container {
      border-radius: 2px;
    }

    .single-list-item {
      height: 20px;
      padding: 5px;
    }
  }

  &.dropdown-size-m {
    @extend .body-2;

    .container-inner {
      height: 32px;
      padding: 8px;
      border-radius: 4px;

      &.opened {
        padding: 8px 7px;
      }
    }

    .list-container {
      border-radius: 4px;
    }

    .single-list-item {
      height: 28px;
      padding: 7px;
    }
  }

  &.dropdown-size-l {
    @extend .body-1;

    .container-inner {
      height: 40px;
      padding: 10px;
      border-radius: 6px;

      &.opened {
        padding: 10px 9px;
      }
    }

    .list-container {
      border-radius: 6px;
    }

    .single-list-item {
      height: 36px;
      padding: 9px;
    }
  }

  &.dropdown-size-xl {
    @extend .body-1;

    .container-inner {
      height: 48px;
      padding: 8px 12px;
      border-radius: 8px;
      padding-right: 3px !important;

      &.opened {
        padding: 8px 11px;
        padding-right: 2px !important;
      }
    }

    .list-container {
      border-radius: 8px;
    }

    .single-list-item {
      height: 44px;
      padding: 11px;
    }
  }

  &.dropdown-size-xxl {
    @extend .subtitle-1;

    .container-inner {
      height: 48px;
      padding: 8px 15px;
      border-radius: 12px;
      padding-right: 7px !important;

      &.opened {
        padding: 8px 14px;
        padding-right: 6px !important;
      }
    }

    .list-container {
      border-radius: 12px;
    }

    .single-list-item {
      height: 44px;
      padding: 14px;
    }
  }
}

.container-inner {
  @apply bg-active-area border-line-neutral flex justify-between items-center
  w-full border gap-1;
  &.invalid {
    @apply border-red-500;
  }

  &:hover {
    @apply border-line-active-neutral;
  }

  &.opened {
    border-width: 2px;
    @apply border-line-active-neutral;
    &.openedDefault {
      @apply rounded-b-none;
    }

    &.openToTop {
      @apply rounded-t-none;
    }
  }
}

.text-wrapper {
  @apply overflow-hidden whitespace-nowrap;
}

.list-container {
  border-width: 2px;
  @apply absolute z-10 left-0 w-full dropdown-animation
  border-line-active-neutral overflow-hidden;
  &.openedDefault {
    @apply border-t-0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .list-item-wrapper {
      @apply flex-col;
    }
  }

  &.openToTop {
    @apply border-b-0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .list-item-wrapper {
      @apply flex-col-reverse;
    }
  }

  .list-item-wrapper {
    @apply overflow-y-auto flex;
  }
}

.single-list-item {
  @apply flex items-center hover:bg-subtle bg-default;
  &.isSelected {
    @apply bg-subtle;
  }
}

.dropdown-animation {
  animation: dropdown-animation 0.2s ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
