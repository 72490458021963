<template>
  <div v-if="showForSpecificDashboard" class="flex flex-col gap-2.5 py-2">
    <h4 class="text-blue-grey-500">Zeitpunkt bestimmen</h4>
    <div class="h-[45px]">
      <YearSlider
        v-show="showSlider"
        class="px-0"
        :years="years"
        :class="{
          'pointer-events-none':
            !getEnergyData && dashboardSelected === 'energy-balance',
        }"
      />
    </div>
  </div>
  <Teleport v-if="!showSlider" to="#app" class="h-[65px]">
    <div
      class="absolute w-full left-0 top-[80px] z-[10000] flex bg-infra-background border-t border-blue-grey-100 shadow-xl"
    >
      <div class="w-full px-5 teleport-slider-wrapper">
        <div class="grid grid-cols-2 gap-12 items-center my-2">
          <div class="flex w-full max-w-[400px] gap-10">
            <div class="flex flex-col">
              <span
                class="text-[13px] font-medium text-black mb-1 scenario-list-scrollToView"
                >Szenario:</span
              >
              <div>{{ scenarioName }}</div>
            </div>
            <div v-if="selectedDistrictName" class="flex flex-col">
              <span
                class="text-[13px] font-medium text-black mb-1 scenario-list-scrollToView"
                >Stadtteil:</span
              >
              <div>{{ selectedDistrictName }}</div>
            </div>
          </div>
          <div>
            <YearSlider
              v-if="showForSpecificDashboard"
              :years="years"
              class="px-0"
              :class="{
                'pointer-events-none':
                  !getEnergyData && dashboardSelected === 'energy-balance',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import YearSlider from '@/features/year-slider/YearSlider.vue';

const store = useStore();
const props = defineProps({
  getEnergyData: Array,
  dashboardSelected: String,
  selectedDistrictName: String,
  scenarioName: String,
  years: Array,
});
const emit = defineEmits(['change-year']);
const showSlider = ref(true);

const showForSpecificDashboard = computed(
  () =>
    props.dashboardSelected === 'energy-balance' ||
    props.dashboardSelected === 'spatial-evaluation' ||
    props.dashboardSelected === 'district-heating-potentials',
);
const getLastScrollPosition = computed(
  () => store.getters['dashboard/getLastScrollPosition'],
);

watch(
  () => getLastScrollPosition.value,
  () => {
    const scrollLimits = {
      default: 407,
      'community-structure': 180,
      'chronological-evaluation': 180,
      'spatial-evaluation': 390,
      potentials: 180,
    };

    // Use the specific scroll limit for the current dashboard selection, or the default if not specified
    const scrollLimit =
      scrollLimits[props.dashboardSelected] || scrollLimits['default'];
    showSlider.value = getLastScrollPosition.value <= scrollLimit;
  },
);
</script>

<style lang="scss" scoped>
.teleport-slider-wrapper {
  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto;
  }
  @media (max-width: 1440px) {
    @apply mx-5;
  }
}
</style>
