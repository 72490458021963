<template>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div class="data-row-dashboard">
      <h3 class="data-row-title">Versorgte Gebäude</h3>
      <div class="data-row-items">
        <DoughnutChart
          class="h-[375px]"
          :chart-data="buildingsByHC"
          :title="`nach Versorgungsart`"
          title-inside="Gebäude"
          :title-inside-l-h="20"
          :custom-radius="['40%', '50%']"
        />
        <bar-chart
          class="h-[375px]"
          :chart-data="buildingsByHT"
          :chart-title="'nach Heiztechnologie'"
          :custom-min-height="38"
        />
        <bar-chart
          class="h-[375px]"
          :chart-data="buildingsSavingsByHT"
          :chart-title="'Änderung nach Heiztechnologie zu Status quo'"
          :custom-min-height="38"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">Wärmebedarf</h3>
      <div class="data-row-items">
        <DoughnutChart
          class="h-[375px]"
          :chart-data="demandByHC"
          :title="`nach Versorgungsart`"
          title-inside="Wärmebedarf"
          :title-inside-l-h="20"
          :chart-unit="'GWh'"
          :multiply="0.000001"
          :custom-radius="['40%', '50%']"
        />
        <bar-chart
          class="h-[375px]"
          :chart-data="demandByHT"
          :chart-title="`nach Heiztechnologie\nin GWh`"
          :multiply="0.000001"
          :custom-min-height="38"
        />
        <total-reduction
          class="h-[375px]"
          :base-value="demandByHT"
          :chart-data="demandSavingsByHT"
          :multiply="1000000"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">Emissionen</h3>
      <div class="data-row-items">
        <DoughnutChart
          class="h-[375px]"
          :chart-data="emissionsByHC"
          :title="`nach Versorgungsart`"
          title-inside="Emissionen"
          :title-inside-l-h="20"
          :chart-unit="'t CO₂'"
          :custom-radius="['40%', '50%']"
        />
        <bar-chart
          class="h-[375px]"
          :chart-data="emissionsByHT"
          :chart-title="'nach Heiztechnologie\nin t CO₂'"
          :custom-min-height="38"
        />
        <total-reduction
          class="h-[375px]"
          chart-unit="t CO₂"
          :base-value="emissionsByHT"
          :chart-data="emissionsSavingsByHT"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import BarChart from '@/apps/analysis-dashboard/charts/barChart.vue';
import TotalReduction from '@/apps/analysis-dashboard/charts/totalReduction.vue';

const store = useStore();
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const buildingsByHC = computed(() =>
  props.data ? props.data['buildings_by_hc'] : null,
);
const buildingsByHT = computed(() =>
  props.data ? props.data['buildings_by_ht'] : null,
);
const buildingsSavingsByHT = computed(() =>
  props.data ? props.data['buildings_savings_by_ht'] : null,
);
const demandByHC = computed(() =>
  props.data ? props.data['demand_by_hc'] : null,
);
const demandByHT = computed(() =>
  props.data ? props.data['demand_by_ht'] : null,
);
const demandSavingsByHT = computed(() =>
  props.data ? props.data['demand_savings_by_ht'] : null,
);
const emissionsByHC = computed(() =>
  props.data ? props.data['emissions_by_hc'] : null,
);
const emissionsByHT = computed(() =>
  props.data ? props.data['emissions_by_ht'] : null,
);
const emissionsSavingsByHT = computed(() =>
  props.data ? props.data['emissions_savings_by_ht'] : null,
);
</script>

<style lang="scss">
.data-row-dashboard {
  @apply flex flex-col justify-center items-center bg-blue-grey-25
  rounded-[4px] overflow-hidden px-4;
  .data-row-title {
    @apply flex border-b-2 border-[#D9DEEC] w-full justify-center pb-4 pt-8 text-[#015B76];
  }

  .data-row-items {
    @apply w-full grid grid-cols-2 lg:grid-cols-3 gap-x-5;
  }

  .chartItem {
    height: 100%;
  }
}
</style>
