<template>
  <div>
    <TheLabel :label="label" />
    <div ref="dropdownRef" class="dropdown-multiple w-full relative">
      <div
        class="flex justify-between text-neutral items-center rounded-[6px] cursor-pointer border h-10"
        :class="{
          'h-[30px]': small,
          'bg-active-area': !customBackground,
          [customBackground]: customBackground,
        }"
        @click="toggleDropdown">
        <div
          ref="placeholderContainer"
          class="truncate text-neutral w-full pl-3"
          :class="{
            'body-1': !small,
            'body-2': small,
          }"
          @mouseover="checkTruncation">
          <span v-if="!dropdownOpen" @click.stop="toggleDropdown"
            >{{ placeholderText }}
            <VTooltip
              v-if="isPlaceHolderTruncated"
              activator="parent"
              location="top"
              content-class="text-inverted-color1 bg-button-primary-default-color1"
              >{{ placeholderText }}
            </VTooltip>
          </span>
        </div>
        <DropDownIcons
          :custom-background="customBackground"
          :selected-items="selectedItemValues"
          :clearable="!readOnly && clearable"
          @remove-clicked="handleClearClick" />
      </div>
      <div
        v-if="dropdownOpen"
        class="dropdown-animation w-full rounded-[6px] absolute z-10 left-0 top-0 bg-default border-2 border-line-active-neutral overflow-hidden"
        @wheel.stop>
        <div
          class="flex justify-between text-neutral items-center py-3 bg-subtle"
          :class="{
            '!py-2': small,
          }">
          <div
            v-if="items.length !== 0"
            class="whitespace-nowrap overflow-hidden text-neutral w-full pl-3"
            :class="{
              'body-1': !small,
              'body-2': small,
            }">
            <InputEl
              :size="small ? 'm' : 'l'"
              suffix="ZoomIcon"
              :suffix-icon="true"
              :model-value="filter"
              class="w-full"
              @click.stop
              @update:model-value="filter = $event" />
          </div>

          <DropDownIcons
            :custom-background="customBackground"
            :selected-items="selectedItemValues"
            :clearable="!readOnly && clearable"
            :dropdown-open="true"
            @arrow-clicked="toggleDropdown"
            @remove-clicked="handleClearClick" />
        </div>
        <div
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }">
          <CheckboxEl
            v-if="optionSelectAll && sortedItems.length > 1"
            v-model="selectAll"
            border-width="2px"
            :disabled="readOnly"
            class="px-3 py-2.5 gap-3"
            @update:model-value="toggleSelectAll">
            <span
              :class="{
                'body-1': !small,
                'body-2': small,
              }"
              >{{ selectAllName }}</span
            ></CheckboxEl
          >
          <div v-for="item in sortedItems" :key="item">
            <CheckboxEl
              v-model="selectedItemValues"
              :value="item"
              :disabled="
                readOnly ||
                (ensureOneSelected &&
                  selectedItemValues.length === 1 &&
                  (dataKey
                    ? item[valueKey] === selectedItemValues[0][valueKey]
                    : item === selectedItemValues[0]))
              "
              border-width="2px"
              class="px-3 py-2.5 gap-3">
              <span
                :class="{
                  'body-1': !small,
                  'body-2': small,
                }"
                >{{ dataKey ? item[dataKey] : item }}</span
              ></CheckboxEl
            >
            <div v-if="groupData">
              <CheckboxEl
                v-for="subItem in item.gemarkungen"
                :key="subItem.id"
                v-model="selectedSubItems"
                :value="subItem"
                border-width="2px"
                class="px-3 py-2.5 gap-3">
                <span
                  :class="{
                    'body-1': !small,
                    'body-2': small,
                  }"
                  >{{ subItem }}</span
                ></CheckboxEl
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useListeners } from '../../composables/useListeners';
import DropDownIcons from '../dropDownMultiple/DropDownIcons.vue';
import TheLabel from '../label/TheLabel.vue';
import InputEl from '../input/InputEl.vue';
import CheckboxEl from '../CheckboxEl/CheckboxEl.vue';

const props = defineProps({
  small: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  items: { type: Array, default: () => [] },
  itemTypeAll: { type: String, default: 'Gemeinden' },
  allSelectedLabel: { type: String, default: '' },
  selectAllName: { type: String, default: 'Alle auswählen' },
  label: { type: String, default: null },
  customBackground: { type: String, default: null },
  initSelectAll: { type: Boolean, default: false },
  selectAllOnItemsChange: { type: Boolean, default: false },
  maxHeightDropdown: { type: Number, default: 200 },
  placeholder: { type: String, default: 'Bitte auswählen' },
  dataKey: { type: String, default: null },
  valueKey: { type: String, default: 'value' },
  groupData: { type: Boolean, default: false },
  optionSelectAll: { type: Boolean, default: true },
  modelValue: { type: Array, default: () => [] },
  ensureOneSelected: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
});

const emits = defineEmits(['update:modelValue']);

const dropdownOpen = ref(false);
const selectAll = ref(false);
const selectedSubItems = ref([]);
const filter = ref('');
const dropdownRef = ref(null);

useListeners({
  click: handleOutsideClick,
  keydown: handleKeydown,
});

onMounted(() => {
  if (props.initSelectAll) emits('update:modelValue', props.items);
});

watch(
  () => props.items,
  () => {
    if (props.selectAllOnItemsChange) {
      emits('update:modelValue', props.items);
    }
  },
  { deep: true, immediate: true },
);

const sortedItems = computed(() => {
  const items = [...props.items].filter((item) => item);
  const filteredItems = filterItems(items, filter.value, props.dataKey);

  filteredItems.sort((a, b) => {
    if (props.dataKey) {
      if (!a[props.dataKey] || !b[props.dataKey]) {
        // Check if the values at dataKey are defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a[props.dataKey].localeCompare(b[props.dataKey]);
    } else {
      if (!a || !b) {
        // Check if a or b is defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a.localeCompare(b);
    }
  });
  return filteredItems;
});

const selectedItemValues = computed({
  get() {
    return handleLessAvailableItemsThenSelectedItems();
  },
  set(value) {
    if (
      props.ensureOneSelected &&
      value.length === 0 &&
      props.modelValue.length === 1
    ) {
      return;
    }
    emits('update:modelValue', value);
  },
});

function getPlaceHolderNoItemsSelected() {
  if (props.placeholder) {
    return props.placeholder;
  }
  return props.modelValue[0];
}

function getPlaceHolderAllItemsSelected() {
  if (props.allSelectedLabel) {
    return `${props.allSelectedLabel}`;
  } else if (props.itemTypeAll !== 'null') {
    return `Alle ${props.items.length} ${props.itemTypeAll} ausgewählt`;
  } else {
    return 'Alle ausgewählt';
  }
}

function getPlaceHolderMoreThanTwoItemsSelected() {
  if (props.itemTypeAll !== 'null') {
    return `${selectedItemValues.value.length} ${props.itemTypeAll} ausgewählt`;
  } else {
    return `${selectedItemValues.value.length} ausgewählt`;
  }
}

function sortPlaceHolderItems() {
  const sortedPlacedholders = [...selectedItemValues.value].sort((a, b) => {
    if (props.dataKey) {
      if (!a[props.dataKey] || !b[props.dataKey]) {
        // Check if the values at props.dataKey are defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a[props.dataKey].localeCompare(b[props.dataKey]);
    } else {
      if (!a || !b) {
        // Check if a or b is defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a.localeCompare(b);
    }
  });

  // Return the truncated list of selected items
  const itemMapper = props.dataKey
    ? (item) => item[props.dataKey]
    : (item) => item;
  return truncateItems(sortedPlacedholders.map(itemMapper));
}

const placeholderText = computed(() => {
  if (selectedItemValues.value.length === 0) {
    return getPlaceHolderNoItemsSelected();
  }
  if (selectedItemValues.value.length === props.items.length) {
    return getPlaceHolderAllItemsSelected();
  } else if (selectedItemValues.value.length >= 3) {
    return getPlaceHolderMoreThanTwoItemsSelected();
  }

  return sortPlaceHolderItems();
});

function toggleDropdown() {
  dropdownOpen.value = !dropdownOpen.value;
}

function toggleSelectAll() {
  if (selectAll.value) {
    selectedItemValues.value = [...sortedItems.value];
  } else {
    if (props.ensureOneSelected) {
      selectedItemValues.value = [sortedItems.value[0]];
    } else {
      selectedItemValues.value = [];
    }
  }
}

function handleClearClick() {
  selectAll.value = false;
  toggleSelectAll();
}

function handleOutsideClick(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
}

function handleKeydown(event) {
  if (
    event.key === 'Escape' &&
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target)
  ) {
    dropdownOpen.value = false;
  }
}

function truncateItems(text) {
  const selectedItemsLength = 5;
  return text.length > selectedItemsLength
    ? text.slice(0, selectedItemsLength).join(', ') + '...'
    : text.join(', ');
}

function filterItems(items) {
  // Provide a default empty string if districtFilter.value is null or undefined
  const filterText = (filter.value || '').trim().toLowerCase();

  // Proceed with filtering and sorting
  return items.filter((item) => {
    const toFilter = props.dataKey ? item[props.dataKey] : item;
    return toFilter.toLowerCase().includes(filterText);
  });
}

// Edge case: comes when prop.items changes and has number of items reduced
// In this edge case we also want to have the props.modelValue to stay as is
function handleLessAvailableItemsThenSelectedItems() {
  const filterIds = new Set(props.items.map((item) => item[props.valueKey]));
  return props.modelValue.filter((item) => filterIds.has(item[props.valueKey]));
}

watch(
  () => selectedItemValues.value,
  (newValues) => {
    selectAll.value = newValues.length === props.items.length;
  },
  { immediate: true, deep: true },
);

// ---------  Start Placeholder Tooltip logic --------
const isPlaceHolderTruncated = ref(false);
const placeholderContainer = ref(null);

function checkTruncation() {
  if (placeholderContainer.value) {
    isPlaceHolderTruncated.value =
      placeholderContainer.value.scrollWidth >
      placeholderContainer.value.clientWidth;
  }
}

watch(placeholderText, checkTruncation);
// ---------  End Placeholder Tooltip logic --------
</script>

<style lang="scss">
.dropdown-animation {
  animation: dropdown-animation 0.2s ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
