import {
  LAYER_KEY__WIND_POWER_PLANT,
  LAYER_KEY__SOLAR_FREE_POWER_PLANT,
  LAYER_KEY__BIOMASS_POWER_PLANT,
  LAYER_KEY__GEOTHERMAL_POWER_PLANT,
} from '../constants';

import {
  layerConfigPoint,
  getLayerStyle,
  getLegend,
} from '@/configs/layers/baseData/helper';

import {
  subtitles,
  betriebsinformationen,
  defaultColors,
} from '@/configs/layers/baseData/constants';

export default {
  [LAYER_KEY__WIND_POWER_PLANT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: layerConfigPoint(LAYER_KEY__WIND_POWER_PLANT),
    style: getLayerStyle(defaultColors.wind, 'wind'),
    legend: getLegend(
      defaultColors.wind,
      'Windenergieanlage',
      'Wind Betriebsstatus',
      'Wind Nettonennleistung',
      'wind',
    ),
    sidebar: {
      name: 'Windenergieanlagen',
      subtitles: subtitles,
      chips: [
        {
          title: '',
          active: true,
          items: [
            betriebsinformationen,
            {
              title: 'Technologie',
              active: false,
              items: [
                {
                  key: 'name_windpark',
                  unit: '',
                  decimal: 2,
                  class: 'powerplant',
                },
                {
                  key: 'hub_height_m',
                  unit: 'm',
                  decimal: 2,
                  class: 'powerplant',
                },
                {
                  key: 'rotor_diameter_m',
                  unit: 'm',
                  decimal: 2,
                  class: 'powerplant',
                },
                {
                  key: 'plant_type',
                  unit: '',
                  decimal: 2,
                  class: 'powerplant',
                },
                {
                  key: 'technology',
                  unit: '',
                  decimal: 2,
                  class: 'powerplant',
                },
                {
                  key: 'manufacturer',
                  unit: '',
                  decimal: 2,
                  class: 'powerplant',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__SOLAR_FREE_POWER_PLANT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: layerConfigPoint(LAYER_KEY__SOLAR_FREE_POWER_PLANT),
    style: getLayerStyle(defaultColors.solar, 'solar'),
    legend: getLegend(
      defaultColors.solar,
      'PV-Freiflächenanlage',
      'PV Betriebsstatus',
      'PV Nettonennleistung',
      'solar',
    ),
    sidebar: {
      name: 'PV-Freiflächenanlagen',
      subtitles: subtitles,
      chips: [
        {
          title: '',
          active: true,
          items: [
            betriebsinformationen,
            {
              title: 'Technologie',
              active: false,
              items: [
                {
                  key: 'alignment',
                  unit: '',
                  decimal: 0,
                  class: 'powerplant',
                },
                {
                  key: 'angle_of_inclination',
                  unit: '',
                  decimal: 0,
                  class: 'powerplant',
                },
                {
                  key: 'count_of_modules',
                  unit: '',
                  decimal: 0,
                  class: 'powerplant',
                },
                {
                  key: 'utilisation',
                  unit: '',
                  decimal: 0,
                  class: 'powerplant',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__BIOMASS_POWER_PLANT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: layerConfigPoint(LAYER_KEY__BIOMASS_POWER_PLANT),
    style: getLayerStyle(defaultColors.bio, 'bio'),
    legend: getLegend(
      defaultColors.bio,
      'Biomasseanlage',
      'Biomasse Betriebsstatus',
      'Bio Nettonennleistung',
      'bio',
    ),
    sidebar: {
      name: 'Biomasseanlagen',
      subtitles: subtitles,
      chips: [
        {
          title: '',
          active: true,
          items: [
            betriebsinformationen,
            {
              title: 'Technologie',
              active: false,
              items: [
                {
                  key: 'main_fuel',
                  unit: '',
                  decimal: 15,
                  class: 'powerplant',
                },
                {
                  key: 'type_of_biomass',
                  unit: '',
                  class: 'powerplant',
                },
                {
                  key: 'technology',
                  unit: '',
                  class: 'powerplant',
                },
                {
                  key: 'chp_id',
                  unit: '',
                  class: 'powerplant',
                },
                {
                  key: 'chp_electrical_capacity_kw',
                  unit: 'kW',
                  decimal: 0,
                  class: 'powerplant',
                },
                {
                  key: 'chp_thermal_capacity_kw',
                  unit: 'kW',
                  decimal: 0,
                  class: 'powerplant',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  [LAYER_KEY__GEOTHERMAL_POWER_PLANT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: layerConfigPoint(LAYER_KEY__GEOTHERMAL_POWER_PLANT),
    style: getLayerStyle(defaultColors.geo, 'geo'),
    legend: getLegend(
      defaultColors.geo,
      'Geothermieanlage',
      'Geothermie Betriebsstatus',
      'Geo Nettonennleistung',
      'geo',
    ),
    sidebar: {
      name: 'Geothermieanlagen',
      subtitles: subtitles,
      chips: [
        {
          title: '',
          active: true,
          items: [
            betriebsinformationen,
            {
              title: 'Technologie',
              active: false,
              items: [
                {
                  key: 'technology',
                  unit: '',
                  decimal: 0,
                  class: 'powerplant',
                },
              ],
            },
          ],
        },
      ],
    },
  },
};
