import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
  energyData: null,
  districtHeatingPotentialsData: null,
  communityData: null,
  comparisonData: null,
  potentialData: null,
  dashboard4filterData: null,
  dashboard3groupBy: false,
  dashboardSelected: null,
  lastScrollPosition: 0,
  showFilterDropdown: true,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
