<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8735 3.40833L18.0511 9.41128C18.1166 9.46775 18.1577 9.54827 18.1656 9.63547C18.1735 9.72266 18.1475 9.80952 18.0933 9.8773C18.0391 9.94509 17.9609 9.98835 17.8758 9.99775C17.7906 10.0071 17.7052 9.98193 17.6381 9.92754L10.667 4.09733L3.6959 9.92754C3.62876 9.98193 3.5434 10.0071 3.45823 9.99775C3.37306 9.98835 3.29493 9.94509 3.2407 9.8773C3.18647 9.80952 3.16049 9.72266 3.16838 9.63547C3.17627 9.54827 3.21739 9.46775 3.28286 9.41128L5.12065 7.87419V5.00045C5.12065 4.912 5.15503 4.82717 5.21621 4.76463C5.2774 4.70208 5.36038 4.66695 5.44691 4.66695H6.42567C6.5122 4.66695 6.59519 4.70208 6.65637 4.76463C6.71756 4.82717 6.75193 4.912 6.75193 5.00045V6.50986L10.4605 3.40833C10.5188 3.35962 10.5917 3.33301 10.667 3.33301C10.7423 3.33301 10.8152 3.35962 10.8735 3.40833ZM4.83366 9.70395L10.667 4.99967L16.5003 9.70395V16.8607C16.5003 17.0302 16.432 17.1927 16.3105 17.3125C16.1889 17.4324 16.0241 17.4997 15.8522 17.4997H12.2874V13.9852C12.2874 13.9005 12.2532 13.8192 12.1924 13.7593C12.1317 13.6994 12.0492 13.6657 11.9633 13.6657H9.3707C9.28475 13.6657 9.20232 13.6994 9.14154 13.7593C9.08076 13.8192 9.04662 13.9005 9.04662 13.9852V17.4997H5.48181C5.30991 17.4997 5.14505 17.4324 5.0235 17.3125C4.90195 17.1927 4.83366 17.0302 4.83366 16.8607V9.70395ZM9.46561 11.3357C9.52639 11.3956 9.60882 11.4292 9.69477 11.4292H11.6392C11.7252 11.4292 11.8076 11.3956 11.8684 11.3357C11.9291 11.2757 11.9633 11.1945 11.9633 11.1097V9.19275C11.9633 9.10802 11.9291 9.02675 11.8684 8.96683C11.8076 8.90692 11.7252 8.87326 11.6392 8.87326H9.69477C9.60882 8.87326 9.52639 8.90692 9.46561 8.96683C9.40484 9.02675 9.3707 9.10802 9.3707 9.19275V11.1097C9.3707 11.1945 9.40484 11.2757 9.46561 11.3357Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
