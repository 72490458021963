<template>
  <v-chart
    class="pointer-events-none"
    style="width: 366px; height: 300px"
    :option="option"
    autoresize
  />
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import * as echarts from 'echarts';

const props = defineProps({
  chartData: {
    type: Array,
    required: true,
  },
});

const option = computed(() => {
  const totalLengthSum = props.chartData.reduce(
    (acc, item) => acc + item.total_length,
    0,
  );
  return {
    graphic: {
      type: 'text',
      left: 'center',
      top: 'middle',
      style: {
        text: 'Anteile\nNennweiten\nKlassen',
        textAlign: 'center',
        fill: '#00030E',
        fontSize: 14,
        lineHeight: 16,
        fontWeight: 600,
        fontFamily: 'Borna',
      },
      z: 100,
    },

    series: [
      // Series for outside labels
      {
        type: 'pie',
        radius: ['40%', '60%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'outside',
          formatter: (params) => {
            const name = `{a|${params.name}mm:}`;
            const length = `{b|${params.value.toLocaleString('de-DE', {
              maximumFractionDigits: 0,
            })}m}`;
            return `${name} \n ${length}`;
          },
          rich: {
            a: {
              fontWeight: '600',
              fontSize: 10,
              padding: [0, 0, 1, 0],
            },
            b: {
              fontSize: 10,
            },
          },
          fontSize: 10,
          fontFamily: 'Inter',
          color: 'black',
        },

        labelLine: {
          show: true,
          length: 8,
          length2: 20,
        },
        data: props.chartData.map((item) => ({
          value: item.total_length,
          name: item.inner_diameter,
        })),
        color: [
          '#E0F7FA',
          '#80DEEA',
          '#26C6DA',
          '#00ACC1',
          '#00838F',
          '#006064',
        ],
      },
      // Series for inside labels
      {
        type: 'pie',
        radius: ['40%', '60%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            // Displaying the percentage inside
            const percentage = (
              (params.value / totalLengthSum) *
              100
            ).toLocaleString('de-DE', {
              maximumFractionDigits: 0,
            });
            return `${percentage}%`;
          },
          fontSize: 9,
          color: 'black',
          backgroundColor: 'white',
          borderRadius: 1,
          padding: 1,
        },
        data: props.chartData.map((item) => ({
          value: item.total_length,
          name: item.inner_diameter,
        })),
        // Reusing the same color array for consistency
        color: [
          '#E0F7FA',
          '#80DEEA',
          '#26C6DA',
          '#00ACC1',
          '#00838F',
          '#006064',
        ],
      },
    ],
  };
});
</script>
