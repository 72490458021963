<template>
  <ScenarioTabWrapper
    v-if="!readOnly"
    :apply-selection-enabled="props.scenario.municipalities.asList.length > 0"
    button-naming="Gemeinden"
    :show-button
    @selection-button-click="
      emitData(
        props.scenario.municipalities.asList.map((e) => e.municipality_id),
      )
    "
  >
    <DropDown
      v-model="scenarioFrame"
      label="Gemeinde Szenariorahmen"
      items-data-key="name"
      value="id"
      :initial-selection="true"
      class="w-[40%]"
      :items-data="scenarioFrames"
      data-test="scenario-frame-drop-down"
      :rules="{ required: true }"
    />
  </ScenarioTabWrapper>
  <DetailSelection
    class="p-5"
    :read-only
    :show-municipality-filter="false"
    :apply-selection-enabled="true"
    :available-items="availableItems"
    :filtered-items="municipalitiesForTable"
    :selected-items="selectedItems"
    :headers="tableHeaders.scenarioFrame"
    :granular-selection-enabled="granularSelectionEnabled"
    :key-name-for-checkbox="'municipality_id'"
    aggregate-level="municipality"
    @detail-selection-visible="showButton = !$event"
    @update:filter="filters = $event"
    @item-click="selectedItems = [...$event]"
    @selection-button-click="emitData(selectedItems)"
  />
</template>

<script setup>
import { tableHeaders } from '@/apps/scenarios/scenario-create-data';
import { computed, onMounted, ref } from 'vue';
import { axios } from '@/utils/axiosHelper';
import ScenarioTabWrapper from '@/apps/scenarios/components/shared/ScenarioTabWrapper.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import DetailSelection from '@/apps/scenarios/components/shared/DetailSelection.vue';
import { useDetailFiltering } from '@/apps/scenarios/detailFiltering';

const props = defineProps({
  scenario: Object,
  activeTab: Number,
  availableItems: Object,
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['setFrameForMunicipalities']);

const { selectedItems, filters, filteredItems } = useDetailFiltering(
  props.scenario.municipalities.asList,
  props.availableItems,
  ['scenario_frame_name'],
  'municipality_name',
);

const showButton = ref(true);
const scenarioFrame = ref(null);
const scenarioFrames = ref([]);

onMounted(async () => {
  axios({ url: '/api/scenario-frames/base-data/', method: 'GET' }).then((r) => {
    for (const frame of r.data.frames) {
      if (frame.progress === 0) {
        scenarioFrames.value.push({ id: frame.id, name: frame.name });
      }
    }
  });
});

const granularSelectionEnabled = computed(() => {
  return props.scenario.municipalities.asList.some((frame) =>
    Boolean(frame.scenario_frame),
  );
});

const municipalitiesForTable = computed(() => {
  return filteredItems.value.map((item) => {
    return { ...item, scenario_frame_name: item.scenario_frame_name || '-' };
  });
});

function emitData(municipalityIds) {
  emit('setFrameForMunicipalities', {
    municipalityIds,
    scenarioFrame: {
      id: scenarioFrame.value,
      name: scenarioFrames.value.find((e) => e.id === scenarioFrame.value).name,
    },
  });
}
</script>

<style scoped></style>
