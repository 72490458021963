import {
  energySourceColors,
  energyTypeColors,
  heatingCarrierColors,
  priorityAreaColors,
  sectorColors,
} from '../layers/baseData/map-colors';

export const SECTOR_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: sectorColors.HH,
      name: 'Private Haushalte',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: sectorColors.PUB,
      name: 'Öffentlich',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: sectorColors.COM,
      name: 'Gewerbe, Handel und Dienstleistungen',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: sectorColors.IND,
      name: 'Industrie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: sectorColors.UNK,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const HEATING_CARRIER_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: heatingCarrierColors.FOS,
      name: 'Fossil',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: heatingCarrierColors.ELE,
      name: 'Elektrifizierung',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: heatingCarrierColors.HNT,
      name: 'Wärmenetz',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: heatingCarrierColors.REN,
      name: 'Erneuerbar',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: heatingCarrierColors.GRG,
      name: 'Grüne Gase',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: heatingCarrierColors.UKN,
      name: 'Unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const PRIORITY_AREA_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: priorityAreaColors.dezentral,
      name: 'Dezentral',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: priorityAreaColors.DIS,
      name: 'Wärmenetz',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: priorityAreaColors.H2,
      name: 'Wasserstoffnetz',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: priorityAreaColors.UKN,
      name: 'Unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const HEATING_TECHNOLOGY_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: energySourceColors.GAS,
      name: 'Erdgaskessel',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.ELE,
      name: 'Strom Direktheizung',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.OIL,
      name: 'Ölkessel',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energyTypeColors.DHU,
      name: 'Wärmenetze',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.PMP,
      name: 'Wärmepumpe',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.H2,
      name: 'Synthetische Energieträger Heizkessel',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.COA,
      name: 'Kohlekessel',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.BIO,
      name: 'Biomassekessel',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.SOL,
      name: 'Solarthermie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.NULL,
      name: 'Keine Heizung',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: energySourceColors.UNK,
      name: 'Unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});
