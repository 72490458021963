<template>
  <div
    class="bg-default rounded-[4px] w-full flex flex-col text-neutral gap-2.5"
  >
    <div class="flex justify-between items-end gap-2.5 h-[58px]">
      <div
        v-if="!readOnly"
        class="flex items-center py-2 px-2.5 button-1 h-10"
        :class="{
          'w-full text-title-neutral bg-button-secondary-default-neutral cursor-pointer':
            granularSelectionEnabled,
          'text-disabled-neutral bg-button-secondary-disabled-neutral cursor-not-allowed':
            !granularSelectionEnabled,
          '!w-[240px]': enableShowItems && showFilters,
        }"
        @click="showItems"
      >
        <IconWrapper
          :icon="`${enableShowItems ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`"
        />
        <span class="ml-1.5">{{
          `${aggregateNaming[aggregateLevel].plural} ${enableShowItems ? 'ausblenden' : 'einblenden'}`
        }}</span>
      </div>
      <div :class="{ 'ml-auto': readOnly }" class="flex gap-2.5 items-end">
        <div v-if="showFilters" class="flex items-end gap-2.5">
          <DropDownMultiple
            v-model="filters.countiesFilter"
            label="Landkreise"
            item-type-all="Landkreise"
            all-selected-label="Alle"
            class="w-52"
            :items="availableItems.counties"
            data-key="county_name"
            value-key="county_id"
            placeholder="Bitte auswählen"
            data-test="county-filter"
          />
          <DropDownMultiple
            v-if="showMunicipalityFilter"
            v-model="filters.municipalitiesFilter"
            label="Gemeinden"
            item-type-all="Gemeinden"
            all-selected-label="Alle"
            class="w-52"
            :items="totalMunicipalities"
            data-key="municipality_name"
            value-key="municipality_id"
            placeholder="Bitte auswählen"
            data-test="municipality-filter"
          />
          <div class="relative">
            <InputEl
              label="Textfilter"
              :model-value="filters.textFilter"
              :placeholder="`Nach ${aggregateNaming[aggregateLevel].singular}`"
              :suffix-icon="true"
              suffix="ZoomIcon"
              class="w-52"
              @update:model-value="filters.textFilter = $event"
            />
            <IconWrapper
              v-if="filters.textFilter"
              icon="close"
              class="absolute right-[34px] top-[38px] translate-y-[-50%] cursor-pointer bg-active-area"
              @click="filters.textFilter = ''"
            />
          </div>
          <ButtonEl
            v-if="showUnsetFilter"
            :text="`${aggregateNaming[aggregateLevel].plural} ohne Wert`"
            :variant="filters.unsetFilter ? 'primary' : 'secondary'"
            :icon="
              filters.unsetFilter
                ? 'radio_button_checked'
                : 'radio_button_unchecked'
            "
            class="whitespace-nowrap"
            :mdi="true"
            @click="filters.unsetFilter = !filters.unsetFilter"
          />
        </div>
        <ButtonEl
          v-if="enableShowItems"
          :mdi="true"
          icon="filter_alt"
          icon-type="round"
          :variant="showFilters ? 'secondary' : 'primary'"
          @click="showFilters = !showFilters"
        />
      </div>
    </div>
    <div v-if="enableShowItems">
      <ButtonEl
        v-if="!readOnly"
        :disabled="!applySelectionEnabled"
        :text="`Eingabe für ${selectedItems.length} ${
          selectedItems.length === 1
            ? aggregateNaming[aggregateLevel].singular
            : aggregateNaming[aggregateLevel].plural
        } anwenden`"
        icon="assignment_turned_in"
        :mdi="true"
        class="w-fit my-2.5"
        icon-type="round"
        variant="secondary"
        @click="emit('selectionButtonClick')"
      />

      <div class="flex flex-col gap-2.5">
        <div
          class="pl-[1px] standard-elevation-0 rounded-md"
          data-test="details-table"
          @scroll.stop
        >
          <VuetifyDataTable
            :headers="[...baseHeaders, ...headers]"
            :item-data="filteredItems"
            :item-value="keyNameForCheckbox"
            :show-select="!readOnly"
            :show-expand="false"
            :select-all-checkboxes="!readOnly"
            @selected-rows="emit('itemClick', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { toObject } from '../../scenario-helpers';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import DropDownMultiple from '@/components/storybook/src/stories/dropDownMultiple/DropDownMultiple.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';

const aggregateNaming = {
  district: {
    singular: 'Stadtteil',
    plural: 'Stadtteile',
  },
  municipality: {
    singular: 'Gemeinde',
    plural: 'Gemeinden',
  },
};

const baseHeaders = computed(() => {
  const baseHeaders = [
    {
      title: 'Gemeinde',
      key: 'municipality_name',
      width: '150px',
      align: 'left',
    },
  ];

  if (!props.readOnly) {
    baseHeaders.unshift({
      title: '',
      key: 'show_details',
      align: 'center',
      sortable: false,
      width: '100px',
    });
  }

  return baseHeaders;
});

const props = defineProps({
  applySelectionEnabled: Boolean,
  showUnsetFilter: {
    default: true,
    type: Boolean,
  },
  showMunicipalityFilter: {
    default: true,
    type: Boolean,
  },
  availableItems: Object,
  filteredItems: Array,
  selectedItems: Array,
  headers: Array,
  granularSelectionEnabled: Boolean,
  keyNameForCheckbox: {
    default: 'district',
    type: String,
  },
  aggregateLevel: {
    default: 'district',
    type: String,
  },
  readOnly: {
    default: false,
    type: Boolean,
  },
});
const emit = defineEmits([
  'update:filter',
  'itemClick',
  'selectionButtonClick',
  'detailSelectionVisible',
]);

onMounted(() => {
  if (props.readOnly) {
    enableShowItems.value = true;
  }
});

const enableShowItems = ref(false);
const showFilters = ref(false);
const filters = ref({
  textFilter: '',
  countiesFilter: props.availableItems.counties,
  municipalitiesFilter: props.availableItems.municipalities,
  unsetFilter: false,
});

const totalMunicipalities = computed(() => {
  const countiesFilterObj = toObject(filters.value.countiesFilter, 'county_id');
  return props.availableItems.municipalities.filter((item) => {
    return countiesFilterObj[item.county_id];
  });
});

watch(totalMunicipalities, (newItems, oldItems) => {
  if (newItems > oldItems) {
    filters.value.municipalitiesFilter = newItems;
  } else {
    // Make sure that selectedMunicipalities keep the state as it was before,
    // but also remove not anymore available municipalities
    const totalMunisObj = toObject(newItems, 'municipality_id');
    filters.value.municipalitiesFilter =
      filters.value.municipalitiesFilter.filter((item) => {
        return totalMunisObj[item.municipality_id];
      });
  }
});

watch(filters, (newFilters) => emit('update:filter', newFilters), {
  deep: true,
});

function showItems() {
  if (props.granularSelectionEnabled) {
    enableShowItems.value = !enableShowItems.value;
  }

  if (!enableShowItems.value) {
    showFilters.value = false;
  }

  emit('detailSelectionVisible', enableShowItems.value);
}
</script>
