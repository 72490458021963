export default class NumberField {
  static fillUndefinedWith = '-';

  static fieldData(
    value,
    options,
    unit,
    decimals = 0,
    multiplyWith = 1,
    fillUndefined = true,
  ) {
    const fieldData = {
      unit: unit,
      ...options,
    };

    if (value === null || typeof value === 'undefined') {
      if (fillUndefined) {
        fieldData.value = NumberField.fillUndefinedWith;
      }
      return fieldData;
    } else {
      fieldData.value = value * multiplyWith;
      fieldData.value = fieldData.value.toLocaleString('de-DE', {
        maximumFractionDigits: decimals,
      });
    }
    return fieldData;
  }
}
