<template>
  <div
    class="alert-screen-size hidden absolute w-full h-full top-0 justify-center items-center z-[10000]"
  >
    <span class="text-white text-xl text-center p-8"
      >Bitte verwenden Sie einen größeren Bildschirm, um <b>INFRA|Wärme</b> zu
      nutzen.</span
    >
  </div>
</template>

<style scoped lang="scss">
.alert-screen-size {
  background: linear-gradient(
    257.82deg,
    rgb(0, 18, 87) -12.99%,
    rgb(6, 146, 188) 117.03%
  );
  @media screen and (max-width: 767px) {
    display: inline-flex !important;
  }
}
</style>
