export function toObject(arr, key) {
  return arr.reduce((result, item) => {
    return { ...result, [item[key]]: true };
  }, {});
}

export function filterAndSort(
  data,
  {
    textFilterKey = 'district_name',
    unsetFilterKeys,
    textFilter,
    unsetFilter,
    countiesFilter,
    municipalitiesFilter,
  },
) {
  const data2 = filterByCounties(data, countiesFilter);
  const data3 = filterByMunicipalities(data2, municipalitiesFilter);
  const data4 = filterByText(data3, textFilter, textFilterKey);
  const data5 = filterByUnset(data4, unsetFilter, unsetFilterKeys);

  return data5.sort((a, b) => a[textFilterKey].localeCompare(b[textFilterKey]));
}

function filterByCounties(data, filter) {
  return aggregateFilter(data, filter, 'county_id');
}

function filterByMunicipalities(data, filter) {
  return aggregateFilter(data, filter, 'municipality_id');
}

function aggregateFilter(data, filter, key) {
  const filterObject = toObject(filter, key);
  return data.filter((item) => {
    return filterObject[item[key]];
  });
}

function filterByText(data, textFilter, textFilterKey) {
  const filterText = (textFilter || '').trim().toLowerCase();
  return data.filter((item) =>
    item[textFilterKey].toLowerCase().includes(filterText),
  );
}

function filterByUnset(data, unsetFilter, unsetFilterKeys) {
  return data.filter((item) => {
    return !unsetFilter || unsetFilterKeys.some((key) => item[key] == null);
  });
}
