import buildings from './buildings';
import estate from './estate';
import aggregation from './aggregation/aggregation';
import inventoryNetwork from './inventoryNetwork';
import potentials from './potentials';
import scenario from './scenario';
import heatSource from './heatSource';
import heatProject from './heatProject';
import baseData from './baseData/baseData';
import markerIcon from '@/assets/img/mapIcons/marker.png';

export default {
  ...buildings,
  ...estate,
  ...aggregation,
  ...potentials,
  ...scenario,
  ...heatSource,
  ...heatProject,
  ...inventoryNetwork,
  ...baseData,
  marker: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      geoJSONSource: true,
      type: 'symbol',
      id: 'markerSource',
      layerId: 'marker',
      visibility: 'visible',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      icons: [
        {
          name: 'markerIcon',
          path: markerIcon,
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': 'markerIcon',
          'icon-size': 0.1,
          'icon-offset': [0, -165],
        },
      },
    },
  },
};
