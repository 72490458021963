<template>
  <div>
    <div ref="refActivator">
      <slot name="activator" />
    </div>
    <Transition name="fadeTooltip" mode="out-in">
      <Teleport to="body">
        <div
          v-if="modelValue"
          ref="refTooltip"
          :style="positionStyles"
          :class="{
            'moving-popup': baseStyle,
            'z-[1000]': true,
            [customTooltipClasses]: true,
          }">
          <slot name="content" />
        </div>
      </Teleport>
    </Transition>
  </div>
</template>

<script setup>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  fixed: {
    type: String,
    default: null,
    validator: (value) => ['x', 'y', 'all', null].includes(value),
  },
  offsetX: {
    type: Number,
    default: 0,
  },
  offsetY: {
    type: Number,
    default: 0,
  },
  baseStyle: {
    type: Boolean,
    default: false,
  },
  activatorBounds: {
    type: Object,
    default: null,
  },
  customTooltipClasses: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['position']);

const mouseX = ref(0);
const mouseY = ref(0);
const tooltipWidth = ref(0);
const tooltipHeight = ref(0);
const computedActivatorBounds = ref(null);
const refTooltip = ref(null);
const refActivator = ref(null);

const positionStyles = computed(() => {
  const baseStyle = {
    position: 'absolute',
    pointerEvents: 'none',
  };

  if (computedActivatorBounds.value) {
    const screenWidth = window.innerWidth;
    // works only for width (x-axis)
    const doesTooltipFitsScreen =
      screenWidth -
        (props.fixed === 'all'
          ? computedActivatorBounds.value.left
          : mouseX.value) >
      tooltipWidth.value / 2;

    switch (props.fixed) {
      case 'x':
        baseStyle.left = `${
          computedActivatorBounds.value.left -
          tooltipWidth.value -
          props.offsetX
        }px`;
        baseStyle.top = `${
          mouseY.value - tooltipHeight.value / 2 - props.offsetY
        }px`;
        break;
      case 'y':
        baseStyle.left = `${
          mouseX.value -
          props.offsetX -
          (doesTooltipFitsScreen ? tooltipWidth.value / 2 : tooltipWidth.value)
        }px`;
        baseStyle.top = `${
          computedActivatorBounds.value.top -
          tooltipHeight.value -
          props.offsetY
        }px`; // Vertically above the activator
        break;
      case 'all':
        baseStyle.left = `${
          computedActivatorBounds.value.left -
          tooltipWidth.value / 2 -
          props.offsetX -
          (doesTooltipFitsScreen
            ? 0
            : screenWidth - computedActivatorBounds.value.left)
        }px`;
        baseStyle.top = `${
          computedActivatorBounds.value.top -
          tooltipHeight.value -
          props.offsetY
        }px`;
        break;
      default:
        baseStyle.left = `${
          mouseX.value - tooltipWidth.value / 2 - props.offsetX
        }px`;
        baseStyle.top = `${mouseY.value - tooltipHeight.value - props.offsetY}px`;
    }
  }
  return baseStyle;
});

onMounted(async () => {
  await nextTick();
  attachMouseMoveEvent();
  attachScrollEvent();
  attachResizeEvent();

  if (refActivator.value) {
    if (props.activatorBounds) {
      computedActivatorBounds.value = props.activatorBounds;
    } else {
      updateActivatorBounds();
    }
  }
});

onBeforeUnmount(() => {
  removeMouseMoveEvent();
  removeScrollEvent();
  removeResizeEvent();
});

function handleMouseMove(event) {
  mouseX.value = event.clientX;
  mouseY.value = event.clientY;
}

function attachMouseMoveEvent() {
  document.addEventListener('mousemove', handleMouseMove);
}

function removeMouseMoveEvent() {
  document.removeEventListener('mousemove', handleMouseMove);
}

function handleScroll() {
  // Update the activator bounds on scroll to keep the tooltip in place
  updateActivatorBounds();
}

function attachScrollEvent() {
  // Execute as early as possible in the capturing phase (true)
  window.addEventListener('scroll', handleScroll, true);
}

function removeScrollEvent() {
  window.removeEventListener('scroll', handleScroll, true);
}

function handleResize() {
  // Update activator bounds on resize to keep the tooltip in place
  updateActivatorBounds();
}

function attachResizeEvent() {
  window.addEventListener('resize', handleResize);
}

function removeResizeEvent() {
  window.removeEventListener('resize', handleResize);
}

function updateActivatorBounds() {
  if (refActivator.value) {
    computedActivatorBounds.value = refActivator.value.getBoundingClientRect();
  }
}

watch(
  () => props.modelValue,
  async (val) => {
    if (val === true) {
      await nextTick();
      tooltipWidth.value = refTooltip.value.offsetWidth;
      tooltipHeight.value = refTooltip.value.offsetHeight;
    }
  },
  { immediate: true },
);

watch(
  positionStyles,
  (val) => {
    if (val) {
      emit('position', val);
    }
  },
  { immediate: true },
);
</script>

<style lang="scss">
.moving-popup {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
}

.fadeTooltip-enter-active,
.fadeTooltip-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fadeTooltip-enter-from,
.fadeTooltip-leave-to {
  opacity: 0;
}
</style>
