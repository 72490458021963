<template>
  <div ref="moreOptionsContainer" class="relative">
    <div
      :class="classes"
      class="flex whitespace-nowrap items-center justify-center hover:bg-inverted-color1 active:bg-subtle aspect-square rounded overflow-hidden"
      data-test="three-dots"
      @click="toggleTooltip($event)">
      <div
        v-for="dot in dotAmount"
        :key="dot"
        :class="dotClasses"
        class="rounded-full"></div>
    </div>
    <Transition name="fade" mode="out-in">
      <div v-show="showMoreOptions" class="z-[100]">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useListeners } from '../../composables/useListeners';

const props = defineProps({
  background: {
    type: String,
    default: 'bg-active-area',
  },
  small: {
    type: Boolean,
    default: false,
  },
  dotAmount: {
    type: Number,
    default: 3,
  },
  dotColor: {
    type: String,
    default: 'bg-title-color1',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const showMoreOptions = ref(false);
const moreOptionsContainer = ref(null);

useListeners({
  click: onOutsideClick,
  keydown: handleKeydown,
});

const classes = computed(() => [
  props.small
    ? 'w-[18px] h-[18px] p-0.5 gap-[1px]'
    : 'w-[28px] h-[28px] p-1 gap-0.5',
  props.disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer',
  props.background,
]);

const dotClasses = computed(() => [
  props.dotColor,
  props.small ? 'w-[3px] h-[3px]' : 'w-1 h-1',
]);

const toggleTooltip = (event) => {
  event.stopPropagation();
  showMoreOptions.value = !showMoreOptions.value;
};

function onOutsideClick(event) {
  if (
    moreOptionsContainer.value &&
    !moreOptionsContainer.value.contains(event.target)
  ) {
    showMoreOptions.value = false;
  }
}

function handleKeydown(event) {
  if (
    event.key === 'Escape' &&
    moreOptionsContainer.value &&
    !moreOptionsContainer.value.contains(event.target)
  ) {
    showMoreOptions.value = false;
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
