export default {
  SET_SCENARIOS: (state, payload) => {
    state.scenarios = payload.results;
    state.scenarioSelected = state.scenarios[0];
  },
  SET_SCENARIO_DISTRICT_OPTIONS: (state, payload) => {
    state.scenarioDistrictOptions = payload.actions.POST;
  },
  SET_SCENARIO: (state, payload) => {
    state.scenarioSelected = payload;
  },
  SET_SCENARIO_BY_ID: (state, payload) => {
    state.scenarioSelected = state.scenarios.find(
      (e) => e.scenario_id === payload,
    );
  },
  ADD_SCENARIO: (state, payload) => {
    state.scenarios.unshift(payload);
  },
  UPDATE_SCENARIO_PROGRESS: (state, payload) => {
    const scenario = state.scenarios.find((e) => e.scenario_id === payload.sid);
    scenario.progress = payload.progress;
    scenario.detailed_progress = payload.detailedProgress || null;
    scenario.progress_percentage = payload.progressPercentage || null;
  },
  REMOVE_SCENARIO: (state, scenarioId) => {
    const index = state.scenarios.findIndex(
      (e) => e.scenario_id === scenarioId,
    );
    if (index > -1) state.scenarios.splice(index, 1);
  },
};
