import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getDeepModuleIdSetFromIds } from '@/configs/infra-modules';

export const useModuleStore = defineStore('Config', () => {
  const activeModules = ref(new Set());

  function isActive(requiredModule) {
    if (requiredModule == null) {
      return true;
    }
    return activeModules.value.has(requiredModule.id);
  }

  function updateModules(moduleIds) {
    activeModules.value = getDeepModuleIdSetFromIds(moduleIds);
  }

  return { isActive, updateModules };
});
