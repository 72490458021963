import {
  distributionNetwork,
  networkCluster,
  buildingConnections,
} from './districtHeatingUtils';

export const LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS =
  'scenario_building_connections';
export const LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK =
  'scenario_distribution_network';
export const LAYER_KEY__SCENARIO_NETWORK_CLUSTER = 'scenario_network_cluster';

export default {
  [LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS]: buildingConnections(
    LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS,
    true,
  ),
  [LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK]: distributionNetwork(
    LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK,
    true,
  ),
  [LAYER_KEY__SCENARIO_NETWORK_CLUSTER]: networkCluster(
    LAYER_KEY__SCENARIO_NETWORK_CLUSTER,
    true,
    [
      LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS,
      LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK,
    ],
    'Zielszenario: Fernwärmeentwicklung',
    [{ key: 'year_of_technology_switch' }],
  ),
};
