import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

export class HeatProjectParameter {
  constructor() {
    this.parameterSets = [];
    this._parameterSetSelected = null; // Use a private property for the selected parameter set
    this.initializeParameters();
  }

  initializeParameters() {
    this.opex_costs_euro_mwh = 0;
    this.interest_rate = 0;
    this.working_life_sales_year = 0;
    this.funding_rate_percentage = 0;
    this.sales_costs_euro_ha = 0;
    this.revenue = 0;
    this.name = null;
    this.nameNewSet = null;
    this.isStandard = false;
    this.isStandardNewSet = false;
  }

  get parameterSetSelected() {
    if (this._parameterSetSelected !== null) {
      return this._parameterSetSelected;
    }
    const standardSet = this.parameterSets.find((set) => set.standard);
    return standardSet ? standardSet.id : null;
  }

  set parameterSetSelected(id) {
    this._parameterSetSelected = id;
    this.updateParametersById(id);
  }

  get sortedParameterSets() {
    return this.parameterSets.sort((a, b) => a.name.localeCompare(b.name));
  }

  get parameterSetNames() {
    return this.parameterSets.map((set) => set.name);
  }

  async fetchParameterSets() {
    try {
      const response = await axios({
        url: '/api/heatprojects/parameter-set/',
        method: 'GET',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.parameterSets = response.data;
      const standardSet = this.parameterSets.find((set) => set.standard);
      if (standardSet) {
        this.updateParameters(standardSet);
      }
    } catch (error) {
      console.error('Error fetching parameter sets:', error);
    }
  }

  updateParametersById(id) {
    const selectedSet = this.parameterSets.find((set) => set.id === id);
    if (selectedSet) {
      this.updateParameters(selectedSet);
    }
  }

  updateParameters(parameterSet) {
    this.opex_costs_euro_mwh = parameterSet.opex_costs_euro_mwh;
    this.interest_rate = parameterSet.interest_rate;
    this.working_life_sales_year = parameterSet.working_life_sales_year;
    this.funding_rate_percentage = parameterSet.funding_rate_percentage;
    this.sales_costs_euro_ha = parameterSet.sales_costs_euro_ha;
    this.revenue = parameterSet.revenue;
    this.isStandard = parameterSet.standard;
    this.name = parameterSet.name;
  }

  get hasEmptyFields() {
    return [
      this.opex_costs_euro_mwh,
      this.interest_rate,
      this.working_life_sales_year,
      this.funding_rate_percentage,
      this.sales_costs_euro_ha,
      this.revenue,
    ].some((field) => field === null || field === undefined || field === '');
  }

  get hasNoEmptyFields() {
    return [
      this.opex_costs_euro_mwh,
      this.interest_rate,
      this.working_life_sales_year,
      this.funding_rate_percentage,
      this.sales_costs_euro_ha,
      this.revenue,
      this.nameNewSet,
    ].some((field) => field === null || field === undefined || field === '');
  }

  get isUniqueParameterSet() {
    return !this.parameterSets.some(
      (set) =>
        set.opex_costs_euro_mwh === this.opex_costs_euro_mwh &&
        set.interest_rate === this.interest_rate &&
        set.working_life_sales_year === this.working_life_sales_year &&
        set.funding_rate_percentage === this.funding_rate_percentage &&
        set.sales_costs_euro_ha === this.sales_costs_euro_ha &&
        set.revenue === this.revenue,
    );
  }

  async createParameterSet() {
    const payload = {
      name: this.nameNewSet,
      opex_costs_euro_mwh: this.opex_costs_euro_mwh,
      interest_rate: this.interest_rate,
      working_life_sales_year: this.working_life_sales_year,
      funding_rate_percentage: this.funding_rate_percentage,
      sales_costs_euro_ha: this.sales_costs_euro_ha,
      revenue: this.revenue,
      standard: this.isStandardNewSet,
    };

    try {
      const response = await axios({
        url: '/api/heatprojects/parameter-set/',
        method: 'POST',
        data: payload,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      await this.fetchParameterSets();
      this.parameterSetSelected = response.data.id; // Set the new parameter set as selected

      // Reset input name and standard state
      this.nameNewSet = null;
      this.isStandardNewSet = false;
    } catch (error) {
      console.error('Error creating parameter set:', error);
    }
  }

  async updateStandardStatus(id) {
    try {
      await axios({
        url: `/api/heatprojects/parameter-set/${id}/`,
        method: 'PATCH',
        data: { standard: true },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      await this.fetchParameterSets();
    } catch (error) {
      console.error('Error updating standard status:', error);
    }
  }

  async deleteParameterSet(id) {
    try {
      await axios({
        url: `/api/heatprojects/parameter-set/${id}/`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      await this.fetchParameterSets();
      // If the deleted set was selected, set the selected set to the standard set
      if (this._parameterSetSelected === id) {
        const standardSet = this.parameterSets.find((set) => set.standard);
        this.parameterSetSelected = standardSet ? standardSet.id : null;
      }
    } catch (error) {
      console.error('Error deleting parameter set:', error);
    }
  }

  resetFields() {
    this.updateParametersById(this.parameterSetSelected);
    // reset fields
    this.nameNewSet = null;
    this.isStandardNewSet = false;
  }
}
