import { LAYERS } from '@/features/heat-project/constants';

export const SNAP_RADIUS_KM = {
  _radius: 0.01,
  get radius() {
    return this._radius;
  },
  set radius(value) {
    if (typeof value !== 'number') this._radius = 0.01;
    else this._radius = value;
  },
};

export const SET_PROPERTIES = {};
