export const infraModules = {
  baseMap: {
    id: 'base',
  },
  districtHeating: {
    id: 'district-heating',
  },
  scenarios: {
    id: 'scenarios',
  },
  scenarioFrames: {
    id: 'scenario-frames',
  },
  potentials: {
    id: 'potentials',
  },
  projectPlaner: {
    id: `project-planer`,
  },
  wire: {
    id: 'wire',
  },
  documentExport: {
    id: 'documents',
  },
  dashboard: {
    id: 'dashboard',
  },
  all: {
    id: 'all',
  },
};

infraModules.baseMap.requiredModules = [];
infraModules.districtHeating.requiredModules = [infraModules.baseMap];
infraModules.scenarios.requiredModules = [infraModules.baseMap];
infraModules.scenarioFrames.requiredModules = [infraModules.baseMap];
infraModules.potentials.requiredModules = [infraModules.baseMap];
infraModules.projectPlaner.requiredModules = [infraModules.baseMap];
infraModules.wire.requiredModules = [infraModules.baseMap];
infraModules.documentExport.requiredModules = [infraModules.baseMap];
infraModules.dashboard.requiredModules = [infraModules.baseMap];

infraModules.all.requiredModules = [
  infraModules.districtHeating,
  infraModules.scenarios,
  infraModules.scenarioFrames,
  infraModules.potentials,
  infraModules.projectPlaner,
  infraModules.wire,
  infraModules.documentExport,
];

const modulesByIdMap = new Map();
for (const module of Object.values(infraModules)) {
  modulesByIdMap.set(module.id, module);
}

function addDeepModuleIdsToSet(module, moduleSet) {
  moduleSet.add(module.id);
  for (const requiredModule of module.requiredModules) {
    addDeepModuleIdsToSet(requiredModule, moduleSet);
  }
  return moduleSet;
}

export function getDeepModuleIdSetFromIds(moduleIds) {
  const deepModuleSet = new Set();
  for (const moduleId of moduleIds) {
    const module = modulesByIdMap.get(moduleId);
    if (module == null) {
      console.error('Unknown module: ', moduleId);
      continue;
    }
    addDeepModuleIdsToSet(module, deepModuleSet);
  }
  return deepModuleSet;
}
