import { createStore } from 'vuex';
import auth from './modules/auth/index';
import layout from './modules/layout/index';
import map from '../features/map/store';
import root from './modules/index';
import search from '../features/map/control-options/search/store';
import scenario from '../apps/scenarios/store';
import heatProject from '../features/heat-project/store';
import dashboard from '../apps/analysis-dashboard/store';
import building from '../features/building-versioning/store';
import task_manager from '../apps/task-manager/store';

export default createStore({
  modules: {
    auth,
    layout,
    map,
    root,
    search,
    heatProject,
    scenario,
    dashboard,
    building,
    task_manager,
  },
});
