const WIRE_APP = 'heatprojects_wirtschaftlichkeit';

export default {
  profil: (state) => state.user,
  hasPermission: (state) => {
    return (app, perm) =>
      state.user.isSuperUser || state.user.permissions[app].has(perm);
  },
  wireCanAddWire(state, getters) {
    return getters.hasPermission(WIRE_APP, 'add_wire');
  },
  wireCanChangeWire(state, getters) {
    return getters.hasPermission(WIRE_APP, 'change_wire');
  },
  wireCanDeleteWire(state, getters) {
    return getters.hasPermission(WIRE_APP, 'delete_wire');
  },
  wireCanDownload(state, getters) {
    return getters.hasPermission(WIRE_APP, `export_data_${WIRE_APP}`);
  },
  wireCanChangeEstateConnection(state, getters) {
    return getters.hasPermission(WIRE_APP, 'change_wireestateconnection');
  },
  wireCanRequestRelease(state, getters) {
    return getters.hasPermission(WIRE_APP, `request_release_${WIRE_APP}`);
  },
  wireCanChangeRelease(state, getters) {
    return getters.hasPermission(WIRE_APP, `change_release_${WIRE_APP}`);
  },
};
