<template>
  <v-file-input
    ref="fileupload"
    show-size
    accept="image/png, image/jpeg, image/bmp"
    hint="Bild auswählen"
    :persistent-hint="true"
    prepand-icon="mdi-camera"
    variant="outlined"
    density="compact"
    @update:model-value="$emit('emitImage', $event[0])"
  />
</template>

<script>
export default {
  props: {
    image: {
      type: File,
    },
  },
  emits: ['emitImage'],
};
</script>
