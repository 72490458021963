<template>
  <div
    class="flex flex-col px-2.5 py-[6px] gap-2.5"
    :class="{
      'bg-infra-highlight-25 pb-3': systemTypeSelected === systemType.id,
      'hover:bg-infra-highlight-50 cursor-pointer': !disabled,
    }"
    @click="updateSystemType"
  >
    <div class="flex justify-between gap-2 items-center">
      <VRadio :disabled="disabled" :density="null" :value="systemType.id">
        <template #label>
          <h6 class="text-infra-highlight-700 mt-0.5 ml-1">
            {{ systemType.label }}
          </h6>
          <ToolTipOnHover
            :tooltip-text="tooltipMap[systemType.label]"
            :icon-size="20"
            class="ml-0.5 mb-0.5"
          />
        </template>
      </VRadio>
      <Component
        :is="icon"
        :class="{ 'opacity-30': disabled }"
        :fill="systemTypeSelected === systemType.id ? '#01799D' : 'initial'"
      />
    </div>
    <!-- required params-->
    <template v-if="systemTypeSelected === systemType.id">
      <div
        class="flex flex-col gap-2 p-2.5 bg-white rounded-[4px] standard-elevation-0-dark"
      >
        <slot name="required-params" />
      </div>

      <!-- optional params -->
      <div
        v-if="optionalParams"
        class="flex flex-col gap-2 standard-elevation-0-dark p-2 rounded-[4px] bg-white"
      >
        <div>
          <ButtonEl
            variant="secondary"
            :full-width="true"
            :text="
              optionalParameters
                ? 'Optionale Parameter ausblenden'
                : 'Optionale Parameter einblenden'
            "
            :icon="optionalParameters ? 'north' : 'south'"
            size="m"
            @click="optionalParameters = !optionalParameters"
          />
        </div>
        <div v-if="optionalParameters" class="flex flex-col gap-2">
          <slot name="optional-params" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';

const props = defineProps({
  systemType: {
    type: Object,
    required: true,
  },
  systemTypeSelected: {
    type: String,
    required: true,
  },
  optionalParams: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'EditPointHeatSourceFilled',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['systemTypeSelected']);

const optionalParameters = ref(false);

function updateSystemType() {
  if (!props.disabled) {
    emit('systemTypeSelected', props.systemType.id);
  }
}

const tooltipMap = {
  Wärmequelle:
    'Einzelne Anlage, die direkt Wärme in das Wärmenetz einspeisen kann',
  Heizzentrale:
    'Fasst die Wärme aus mehreren Wärmequellen zusammen und speist diese in das Netz ein',
  Aufspeisepunkt:
    'Punkt für Anschluss von einem neuen Fernwärmenetz an das Bestandsnetz',
};
</script>
