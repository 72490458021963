<template>
  <div class="w-fit">
    <component
      :is="tooltipIcon"
      :fill="tooltipIconFill"
      class="cursor-pointer" />
    <v-tooltip activator="parent" location="end">
      <div class="subtitle-2 text-white">
        <slot name="text" />
        {{ tooltipText }}
      </div>
    </v-tooltip>
  </div>
</template>
<script>
import HelpIcon from '../../assets/icons/help-icon.vue';

export default {
  components: {
    HelpIcon,
  },
  props: {
    tooltipIcon: {
      default: HelpIcon,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    tooltipIconFill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
