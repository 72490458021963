export const statusMapping = {
  0: {
    value: 0,
    label: 'Offen',
    display: {
      requestedInfoClasses: '',
      infoBoxBackground: 'bg-subtle',
      icon: 'info',
      color: 'text-neutral',
      decisionText: 'Freigabe wurde angefordert',
    },
  },
  1: {
    value: 1,
    label: 'Angefordert',
    display: {
      requestedInfoClasses: '',
      infoBoxBackground: 'bg-subtle',
      icon: 'info',
      color: 'text-neutral',
      decisionText: 'Freigabe wurde angefordert',
    },
  },
  2: {
    value: 2,
    label: 'Genehmigt',
    display: {
      requestedInfoClasses: 'border-b border-line-neutral pb-[30px]',
      infoBoxBackground: 'bg-fill-success',
      icon: 'check',
      color: 'text-spot-success',
      decisionText: 'Freigabe wurde genehmigt',
      decisionVerb: 'erteilt',
    },
  },
  3: {
    value: 3,
    label: 'Abgelehnt',
    display: {
      requestedInfoClasses: 'border-b border-line-neutral pb-[30px]',
      infoBoxBackground: 'bg-fill-error',
      icon: 'close',
      color: 'text-error',
      decisionText: 'Freigabe wurde abgelehnt',
      decisionVerb: 'abgelehnt',
    },
  },
  4: {
    value: 4,
    label: 'Alle',
  },
};
