import { LAYERS } from '@/features/heat-project/constants';

export const LAYER_KEY__HEAT_PROJECT_NETWORK = 'heatProjectNetwork';
export const LAYER_KEY__HEAT_PROJECT_CENTROID = 'heatProjectCentroid';
export const LAYER_KEY__HEAT_PROJECT_ESTATE = 'heatProjectEstate';

import projectClusterIcon from '@/assets/img/mapIcons/project-cluster.png';

export default {
  [LAYER_KEY__HEAT_PROJECT_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__HEAT_PROJECT_NETWORK,
      layerId: LAYER_KEY__HEAT_PROJECT_NETWORK,
      geoJSONSource: true,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
    },
    sidebar: {
      name: 'Verteilnetzsegment',
      subtitles: [],
      chips: [
        {
          title: '',
          active: true,
          items: [
            {
              title: 'Netzsegmentinformationen',
              active: true,
              items: [
                {
                  key: 'flow_velocity',
                  unit: 'm/s',
                  decimal: 2,
                },
                {
                  key: 'volume_flow',
                  unit: 'm³/h',
                  decimal: 2,
                },
                {
                  key: 'pressure_loss',
                  unit: 'Pa/m',
                  decimal: 2,
                },
                {
                  key: 'specific_heat_loss',
                  unit: 'W/m',
                  decimal: 2,
                },
                {
                  key: 'temperature_in',
                  unit: '°C',
                  decimal: 4,
                },
                {
                  key: 'temperature_out',
                  unit: '°C',
                  decimal: 4,
                },
              ],
            },
            {
              title: 'Rohrinformationen',
              active: true,
              items: [
                {
                  key: 'pipe_description',
                  unit: '',
                },
                {
                  key: 'pipe_inner_diameter',
                  unit: 'mm',
                  decimal: 2,
                },
                {
                  key: 'pipe_outer_diameter',
                  unit: 'mm',
                  decimal: 2,
                },
                {
                  key: 'pipe_friction_coefficient',
                  unit: '',
                  decimal: 2,
                },
                {
                  key: 'pipe_thermal_conductivity',
                  unit: 'W/mK',
                  decimal: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'not-in-year-range'], false],
          '#ababab',
          [
            'match',
            ['get', 'type'],
            LAYERS.HOME_CONNECTION,
            [
              'case',
              ['boolean', ['feature-state', 'clicked'], false],
              '#850000',
              '#ff9500',
            ],
            // default color for networks which are not HOME_CONNECTION
            [
              'case',
              ['boolean', ['feature-state', 'clicked'], false],
              '#850000',
              [
                'interpolate',
                ['linear'],
                ['get', 'inner_diameter'],
                0,
                '#E0F7FA',
                100,
                '#006064',
              ],
            ],
          ],
        ],
        lineWidth: [
          'interpolate',
          ['linear'],
          ['get', 'inner_diameter'],
          10,
          2,
          200,
          6,
        ],
      },
    },
    legend: {
      default: {
        name: 'Projektgebiet',
        subTitle: `Innendurchmesser Versorgungsnetz`,
        items: [
          {
            type: 'gradient',
            items: {
              unit: 'mm',
              id: `Innendurchmesser Versorgungsnetz`,
              min: {
                value: 0,
                color: '#E0F7FA',
              },
              max: {
                value: 100,
                color: '#006064',
              },
            },
          },
          {
            color: '#ff9500',
            name: 'Hausanschlussleitung',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__HEAT_PROJECT_CENTROID]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'heatprojects',
      router_name: 'HeatProject',
      key_name: 'id',
      type: 'symbol',
      id: LAYER_KEY__HEAT_PROJECT_CENTROID,
      layerId: LAYER_KEY__HEAT_PROJECT_CENTROID,
      geoJSONSource: true,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      icons: [
        {
          name: 'project-cluster',
          path: projectClusterIcon,
        },
      ],
    },
    sidebar: {},
    style: {
      default: {
        layout: {
          'icon-image': 'project-cluster',
          'icon-size': 0.3,
          'icon-allow-overlap': true,
          visibility: 'none',
        },
        iconOpacity: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          1,
          ['boolean', ['feature-state', 'not-in-year-range'], false],
          0.7,
          0.9,
        ],
      },
    },
    legend: {
      default: {
        name: 'Projektgebiet',
        unit: '',
        items: [
          {
            name: 'project-cluster',
            path: projectClusterIcon,
          },
        ],
      },
    },
  },
  [LAYER_KEY__HEAT_PROJECT_ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__HEAT_PROJECT_ESTATE,
      layerId: LAYER_KEY__HEAT_PROJECT_ESTATE,
      geoJSONSource: true,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
    },
    style: {
      default: {
        circleColor: 'rgba(0,161,209,0)',
        circleStrokeWidth: 3,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'not-in-year-range'], false],
          '#ababab',
          ['==', ['get', 'isConnected'], true],
          '#00A1D1',
          'rgba(0,161,209,0.34)',
        ],
        circleRadius: 3,
      },
    },
    legend: {
      default: {
        name: `Projektgebiet`,
        unit: '',
        items: [
          {
            color: '#00A1D1',
            name: 'Flurstück Angeschlossen',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: 'rgba(0,161,209,0.34)',
            name: 'Flurstück nicht angeschlossen',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
