export function getCustomFilterPayload(year, filter) {
  const featureWithYear = ['get', `${filter.feature}_${year}`];
  return {
    layerKeys: filter.onLayers,
    filter: {
      id: `${filter.feature}_filter`,
      filter: [
        'all',
        ['<', filter.minValue, featureWithYear],
        ['>', filter.maxValue, featureWithYear],
      ],
    },
  };
}
