<template>
  <div
    v-if="base !== 0"
    class="flex flex-col w-full items-center py-7 chartItem h-full"
  >
    <h5 class="mb-7 text-center">{{ chartTitle }}</h5>
    <div
      :class="
        totalReduction === 0
          ? 'bg-blue-grey-200'
          : totalReduction >= 0
            ? 'bg-green'
            : 'bg-red'
      "
      class="flex items-center justify-center flex-col w-full max-w-[175px] h-24 text-white rounded-[6px]"
    >
      <h4 class="text-white">
        {{ formatValue(totalReduction) + ' %' }}
      </h4>
      <h6 class="text-white">
        {{ formatValue(sumValuesSavingsCurrentYear) + ' ' + chartUnit }}
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TotalReduction',
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    chartTitle: {
      type: String,
      default: 'Gesamtreduktion zu Status Quo',
    },
    chartUnit: {
      type: String,
      default: 'GWh',
    },
    baseValue: {
      type: Array,
      default: () => [],
    },
    multiply: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      base: 0,
    };
  },
  computed: {
    sumValuesSavingsCurrentYear() {
      return this.chartData
        .map((item) => item.value / this.multiply)
        .reduce((a, b) => a + b, 0);
    },
    totalReduction() {
      const reduction = this.sumValuesSavingsCurrentYear / this.base;
      return reduction * 100;
    },
  },
  mounted() {
    this.sumValuesBase();
  },
  methods: {
    sumValuesBase() {
      this.base = this.baseValue
        .map((item) => item.value / this.multiply)
        .reduce((a, b) => a + b, 0);
    },
    formatValue(value) {
      return value.toLocaleString('de-DE', {
        maximumFractionDigits: 1,
      });
    },
  },
};
</script>
